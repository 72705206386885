import React, {
  useState,
  FunctionComponent,
  useContext,
  useRef,
} from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { UIProps } from '../../types';
import { InputWrapper } from '../../styles/ui-controls';
import { DropdownElement, InputField } from '../../elements';
import { ReactComponent as ProgressIcon } from '../../assets/ipchange-icon.svg';
import { ReactComponent as ClearIcon } from '../../assets/remove_icon_active.svg';
import iconSearchActive from '../../assets/search-icon.svg';
import iconSearchInActive from '../../assets/icon-search-inactive.svg';
import { ConnectionViewContext } from '../../context/ConnectionViewContext';
import { getOptions } from '../../constants';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

interface ConnectionSearchProps {
  onChange?: Function;
}

const debounceCall = debounce(
  (callback) => {
    callback();
  },
  50,
  {
    leading: false,
    trailing: true,
  },
);

export const ConnectionSearch: FunctionComponent<ConnectionSearchProps> = (
  props,
) => {
  const { onChange = () => {} } = props;

  const context = useContext(ConnectionViewContext);

  const [keyword, setKeyword] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [activeField, setActiveField] = useState(false);
  const [affiliation, setAffiliation] = useState(
    getOptions().affiliationOptions[0],
  );
  const ref = useRef(null);

  return (
    <Wrapper>
      <FormContainer>
        <InputContainer>
          <DropdownContainer>
            <DropdownElement
              value={affiliation}
              isSearchable={false}
              onSelected={(value) => {
                setAffiliation(value);
                context.setAffiliation(value);
              }}
              options={getOptions().affiliationOptions}
            />
          </DropdownContainer>
          <InputWrapper ref={ref}>
            <Icon icon={activeField ? iconSearchActive : iconSearchInActive} />
            <InputField
              value={keyword}
              onBlur={() =>
                setActiveField(false)}
              onFocus={() =>
                setActiveField(true)}
              style={{ paddingRight: '50px', paddingLeft: '30px' }}
              placeholder="Search.."
              error={false}
              errorMsg=""
              onChange={({ target }) => {
                setKeyword(target?.value);
                setShowProgress(true);

                debounceCall(() => {
                  setShowProgress(false);
                  onChange(target?.value);
                });
              }}
            />
            <IconWrapper>
              <Progress showProgress={showProgress} />
              <Clear
                showClear={keyword.length > 0}
                onClick={() => {
                  setKeyword('');
                  onChange('');
                  ref?.current?.getElementsByTagName('input')[0].focus();
                }}
              />
            </IconWrapper>
          </InputWrapper>
        </InputContainer>
      </FormContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const FormContainer = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const DropdownContainer = styled.div`
  width: 20%;
  z-index: 2;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

const InputContainer = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

interface ProgressProps {
  showProgress: boolean;
}

const Progress = styled(ProgressIcon)<ProgressProps>`
  opacity: ${(props) =>
    (props.showProgress ? 1 : 0)};

  * {
    fill: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  }
`;

interface ClearProps {
  showClear: boolean;
}

const Clear = styled(ClearIcon)<ClearProps>`
  cursor: pointer;
  opacity: ${(props) =>
    (props.showClear ? 1 : 0)};

  &:hover {
    opacity: 0.7;
  }
`;

interface IconProps {
  icon?: string;
}
const Icon = styled.div<IconProps>`
  left: 5px;
  position: absolute;
  z-index: 1;
  background-image: url(${(props: IconProps) =>
    props?.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 50%;
  transform: translateY(-51%);
  width: 20px;
  height: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  position: absolute;
  top: 12%;
  right: 15px;
`;
