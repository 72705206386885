import React, { useState, useEffect, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import store from '../../../../core/store/store';
import { CheckBox } from '../../../atoms/Checkbox';
import { DropdownElement } from '../../../../elements';
import { SelectOption } from '../../../../types';
import { updateConnection } from '../../../../core/store/actions/connections';
import { Phone } from '../../../../types/phones';
import { ButtonLoader } from '../../../atoms';
import { WhitelistIpInput } from '../../../common/WhitelistIpInput';
import { VideoInstructionBlock } from '../../../../styles/ui-controls';
import { getGlobalDNSLink } from '../../../../utils/instruction-links';
import {
  ButtonWrapper,
  ElementWrapper,
  FormWrapper,
  Wrapper,
} from './Common';
import { windowOpen } from '../../../../utils';

interface DNSAtomProps {
  phone?: Phone;
  setIpList?: Function;
}

export const DNSAtom: FunctionComponent<DNSAtomProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { phone, setIpList } = props;

  const dnsOptions: SelectOption<any>[] = [
    {
      value: 'custom',
      label: 'Custom DNS',
    },
    {
      value: '1.1.1.1',
      label: 'Cloudflare DNS -> 1.1.1.1',
    },
    {
      value: '8.8.8.8',
      label: 'Google DNS -> 8.8.8.8',
    },
  ];

  const [enableCustomeDNS, setEnableCustomeDNS] = useState(
    phone?.dnsIpList?.length > 0,
  );
  const [dnsOption, setDnsOption] = useState(dnsOptions[0]);
  const [customDNS, setCustomDNS] = useState([]);
  const [isChangedOpenVPNDns, setIsChangedOpenVPNDns] = useState(false);

  useEffect(() => {
    if (phone != null) {
      const ipList = phone?.dnsIpList;

      if (ipList != null && ipList?.length > 0) {
        const dnopt = dnsOptions?.find((item) =>
          item?.value === ipList[0]);
        setCustomDNS(ipList);

        if (dnopt) {
          setDnsOption(dnopt);
        }
      }
    }
  }, [phone]);

  useEffect(() => {
    if (setIpList) {
      setIpList(
        enableCustomeDNS
          ? dnsOption?.value === 'custom'
            ? customDNS
            : [dnsOption?.value]
          : [],
      );
    }
  }, [customDNS, enableCustomeDNS, dnsOption?.value]);

  const save = () => {
    const ipList = enableCustomeDNS
      ? dnsOption?.value === 'custom'
        ? customDNS
        : [dnsOption?.value]
      : [];

    const payload = {
      dnsIpList: ipList,
    };

    setIsChangedOpenVPNDns(true);

    store.dispatch(
      updateConnection.request({
        id: phone.id,
        ...payload,
      }),
    );

    setIsChangedOpenVPNDns(false);
  };

  return (
    <Wrapper>
      <CheckboxWrapper>
        <CheckBox
          hasLabel
          value={enableCustomeDNS}
          label={`${t('proSettings.selectDNS')}`}
          onClick={(status) => {
            setEnableCustomeDNS(status);
            setCustomDNS([]);
            if (!status) {
              store.dispatch(
                updateConnection.request({
                  id: phone.id,
                  dnsIpList: [],
                }),
              );
            }
          }}
        />
        <VideoInstructionBlock
          onClick={() =>
            windowOpen(getGlobalDNSLink(i18n.language))}
        >
          {t('proSettings.instruction')}
        </VideoInstructionBlock>
      </CheckboxWrapper>
      {enableCustomeDNS && (
        <FormWrapper>
          <ElementWrapper>
            <DropdownElement
              disabled={!enableCustomeDNS}
              value={dnsOption}
              onSelected={(value) => {
                setDnsOption(value);
                setCustomDNS([]);
              }}
              options={dnsOptions}
            />
          </ElementWrapper>
          {dnsOption?.value === 'custom' ? (
            <ElementWrapper>
              <WhitelistIpInput
                placeholder="Set DNS IP address"
                onListChange={(list) =>
                  setCustomDNS(list || [])}
                ipList={customDNS?.join('\n')}
                disabled={!enableCustomeDNS}
                noLabel
              />
            </ElementWrapper>
          ) : null}
          {!setIpList && (
            <ButtonWrapper>
              <ButtonLoader
                size="small"
                onClick={() =>
                  save()}
                showProgress={isChangedOpenVPNDns}
              >
                {t('editForm.apply')}
              </ButtonLoader>
            </ButtonWrapper>
          )}
        </FormWrapper>
      )}
    </Wrapper>
  );
};

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
