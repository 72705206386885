import React, { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { LINK_REL, TARGET_BLANK } from '../../utils/constants';
import { UIProps } from '../../types';

interface MarkdownContainerProps {
  maxWidth?: string;
  color?: string;
  fontSize?: string;
}

interface ReactMDProps extends MarkdownContainerProps {
  markdown: string;
}

interface CustomLinkProps {
  children?: React.ReactNode;
}

const CustomLink: FunctionComponent<CustomLinkProps> = ({ children, ...props }) =>
  (
    <a {...props} target={TARGET_BLANK} rel={LINK_REL}>
      {children}
    </a>
  );

const customComponents = {
  a: (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) =>
    <CustomLink {...props} />,
};

export const ReactMD: FunctionComponent<ReactMDProps> = ({
  markdown,
  maxWidth,
  color,
  fontSize,
}) =>
  (
    <MarkdownContainer
      maxWidth={maxWidth}
      color={color}
      fontSize={fontSize}
    >
      <ReactMarkdown components={customComponents}>
        {markdown}
      </ReactMarkdown>
    </MarkdownContainer>
  );

const MarkdownContainer = styled.div<MarkdownContainerProps>`
  p, h1, h2, h3, ul, ol {
    margin: 0;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};
    max-width: ${(props) =>
    props.maxWidth};
    color: ${(props) =>
    props.color || 'inherit'};
    ${(props) =>
    props.fontSize};
  }
`;
