import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { BasicButton } from '../styles/ui-controls';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import iconRemove from '../assets/phone_icons/remove_icon.svg';
import { Phone } from '../types/phones';
import { UIProps } from '../types';

interface PhonesScrollListProps {
  phonesId: string[];
  actionText: string;
  isValid: boolean;
  onActionSubmit: (ids: string[]) => void;
  additionalBlock?: React.ReactNode;
}

export const PhonesScrollList: FunctionComponent<PhonesScrollListProps> = (
  props: PhonesScrollListProps,
) => {
  const {
    phonesId, actionText, isValid, onActionSubmit, additionalBlock,
  } = props;

  const { t } = useTranslation();
  const [selectedPhones, setSelectedPhones] = useState<Phone[] | null>(null);
  const phones = useTypedSelector(({ connections }) =>
    connections);
  const { id } = useTypedSelector(({ user }) =>
    user?.profile);

  useEffect(() => {
    if (phonesId != null) {
      getPhoneList();
    }
  }, [phonesId]);

  const getPhoneList = () => {
    const filteredPhones = phones?.data?.filter((i) =>
      phonesId.includes(i?.id) && i.userId === id);
    setSelectedPhones(filteredPhones);
  };

  const removePhone = (phoneId) => {
    const list = selectedPhones?.filter((i) =>
      i.id !== phoneId);
    setSelectedPhones(list as any);
  };

  return (
    <>
      <div className="scroll-container">
        <Scrollbars>
          <div>
            {selectedPhones != null ? (
              <GroupsList>
                {selectedPhones?.map((phone) =>
                  (
                    <div className="item" key={`phone_list_${phone?.id}`}>
                      {phone?.name}
                      <div
                        className="close"
                        onClick={() => {
                          removePhone(phone?.id);
                        }}
                      />
                    </div>
                  ))}
              </GroupsList>
            ) : null}
          </div>
        </Scrollbars>
        <div className="total-goups">
          {`${t('connection.total')}: ${
            selectedPhones?.length != null ? selectedPhones?.length : 0
          }`}
        </div>

        {!additionalBlock && (
          <div className="savebt">
            <BasicButton
              size="small"
              disable={!isValid}
              onClick={() => {
                if (isValid) {
                  onActionSubmit(selectedPhones?.map((i) =>
                    i?.id));
                }
              }}
            >
              {actionText}
            </BasicButton>
          </div>
        )}
      </div>
      {additionalBlock ?? null}
      {additionalBlock && (
        <div className="savebt">
          <BasicButton
            size="small"
            disable={!isValid}
            onClick={() => {
              if (isValid) {
                onActionSubmit(selectedPhones?.map((i) =>
                  i?.id));
              }
            }}
          >
            {actionText}
          </BasicButton>
        </div>
      )}
    </>
  );
};

const GroupsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .item {
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerMedium};
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid ${(props: UIProps) =>
    props.theme.colors.paleBlue};
    min-height: 15px;
    :hover {
      background: rgba(180, 180, 180, 0.15);
      .close {
        display: block;
      }
    }
  }

  .close {
    display: none;
    position: absolute;
    width: 10px;
    height: 10px;
    cursor: pointer;
    top: 13px;
    right: 15px;
    background-image: url(${iconRemove});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;
