import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { ModalComponent } from '../components';
import { UIProps } from '../types';
import { TopMenuComponent } from '../components/common/TopMenuComponent';
import { FooterTemplate } from '../components/common/FooterTemplate';
import { BREAKPOINT_MOBILE } from '../utils/constants';

const Wrapper = styled.div`
  max-width: ${(prop: UIProps) =>
    prop.theme.sizes.maxWidth};
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  position: relative;
  height: 100%;
  min-height: calc(100vh - 110px - 225px);

  .Toastify__toast--info {
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.greenMud};
  }
`;

interface HeaderProps extends UIProps {
  expanded: boolean;
}

const Header = styled.div<HeaderProps>`
  background-color: ${(props) =>
    (props.expanded ? props.theme.colors.lightOcean : props.theme.colors.deepBlue)};
  height: 220px;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
`;

const MainContent = styled.div`
  width: 100%;
  margin-top: -250px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

const AppContent = styled.div`
  width: 100%;
  background-color: ${(props: UIProps) =>
    props.theme.colors.white};
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.mediumZero};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};
`;

export interface PublicTemplateProps {
  children?: any;
}

export const PublicTemplate: FunctionComponent<PublicTemplateProps> = (props) => {
  const { children } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Header expanded={expanded} />
      <Wrapper>
        <ModalComponent />
        <ToastContainer />
        <MainContent>
          <TopMenuComponent
            isPublic
            expanded={expanded}
            onExpanded={setExpanded}
          />
          <AppContent>
            {children}
          </AppContent>
        </MainContent>
      </Wrapper>
      <FooterTemplate />
    </>
  );
};
