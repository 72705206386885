import { CITY_MAP, COUNTRY_MAP } from '../constants';
import { ServerListZone } from '../types';

interface Country {
  name: string;
  code: string;
}

export const getCityByCode = (code: string): string =>
  CITY_MAP[code];

export const getCountryByCode = (code: string): Country =>
  COUNTRY_MAP.find(
    (i: Country) =>
      i.code.toLowerCase() === code.toLowerCase(),
  );

export const getSortCountries = ({
  codes,
  servers,
  noInclude = false,
  isSortIndexFirst = false,
}: {
  servers: ServerListZone[];
  codes: string[];
  noInclude?: boolean;
  isSortIndexFirst?: boolean;
}) =>
  servers
    ?.filter((server) => {
      const isInclude = codes.includes(server.zone?.split('-')[0]);
      return noInclude ? !isInclude : isInclude;
    })
    ?.sort((a, b) => {
      const defaultSort = a.zone?.split('-')[1] > b.zone?.split('-')[1] ? 1 : -1;
      return isSortIndexFirst
        ? codes.indexOf(a.zone?.split('-')[0])
          === codes.indexOf(b.zone?.split('-')[0])
          ? defaultSort
          : codes.indexOf(a.zone?.split('-')[0])
            - codes.indexOf(b.zone?.split('-')[0])
        : defaultSort;
    });
