import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface FaqScreenProps {}

export const FaqScreen: FunctionComponent<FaqScreenProps> = () =>
  (
    <Wrapper>faq</Wrapper>
  );
const Wrapper = styled.div``;
