import React, {
  useState,
  useEffect,
  FunctionComponent,
  useMemo,
  useContext,
} from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';

import moment from 'moment';
import { isEmpty } from 'lodash';
import ProgressBar from '@ramonak/react-progress-bar';
import store from '../../core/store/store';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import {
  NewPhone,
  RegionType,
  RegionTypeLabel,
  ServerListZone,
  UIProps,
} from '../../types';
import {
  BasicButton,
  InputWrapper,
  WrapperScreen,
} from '../../styles/ui-controls';
import {
  createNewConnection,
  loadUserConnections,
  loadUserPhones,
} from '../../core/store/actions/connections';
import {
  getSortCountries,
  getUrlParameterByName,
  windowOpen,
} from '../../utils';
import { InputField } from '../../elements';
import { BaseModalFormParams } from '../../modal';
import { useModalHook } from '../../hooks';
import iconLink from '../../assets/link_icon.svg';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { ModalTitle } from '../common/ModalTitle';
import { Api } from '../../core/api';
import theme from '../../styles/ApplicationTheme';
import {
  AmericaCountryCodes,
  AsiaCountryCodes,
  AustraliaCountryCodes,
} from '../../constants/geo';
import { getFAQLink, getServerLocationLink } from '../../utils/instruction-links';
import { updatePhonesGroup } from '../../core/store/actions/app';
import { loadServersList } from '../../core/store/actions/servers';
import { ConnectionViewContext } from '../../context/ConnectionViewContext';
import {
  LINK_REL,
  NON_GROUP,
  BREAKPOINT_MOBILE,
  TARGET_BLANK,
  telegramSupport,
  BREAKPOINT_TABLET,
} from '../../utils/constants';
import { FlagComponent } from '../common/FlagComponent';

interface AddPhoneFormProps extends BaseModalFormParams {}

export const AddPhoneForm: FunctionComponent<AddPhoneFormProps> = () => {
  const context = useContext(ConnectionViewContext);
  const moveToNonGroup = () =>
    context?.setGroupKey(NON_GROUP);

  const phoneConnections = useTypedSelector(
    ({ connections }) =>
      connections.data,
  );
  const data = useTypedSelector(
    ({ servers }) =>
      servers.data,
  );
  const servers: ServerListZone[] = useMemo(() =>
    data, [data]);
  const profile = useTypedSelector(({ user }) =>
    user.profile);
  const modalOptions = useModalHook();
  const [isProcessed, setIsProcessed] = useState(false);
  const [disableCreation, setDisableCreation] = useState(false);
  const [expiredTariffExist, setExpiredTariffExist] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [typeCreateActiveTab, setTypeCreateActiveTab] = useState(0);
  const [region, setRegion] = useState(RegionType.All);

  const [inputQuantity, setInputQuantity] = useState(1);
  const [quantity, setQuantity] = useState(1);

  const [numbering, setNumbering] = useState(1);

  const [count, setCount] = useState(0);

  const [progressView, setProgressView] = useState(false);

  const [connection, setConnection] = useState<NewPhone>({
    name: null,
    slaveServerId: null,
    userId: null,
    description: null,
  });

  const { t, i18n } = useTranslation();

  const sortServers = useMemo(() => {
    const americaCountry = getSortCountries({
      servers,
      codes: AmericaCountryCodes,
      isSortIndexFirst: true,
    });

    const asiaCountry = getSortCountries({ codes: AsiaCountryCodes, servers });

    const australiaCountry = getSortCountries({
      codes: AustraliaCountryCodes,
      servers,
    });

    const europeCountry = getSortCountries({
      servers,
      codes: AsiaCountryCodes?.concat(
        AustraliaCountryCodes,
        AmericaCountryCodes,
      ),
      noInclude: true,
    });

    const contries = {
      [RegionType.America]: americaCountry,
      [RegionType.All]: americaCountry?.concat(
        europeCountry,
        australiaCountry,
        asiaCountry,
      ),
      [RegionType.Asia]: asiaCountry,
      [RegionType.Australia]: australiaCountry,
      [RegionType.Europe]: europeCountry,
    };

    return contries[region];
  }, [region, servers]);

  useEffect(() => {
    // expirationTimestamp
    if (phoneConnections != null) {
      const hasExpiredConnection = phoneConnections?.find((con) =>
        (con?.userId !== profile?.id
          ? false
          : con?.activePlans != null && !con?.activePlans.length
            ? true
            : con?.activePlans == null
              ? false
              : moment().isSameOrAfter(con?.activePlans[0]?.expirationTimestamp)));
      setExpiredTariffExist(hasExpiredConnection != null);
      setDisableCreation(
        (hasExpiredConnection != null
          && !profile.ignoreOneConnectionRestriction)
          || isEmpty(connection?.name)
          || isEmpty(connection?.slaveServerId),
      );
    }
  }, [phoneConnections, connection]);

  useEffect(() => {
    if (profile != null) {
      setConnection({
        ...connection,
        userId: profile.id,
      });
    }
  }, [profile]);

  useEffect(() => {
    const srvId = getUrlParameterByName('srvid');

    if (srvId) {
      setConnection({ ...connection, slaveServerId: srvId });
    }
  }, []);

  useEffect(() => {
    store.dispatch(loadServersList.request(null, null));
  }, []);

  const setInputValue = ({ target }, key) => {
    setConnection({
      ...connection,
      [key]: target.value,
    });
  };

  const createConnection = async () => {
    setIsProcessed(true);

    if (!disableCreation) {
      if (typeCreateActiveTab === 0) {
        store.dispatch(createNewConnection.request({
          phone: connection,
          callback: (res) => {
            store.dispatch(
              updatePhonesGroup.request({
                groupId: NON_GROUP,
                phonesId: [res.id],
              }),
            );
          },
        }));
        modalOptions.hide();
        moveToNonGroup();
        setTimeout(() => {
          store.dispatch(loadUserConnections.request({ userId: profile.id }));
        }, 3000);
        return null;
      }

      setProgressView(true);

      const maxTry = 5;

      const request = [];
      for (let i = 0; i < quantity; i += 1) {
        let tryNumber = 0;

        const create = async () => {
          try {
            const result = await Api.post(
              '/phones/smart_create',
              {
                ...connection,
                description: null,
                name: connection.name + (numbering + i),
              },
              null,
              false,
            );

            setCount(i + 1);

            return result;
          } catch (e) {
            if (tryNumber === maxTry) {
              throw e;
            }
            tryNumber += 1;
            return create();
          }
        };

        request.push(
          new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 500);
          }).then(() =>
            create()),
        );
      }

      Promise.all(request).then((res) => {
        const phonesIds = res.map((item) =>
          item.data.id);

        store.dispatch(loadUserPhones.request(null));
        store.dispatch(
          updatePhonesGroup.request({
            groupId: NON_GROUP,
            phonesId: phonesIds,
          }),
        );

        moveToNonGroup();
        setTimeout(() => {
          modalOptions.hide();
        }, 1200);
      });
    }

    return null;
  };

  const selectCountry = (cServers) => {
    const instance = cServers?.servers[
      Math.floor(Math.random() * (cServers?.servers?.length ?? 0))
    ];
    setConnection({ ...connection, slaveServerId: instance?.id });
  };

  const handleActiveTab = (tab: any) => {
    setActiveTab(tab);
  };

  const renderServers = () =>
    (
      <>
        <TabsAtom
          config={Object.values(RegionType)
            .filter((el) =>
              typeof el === 'number')
            .map((region) =>
              ({
                title: RegionTypeLabel[region],
                component: <></>,
              }))}
          initTab={region}
          getActiveTab={setRegion}
        />
        <div className="info">
          <div className="description">{t('connection.chooseLocation')}</div>
          <div
            className="helper"
            onClick={() =>
              windowOpen(getServerLocationLink(i18n.language))}
          >
            {t('connection.howToChooseLocation')}
          </div>
        </div>

        <ServersList error={isProcessed && isEmpty(connection?.slaveServerId)}>
          {expiredTariffExist && !profile.ignoreOneConnectionRestriction ? (
            <div className="lock-server" />
          ) : null}

          {sortServers?.map((item) => {
            const isSelected = item?.servers.find(
              (i) =>
                i?.id === connection?.slaveServerId,
            );
            return (
              <ServerItem
                key={item.zone}
                isActive={isSelected != null}
                onClick={() =>
                  selectCountry(item)}
              >
                <FlagComponent
                  serverGeo={{
                    country: item.zone?.split('-')[0],
                    city: item.zone?.split('-')[1],
                  }}
                  width="41px"
                  height="41px"
                />
              </ServerItem>
            );
          })}
        </ServersList>
      </>
    );

  const renderVipServers = () =>
    (
      <>
        {profile?.vipServers?.length === 0 ? (
          <Instruction>
            <div className="instruction">
              <div className="instruction-text">
                {t('connection.emptyVipServers_0')}
              </div>
              <div className="instruction-text">
                {t('connection.emptyVipServers_1')}
              </div>
              <div className="instruction-text">
                {t('connection.emptyVipServers_2')}
              </div>
              <div className="instruction-text">
                <strong>{t('connection.emptyVipServers_3')}</strong>
                <ul className="instruction-list">
                  <li className="instruction-list-item">
                    {t('connection.emptyVipServers_4')}
                  </li>
                  <li className="instruction-list-item">
                    {t('connection.emptyVipServers_5')}
                  </li>
                  <li className="instruction-list-item">
                    {t('connection.emptyVipServers_6')}
                  </li>
                  <li className="instruction-list-item">
                    {t('connection.emptyVipServers_7')}
                  </li>
                  <li className="instruction-list-item">
                    {t('connection.emptyVipServers_8')}
                  </li>
                  <li className="instruction-list-item">
                    {t('connection.emptyVipServers_9')}
                  &nbsp;
                    <strong>
                      <a href={getFAQLink(i18n.language)}>
                        {t('connection.emptyVipServers_10')}
                      </a>
                    </strong>
                  </li>
                </ul>
              </div>
              <div className="instruction-text">
                {t('connection.emptyVipServers_11')}
              &nbsp;
                <strong>
                  <a
                    href={telegramSupport}
                    target={TARGET_BLANK}
                    rel={LINK_REL}
                  >
                    {t('connection.emptyVipServers_12')}
                  </a>
                </strong>
              </div>
            </div>
          </Instruction>
        ) : (
          <>
            <div className="info">
              <div className="description">{t('connection.selectServer')}</div>
            </div>

            <ServersList
              error={isProcessed && isEmpty(connection?.slaveServerId)}
            >
              {expiredTariffExist && !profile.ignoreOneConnectionRestriction ? (
                <div className="lock-server" />
              ) : null}
              {profile?.vipServers?.map((item) => {
                const isSelected = item?.id === connection?.slaveServerId;
                return (
                  <ServerItem
                    key={item?.id}
                    isActive={isSelected != null && isSelected}
                    onClick={() =>
                      setConnection({ ...connection, slaveServerId: item?.id })}
                  >
                    <FlagComponent
                      serverGeo={{
                        country: item.zone?.split('-')[0],
                        city: item.zone?.split('-')[1],
                      }}
                      code={item.hostname.split('.')[0]}
                      width="41px"
                      height="41px"
                    />
                  </ServerItem>
                );
              })}
            </ServersList>
          </>
        )}
      </>
    );

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'shared servers',
      title: t('connection.sharedServers'),
      component: renderServers(),
    },
    {
      key: 'personal servers',
      title: t('connection.personalServers'),
      component: renderVipServers(),
    },
  ];

  const tabsTypeCreateConfig: TabsAtomConfig[] = [
    {
      key: 'one by one',
      title: t('connection.oneByOne'),
      component: <></>,
    },
    {
      key: 'bulk addition',
      title: t('connection.bulkAddition'),
      component: <></>,
    },
  ];

  return servers != null ? (
    <WrapperScreen>
      <Wrapper>
        <Scrollbars>
          <FormContainer>
            <ModalTitle fontSize="largeBold">{t('connection.createConnection')}</ModalTitle>

            <TabsAtom
              config={tabsConfig}
              initTab={activeTab}
              getActiveTab={handleActiveTab}
            />

            {
                activeTab === 0 || (activeTab === 1 && profile?.vipServers?.length > 0)
                  ? (
                    <>
                      {expiredTariffExist
                    && !profile.ignoreOneConnectionRestriction ? (
                      <div className="warning-msg">
                        {t('errors.expiredConExist')}
                      </div>
                        ) : null}

                      {profile.ignoreOneConnectionRestriction && (
                      <div>
                        <TabsAtom
                          config={tabsTypeCreateConfig}
                          initTab={typeCreateActiveTab}
                          getActiveTab={setTypeCreateActiveTab}
                        />
                      </div>
                      )}
                      <InputContainer>
                        {typeCreateActiveTab === 1 && (
                        <InputWrapper>
                          <InputLabel>
                            {t('connection.numberOfConnections')}
                          </InputLabel>
                          <InputField
                            value={inputQuantity}
                            floatLabel
                            placeholder={`${t('connection.numberOfConnections')}`}
                            onChange={(e) => {
                              let value = e.target.value.replace(/ /g, '');
                              if (
                                Number.isNaN(+value)
                                || +value > 100
                                || +value < 0
                              ) {
                                return;
                              }
                              if (value === '') {
                                value = '0';
                                setInputQuantity(+value);
                                return;
                              }
                              setInputQuantity(+value);
                              setQuantity(+value);
                            }}
                            onBlur={() => {
                              setInputQuantity(quantity);
                            }}
                          />
                          <span style={{ color: 'red', fontSize: '12px' }}>
                            {t('connection.enterValueFromTo', {
                              from: 1,
                              to: 100,
                            })}
                          </span>
                        </InputWrapper>
                        )}
                        <InputWrapper>
                          <InputLabel>
                            {typeCreateActiveTab === 0
                              ? t('connection.name')
                              : t('connection.theNameStartsWith')}
                          </InputLabel>
                          <InputField
                            value={connection.name}
                            floatLabel
                            disabled={
                            expiredTariffExist
                            && !profile.ignoreOneConnectionRestriction
                          }
                            placeholder={`${
                              typeCreateActiveTab === 0
                                ? t('connection.name')
                                : t('connection.theNameStartsWith')
                            }`}
                            error={
                            isProcessed
                            && (connection?.name == null || connection?.name === '')
                          }
                            errorMsg={t('errors.emptyField')}
                            onChange={(e) =>
                              setInputValue(e, 'name')}
                          />
                        </InputWrapper>
                        {typeCreateActiveTab === 1 && (
                        <InputWrapper>
                          <InputLabel>
                            {t('connection.numberingStartsWith')}
                          </InputLabel>
                          <MiniInput
                            value={numbering}
                            placeholder={`${t('connection.numberingStartsWith')}`}
                            onChange={(e) => {
                              let value = e.target.value.replace(/ /g, '');
                              if (Number.isNaN(+value) || +value < 0) {
                                return;
                              }
                              if (value === '') {
                                value = '0';
                              }
                              setNumbering(+value);
                            }}
                          />
                        </InputWrapper>
                        )}
                        {typeCreateActiveTab === 0 && (
                        <InputWrapper>
                          <InputLabel>{t('connection.description')}</InputLabel>
                          <InputField
                            multiline
                            row={2}
                            value={connection.description}
                            floatLabel
                            disabled={
                              expiredTariffExist
                              && !profile.ignoreOneConnectionRestriction
                            }
                            placeholder={`${t('connection.description')}`}
                            onChange={(e) =>
                              setInputValue(e, 'description')}
                          />
                        </InputWrapper>
                        )}
                      </InputContainer>
                      {!progressView ? (
                        <BasicButton
                          size="small"
                          className="add-phone-submit"
                          disable={
                          (expiredTariffExist
                            && !profile.ignoreOneConnectionRestriction)
                          || (isProcessed && disableCreation)
                        }
                          onClick={createConnection}
                        >
                          {t('connection.createConnection')}
                        </BasicButton>
                      ) : (
                        <>
                          <ProgressBar
                            completed={+((count / quantity) * 100).toFixed(2)}
                            animateOnRender
                            bgColor={theme.colors.deepBlue}
                            margin="30px 0 10px 0"
                            height="35px"
                          />
                          <Processing>{`Processing (${count}/${quantity})`}</Processing>
                        </>
                      )}
                    </>
                  ) : null
              }
          </FormContainer>
        </Scrollbars>
      </Wrapper>
    </WrapperScreen>
  ) : null;
};

const Instruction = styled.div`
  .instruction {
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    }
  }

  .instruction-text {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    &:first-child {
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }

  .instruction-list {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

    .instruction-list-item {
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    }
  }
`;

const Wrapper = styled.div`
  height: 80vh;
  min-width: 800px;

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    height: 100vh;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    min-width: auto;
  }

  .warning-msg {
    border: 1px solid ${(props: UIProps) =>
    props.theme.colors.warning};
    box-sizing: border-box;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    color: ${(props: UIProps) =>
    props.theme.colors.warning};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }

  .add-phone-submit {
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 100%;
    }
  }
`;

const FormContainer = styled.div`
  margin-bottom: 35px;
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  overflow-y: auto;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: 40px;
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  .info {
    display: flex;
    align-items: center;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .description {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
    }
  }

  .helper {
    position: relative;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smallSemibold};
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    display: flex;
    margin-left: 25px;
    cursor: pointer;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin: 12px 0px 12px -7px;
    }

    :before {
      content: '';
      display: block;
      width: 28px;
      height: 12px;
      background-image: url(${iconLink});
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      top: 3px;

      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        width: 28px;
        height: 12px;
      }
    }
  }
`;

const InputContainer = styled.div`
`;

interface ServersListProps extends UIProps {
  error: boolean;
}

const ServersList = styled.div<ServersListProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  position: relative;
  border: 1px solid
    ${(props: ServersListProps) =>
    (props?.error ? props.theme.colors.warning : 'transparent')};
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    justify-content: space-between;
  }

  .lock-server {
    background: ${(props: UIProps) =>
    props.theme.colors.white};
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

interface ServerItemProps extends UIProps {
  isActive?: boolean;
}

const ServerItem = styled.div<ServerItemProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  flex: 1 1 10%;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  width: 100%;
  max-width: 120px;
  min-width: 100px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: flex-start;

    width: 48%;
    min-width: 48%;
    max-width: 48%;

    margin: 0 0 10px;
    padding: 4px;
    gap: 10px;
  }

  ${(props: ServerItemProps) =>
    props.isActive
    && css`
      background: rgba(180, 180, 180, 0.15);
      border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  `}

  :hover {
    background: rgba(180, 180, 180, 0.15);
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  }

  .country_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin: 0 8px 0 0;
    }
  }

  .country_name {
    width: max-content;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }

  .country_code {
    width: max-content;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.small};
      margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }
  }
`;

const InputLabel = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const MiniInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  padding: 11px;
  font: 112.5%/1.45em 'Montserrat', sans-serif;
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.darkGray};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  outline: none;

  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  :focus {
    border-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
  }
`;

const Processing = styled.div`
  text-align: center;
`;
