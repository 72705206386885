import React, {
  useState,
  FunctionComponent,
  useMemo,
  useContext,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { CheckBox } from '../../atoms/Checkbox';
import store from '../../../core/store/store';
import { updateConnection } from '../../../core/store/actions/connections';
import { RebootBySchedule } from '../RebootBySchedule';
import { InputLabel, InputWrapper } from '../../../styles/ui-controls';
import { InputField } from '../../../elements';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';

export const debounceCall = debounce(
  (callback) => {
    callback();
  },
  50,
  {
    leading: false,
    trailing: true,
  },
);

interface SchedulerSettingsProps {
  selectedPhone;
}

export const SchedulerSettings: FunctionComponent<SchedulerSettingsProps> = (
  props,
) => {
  const { selectedPhone } = props;
  const { t } = useTranslation();
  const context = useContext(ConnectionEditViewContext);

  const [autonomyDelay, setAutonomyDelay] = useState(
    selectedPhone?.ipChangeDelaySeconds || 1,
  );
  const [batteryReboot, setBatteryReboot] = useState(
    selectedPhone?.lowBatteryRebootThreshold || 1,
  );
  const [connectionIssuesReboot, setConnectionIssuesReboot] = useState(
    selectedPhone?.noNetworkRebootTimeoutSeconds != null
      && selectedPhone?.noNetworkRebootTimeoutSeconds < 10000000
      ? (selectedPhone?.noNetworkRebootTimeoutSeconds ?? 0) / 60
      : 1,
  );

  const [noNetworkAirplane, setNoNetworkAirplane] = useState(
    selectedPhone?.noNetworkAirplaneToggleTimeoutSeconds != null
      && selectedPhone?.noNetworkAirplaneToggleTimeoutSeconds < 10000000
      ? (selectedPhone?.noNetworkAirplaneToggleTimeoutSeconds ?? 0) / 60
      : 1,
  );

  const enableSchesulreReboot = useMemo(
    () =>
      (selectedPhone?.rebootCronString != null
        && selectedPhone?.rebootCronString !== '')
      || false,
    [selectedPhone],
  );
  const enableAutonomy = useMemo(
    () =>
      selectedPhone?.ipChangeDelaySeconds
      && selectedPhone?.ipChangeDelaySeconds !== 0,
    [selectedPhone],
  );
  const enableBattery = useMemo(
    () =>
      selectedPhone?.lowBatteryRebootThreshold > 0,
    [selectedPhone],
  );
  const enableConnection = useMemo(
    () =>
      selectedPhone?.noNetworkRebootTimeoutSeconds
      && selectedPhone?.noNetworkRebootTimeoutSeconds < 10000000,
    [selectedPhone],
  );
  const enableNoNetwork = useMemo(
    () =>
      selectedPhone?.noNetworkAirplaneToggleTimeoutSeconds
      && selectedPhone?.noNetworkAirplaneToggleTimeoutSeconds < 10000000,
    [selectedPhone],
  );

  const [showScheduler, setShowScheduler] = useState(enableSchesulreReboot);
  const [showAutonomy, setShowAutonomy] = useState(enableAutonomy);
  const [showBattery, setShowBattery] = useState(enableBattery);
  const [showConnection, setShowConnection] = useState(enableConnection);
  const [showNoNetworkConnection, setShowNoNetworkConnection] = useState(enableNoNetwork);

  const updateAirplaneModeDelay = (value) => {
    if (value > 0 && value <= 3600) {
      debounceCall(() => {
        store.dispatch(
          updateConnection.request({
            id: selectedPhone.id,
            ipChangeDelaySeconds: +value,
          }),
        );
      });
    }
  };

  const renderAirplaneModeDelay = () =>
    (
      <SubWrapper>
        <InputWrapper>
          <InputLabel>
            {' '}
            {t('proSettings.inSeconds')}
            {' '}
            <span>1 - 3600</span>
          </InputLabel>
          <InputField
            value={autonomyDelay}
            number
            minimum={1}
            floatLabel
            placeholder={`${t('proSettings.inSeconds')}`}
            onBlur={({ target }) => {
              const val = target.value == null || target.value?.replaceAll(' ', '') === ''
                ? 1
                : +target.value >= 3600
                  ? 3600
                  : +target.value;

              setAutonomyDelay(val);
              updateAirplaneModeDelay(+val);
            }}
            onChange={({ target }) => {
              setAutonomyDelay(target.value);
              updateAirplaneModeDelay(+target.value);
            }}
          />
        </InputWrapper>
      </SubWrapper>
    );

  const updateLowBatteryReboot = (value) => {
    if (value > 0 && value <= 95) {
      debounceCall(() => {
        store.dispatch(
          updateConnection.request({
            id: selectedPhone.id,
            lowBatteryRebootThreshold: +value,
          }),
        );
      });
    }
  };

  const renderLowBatteryReboot = () =>
    (
      <SubWrapper>
        <InputWrapper>
          <InputLabel>
            {' '}
            {t('proSettings.inPercentage')}
            {' '}
            <span>1 - 95%</span>
            {' '}
          </InputLabel>
          <InputField
            value={batteryReboot}
            number
            minimum={1}
            floatLabel
            placeholder={`${t('proSettings.inPercentage')}`}
            onBlur={({ target }) => {
              const val = target.value == null || target.value?.replaceAll(' ', '') === ''
                ? 1
                : +target.value >= 95
                  ? 95
                  : +target.value;
              setBatteryReboot(val);
              updateLowBatteryReboot(+val);
            }}
            onChange={({ target }) => {
              setBatteryReboot(target.value);
              updateLowBatteryReboot(+target.value);
            }}
          />
        </InputWrapper>
      </SubWrapper>
    );

  const updateConnectionIssues = (value) => {
    if (value > 0 && value <= 60) {
      debounceCall(() => {
        store.dispatch(
          updateConnection.request({
            id: selectedPhone.id,
            noNetworkRebootTimeoutSeconds: +value * 60,
          }),
        );
      });
    }
  };

  const renderConnectionIssues = () =>
    (
      <SubWrapper>
        <InputWrapper>
          <InputLabel>
            {' '}
            {t('proSettings.inMinutess')}
            {' '}
            1 - 60
            {}
          </InputLabel>
          <InputField
            value={Math.ceil(connectionIssuesReboot)}
            number
            minimum={1}
            floatLabel
            placeholder={`${t('proSettings.inPercentage')}`}
            onBlur={({ target }) => {
              const val = target.value == null || target.value?.replaceAll(' ', '') === ''
                ? 1
                : +target.value >= 60
                  ? 60
                  : +target.value;
              setConnectionIssuesReboot(val);
              updateConnectionIssues(+val);
            }}
            onChange={({ target }) => {
              setConnectionIssuesReboot(+target.value);
              updateConnectionIssues(+target.value);
            }}
          />
        </InputWrapper>
      </SubWrapper>
    );

  const updateNoNetwork = (value) => {
    if (value > 0 && value <= 60) {
      debounceCall(() => {
        store.dispatch(
          updateConnection.request({
            id: selectedPhone.id,
            noNetworkAirplaneToggleTimeoutSeconds: value * 60,
          }),
        );
      });
    }
  };

  const renderNoNetwork = () =>
    (
      <SubWrapper>
        <InputWrapper>
          <InputLabel>
            {t('proSettings.inMinutess')}
            {' '}
            <span>1 - 60</span>
          </InputLabel>
          <InputField
            value={Math.ceil(noNetworkAirplane)}
            number
            minimum={1}
            floatLabel
            placeholder={`${t('proSettings.inPercentage')}`}
            onBlur={({ target }) => {
              const val = target.value == null || target.value?.replaceAll(' ', '') === ''
                ? 1
                : +target.value >= 60
                  ? 60
                  : +target.value;
              setNoNetworkAirplane(+val);
              updateNoNetwork(+val);
            }}
            onChange={({ target }) => {
              setNoNetworkAirplane(+target.value);
              updateNoNetwork(+target.value);
            }}
          />
        </InputWrapper>
      </SubWrapper>
    );

  return (
    <Wrapper>
      <ReactTooltip />
      {!context?.isOwner && !context?.permissions?.scheduledReboot ? null : (
        <>
          <span
            data-tip="ROOT / Owner Mode"
          >
            <CheckBox
              value={showScheduler}
              onClick={(status) => {
                setShowScheduler(status);
                store.dispatch(
                  updateConnection.request({
                    id: selectedPhone.id,
                    rebootCronString: '',
                  }),
                );
              }}
              label={`${t('proSettings.rebootOnSchedule')}`}
              hasLabel
            />
          </span>
          {showScheduler ? <RebootBySchedule phone={selectedPhone} /> : null}
        </>
      )}
      {!context?.isOwner && !context?.permissions?.batteryLevelLower ? null : (
        <>
          <span
            data-tip="ROOT / Owner Mode"
          >
            <CheckBox
              value={showBattery}
              onClick={(status) => {
                setShowBattery(status);
                store.dispatch(
                  updateConnection.request({
                    id: selectedPhone.id,
                    lowBatteryRebootThreshold: status ? 1 : 0,
                  }),
                );
              }}
              label={`${t('proSettings.rebootOnBatteryDrop')}`}
              hasLabel
            />
          </span>
          {showBattery ? renderLowBatteryReboot() : null}
        </>
      )}
      {!context?.isOwner
      && !context?.permissions?.proxiesAreDisconnected ? null : (
        <>
          <span
            data-tip="ROOT / Owner Mode"
          >
            <CheckBox
              value={showConnection}
              onClick={(status) => {
                setShowConnection(status);
                store.dispatch(
                  updateConnection.request({
                    id: selectedPhone.id,
                    noNetworkRebootTimeoutSeconds: status ? 1 * 60 : 10000000,
                  }),
                );
              }}
              label={`${t('proSettings.rebootOnProxyOff')}`}
              hasLabel
            />
          </span>
          {showConnection ? renderConnectionIssues() : null}
        </>
        )}
      {!context?.isOwner && !context?.permissions?.airplaneMode ? null : (
        <>
          <CheckBox
            value={showAutonomy}
            onClick={(status) => {
              setShowAutonomy(status);
              store.dispatch(
                updateConnection.request({
                  id: selectedPhone.id,
                  ipChangeDelaySeconds: status ? 1 : 0,
                }),
              );
            }}
            label={`${t('proSettings.changeAirplaneModeTime')}`}
            hasLabel
          />
          {showAutonomy ? renderAirplaneModeDelay() : null}
        </>
      )}
      {!context?.isOwner && !context?.permissions?.toggleAirplaneMode ? null : (
        <>
          <CheckBox
            value={showNoNetworkConnection}
            onClick={(status) => {
              setShowNoNetworkConnection(status);
              store.dispatch(
                updateConnection.request({
                  id: selectedPhone.id,
                  noNetworkAirplaneToggleTimeoutSeconds: status
                    ? 1 * 60
                    : 10000000,
                }),
              );
            }}
            label={`${t('proSettings.mobileNetworkIdle')}`}
            hasLabel
          />
          {showNoNetworkConnection ? renderNoNetwork() : null}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const SubWrapper = styled.div``;
