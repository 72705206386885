import React, {
  FunctionComponent,
  useContext,
} from 'react';
import styled from 'styled-components';
import { PhoneActions } from './PhoneActions';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';

interface ActionsProps {}

export const Actions: FunctionComponent<ActionsProps> = () => {
  const context = useContext(ConnectionEditViewContext);

  return (
    <Wrapper>
      <PhoneActions selectedPhone={context?.selectedPhone} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
`;
