import { createAsyncAction } from 'typesafe-actions';
import { Plan } from '../../../types';

const LOAD_PLANS_LIST = 'plans/LOAD_PLANS_LIST';
const LOAD_PLANS_SUCCESS = 'plans/LOAD_PLANS_SUCCESS';
const LOAD_PLANS_FAILURE = 'plans/LOAD_PLANS_FAILURE';

export const loadPlanList = createAsyncAction(
  LOAD_PLANS_LIST,
  LOAD_PLANS_SUCCESS,
  LOAD_PLANS_FAILURE,
)<null, Plan[], Error>();
