import styled from 'styled-components';
import { UIProps } from '../../types';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

export const ProxyConnectionsList = styled.div``;

interface ProxyConnectionListRowProps {
  flexDirection?: string;
}

export const ProxyConnectionsListRow = styled.div<ProxyConnectionListRowProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 0 0;
  box-sizing: border-box;
  margin-bottom: 8px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    padding: 25px 16px 16px 16px;
    background-color: ${(props: UIProps) =>
    props.theme.colors.skyBlueTint};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    ${(props) =>
    `flex-direction: ${props.flexDirection}`};
  }

  &:last-child {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

interface RowContentProps {
  isEdit: boolean;
}

export const RowContent = styled.div<RowContentProps>`
  display: flex;
  width: 100%;
  background-color: ${(props: RowContentProps) =>
    (props.isEdit ? '#e9eef869' : undefined)};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-wrap: wrap;
  }
`;

interface ItemContentProps extends UIProps {
  content: string;
  isEdit?: boolean;
}

export const ItemContent = styled.div<ItemContentProps>`
  position: relative;
  padding: 8px;

  box-sizing: border-box;
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};

  background-color: ${(props: UIProps) =>
    props.theme.colors.white};
  border: 1px solid ${(props: ItemContentProps) =>
    props.theme.colors.deepBlue};
  border-right: none;
  cursor: ${(props: ItemContentProps) =>
    (props?.isEdit ? 'pointer' : 'auto')};

  & > span {
    white-space: nowrap;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: 0 0 25px 0;
    padding: 2px 4px;
    display: flex;
    align-items: center;
  }

  &:nth-child(1) {
    width: 15%;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 25%;
    }
  }

  &:nth-child(2) {
    width: 20%;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 50%;
    }
  }

  &:nth-child(3) {
    width: 15%;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 25%;
      border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.rightAnglesSmaller};
      border-right: 1px solid
        ${(props: ItemContentProps) =>
    props.theme.colors.deepBlue};
    }
  }

  &:nth-child(4) {
    width: 30%;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 50%;
      border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.leftAnglesSmaller};
      margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }

  &:nth-child(5) {
    width: 20%;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 50%;
      margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }

  &:first-child {
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.leftAnglesSmaller};
  }

  &:last-child {
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.rightAnglesSmaller};
    border-right: 1px solid
      ${(props: ItemContentProps) =>
    props.theme.colors.deepBlue};

    span {
      display: block;
      overflow: hidden;
    }
  }

  &:after {
    content: "${(props: ItemContentProps) =>
    props.content}";
    position: absolute;
    color: ${(props: UIProps) =>
    props.theme.colors.dark};
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    top: -20px;
    left: 5px;
  }
`;
