import React, { FunctionComponent, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo_iproxy.svg';
import { SideMenuActions } from './SideMenuActions';
import { Content, MobileTechSupport, Sidebar } from './OnboardingScreen';
import { ChangePassword } from './ChangePassword';
import { UIProps } from '../types';
import { DAY_IN_MS, YEAR_IN_MS, MONTH_IN_MS } from '../constants/common';
import { BasicButton } from '../styles/ui-controls';
import { useOnClickOutside } from '../hooks';
import { ApiWarningBlock } from '../components/common/ApiWarningBlock';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../utils/constants';
import { useIsMobileHook } from '../hooks/useIsMobileHook';
import { ReactMD } from '../components/common/ReactMarkdown';
import theme from '../styles/ApplicationTheme';

const Wrapper = styled.div`
  background-color: ${(props: UIProps) =>
    props.theme.colors.white};
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  position: absolute;

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    width: 100%;
  }

  .row {
    display: flex;
    width: 100%;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      display: block;
    }
  }

  .input-container {
    width: 100%;
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.large};

    :last-child {
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }

  .title {
    ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    width: 100%;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .container {
    width: 75%;
    padding: 0 23px;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 100%;
      padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }
`;

const ConfirmWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.clearDark};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmall};
`;

const ConfirmBlock = styled.div`
  max-height: 140px;
  max-width: 500px;
  background-color: ${(props: UIProps) =>
    props.theme.colors.white};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  }
`;

const BaseDescription = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumSemibold};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

const Buttons = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
`;

export interface ChangePswScreenProps {
  onFinish: Function;
}

export const ChangePswScreen: FunctionComponent<ChangePswScreenProps> = ({
  onFinish,
}) => {
  const { t } = useTranslation();
  const confirmRef = useRef(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const ref = useRef(null);
  const isMobile = useIsMobileHook();

  const deadline = Math.min(
    profile.lastChangePswDate ?? 0,
    profile.lastUpdateApiKeyDate ?? 0,
  )
    + YEAR_IN_MS
    + MONTH_IN_MS;

  const daysLeft = Math.ceil((deadline - Date.now()) / DAY_IN_MS);

  useOnClickOutside(confirmRef, () => {
    setShowConfirmModal(false);
  });

  return (
    <Wrapper ref={ref}>
      <Content>
        <div className="content-container">
          {!(deadline < +Date.now()) && (
            <ApiWarningBlock
              components={(
                <>
                  <div className="skip-text">
                    <ReactMD
                      markdown={t('changePswAndApiKey.skipText', {
                        daysLeft: `**${daysLeft}**`,
                      })}
                      fontSize={theme.sizes.font.largeMedium}
                    />
                    <div>{t('changePswAndApiKey.skipDescription')}</div>
                  </div>
                </>
              )}
              onClick={() => {
                setShowConfirmModal(true);
                if (ref?.current) {
                  ref.current.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                  });
                }
              }}
              width="75%"
              padding="0 23px"
              imgSize="40px"
              isWarning={false}
            />
          )}

          <div className="container">
            <div className="title">
              {t('changePswAndApiKey.changePassword')}
            </div>
            <ChangePassword setChangePassword={() =>
              onFinish()}
            />
          </div>
        </div>
        {
          isMobile && (
            <MobileTechSupport>
              <SideMenuActions />
            </MobileTechSupport>
          )
        }
      </Content>
      <Sidebar>
        <div className="onb_logo">
          <img width="199px" src={logo} alt="" />
        </div>
        {
          !isMobile && (
            <SideMenuActions />
          )
        }
      </Sidebar>
      {showConfirmModal && (
        <ConfirmWrapper>
          <ConfirmBlock ref={confirmRef}>
            <BaseDescription>
              {t('changePswAndApiKey.confirmSkip', {
                daysLeft,
              })}
            </BaseDescription>

            <Buttons>
              <BasicButton
                fillWidth
                onClick={() =>
                  onFinish()}
              >
                {t('editForm.confirmRemoval')}
              </BasicButton>
              <BasicButton
                fillWidth
                color="warning"
                onClick={() =>
                  setShowConfirmModal(false)}
              >
                {t('editForm.cancelRemoval')}
              </BasicButton>
            </Buttons>
          </ConfirmBlock>
        </ConfirmWrapper>
      )}
    </Wrapper>
  );
};
