import React, {
  FunctionComponent,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SystemSettingsForm } from './settings/system-settings-form';
import { PaymentSettingsForm } from './settings/payment-settings-form';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { IntegrationSettingsForm } from './settings/integration-settings-form';
import { TableSettingsForm } from './settings/display-columns-form';
import { CredentialsSettingsForm } from './settings/credentials-settings-form';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../../utils/constants';
import { UIProps } from '../../types';

interface DashboardSettingsFormProps {}

export const DashboardSettingsForm: FunctionComponent<
DashboardSettingsFormProps
> = () => {
  const { t } = useTranslation();

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'system settings',
      title: `${t('dashboard.setup')}`,
      component: (
        <SystemSettingsForm />
      ),
    },
    {
      key: 'credentials settings',
      title: `${t('dashboard.credentialsSettings')}`,
      component: (
        <CredentialsSettingsForm />
      ),
    },
    {
      key: 'columns settings',
      title: `${t('dashboard.tableSettings')}`,
      component: (
        <TableSettingsForm />
      ),
    },
    {
      key: 'integration settings',
      title: 'Integration',
      component: (
        <IntegrationSettingsForm />
      ),
    },
    {
      key: 'payment settings',
      title: `${t('dashboard.settings')}`,
      component: (
        <PaymentSettingsForm />
      ),
    },
  ];
  return (
    <Wrapper>
      <TabsAtom config={tabsConfig} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 630px;
  min-height: 350px;
  padding: 0 16px;

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    padding-top: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    min-width: unset;
    min-height: 100vh;
    margin-top: 50px;
    padding-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;
