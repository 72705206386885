import React from 'react';

import { NavigationStack } from '../../types';
import { routes } from '../routes';
import { FaqScreen } from '../../screens';
import { RecoverScreen } from '../../screens/RecoverScreen';

export const appStack: NavigationStack = [
  {
    private: false,
    path: routes.common.faq,
    component: <FaqScreen />,
  },
  {
    private: false,
    path: routes.common.recover,
    component: <RecoverScreen />,
  },

];
