import styled from 'styled-components';
import { BREAKPOINT_MOBILE } from '../utils/constants';

import closeIcon from '../assets/phone_icons/remove_icon_white.svg';
import { UIProps } from '../types';

interface WrapperProps {
  background: string;
}

export const Wrapper = styled.div<WrapperProps>`
  background: ${(props) =>
    props.background};
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.midsmallZero};
  width: 100%;
  box-sizing: border-box;
`;

export const ContentLayer = styled.div`
  margin: auto;
  position: relative;
  z-index: 5;
  padding-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  box-sizing: border-box;
  max-width: 90%;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    max-width: 100%;
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroMedium};
  }
`;

export const Text = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
  color: ${(props) =>
    props.theme.colors.white};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    max-width: 350px;
  }
`;

export const ChangeButton = styled.div`
  display: inline-block;
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumBold};
  color: ${(props) =>
    props.theme.colors.darkBlue};
  background-color: ${(props) =>
    props.theme.colors.white};
  padding: 14px 24px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  cursor: pointer;
  transition: 0.3s;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    padding: 7px 10px;
  }
`;

export const CloseButton = styled.div`
  display: block;
  margin-left: 24px;
  background-color: transparent;
  background-image: url(${closeIcon});
  background-position: center;
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    opacity: 0.7;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;
