import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TimezoneSelect, { ITimezoneOption, allTimezones } from 'react-timezone-select';
import { InputLabel, InputWrapper } from '../../../../styles/ui-controls';
import { InputField } from '../../../../elements';
import { WhitelistIpInput } from '../../../common/WhitelistIpInput';
import { ButtonLoader, CustomeDateInputAtom, CustomeTimeInputAtom } from '../../../atoms';
import { ExpereationDateWrapper } from '../../BasicSettings/SetCommonExpirationDate';
import { UIProps } from '../../../../types';

interface OpenVpnEditProps {
  ovpnConfig: any;
  onChange?: Function;
  setMode?: Function;
}

export const OpenVpnEdit: FunctionComponent<OpenVpnEditProps> = (props) => {
  const { ovpnConfig, onChange, setMode } = props;
  const { t, i18n } = useTranslation();

  const [connection, setConnection] = useState({
    name: '',
    expiredTimestamp: null,
    dns: null,
  });

  const defaultTimexone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [selectedTimezone, setSelectedTimezone] = useState<ITimezoneOption | string>(defaultTimexone);

  useEffect(() => {
    if (ovpnConfig != null) {
      setConnection({
        name: ovpnConfig?.name,
        expiredTimestamp: ovpnConfig?.expiredTimestamp,
        dns: ovpnConfig.dns,
      });
    }
  }, [ovpnConfig]);

  return (
    <Wrapper>
      <InputWrapper>
        <InputLabel>Name</InputLabel>
        <InputField
          value={connection.name}
          floatLabel
          placeholder="Name"
          onChange={({ target }) =>
            setConnection({ ...connection, name: target.value })}
        />
      </InputWrapper>

      <InputWrapper>
        <WhitelistIpInput
          placeholder="Set DNS IP address"
          label="DNS"
          onListChange={(list) =>
            setConnection({ ...connection, dns: list })}
          ipList={connection?.dns?.join('\n')}
        />
      </InputWrapper>

      <InputWrapper>
        <ExpereationDateWrapper>
          <div className="date-box">
            <div className="date-time">
              <InputLabel>{t('openVpnEdit.date')}</InputLabel>
              <CustomeDateInputAtom
                indicator
                value={connection?.expiredTimestamp}
                activeData={false}
                disabled={!connection?.expiredTimestamp}
                locale={i18n.language}
                minDate={+new Date()}
                maxDate={connection.expiredTimestamp}
                onChange={(date) => {
                  setConnection({
                    ...connection,
                    expiredTimestamp: +new Date(date),
                  });
                }}
              />
              <div className="time-box">
                <CustomeTimeInputAtom
                  indicator
                  value={connection?.expiredTimestamp}
                  onChange={(date) => {
                    setConnection({
                      ...connection,
                      expiredTimestamp: +new Date(date),
                    });
                  }}
                  disabled={!connection?.expiredTimestamp}
                  activeData={false}
                  locale={i18n.language}
                />
              </div>
            </div>
            {connection?.expiredTimestamp != null && (
            <div className="selectTimezone-box">
              <InputLabel>{t('openVpnEdit.timezone')}</InputLabel>
              <TimezoneSelect
                value={selectedTimezone}
                onChange={setSelectedTimezone}
                timezones={allTimezones}
                maxMenuHeight={150}
              />
            </div>
            )}
          </div>
        </ExpereationDateWrapper>
      </InputWrapper>

      <ButtonWrapper>
        <ButtonLoader
          size="small"
          onClick={async () => {
            const timezone = selectedTimezone as ITimezoneOption;

            if (timezone.offset !== undefined) {
              const shift = timezone.offset - (new Date(connection.expiredTimestamp).getTimezoneOffset()) / -60;
              const expiredTimestampTZ = connection.expiredTimestamp - shift * 60 * 60 * 1000;
              const newConnection = { ...connection, expiredTimestamp: expiredTimestampTZ };

              if (onChange) {
                onChange(newConnection);
              }
              setConnection(newConnection);

              return;
            }

            if (onChange) {
              onChange(connection);
            }
          }}
        >
          {t('editForm.save')}
        </ButtonLoader>
        <ButtonLoader
          size="small"
          onClick={() =>
            setMode('list')}
        >
          {t('editForm.cancelRemoval')}
        </ButtonLoader>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;
