import React, {
  FunctionComponent,
  useContext,
} from 'react';
import styled from 'styled-components';
import { UniqueIP } from '../../UniqueIP';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import ScrollWrapper from '../../common/ScrollWrapper';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';

interface UniqueIPTabProps {}

export const UniqueIPTab: FunctionComponent<UniqueIPTabProps> = () => {
  const context = useContext(ConnectionEditViewContext);

  return (
    <ScrollWrapper>
      <Wrapper>
        {!context?.isOwner
        && !context?.permissions?.enableUniqueIP ? null : (
          <UniqueIP phone={context?.selectedPhone} />
          )}
      </Wrapper>
    </ScrollWrapper>
  );
};

const Wrapper = styled.div`
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;
