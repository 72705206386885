export const DEFAULT_TIMEZONE = 'Europe/Moscow';

export const FINGERPRINTS_OPTIONS = [
  {
    label: 'WinXP',
    value: 'WinXP',
  },
  {
    label: 'Win78',
    value: 'Win78',
  },
  {
    label: 'Win10',
    value: 'Win10',
  },
  {
    label: 'WinNT',
    value: 'WinNT',
  },
  {
    label: 'Nintendo',
    value: 'Nintendo',
  },
  {
    label: 'FreeBSD',
    value: 'FreeBSD',
  },
  {
    label: 'FreeBSD9',
    value: 'FreeBSD9',
  },
  {
    label: 'MacOS',
    value: 'MacOS',
  },
  // {
  //     label: 'Linux2',
  //     value: 'Linux2'
  // },
  // {
  //     label: 'Droid',
  //     value: 'Droid'
  // },
  // {
  //     label: 'ChromeOS',
  //     value: 'ChromeOS'
  // },
  // {
  //     label: 'linuxbg',
  //     value: 'linuxbg'
  // }
];
