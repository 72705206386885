import React, {
  useState, useEffect, useRef, FunctionComponent,
} from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import TimezoneSelect, {
  ITimezoneOption,
  allTimezones,
} from 'react-timezone-select';
import ReactTooltip from 'react-tooltip';
import { InputLabel, InputWrapper } from '../../../styles/ui-controls';
import { DropdownElement, InputField } from '../../../elements';
import {
  ButtonLoader,
  CustomeDateInputAtom,
  CustomeTimeInputAtom,
} from '../../atoms';
import store from '../../../core/store/store';
import { updateConnectionCred } from '../../../core/store/actions/connections';
import {
  Connection,
  ConnectionType,
  Plan,
  SelectOption,
  UIProps,
} from '../../../types';
import { LogsWeeklyTool } from '../../LogsWeeklyTool';
import { Phone } from '../../../types/phones';
import {
  isIncorrectIpsContain,
  WhitelistIpInput,
} from '../../common/WhitelistIpInput';
import { CheckBox } from '../../atoms/Checkbox';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { generateRandomPassword, showNotification } from '../../../utils';
import { ExpereationDateWrapper } from './SetCommonExpirationDate';
import { ProxyAction } from './ProxyAction';

interface EditConnectionProps {
  connection: Connection;
  phone: Phone;
  selectedTariffId?: Plan;
  onSave: Function;
}

export const EditConnection: FunctionComponent<EditConnectionProps> = (
  props,
) => {
  const {
    connection, phone, onSave, selectedTariffId,
  } = props;
  const { t, i18n } = useTranslation();
  const [listIps, setListIps] = useState(null);
  const { profile } = useTypedSelector(({ user }) =>
    user);

  const [selectedTimezone, setSelectedTimezone] = useState<
  ITimezoneOption | string
  >(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const CON_TYPE: SelectOption<ConnectionType>[] = [
    {
      value: 'socks5',
      label: 'socks5',
    },
    {
      value: 'http',
      label: 'http',
    },
  ];

  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [errorLogin, setErrorLogin] = useState<boolean>(false);
  const [matchError, setMatchError] = useState<boolean>(false);

  const ref = useRef(null);

  const [updatedConnectionData, setUpdatedConnectionData] = useState({
    username: '',
    password: '',
    id: '',
    type: '',
    auth_type: '',
    description: '',
    expirationTimestamp: null,
  });

  useEffect(() => {
    if (connection != null) {
      setUpdatedConnectionData({
        auth_type: connection?.auth_type,
        password:
          connection?.auth_type === 'noauth' ? '' : connection?.password,
        username: connection?.auth_type === 'noauth' ? '' : connection?.login,
        type: connection?.listen_service,
        id: connection?.id,
        description: connection?.description,
        expirationTimestamp: connection?.expirationTimestamp,
      });

      if (connection?.allow_from != null) {
        setListIps(connection?.allow_from);
      }
    }
  }, [connection]);

  // userpass  noauth
  const updateConnection = () => {
    store.dispatch(
      updateConnectionCred.request({
        login: updatedConnectionData.username.replaceAll(' ', ''),
        password: updatedConnectionData.password.replaceAll(' ', ''),
        connectionId: connection?.id,
        auth_type: isEmpty(updatedConnectionData.password)
          ? 'noauth'
          : 'userpass',
        listen_service: updatedConnectionData.type,
        phoneId: phone?.id,
        allow_from: listIps,
        description: updatedConnectionData?.description,
        expirationTimestamp: updatedConnectionData?.expirationTimestamp,
      }),
    );
  };

  const isValidLogAndPass = isEmpty(updatedConnectionData.username)
    || isEmpty(updatedConnectionData.password)
    ? isEmpty(updatedConnectionData.username)
        && isEmpty(updatedConnectionData.password)
    : !isEmpty(updatedConnectionData.username)
        && !isEmpty(updatedConnectionData.password);

  const isValidNonauth = updatedConnectionData.auth_type === 'noauth'
    ? listIps != null && listIps.length > 0 && !isIncorrectIpsContain(listIps)
    : true;

  const onSaveConnection = () => {
    const login = updatedConnectionData.username.replaceAll(' ', '');
    const password = updatedConnectionData.password.replaceAll(' ', '');
    const REGEXP = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{12,}$/;
    const loginValid = REGEXP.test(login);
    const passwordValid = REGEXP.test(password);
    const scrollToRef = () =>
      ref?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });

    setMatchError(false);

    if ((!loginValid || !passwordValid) && listIps == null) {
      setErrorLogin(!loginValid);
      setErrorPassword(!passwordValid);
      scrollToRef();
      return;
    }

    if (updatedConnectionData.auth_type === 'userpass' && login === password) {
      setMatchError(true);
      setErrorLogin(!loginValid);
      setErrorPassword(!passwordValid);
      scrollToRef();
      return;
    }

    updateConnection();
    setTimeout(() => {
      onSave();
      setUpdatedConnectionData(null);
    }, 1000);
  };

  const resetProxyPassword = () => {
    setUpdatedConnectionData((prevState) =>
      ({
        ...prevState,
        password: generateRandomPassword(),
      }));
    showNotification(`${t('connection.resetPasswordSuccess')}`);
  };

  return (
    <Wrapper>
      <ReactTooltip />

      <InputContainer>
        <InputWrapper>
          <InputLabel>{t('editConnection.connectionType')}</InputLabel>
          <DropdownElement
            isSearchable={false}
            value={CON_TYPE?.find(
              (i) =>
                i?.value === updatedConnectionData.type,
            )}
            placeholder={`${t('editConnection.connectionType')}`}
            options={CON_TYPE}
            onSelected={(target) =>
              setUpdatedConnectionData({
                ...updatedConnectionData,
                type: target.value,
              })}
          />
        </InputWrapper>
        <InputWrapper ref={ref}>
          <InputLabel>{t('editConnection.login')}</InputLabel>
          <InputField
            value={updatedConnectionData.username}
            floatLabel
            placeholder={`${t('connection.name')}`}
            onChange={({ target }) =>
              setUpdatedConnectionData({
                ...updatedConnectionData,
                username: target?.value,
                auth_type:
                  isEmpty(target?.value)
                  && isEmpty(updatedConnectionData.password)
                    ? 'noauth'
                    : 'userpass',
              })}
            error={errorLogin || matchError}
            errorMsg={
              errorLogin
                ? t('editConnection.loginError')
                : t('editConnection.matchError')
            }
          />
        </InputWrapper>
        <InputWrapper>
          <PasswordWrapper>
            <InputLabel>{t('editConnection.password')}</InputLabel>
            <ProxyAction
              connection={connection}
              onClick={resetProxyPassword}
              actionType="resetPassword"
            />
          </PasswordWrapper>
          <InputField
            value={updatedConnectionData.password}
            floatLabel
            placeholder={`${t('connection.description')}`}
            onChange={({ target }) =>
              setUpdatedConnectionData({
                ...updatedConnectionData,
                password: target?.value,
                auth_type:
                  isEmpty(target?.value)
                  && isEmpty(updatedConnectionData.username)
                    ? 'noauth'
                    : 'userpass',
              })}
            error={errorPassword}
            errorMsg={t('editConnection.passwordError')}
          />
        </InputWrapper>

        <WhitelistIpInput
          onListChange={(list) =>
            setListIps(list)}
          ipList={connection?.allow_from?.join('\n')}
          haveInstruction
        />

        <InputWrapper>
          <InputLabel>{t('editConnection.description')}</InputLabel>
          <Description
            onChange={({ target }) =>
              setUpdatedConnectionData({
                ...updatedConnectionData,
                description: target?.value,
              })}
            value={updatedConnectionData?.description}
          />
        </InputWrapper>
        <InputWrapper>
          <ExpereationDateWrapper>
            <div className="date-box">
              <div className="date-time">
                <InputLabel>{t('openVpnEdit.date')}</InputLabel>
                <CustomeDateInputAtom
                  indicator
                  value={updatedConnectionData?.expirationTimestamp}
                  activeData={false}
                  disabled={!updatedConnectionData?.expirationTimestamp}
                  locale={i18n.language}
                  minDate={+new Date()}
                  onChange={(date) => {
                    setUpdatedConnectionData({
                      ...updatedConnectionData,
                      expirationTimestamp: +new Date(date),
                    });
                  }}
                />
                <div className="time-box">
                  <CustomeTimeInputAtom
                    indicator
                    value={updatedConnectionData?.expirationTimestamp}
                    onChange={(date) => {
                      setUpdatedConnectionData({
                        ...updatedConnectionData,
                        expirationTimestamp: +new Date(date),
                      });
                    }}
                    disabled={!updatedConnectionData?.expirationTimestamp}
                    activeData={false}
                    locale={i18n.language}
                  />
                </div>
              </div>
              {updatedConnectionData?.expirationTimestamp != null && (
                <div className="selectTimezone-box">
                  <InputLabel>{t('openVpnEdit.timezone')}</InputLabel>
                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                    timezones={allTimezones}
                    maxMenuHeight={150}
                  />
                </div>
              )}
            </div>
            <div className="activation-box">
              <CheckBox
                value={updatedConnectionData?.expirationTimestamp != null}
                onClick={(status) => {
                  setUpdatedConnectionData({
                    ...updatedConnectionData,
                    expirationTimestamp: status ? new Date() : null,
                  });
                }}
                label={t('openVpnEdit.checkbox')}
                hasLabel
              />
            </div>
          </ExpereationDateWrapper>
        </InputWrapper>
      </InputContainer>

      <div className="bt-save">
        <ButtonLoader
          size="small"
          disabled={
            updatedConnectionData.auth_type === 'noauth'
              ? !isValidNonauth
              : !isValidLogAndPass
          }
          onClick={onSaveConnection}
        >
          {t('editForm.save')}
        </ButtonLoader>
      </div>

      {!profile?.isLogsForbidden && (
        <LogsContainer>
          <div className="title">{t('proSettings.logs')}</div>
          <LogsWeeklyTool
            phone={phone}
            ports={[connection?.port]}
            phoneTariff={selectedTariffId}
            logsVisibleWeeksNumber={
              selectedTariffId?.logsVisibleWeeksNumber || 1
            }
          />
        </LogsContainer>
      )}
    </Wrapper>
  );
};

const LogsContainer = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

  .title {
    color: ${(props: UIProps) =>
    props?.theme?.colors?.darkGray};
    ${(props: UIProps) =>
    props?.theme?.sizes?.font?.medium}
    margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerZero};
  }
`;

const Wrapper = styled.div``;

const InputContainer = styled.div``;

interface IpListContainerProps extends UIProps {
  hasError?: boolean;
}

export const IpListContainer = styled.textarea<IpListContainerProps>`
padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  margin-top: 0px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  width: 100%;
  box-sizing: border-box;
  resize: none;
  border-color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  height: 100px;
  font-family: 'Montserrat', sans-serif !important;
  outline: none;
  :focus {
    border-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
  }

  ${(props: IpListContainerProps) =>
    props?.hasError
    && css`
      border-color: ${(props: UIProps) =>
    props.theme.colors.warning};

      :focus {
        border-color: ${(props: UIProps) =>
    props.theme.colors.warning};
      }
    `}
`;

const Description = styled.textarea`
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  font-family: 'Montserrat', sans-serif !important;
  margin-top: 0px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  width: 100%;
  box-sizing: border-box;
  resize: none;
  border-color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  height: 100px;
  outline: none;
  :focus {
    border-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
  }
`;

const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;
