import React, { useState, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import iconYes from '../../assets/onb_yes.svg';
import { OnboardButton } from '../../screens/OnboardingScreen';
import { VideoInstructionBlock } from '../../styles/ui-controls';
import { updateConnection } from '../../core/store/actions/connections';
import { UIProps } from '../../types';
import { BREAKPOINT_MOBILE } from '../../utils/constants';
import { windowOpen } from '../../utils';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { updateUserProfile } from '../../core/store/actions/user';
import store from '../../core/store/store';

interface OnbConnectionFormProps {
  nextScreen?: Function;
  onboadingData?: any;
}

export const OnbConnectionForm: FunctionComponent<OnbConnectionFormProps> = (
  props,
) => {
  const { nextScreen = () => {}, onboadingData } = props;
  const [step, setStep] = useState(0);
  const { t, i18n } = useTranslation();

  const { profile } = useTypedSelector((state) =>
    state?.user);

  const [phoneInfo] = useState({
    name: onboadingData?.phone?.name || '',
    desc: onboadingData?.phone?.description || '',
  });

  const getVideoInstructions = (video) => {
    if (video === 'vpn') {
      return i18n.language === 'ru'
        ? 'https://youtu.be/V-qu-72mlvw?t=41'
        : 'https://youtu.be/bTgF8WKemQc?t=42';
    }
    if (video === 'ip') {
      return i18n.language === 'ru'
        ? 'https://youtu.be/V-qu-72mlvw?t=68'
        : 'https://youtu.be/bTgF8WKemQc?t=68';
    }
    return '';
  };

  const [steps, setSteps] = useState([
    {
      title: t('onboarding.connection.steps.step1.title'),
      video: 'vpn',
      done: false,
      content: (
        <div className="mobile-pallet">
          {t('onboarding.connection.steps.step1.desc')}
        </div>
      ),
    },
    {
      title: t('onboarding.connection.steps.step2.title'),
      video: 'ip',
      done: false,
      content: (
        <div className="mobile-pallet">
          {t('onboarding.connection.steps.step2.title')}
        </div>
      ),
    },
  ]);

  const next = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
      steps[step].done = true;
      setSteps(steps);
    } else {
      if (
        phoneInfo?.name !== onboadingData?.phone?.name
        || phoneInfo?.desc !== onboadingData?.phone?.description
      ) {
        store.dispatch(
          updateConnection.request({
            id: onboadingData?.phone?.id,
            name: phoneInfo.name,
            description: phoneInfo.desc,
          } as any),
        );
      }

      nextScreen();
    }
  };

  useEffect(() => {
    if (!profile?.visitedOnboarding?.setup) {
      store.dispatch(
        updateUserProfile.request({
          ...profile,
          visitedOnboarding: {
            ...profile?.visitedOnboarding,
            setup: Date.now(),
          },
        }),
      );
    }
  }, []);

  return (
    <Wrapper>
      <h2>{t('onboarding.connection.title')}</h2>

      <div className="info">{t('onboarding.connection.desc')}</div>

      <div className="video-inst">
        <VideoInstructionBlock
          onClick={() =>
            windowOpen(getVideoInstructions(steps[step].video))}
        >
          {t('bot.videoInstructions')}
        </VideoInstructionBlock>
      </div>

      <SetupSteps>
        <div className="dotline" />
        {steps?.map((item, index) =>
          (
            <Step
              key={item.title}
              className={item.done ? 'item-passed' : ''}
              onClick={() => {
                if (!item?.done) return;
                setStep(index);
              }}
            >
              <StepStatus className="status-icon" active={item.done}>
                {item.done ? '' : index + 1}
              </StepStatus>
              <div
                className={`status-label ctitle ${
                  index === step ? 'ctitle-active' : ''
                }`}
              >
                {item.title}
              </div>
            </Step>
          ))}
      </SetupSteps>
      <div className="step-content">{steps[step]?.content}</div>

      <div className="actions-block">
        <OnboardButton
          id="bt"
          className={step === 0 ? 'download_setup_OpenVPN' : 'setup_IPchange'}
          onClick={() =>
            next()}
        >
          {t('onboarding.connection.action')}
        </OnboardButton>
      </div>
    </Wrapper>
  );
};

const Step = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: 10px 40px;
  width: 300px;
  cursor: pointer;

  .ctitle {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    color: ${(props: UIProps) =>
    props.theme.colors.black};
  }

  .ctitle-active {
    font-weight: 700;
  }


  :last-of-type{
    padding-bottom${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    background-color: ${(props: UIProps) =>
    props.theme.colors.white};
    margin-bottom: 60px;
    padding-top: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    :last-of-type{
      padding-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
      margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }
`;

interface StepStatusProps {
  active?: boolean;
}

const StepStatus = styled.div<StepStatusProps>`
  border-radius: ${(props: StepStatusProps) =>
    (props.active ? '100%' : '5px')};
  margin: auto;
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumBold};
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: ${(props: UIProps) =>
    props.theme.colors.aquamarine}
    ${(props: StepStatusProps) =>
    (props.active ? `url(${iconYes})` : 'url()')}
    no-repeat center;
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  margin-bottom: 14px;
  box-sizing: border-box;
  transition: 0.2s;
`;

const SetupSteps = styled.div`
  position: relative;
  display: flex;
  min-height: 0px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  text-align: center;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  margin-bottom: 50px;
  height: 100px;

  .dotline {
    top: 30px;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 1px dashed ${(props: UIProps) =>
    props.theme.colors.aquamarine};
    position: absolute;
    z-index: 1;

    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 1px;
      height: 100%;
      border-top: none;
      border-left: 1px dashed
        ${(props: UIProps) =>
    props.theme.colors.aquamarine};
    }
  }

  .item-passed {
    .status-label {
      opacity: 0.5;
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    height: auto;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .aggree {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23px;
  }

  .step-content {
    width: 100%;
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      min-height: 100px;
      width: 100%;
    }
  }

  .editor-container {
    .desc {
      margin-bottom: 40px;
      line-height: 0.9;
    }

    .input-container-f {
      margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
      text-align: left;
      width: 80%;
      margin: auto;
    }
  }

  .video-inst {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  }

  .actions-block {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 100%;
      #bt {
        width: 100%;
      }
    }
  }

  .actions {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  }

  .icon {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  }

  h2 {
    ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  }

  .info {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  }

  .mobile-pallet {
    border: 1px solid #79797938;
    box-sizing: border-box;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    width: 100%;
    max-width: 1000px;
  }

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    .mobile-pallet {
      background: ${(props: UIProps) =>
    props.theme.colors.softCloud};
      max-width: 100%;
    }
  }
`;
