import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { OnboardButton } from '../../screens/OnboardingScreen';
import { VideoInstructionBlock } from '../../styles/ui-controls';
import { Auth } from '../edit-connection/BasicSettings/Auth';
import theme from '../../styles/ApplicationTheme';
import { UIProps } from '../../types';
import { BREAKPOINT_MOBILE } from '../../utils/constants';
import { windowOpen } from '../../utils';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import store from '../../core/store/store';
import { updateUserProfile } from '../../core/store/actions/user';

interface OnbSetupFormProps {
  nextScreen?: Function;
  phoneId: string;
}

export const OnbSetupForm: FunctionComponent<OnbSetupFormProps> = (props) => {
  const { nextScreen, phoneId } = props;
  const { t, i18n } = useTranslation();

  const { profile } = useTypedSelector((state) =>
    state?.user);

  useEffect(() => {
    if (!profile?.visitedOnboarding?.authorization) {
      store.dispatch(
        updateUserProfile.request({
          ...profile,
          visitedOnboarding: {
            ...profile?.visitedOnboarding,
            authorization: Date.now(),
          },
        }),
      );
    }
  }, []);

  return (
    <Wrapper>
      <h2>{t('proxy.authorization')}</h2>

      <div className="info">{t('onboarding.install.description')}</div>
      <div className="video-inst">
        <VideoInstructionBlock
          onClick={() =>
            windowOpen(
              i18n?.language === 'ru'
                ? 'https://youtu.be/V-qu-72mlvw?t=24'
                : 'https://youtu.be/bTgF8WKemQc?t=25',
            )}
        >
          {t('bot.videoInstructions')}
        </VideoInstructionBlock>
      </div>

      {phoneId === undefined || phoneId === null ? (
        <span className="warning">{t('onboarding.install.warning')}</span>
      ) : (
        <div className="auth-wrapper">
          <Auth isPhoneOwner phoneId={phoneId} />
        </div>
      )}

      <div className="actions">
        <OnboardButton
          id="bt"
          className="verification_code_in_application"
          onClick={() =>
            nextScreen()}
        >
          {t('onboarding.download.action')}
        </OnboardButton>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .video-inst {
    margin: 15px 0 25px 0;
  }

  .auth-wrapper div div div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .warning {
    color: ${theme.colors.warning};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }

  .actions {
    margin-top: 40px;

    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 100%;
      #bt {
        width: 100%;
      }
    }
  }

  .icon {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  }

  h2 {
    ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  }

  .info {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  }

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    .dis-info {
      background-color: ${(props: UIProps) =>
    props.theme.colors.softCloud};
      box-sizing: border-box;
      padding: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
      border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};
      text-align: left;
      ${(props: UIProps) =>
    props.theme.sizes.font.small};
    }
  }
`;
