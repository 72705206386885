export const getWiFiSplitInstructionLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/wifi-split';
    case 'tr':
      return 'https://iproxy.online/tr/blog/wifi-split';
    case 'th':
      return 'https://iproxy.online/th/blog/wifi-split';
    case 'pt':
      return 'https://iproxy.online/pt/blog/wifi-split';
    case 'ua':
      return 'https://iproxy.online/uk/blog/wifi-split';
    case 'es':
      return 'https://iproxy.online/es/blog/wifi-split';
    case 'hi':
      return 'https://iproxy.online/hi/blog/wifi-split';
    case 'vi':
      return 'https://iproxy.online/vi/blog/wifi-split';
    case 'zh':
      return 'https://iproxy.online/zh/blog/wifi-split';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/wifi-split';
  }
};

export const getOVPNInstructionLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/vse-pro-OVPN-konfig-udp-http3-quiq';
    case 'tr':
      return 'https://iproxy.online/tr/blog/ovpn-yapilandirmalari-udp-destegi-http3-quiq-hakkinda-her-sey';
    case 'th':
      return 'https://iproxy.online/th/blog/kar-kahnd-kha-ovpn-udp-http3-quiq';
    case 'pt':
      return 'https://iproxy.online/pt/blog/o-que-e-configuracao-opvn-udp-http3-quiq';
    case 'ua':
      return 'https://iproxy.online/uk/blog/vse-pro-OVPN-konfigi-pidtrimka-udp-http3-quiq';
    case 'es':
      return 'https://iproxy.online/es/blog/todo-sobre-las-configuraciones-ovpn-udp-http3-y-el-soporte-quiq';
    case 'hi':
      return 'https://iproxy.online/hi/blog/sab-kuch-ovpn-config-udp-http3-quiq-samarthan-ke-bare-mein';
    case 'vi':
      return 'https://iproxy.online/vi/blog/tat-ca-ve-cau-hinh-ovpn-udp-http3-ho-tro-quiq';
    case 'zh':
      return 'https://iproxy.online/zh/blog/you-guan-ovpn-pei-zhi-udp-http3-he-quiq-de-suo-you-xin-xi';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/all-about-ovpn-config-udp-http3-quiq';
  }
};

export const getPofInstructionLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/podmena-passive-os-fingerprint-v-mobilnih-proksi-iproxyonline';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonline-mobil-proxyleri-ile-pasif-os-parmak-izi-degistirme';
    case 'th':
      return 'https://iproxy.online/th/blog/thay-the-OS-fingerprint-thut-yarng-nai-mobile-proxies-iProxyonline';
    case 'pt':
      return 'https://iproxy.online/pt/blog/substituicao-de-os-fingerprint-em-proxies-moveis-iproxyonline';
    case 'ua':
      return 'https://iproxy.online/uk/blog/pidmina-passive-os-fingerprint-v-mobilnih-proksi-iproxyonline';
    case 'es':
      return 'https://iproxy.online/es/blog/reemplazar-huella-digital-OS-pasiva-en-proxies-moviles-iProxyonline';
    case 'hi':
      return 'https://iproxy.online/hi/blog/mobile-proxies-mein-passive-OS-fingerprint-badlen-iProxyonline';
    case 'vi':
      return 'https://iproxy.online/vi/blog/thay-the-dau-van-tay-OS-thu-dong-trong-proxy-di-dong-iProxyonline';
    case 'zh':
      return 'https://iproxy.online/zh/blog/zai-mobile-proxies-li-tihuan-bei-dong-OS-zhiwen-iProxyonline';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/replace-passive-OS-fingerprint-in-mobile-proxies-iProxyonline';
  }
};

export const getSMSBotInstructionLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/kak-podkluchit-sms-bot-telegram';
    case 'tr':
      return 'https://iproxy.online/tr/blog/androidden-gelen-smslerin-telegram-botuna-kopyalanmasi-nasil-ayarlanir';
    case 'th':
      return 'https://iproxy.online/th/blog/yang-rai-thi-set-up-sms-bot';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-configurar-uma-segunda-via-de-sms-recebidos-do-android-para-o-bot-no-telegram';
    case 'ua':
      return 'https://iproxy.online/uk/blog/yak-nalashtuvati-dublyuvannya-vhodnyx-sms-z-android-do-telegram-bota';
    case 'es':
      return 'https://iproxy.online/es/blog/como-configurar-sms-bot';
    case 'hi':
      return 'https://iproxy.online/hi/blog/sms-bot-ko-kaise-set-up-karen';
    case 'vi':
      return 'https://iproxy.online/vi/blog/cach-thiet-lap-sms-bot';
    case 'zh':
      return 'https://iproxy.online/zh/blog/ruhe-shezhi-sms-bot';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/how-to-set-up-sms-bot';
  }
};

export const getVoiceAssistantInstructionLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/kak-rabotaiet-udalennaya-smena-ip-v-iproxy-online#t2_2';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonlineda-uzaktan-ip-adresi-degisikligi-nasil-ayarlanir#t2_2';
    case 'th':
      return 'https://iproxy.online/th/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_2';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-configurar-uma-alteracao-remota-de-endereco-ip-em-iproxyonline#t2_2';
    case 'ua':
      return 'https://iproxy.online/uk/blog/yak-nalashtuvati-viddalenu-zminu-ip-adresi-v-iproxyonline#t2_2';
    case 'es':
      return 'https://iproxy.online/es/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_2';
    case 'hi':
      return 'https://iproxy.online/hi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_2';
    case 'vi':
      return 'https://iproxy.online/vi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_2';
    case 'zh':
      return 'https://iproxy.online/zh/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_2';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_2';
  }
};

export const getMacrodroidInstructionLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/kak-rabotaiet-udalennaya-smena-ip-v-iproxy-onlinet#t2_3';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonlineda-uzaktan-ip-adresi-degisikligi-nasil-ayarlanir#t2_3';
    case 'th':
      return 'https://iproxy.online/th/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_3';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-configurar-uma-alteracao-remota-de-endereco-ip-em-iproxyonline#t2_3';
    case 'ua':
      return 'https://iproxy.online/uk/blog/yak-nalashtuvati-viddalenu-zminu-ip-adresi-v-iproxyonline#t2_3';
    case 'es':
      return 'https://iproxy.online/es/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_3';
    case 'hi':
      return 'https://iproxy.online/hi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_3';
    case 'vi':
      return 'https://iproxy.online/vi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_3';
    case 'zh':
      return 'https://iproxy.online/zh/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_3';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_3';
  }
};

export const getRootInstructionLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/kak-rabotaiet-udalennaya-smena-ip-v-iproxy-online#t2_4';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonlineda-uzaktan-ip-adresi-degisikligi-nasil-ayarlanir#t2_4';
    case 'th':
      return 'https://iproxy.online/th/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_4';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-configurar-uma-alteracao-remota-de-endereco-ip-em-iproxyonline#t2_4';
    case 'ua':
      return 'https://iproxy.online/uk/blog/yak-nalashtuvati-viddalenu-zminu-ip-adresi-v-iproxyonline#t2_4';
    case 'es':
      return 'https://iproxy.online/es/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_4';
    case 'hi':
      return 'https://iproxy.online/hi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_4';
    case 'vi':
      return 'https://iproxy.online/vi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_4';
    case 'zh':
      return 'https://iproxy.online/zh/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_4';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_4';
  }
};

export const getUniqueIPInstructionLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/faq/lichnyj-kabinet#538';
    case 'tr':
      return 'https://iproxy.online/tr/faq/kisisel-hesap#540';
    case 'th':
      return 'https://iproxy.online/th/faq/khet-phaeng-khon-tieng#543';
    case 'pt':
      return 'https://iproxy.online/pt/faq/area-pessoal#541';
    case 'ua':
      return 'https://iproxy.online/uk/faq/osobistiy-kabinet#542';
    case 'es':
      return 'https://iproxy.online/es/faq/cuenta-personal#833';
    case 'hi':
      return 'https://iproxy.online/hi/faq/vyaktigat-kshetra#649';
    case 'vi':
      return 'https://iproxy.online/vi/faq/khu-vuc-ca-nhan#925';
    case 'zh':
      return 'https://iproxy.online/zh/faq/ge-ren-qu-yu#741';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/faq/personal-area#539';
  }
};

export const getNotificationWarningLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/put-traffica-ot-telefona-do-programmi-v-kotoroi-vy-ispolzuete-proksi';
    case 'tr':
      return 'https://iproxy.online/tr/blog/telefondan-proxy-kullandiginiz-programa-giden-trafik-yolu';
    case 'th':
      return 'https://iproxy.online/th/blog/traffic-path-jak-android-pai-nai-program-kab-proxy';
    case 'pt':
      return 'https://iproxy.online/pt/blog/o-caminho-do-trafego-do-seu-celular-ate-o-programa-em-que-voce-esta-usando-o-proxy';
    case 'ua':
      return 'https://iproxy.online/uk/blog/shlyah-trafika-vid-telefonu-do-programi-v-yakiy-vi-vikoristovuete-proksi';
    case 'es':
      return 'https://iproxy.online/es/blog/ruta-de-trafico-de-android-a-programa-con-proxy';
    case 'hi':
      return 'https://iproxy.online/hi/blog/android-se-program-tak-proxy-ke-saath-traffic-path';
    case 'vi':
      return 'https://iproxy.online/vi/blog/duong-di-lu-luong-tu-android-den-chuong-trinh-voi-proxy';
    case 'zh':
      return 'https://iproxy.online/zh/blog/cong-android-dao-program-de-dai-li-traffic-lujing';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/traffic-path-from-android-to-program-with-proxy';
  }
};

export const getFAQLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/faq/vip-servera';
    case 'tr':
      return 'https://iproxy.online/tr/faq/vip-sunucular';
    case 'th':
      return 'https://iproxy.online/th/faq/server-vip';
    case 'pt':
      return 'https://iproxy.online/pt/faq/servidores-vip';
    case 'ua':
      return 'https://iproxy.online/uk/faq/vip-server';
    case 'es':
      return 'https://iproxy.online/es/faq/vip-servidores';
    case 'hi':
      return 'https://iproxy.online/hi/faq/servers-vip';
    case 'vi':
      return 'https://iproxy.online/vi/faq/may-chu-vip';
    case 'zh':
      return 'https://iproxy.online/zh/faq/vip-fu-wu-qi';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/faq/vip-servers';
  }
};

export const getServerLocationLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/vybor-servera-pri-rabote-s-iproxyonline';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonline-ile-calisirken-sunucu-secimi';
    case 'th':
      return 'https://iproxy.online/th/blog/kar-leuxk-seirfwexr-meux-thangan-kab-iproxyonline';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-escolher-o-local-certo-do-servidor';
    case 'ua':
      return 'https://iproxy.online/uk/blog/vibir-serveru-pri-roboti-z-iproxyonline';
    case 'es':
      return 'https://iproxy.online/es/blog/como-elegir-la-ubicacion-adecuada-del-servidor';
    case 'hi':
      return 'https://iproxy.online/hi/blog/server-ke-sahi-sthan-ko-kaise-chune';
    case 'vi':
      return 'https://iproxy.online/vi/blog/lam-the-nao-de-chon-dung-vi-tri-cua-may-chu';
    case 'zh':
      return 'https://iproxy.online/zh/blog/ru-he-xuan-ze-zheng-que-de-fu-wu-qi-wei-zhi';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/how-to-choose-the-right-location-of-the-server';
  }
};

export const getGlobalDNSLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/faq/harakteristiki-proksi-podnyatyh-cherez-prilozhenie-iproxy';
    case 'tr':
      return 'https://iproxy.online/tr/faq/h%C4%B1z-proxy-gizliligi-DNS-degisimi';
    case 'th':
      return 'https://iproxy.online/th/faq/lakshana-proxy-nai-kar-prayuk-iproxy';
    case 'pt':
      return 'https://iproxy.online/pt/faq/velocidade-privacidade-de-proxy-falsificacao-de-dns';
    case 'ua':
      return 'https://iproxy.online/uk/faq/shvidkist-privatnist-proksi-pidmina-dns';
    case 'es':
      return 'https://iproxy.online/es/faq/caracteristicas-del-proxy-generadas-a-traves-de-la-aplicacion-proxy';
    case 'hi':
      return 'https://iproxy.online/hi/faq/iproxy-app-dwara-uthaye-gaye-proxy-visheshtayen';
    case 'vi':
      return 'https://iproxy.online/vi/faq/dac-diem-proxy-duoc-nang-cao-qua-ung-dung-iproxy';
    case 'zh':
      return 'https://iproxy.online/zh/faq/tong-guo-iproxy-ying-yong-ti-sheng-de-dai-li-te-zheng';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/faq/proxy-characteristics-raised-through-the-iproxy-application#99';
  }
};

export const getDigitalAssistantLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/kak-rabotaiet-udalennaya-smena-ip-v-iproxy-online#t2_2';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonlineda-uzaktan-ip-adresi-degisikligi-nasil-ayarlanir#t2_2';
    case 'th':
      return 'https://iproxy.online/th/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-configurar-uma-alteracao-remota-de-endereco-ip-em-iproxyonline#t2_2';
    case 'ua':
      return 'https://iproxy.online/uk/blog/yak-nalashtuvati-viddalenu-zminu-ip-adresi-v-iproxyonline#t2_2';
    case 'es':
      return 'https://iproxy.online/es/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_2';
    case 'hi':
      return 'https://iproxy.online/hi/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'vi':
      return 'https://iproxy.online/vi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_2';
    case 'zh':
      return 'https://iproxy.online/zh/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_2';
  }
};

export const getOwnerModeLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/kak-rabotaiet-udalennaya-smena-ip-v-iproxy-online#t2_3';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonlineda-uzaktan-ip-adresi-degisikligi-nasil-ayarlanir#t2_3';
    case 'th':
      return 'https://iproxy.online/th/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-configurar-uma-alteracao-remota-de-endereco-ip-em-iproxyonline#t2_3';
    case 'ua':
      return 'https://iproxy.online/uk/blog/yak-nalashtuvati-viddalenu-zminu-ip-adresi-v-iproxyonline#t2_3';
    case 'es':
      return 'https://iproxy.online/es/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_3';
    case 'hi':
      return 'https://iproxy.online/hi/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'vi':
      return 'https://iproxy.online/vi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_3';
    case 'zh':
      return 'https://iproxy.online/zh/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_3';
  }
};

export const getRootDeviceLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/kak-rabotaiet-udalennaya-smena-ip-v-iproxy-online#t2_5';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonlineda-uzaktan-ip-adresi-degisikligi-nasil-ayarlanir#t2_5';
    case 'th':
      return 'https://iproxy.online/th/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-configurar-uma-alteracao-remota-de-endereco-ip-em-iproxyonline#t2_5';
    case 'ua':
      return 'https://iproxy.online/uk/blog/yak-nalashtuvati-viddalenu-zminu-ip-adresi-v-iproxyonline#t2_5';
    case 'es':
      return 'https://iproxy.online/es/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_5';
    case 'hi':
      return 'https://iproxy.online/hi/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'vi':
      return 'https://iproxy.online/vi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_5';
    case 'zh':
      return 'https://iproxy.online/zh/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_5';
  }
};

export const getMacrodroidLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/kak-rabotaiet-udalennaya-smena-ip-v-iproxy-online#t2_4';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonlineda-uzaktan-ip-adresi-degisikligi-nasil-ayarlanir#t2_4';
    case 'th':
      return 'https://iproxy.online/th/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-configurar-uma-alteracao-remota-de-endereco-ip-em-iproxyonline#t2_4';
    case 'ua':
      return 'https://iproxy.online/uk/blog/yak-nalashtuvati-viddalenu-zminu-ip-adresi-v-iproxyonline#t2_4';
    case 'es':
      return 'https://iproxy.online/es/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_4';
    case 'hi':
      return 'https://iproxy.online/hi/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'vi':
      return 'https://iproxy.online/vi/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_4';
    case 'zh':
      return 'https://iproxy.online/zh/blog/Ways-to-change-your-IP-address-using-iProxy-online';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/Ways-to-change-your-IP-address-using-iProxy-online#t2_4';
  }
};

export const getDevicesLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/devices';
    case 'tr':
      return 'https://iproxy.online/tr/devices';
    case 'th':
      return 'https://iproxy.online/th/devices';
    case 'pt':
      return 'https://iproxy.online/pt/devices';
    case 'ua':
      return 'https://iproxy.online/uk/devices';
    case 'es':
      return 'https://iproxy.online/es/devices';
    case 'hi':
      return 'https://iproxy.online/hi/devices';
    case 'vi':
      return 'https://iproxy.online/vi/devices';
    case 'zh':
      return 'https://iproxy.online/zh/devices';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/devices';
  }
};

export const getDownloadLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/download';
    case 'tr':
      return 'https://iproxy.online/tr/download';
    case 'th':
      return 'https://iproxy.online/th/download';
    case 'pt':
      return 'https://iproxy.online/pt/download';
    case 'ua':
      return 'https://iproxy.online/uk/download';
    case 'es':
      return 'https://iproxy.online/es/download';
    case 'hi':
      return 'https://iproxy.online/hi/download';
    case 'vi':
      return 'https://iproxy.online/vi/download';
    case 'zh':
      return 'https://iproxy.online/zh/download';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/download';
  }
};

export const getSellProxiesLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/kak-zarabatyvat-na-sdache-v-arendu-mobilnih-proksi';
    case 'tr':
      return 'https://iproxy.online/tr/blog/mobil-proxyler-ile-nasil-para-kazanilir-tam-kilavuz';
    case 'th':
      return 'https://iproxy.online/th/blog/how-to-earn-money-on-mobile-proxies-in-any-city-or-country-th';
    case 'pt':
      return 'https://iproxy.online/pt/blog/como-ganhar-dinheiro-com-proxies-moveis-guia-completo';
    case 'ua':
      return 'https://iproxy.online/uk/blog/yak-zaroblyati-na-mobilnih-proksi';
    case 'es':
      return 'https://iproxy.online/es/blog/how-to-earn-money-on-mobile-proxies-in-any-city-or-country-es';
    case 'hi':
      return 'https://iproxy.online/hi/blog/how-to-earn-money-on-mobile-proxies-in-any-city-or-country-hi';
    case 'vi':
      return 'https://iproxy.online/vi/blog/how-to-earn-money-on-mobile-proxies-in-any-city-or-country-vi';
    case 'zh':
      return 'https://iproxy.online/zh/blog/how-to-earn-money-on-mobile-proxies-in-any-city-or-country-zh';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/how-to-earn-money-on-mobile-proxies-in-any-city-or-country';
  }
};

export const getGuideForSettingUpProxiesLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/blog/polniy-guide-po-nastroike-iproxy-online';
    case 'tr':
      return 'https://iproxy.online/tr/blog/iproxyonlineda-mobil-proxyleri-kurma-kilavuzu';
    case 'th':
      return 'https://iproxy.online/th/blog/namthiaw-kan-set-up-mobile-proxies-nai-iproxyonline';
    case 'pt':
      return 'https://iproxy.online/pt/blog/guia-de-configuracao-de-proxy-movel-no-iproxy-online';
    case 'ua':
      return 'https://iproxy.online/uk/blog/gaid-po-nalashtuvannyu-mobilnih-proksi-v-iproxyonline';
    case 'es':
      return 'https://iproxy.online/es/blog/guia-para-configurar-proxies-moviles-en-iproxyonline';
    case 'hi':
      return 'https://iproxy.online/hi/blog/mobile-proxies-set-up-karne-ke-liye-guide-iproxyonline-mein';
    case 'vi':
      return 'https://iproxy.online/vi/blog/huong-dan-cai-dat-proxy-di-dong-trong-iproxyonline';
    case 'zh':
      return 'https://iproxy.online/zh/blog/zhinan-shezhi-shouji-dailli-zai-iproxyonline';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/blog/guide-for-setting-up-mobile-proxies-in-iproxyonline';
  }
};

export const getTermsAndConditionsLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/offerta';
    case 'tr':
      return 'https://iproxy.online/tr/terms-of-service';
    case 'th':
      return 'https://iproxy.online/th/terms-of-service';
    case 'pt':
      return 'https://iproxy.online/pt/terms-of-service';
    case 'ua':
      return 'https://iproxy.online/uk/terms-of-service';
    case 'es':
      return 'https://iproxy.online/es/terms-of-service';
    case 'hi':
      return 'https://iproxy.online/hi/terms-of-service';
    case 'vi':
      return 'https://iproxy.online/vi/terms-of-service';
    case 'zh':
      return 'https://iproxy.online/zh/terms-of-service';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/terms-of-service';
  }
};

export const getPrivacyPolicyLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/privacy-policy';
    case 'tr':
      return 'https://iproxy.online/tr/privacy-policy';
    case 'th':
      return 'https://iproxy.online/th/privacy-policy';
    case 'pt':
      return 'https://iproxy.online/pt/privacy-policy';
    case 'ua':
      return 'https://iproxy.online/uk/privacy-policy';
    case 'es':
      return 'https://iproxy.online/es/privacy-policy';
    case 'hi':
      return 'https://iproxy.online/hi/privacy-policy';
    case 'vi':
      return 'https://iproxy.online/vi/privacy-policy';
    case 'zh':
      return 'https://iproxy.online/zh/privacy-policy';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/privacy-policy';
  }
};

export const getWhiteListIpFaqLink = (locale: string) => {
  switch (locale) {
    case 'ru':
      return 'https://iproxy.online/ru/faq/lichnyj-kabinet#1832';
    case 'tr':
      return 'https://iproxy.online/tr/faq/kisisel-hesap#528';
    case 'th':
      return 'https://iproxy.online/th/faq/khet-phaeng-khon-tieng#530';
    case 'pt':
      return 'https://iproxy.online/faq/personal-area#527';
    case 'ua':
      return 'https://iproxy.online/uk/faq/osobistiy-kabinet#531';
    case 'es':
      return 'https://iproxy.online/es/faq/cuenta-personal#831';
    case 'hi':
      return 'https://iproxy.online/hi/faq/vyaktigat-kshetra#647';
    case 'vi':
      return 'https://iproxy.online/vi/faq/khu-vuc-ca-nhan#923';
    case 'zh':
      return 'https://iproxy.online/zh/faq/ge-ren-qu-yu#739';
    case 'en':
    default:
      /* TODO всегда надо дополнять для разных яхыков */
      return 'https://iproxy.online/faq/personal-area#527';
  }
};
