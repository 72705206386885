import React, { useState, useEffect, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import { TextInProgressAtom } from './TextInProgressAtom';
import { BasicButton, BasicButtonColor } from '../../styles/ui-controls';

type Preset = 'default' | 'fit';
type Size = 'small' | 'medium' | 'large';

interface ButtonLoaderProps {
  onClick?: Function;
  preset?: Preset;
  size?: Size;
  color?: BasicButtonColor;
  disabled?: boolean;
  timer?: number;
  children?: any;
  showProgress?: boolean;
}

export const ButtonLoader: FunctionComponent<ButtonLoaderProps> = (props) => {
  const {
    onClick = () => {},
    children,
    color,
    size = 'medium',
    timer = 1000,
    preset = 'default',
    disabled = false,
    showProgress = false,
  } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, timer);
    }
  }, [show]);

  return (
    <Wrapper preset={preset} size={size}>
      <BasicButton
        color={color}
        onClick={() => {
          if (!disabled) {
            setShow(true);
            onClick();
          }
        }}
        disable={disabled}
        align="center"
        style={{ padding: '10px 32px' }}
        size={size}
        fillWidth
        fillHeight
      >
        <TextInProgressAtom showProgress={!disabled && (show || showProgress)}>
          {children}
        </TextInProgressAtom>
      </BasicButton>
    </Wrapper>
  );
};

const calculateSize = (size: Size, val) => {
  switch (size) {
    case 'medium':
      return val * 1.6;
    case 'large':
      return val * 1.2;
    default:
      return val;
  }
};

interface WrapperProps {
  preset: Preset;
  size?: Size;
}
const Wrapper = styled.div<WrapperProps>`
  ${(props: WrapperProps) => {
    switch (props.preset) {
      case 'fit':
        return css`
          width: fit-content;
          height: ${calculateSize(props.size, 40)}px;
        `;
      default:
        return css`
          width: 100%;
          height: 100%;
        `;
    }
  }}
`;
