import React, {
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../../../types';
import { VideoInstructionBlock } from '../../../styles/ui-controls';
import { MacrosUrlSetting } from '../AdvancedSettings/MacrosUrlSetting';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import ScrollWrapper from '../../common/ScrollWrapper';
import { CheckBox } from '../../atoms/Checkbox';
import {
  getDigitalAssistantLink,
  getOwnerModeLink,
  getRootDeviceLink,
  getMacrodroidLink,
} from '../../../utils/instruction-links';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { windowOpen } from '../../../utils';

interface AboutProps {}

export const About: FunctionComponent<AboutProps> = () => {
  const context = useContext(ConnectionEditViewContext);
  const { t, i18n } = useTranslation();
  const [showMacrosUrl, setShowMacrosUrl] = useState(context?.selectedPhone.macrosUrl?.length > 0);

  const ITEMS = [
    { title: 'Digital assistant', link: getDigitalAssistantLink(i18n.language) },
    { title: 'Owner mode', link: getOwnerModeLink(i18n.language) },
    { title: 'ROOT device', link: getRootDeviceLink(i18n.language) },
    { title: 'Macrodroid app', link: getMacrodroidLink(i18n.language) },
  ];

  return (
    <ScrollWrapper>
      <Wrapper>
        <BaseDescription>
          {t('rotation.setup')}
        </BaseDescription>
        {!context?.isOwner && !context?.permissions?.macrosURL ? null : (
          <>
            <InstructionBlock>
              {
              ITEMS.map(({ title, link }) =>
                (
                  <ProBlockWrapper key={title}>
                    <TextBlock>
                      {title}
                    </TextBlock>
                    <VideoInstructionBlock
                      onClick={() =>
                        windowOpen(link)}
                    >
                      {t('proSettings.instruction')}
                    </VideoInstructionBlock>
                  </ProBlockWrapper>
                ))
              }
            </InstructionBlock>
            <CheckboxWrapper>
              <CheckBox
                value={showMacrosUrl}
                onClick={(status) => {
                  setShowMacrosUrl(status);
                }}
                label={t('rotation.enableMacrodroid')}
                hasLabel
              />
            </CheckboxWrapper>
            {
              showMacrosUrl
                ? <MacrosUrlSetting selectedPhone={context?.selectedPhone} />
                : null
            }
          </>
        )}
      </Wrapper>
    </ScrollWrapper>
  );
};

const Wrapper = styled.div`
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-right:${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const CheckboxWrapper = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const BaseDescription = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

const TextBlock = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  width: 150px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }
`;

const InstructionBlock = styled.ul`
  margin-bottom: 25px;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
`;

const ProBlockWrapper = styled.li`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};

  &:before {
    content: '';
    width: 7px;
    height: 7px;
    background-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.round};
  }

  &:last-child {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;
