import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { UIProps } from '../../types';
import { Alert } from '../../types/phones';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

interface AlertTrafficsComponentProps {
  alert: Alert;
}

export const AlertTrafficsComponent: FunctionComponent<
AlertTrafficsComponentProps
> = ({ alert }) => {
  const getTrafficValue = (value: number) => {
    const valueInGb = value / 1024 ** 3;

    return valueInGb > 1
      ? `${parseFloat(valueInGb.toFixed(2))} GB`
      : `${Math.round(valueInGb * 1024)} MB`;
  };

  return (
    <AlertTrafficsBox>
      <AlertTraffic isLimit={alert.current_in_bytes >= alert.max_in_bytes}>
        IN:
        {' '}
        {getTrafficValue(alert.current_in_bytes)}
        {' '}
        /
        {' '}
        {getTrafficValue(alert.max_in_bytes)}
        {' '}
        <AlertTrafficPercent>
          {((alert.current_in_bytes * 100) / alert.max_in_bytes).toFixed(2)}
          %
        </AlertTrafficPercent>
      </AlertTraffic>
      <AlertTraffic isLimit={alert.current_out_bytes >= alert.max_out_bytes}>
        OUT:
        {' '}
        {getTrafficValue(alert.current_out_bytes)}
        {' '}
        /
        {' '}
        {getTrafficValue(alert.max_out_bytes)}
        {' '}
        <AlertTrafficPercent>
          {((alert.current_out_bytes * 100) / alert.max_out_bytes).toFixed(2)}
          %
        </AlertTrafficPercent>
      </AlertTraffic>
      <AlertTraffic
        isLimit={alert.current_total_bytes >= alert.max_total_bytes}
      >
        TOTAL:
        {' '}
        {getTrafficValue(alert.current_total_bytes)}
        {' '}
        /
        {' '}
        {getTrafficValue(alert.max_total_bytes)}
        {' '}
        <AlertTrafficPercent>
          {((alert.current_total_bytes * 100) / alert.max_total_bytes).toFixed(
            2,
          )}
          %
        </AlertTrafficPercent>
      </AlertTraffic>
    </AlertTrafficsBox>
  );
};

const AlertTrafficsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

interface AlertTrafficProps extends UIProps {
  isLimit?: boolean;
}

const AlertTraffic = styled.div<AlertTrafficProps>`
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.spaceGray};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: AlertTrafficProps) =>
    (props?.isLimit ? props.theme.colors.warning : props.theme.colors.greenMud)};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
  }
`;

const AlertTrafficPercent = styled.span`
  font-weight: bold;
  margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;
