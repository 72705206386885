import React from 'react';
import styled from 'styled-components';
import Markdown from 'markdown-it';

import { UIProps } from '../types';
import {
  BREAKPOINT_MOBILE,
  BREAKPOINT_TABLET,
} from '../utils/constants';
import { ReactMD } from '../components/common/ReactMarkdown';

export function WebContentRender({ content } : any) {
  const md = Markdown({
    html: true,
    linkify: true,
    typographer: true,
  });

  return (
    <Web>
      <ReactMD
        markdown={md.render(content)}
      />
    </Web>
  );
}

const Web = styled.div`
   box-sizing: content-box;
   a {
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    text-decoration: none;
    transition: .3s;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }

  blockquote {
    background-color: #fdf7f7;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    border-left: 6px solid #f7f3f3;
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    margin: 0px 35px 0px 35px;
  }


  img {
    display: block;
    cursor: pointer;
    background: #C4C4C4;
    width: 50%;
    margin: auto;
  }

  p {
    margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    text-align: justify;
    font-weight: normal;
    margin-bottom: 20px;
     @media only screen and (max-width: 500px) {
      font-size: 12px;
     }
  }

  ul {
    text-align: start;
    background: ${(props: UIProps) =>
    props.theme.colors.lightSky};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    padding: 15px 35px 15px 35px;
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    box-sizing: content-box;

    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    }
  }

  ol {
    padding: 0px 35px 0px 35px;
  }

  iframe {
    display: block;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    width: 500px;
    height: 300px;
    margin: 20px auto 20px;
    box-sizing: border-box;

    @media only screen and (max-width: ${BREAKPOINT_TABLET}px) {
      width: 100%;
      max-width: 70%;
      height: 35vw;
      max-height: 400px;
      min-width: 275px;
      min-height: 180px;
    }
  }

  h1 {
    text-align: left;
    ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
    margin: 32px 0;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.medium};
    }
  }

  h2 {
    font-weight: bold;
    margin: 15px 0;
    padding: 0;
    font-size: 16px;
    line-height: 20px;

     @media only screen and (max-width: 880px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  h3 {
    margin: ${(props: UIProps) =>
    props.theme.sizes.padding.midsmallZero};
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
  }
`;
