// useWidgetPolicyHook

import { Phone } from '../types/phones';
import { PlansType } from '../types';

type WidgetPermissionType = 'actionFixLte' | 'customActions' | 'bigDaddyPro';

const Permissions = {
  actionFixLte: [PlansType.BigDaddyPro.toString()],
  customActions: [PlansType.BigDaddyPro.toString()],
};

export const useWidgetPolicyHook = (phone: Phone) =>
  ({
    hasWidgetPermission: (widget: WidgetPermissionType): boolean =>
      (phone?.activePlans?.find((i) =>
        Permissions[widget]?.includes(i?.id))) != null,
  });
