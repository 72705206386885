import React from 'react';
import styled from 'styled-components';
import { ThemeUI } from '../types';

export function ContentComponent({ children }: any) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: ${({ theme }) =>
    (theme as ThemeUI).sizes.maxWidth};
  width: 100%;
  margin: auto;
  
`;
