import { FunctionComponent } from 'react';

import modalStack, { ConfirmationModalParams, ModalStackType } from '../modal';

export const useModalHook = () => {
  const EVENT_NAME = 'modalEvent';
  const MODAL_CONFIRMATION_INIT = 'MODAL_CONFIRMATION_INIT';

  return {
    show: (templateKey: ModalStackType, props?: any, closeBt = true) => {
      document.dispatchEvent(new CustomEvent(EVENT_NAME, {
        detail: {
          content: modalStack.hasOwnProperty(templateKey) ? modalStack[templateKey] : null,
          params: props,
          key: templateKey,
          status: true,
          hasCloseBt: closeBt,
        },
      }));
    },
    hide: () => {
      document.dispatchEvent(new CustomEvent(EVENT_NAME, {
        detail: {
          content: null,
          status: false,
        },
      }));
    },
    listen: (callback) => {
      document.addEventListener(EVENT_NAME, callback);
    },
    activeConfirmation: (component: FunctionComponent, props?: ConfirmationModalParams) => {
      document.dispatchEvent(new CustomEvent(MODAL_CONFIRMATION_INIT, {
        detail: {
          content: component,
          params: props,
          status: true,
        },
      }));
    },
    _listenConfirmationParams: (callback) => {
      document.addEventListener(MODAL_CONFIRMATION_INIT, callback);
    },
    removeEven: () => {
      document.removeEventListener(EVENT_NAME, () => {});
    },
  };
};
