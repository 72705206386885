import React, { useState, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../elements';

interface NumberInputComponentProps {
  value: number;
  setValue: (value) => void;
  from: number;
  to: number;
  isChangeRotation?: boolean;
  isMultiChangeRotation?: boolean;
}

export const NumberInputComponent: FunctionComponent<
NumberInputComponentProps
> = ({
  setValue, value, from, to, isChangeRotation, isMultiChangeRotation,
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(isChangeRotation ? value : from);

  return (
    <>
      <InputField
        value={inputValue}
        floatLabel
        onChange={(e) => {
          const currentValue = e.target.value.replace(/ /g, '');
          if (Number.isNaN(+currentValue) || +currentValue > to) {
            return;
          }
          if (+currentValue < from) {
            setInputValue(+currentValue);
            return;
          }
          setInputValue(+currentValue);
          setValue(isMultiChangeRotation
            ? { value: +currentValue, label: '' }
            : +currentValue);
        }}
        onBlur={() => {
          setInputValue(value);
        }}
      />
      <span style={{ fontSize: '12px' }}>
        {t('connection.enterValueFromTo', {
          from,
          to,
        })}
      </span>
    </>
  );
};
