import React, { useState, FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { BasicButton, InputLabel, InputWrapper } from '../../../styles/ui-controls';
import { InputField } from '../../../elements';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { Api } from '../../../core/api';
import store from '../../../core/store/store';
import { updateUserProfile } from '../../../core/store/actions/user';
import { debounceCall } from '../../../utils';

interface IntegrationSettingsFormProps {

}

export const debounceLoad = debounce((callback) => {
  callback();
}, 500, {
  leading: false,
  trailing: true,
});

export const IntegrationSettingsForm: FunctionComponent<IntegrationSettingsFormProps> = () => {
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const { t } = useTranslation();
  const [changesRegistered, setChangesRegistered] = useState(false);
  const [validationRuning, setValidationRuning] = useState(false);

  const [integratonKeys, setIntegratonKeys] = useState({
    octobrowserApiKey: profile?.octobrowserApiKey,
    scenumUserToken: profile?.scenumUserToken,
  });

  const [erros, setErrors] = useState({
    octobrowserApiKey: false,
    scenumUserToken: false,
  });

  const hasError = useMemo(() =>
    Object.values(erros).find((error) =>
      error), [erros]);

  const validateOctobroserToken = async () => {
    try {
      setValidationRuning(true);
      const { correct } = (await Api.post('/integrations/octobrowser/check-api-token', {
        token: integratonKeys?.octobrowserApiKey,
      })).data;
      setErrors({ ...erros, octobrowserApiKey: !correct });
      setChangesRegistered(false);
      setValidationRuning(false);
    } catch (e) {
      console.error('Error:> [integration-settings-form.tsx] - octo validation :=', e);
      setErrors({ ...erros, octobrowserApiKey: true });
      setChangesRegistered(false);
      setValidationRuning(false);
    }
  };

  const validateScenumToken = async () => {
    try {
      setValidationRuning(true);
      const { correct } = (await Api.post('/integrations/scenum/check-api-token', {
        token: integratonKeys?.scenumUserToken,
      })).data;
      setErrors({ ...erros, scenumUserToken: !correct });
      setChangesRegistered(false);
      setValidationRuning(false);
    } catch (e) {
      console.error('Error:> [integration-settings-form.tsx] - octo validation :=', e);
      setErrors({ ...erros, scenumUserToken: true });
      setChangesRegistered(false);
      setValidationRuning(false);
    }
  };

  const saveIntegrationKeys = () => {
    try {
      store.dispatch(updateUserProfile.request(integratonKeys));
      return null;
    } catch (e) {
      console.error('Error:> [integration-settings-form.tsx] - octo validation :=', e);
      return null;
    }
  };

  return (
    <Wrapper>
      <InputWrapper>
        <InputLabel>Octobrowser API Token</InputLabel>
        <InputField
          value={integratonKeys?.octobrowserApiKey || null}
          floatLabel
          showLoader={validationRuning}
          error={erros?.octobrowserApiKey}
          errorMsg="Incorrect token"
          onMouseLeave={async () => {
            debounceCall(async () => {
              if (changesRegistered) {
                await validateOctobroserToken();
              }
            });
          }}
          onBlur={async () => {
            if (changesRegistered) {
              await validateOctobroserToken();
            }
          }}
          placeholder="Octobrowser API Token"
          onChange={({ target }) => {
            setIntegratonKeys({ ...integratonKeys, octobrowserApiKey: target?.value });
            setChangesRegistered(true);
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Scenum API Token</InputLabel>
        <InputField
          value={integratonKeys?.scenumUserToken || null}
          floatLabel
          showLoader={validationRuning}
          error={erros?.scenumUserToken}
          errorMsg="Incorrect token"
          onMouseLeave={async () => {
            debounceCall(async () =>
              changesRegistered && await validateScenumToken());
          }}
          onBlur={async () => {
            if (changesRegistered) {
              await validateScenumToken();
            }
          }}
          placeholder="Scenum API Token"
          onChange={({ target }) => {
            setIntegratonKeys({ ...integratonKeys, scenumUserToken: target?.value });
            setChangesRegistered(true);
          }}
        />
      </InputWrapper>
      <BasicButton
        size="small"
        disable={changesRegistered || hasError}
        onClick={() => {
          if (!hasError) {
            saveIntegrationKeys();
          }
        }}
      >
        {t('editForm.save')}
      </BasicButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`

`;
