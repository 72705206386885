import React, { useState, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { BaseModalFormParams } from '../../../modal';
import { BasicButton, WrapperScreen } from '../../../styles/ui-controls';
import { InputField } from '../../../elements';
import store from '../../../core/store/store';
import { loadUserPhones } from '../../../core/store/actions/connections';
import { useModalHook } from '../../../hooks';
import { Api } from '../../../core/api';
import {
  FormContainer, Wrapper, GroupsList, GroupsItem,
} from './styles';
import { ProgressBarComponent } from './progress-bar-component';
import { useMassActionsHook } from '../../../hooks/useMassActionsHook';

interface MultiRenameProps extends BaseModalFormParams {}

export const MultiRename: FunctionComponent<MultiRenameProps> = (props) => {
  const { modalParams } = props;

  const modalOptions = useModalHook();

  const { t } = useTranslation();
  const {
    selectedPhones, setSelectedPhones, getPhoneList, removePhone,
  } = useMassActionsHook();

  const [progressView, setProgressView] = useState(false);
  const [buyCount, setBuyCount] = useState(0);

  useEffect(() => {
    if (modalParams?.selectedPhones != null) {
      getPhoneList(modalParams, true);
    }
  }, [modalParams?.selectedPhones]);

  useEffect(() => {
    if (progressView && selectedPhones != null) {
      const request = selectedPhones.map(async (phone) =>
        Api.post(
          '/phone/update',
          {
            id: phone.id,
            name: phone.newName,
          },
          null,
          false,
        ).then(() =>
          setBuyCount(() =>
            buyCount + 1)));

      Promise.all(request).then(() => {
        store.dispatch(loadUserPhones.request(null));
        setTimeout(() => {
          modalOptions.hide();
        }, 1200);
      });
    }
  }, [progressView]);

  return (
    <WrapperScreen>
      <Wrapper
        minWidth="900px"
      >
        <FormContainer
          marginBottom="100px"
        >
          <div className="info">
            <div className="title">{t('massActions.multiRename')}</div>
          </div>
          <div className="scroll-container">
            <Scrollbars>
              <div>
                {selectedPhones != null ? (
                  <GroupsList>
                    {selectedPhones.map((phone) =>
                      (
                        <GroupsItem
                          key={`phone_list_${phone.id}`}
                        >
                          <div className="item-content">{phone?.name}</div>
                          <InputField
                            value={phone?.newName}
                            onChange={({ target }) => {
                              setSelectedPhones(
                                selectedPhones.map((sp) =>
                                  (phone.id === sp.id
                                    ? { ...sp, newName: target.value }
                                    : sp)),
                              );
                            }}
                          />
                          <div
                            className="close"
                            onClick={() => {
                              removePhone(phone?.id);
                            }}
                          />
                        </GroupsItem>
                      ))}
                  </GroupsList>
                ) : null}
              </div>
            </Scrollbars>
            <div className="total-goups">
              {`${t('connection.total')}: ${
                selectedPhones?.length != null ? selectedPhones?.length : 0
              }`}
            </div>
            <div className="savebt">
              <BasicButton
                size="small"
                onClick={() =>
                  setProgressView(true)}
              >
                {t('massActions.multiRename')}
              </BasicButton>
            </div>
          </div>
        </FormContainer>
        {progressView && (
          <ProgressBarComponent
            buyCount={buyCount}
            selectedPhones={selectedPhones}
          />
        )}
      </Wrapper>
    </WrapperScreen>
  );
};
