import React, {
  InputHTMLAttributes,
  forwardRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { ReactComponent as ProgressIcon } from '../assets/ipchange-icon.svg';
import eyeIcon from '../assets/eye.svg';
import slashEyeIcon from '../assets/eye-slash.svg';

import { UIProps } from '../types';
import { BREAKPOINT_MOBILE } from '../utils/constants';

type InputFieldType = 'number' | 'password';
// @ts-ignore
interface InputFieldProps extends InputHTMLAttributes<any> {
  onChange?: Function;
  onFocus?: Function;
  onBlur?: Function;
  onMouseLeave?: Function;
  onClick?: Function;
  floatLabel?: boolean;
  error?: boolean;
  errorMsg?: string;
  disabled?: boolean;
  readOnly?: boolean;
  multiline?: boolean;
  number?: boolean;
  minimum?: number;
  row?: number;
  type?: InputFieldType;
  style?: any;
  showLoader?: boolean;
  showPasswordIcon?: boolean;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const {
    placeholder,
    errorMsg = 'error',
    showLoader = false,
    error = false,
    value = null,
    disabled = false,
    readOnly = false,
    number = false,
    minimum = null,
    floatLabel = false,
    type = null,
    multiline = false,
    style,
    row = 1,
    showPasswordIcon = false,
    onChange = () => {},
    onClick = () => {},
    onMouseLeave = () => {},
    onFocus = () => {},
    onBlur = () => {},
  } = props;
  const [active, setActive] = useState(!isEmpty(value));
  const [showPsw, setShowPsw] = useState(false);
  const inputType = showPasswordIcon
    ? (showPsw ? 'text' : 'password')
    : type != null ? type : 'text';

  return (
    <Wrapper active={active}>
      <InputWrapper>
        {multiline ? (
          <Textarea
            row={row}
            value={value || ''}
            onClick={() => {
              onClick();
              setActive(true);
            }}
            error={error}
            onBlur={(e) => {
              onBlur(e);
              if (isEmpty(value)) {
                setActive(false);
              }
            }}
            onChange={(e) => {
              onChange(e);
            }}
          />
        ) : (
          <InputContainer>
            <Input
              style={style}
              ref={ref}
              disabled={disabled}
              readOnly={readOnly}
              onFocus={() =>
                onFocus()}
              value={value || ''}
              type={inputType}
              placeholder={floatLabel ? '' : placeholder}
              onClick={() => {
                onClick();
                setActive(true);
              }}
              onMouseLeave={() =>
                onMouseLeave()}
              error={error}
              onBlur={(e) => {
                onBlur(e);
                if (isEmpty(value)) {
                  setActive(false);
                }

                if (value < minimum) {
                  onChange({ target: { value: minimum } });
                }
              }}
              onChange={(e) => {
                if (number && minimum != null) {
                  if (Number.isNaN(+e.target.value)) {
                    return null;
                  }
                }
                onChange(e);
                return null;
              }}
            />
            {
              showPasswordIcon
              && (
              <div
                onClick={() =>
                  setShowPsw(!showPsw)}
              >
                <img
                  src={showPsw ? eyeIcon : slashEyeIcon}
                  alt="Show password icon"
                />
              </div>
              )
            }
            {showLoader ? (
              <LoaderContainer>
                <Progress />
              </LoaderContainer>
            ) : null}
          </InputContainer>
        )}
      </InputWrapper>

      {error ? (
        <Error>
          {' '}
          {errorMsg}
          {' '}
        </Error>
      ) : null}
    </Wrapper>
  );
});

interface ProgressProps extends UIProps {
}

const Progress = styled(ProgressIcon)<ProgressProps>`

  * {
    fill: ${(props) =>
    props.theme.colors.deepBlue};
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;

  img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 23%;
    right: 15px;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface WrapperProps extends UIProps {
  active: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;

  .full {
    width: 100%;
    position: relative;
  }
`;

interface InputProps extends UIProps {
  error?: boolean;
}

export const Input = styled.input<InputProps>`
  width: 100%;
  box-sizing: border-box;
  height: 38px;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  border: 1px solid
    ${(props: InputProps) =>
    (props.error ? props.theme.colors.warning : props.theme.colors.darkGray)};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  outline: none;
  font: 14px "Montserrat", sans-serif;

  ::placeholder {
    font: 14px "Montserrat", sans-serif;
  }
  :disabled, :read-only{
    background-color: #5f5f5f1c;
  }

  :read-only {
    :hover {
      opacity: 0.9;
    }
  }

  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  :focus {
    border-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ::placeholder {
      ${(props: UIProps) =>
    props.theme.sizes.font.small};
    }
  }
`;

interface TextareaProps extends UIProps {
  error?: boolean;
  row: number;
}

export const Textarea = styled.textarea<TextareaProps>`
  width: 100%;
  outline: none !important;
  box-sizing: border-box;
  height: ${(props: TextareaProps) =>
    `${40 * props.row}px`};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  resize: none;
  border: 1px solid
    ${(props: TextareaProps) =>
    (props.error ? props.theme.colors.warning : props.theme.colors.darkBlue)};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};

  :focus {
    outline: none !important;
    border-color: ${(props: TextareaProps) =>
    props.theme.colors.darkBlue};
  }
`;

export const Error = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerZero};
`;
