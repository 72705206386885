import React, { useEffect, FunctionComponent, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import { ConnectionEditViewContext } from '../../context/ConnectionEditViewContext';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { About } from './SMSApp/About';
import { Send } from './SMSApp/Send';
import { SmsHistoryForm } from './HistorySettings/SmsHistoryForm';
import { WarningLabel } from '../../elements/warning-label';
import { PlansType } from '../../types';

interface SMSAppFormProps {
}

export const SMSAppForm: FunctionComponent<SMSAppFormProps> = () => {
  const context = useContext(ConnectionEditViewContext);
  const { t } = useTranslation();

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'about',
      title: `${t('connection.about')}`,
      component: (<About />),
    },
    {
      key: 'send',
      title: `${t('sms.send')}`,
      component: (
        <Send
          hasSmsPermission={
            context?.selectedPhone?.activePlans[0]?.id === PlansType.BigDaddyPro
          }
          phoneId={context?.selectedPhone?.id}
        />
      ),
    },
    {
      key: 'sms history',
      title: `${t('history.smsHistoryHeading')}`,
      component: (
        <SmsHistoryForm
          hasSmsPermission={
            context?.selectedPhone?.activePlans[0]?.id === PlansType.BigDaddyPro
          }
        />
      ),
    },
  ];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Wrapper>
      <WarningLabel
        message="policyMsg.exclusive"
      />

      <ReactTooltip />
      <TabsAtom config={tabsConfig} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
