import React, {
  useState,
  useEffect,
  FunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DnDList } from './types/phones';
import { formatDnDList, getData } from './utils/dnd-utils';
import { DnDListWrapper } from './styles/ui-controls';
import { NON_GROUP } from './utils/constants';

export const DnDPHoneGroupList: FunctionComponent<DnDList> = (
  props,
) => {
  const {
    rawGroup, onSave = () => {}, selectedGroup, rawPhones,
  } = props;
  const [phones, setPhones] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedGroup != null) {
      const data = selectedGroup?.value !== NON_GROUP
        ? getGroupPhones(rawGroup, selectedGroup)
        : getNonGroupPhones(rawGroup);

      if (data != null) {
        setPhones(formatDnDList(data));
      }
    }
  }, [selectedGroup]);

  const getGroupPhones = (rawGroup, selectedGroup) => {
    const connection = rawGroup?.find((i) =>
      i?.name === selectedGroup?.value);
    if (connection != null) {
      return connection?.connections?.reduce((accum, val) => {
        const item = rawPhones?.find((i) =>
          i.id === val);
        if (item != null && item) {
          accum.push(item);
        }
        return accum;
      }, []);
    }
    return null;
  };

  const getNonGroupPhones = (rawGroup) => {
    const hasNonGroup = rawGroup?.find((i) =>
      i?.name === NON_GROUP);

    if (hasNonGroup) {
      return hasNonGroup?.connections?.reduce((accum, val) => {
        const item = rawPhones?.find((i) =>
          i.id === val);
        if (item != null && item) {
          accum.push(item);
        }
        return accum;
      }, []);
    }
    const phoneWithGroup = rawGroup?.reduce((accum, val) => {
      const newAccum = [...accum, ...val.connections];
      return newAccum;
    }, []);

    return rawPhones?.filter((i) =>
      !phoneWithGroup.includes(i.id));
  };

  const onDragEnd = (result) => {
    const data = getData(result, phones, true);

    if (!data) return;

    setPhones(data.sortedData);
    onSave(data.dataForSave);
  };

  return (
    <DnDListWrapper>
      {Array.isArray(phones) ? (
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) =>
                (
                  <div
                    {...provided.droppableProps}
                    className="group-container"
                    ref={provided.innerRef}
                  >
                    {phones?.map((v, i) =>
                      (
                        <Draggable key={v.id} draggableId={v.id} index={i}>
                          {(provided) =>
                            (
                              <div
                                className="item"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {v.content?.name}
                              </div>
                            )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <div className="empty-list">
          {selectedGroup != null ? t('groupForm.empty') : null}
        </div>
      )}
    </DnDListWrapper>
  );
};
