import React, {
  useState, useEffect, FunctionComponent, useRef,
} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import iconRemove from './assets/phone_icons/remove_icon.svg';
import { ReactComponent as IconSettings } from './assets/phone_icons/setting_icon.svg';
import { ConfirmForm } from './components/common/ConfirmForm';
import { DnDList } from './types/phones';
import { getData, formatDnDList } from './utils/dnd-utils';
import { DnDListWrapper } from './styles/ui-controls';

export const DnDGroupList: FunctionComponent<DnDList> = (props) => {
  const {
    groupList,
    onSave = () => {},
    onRemove = () => {},
    onEdit = () => {},
  } = props;

  const [items, setItems] = useState<any>(null);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const { t } = useTranslation();
  const prevGroupListRef = useRef<any>(null);

  useEffect(() => {
    if (JSON.stringify(prevGroupListRef.current) !== JSON.stringify(groupList)) {
      setItems(formatDnDList(groupList));
      prevGroupListRef.current = groupList;
    }
  }, [groupList]);

  const onDragEnd = (result) => {
    const data = getData(result, items, false);

    setItems(data.sortedData);
    onSave(data.dataForSave);
  };

  return items != null ? (
    <DnDListWrapper icon={iconRemove}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) =>
            (
              <div
                {...provided.droppableProps}
                className="group-container"
                ref={provided.innerRef}
              >

                {items?.map((v, i) =>
                  (
                    removeConfirmation && i === groupId ? (
                      <ConfirmForm
                        hasScrollTo
                        text={t('groupForm.removalConfirmation')}
                        onApprove={() => {
                          onRemove(v.content?.name);
                          setRemoveConfirmation(false);
                          setTimeout(() =>
                            setGroupId(null), 0);
                        }}
                        onCancel={() => {
                          setRemoveConfirmation(false);
                          setGroupId(null);
                        }}
                      />
                    )
                      : (
                        <Draggable key={v.id} draggableId={v.id} index={i}>
                          {(provided) =>
                            (

                              <div
                                className="item"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {v.content?.name}
                                <div
                                  className="edit"
                                  onClick={() =>
                                    onEdit(v)}
                                >
                                  <IconSettings />
                                </div>
                                <div
                                  className="close"
                                  onClick={() => {
                                    setRemoveConfirmation(true);
                                    setGroupId(i);
                                  }}
                                />
                              </div>
                            )}
                        </Draggable>
                      )
                  ))}
                {provided.placeholder}

              </div>
            )}
        </Droppable>

      </DragDropContext>
    </DnDListWrapper>
  ) : null;
};
