export const routes = {

  common: {
    dashboard: '/',
    faq: '/faq',
    api: '/api-page',
    ref: '/ref',
    recover: '/app/recover',
  },

  connection: {
    menu: 'con-menu',
    access: 'con-access',
    baseinfo: 'con-baseinfo',
    notification: 'con-notification',
    dns: 'con-dns',
    rotation: 'con-rotation',
    wblist: 'con-wblist',
    team: 'con-team',
    makro: 'con-makro',
    rotationurl: 'con-rurl',
    logs: 'con-logs',
    fordev: 'con-dev',
    iphistory: 'con-iphistory',
    stats: 'con-stats',
  },
};
