import { debounce } from 'lodash';

export enum NotificationStreams {
  IP_CHANGE,
  CONNECTION_EDIT_FORM,
  PROFILE_EDIT,
}

const debounceCall = debounce((callback) => {
  callback();
}, 100, {
  leading: false,
  trailing: true,
});
export const useNotificatorHook = () => {
  const EVENT_NAME = 'iproxy_notificator_events';

  return {
    _listen: (callback) => {
      document.addEventListener(EVENT_NAME, callback);
    },

    notify: (content, stream = null) => {
      debounceCall(() => {
        document.dispatchEvent(new CustomEvent(EVENT_NAME, {
          detail: {
            content,
            stream,
          },
        }));
      });
    },
  };
};
