import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getUrlParameterByName, showNotification } from '../utils';
import { ButtonLoader } from '../components/atoms';
import { Api } from '../core/api';
import { UIProps } from '../types';
import { landingPath } from '../constants';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import store from '../core/store/store';
import { loadUserInfo, updateApiKey } from '../core/store/actions/user';

import { ChangePasswordForm } from '../components/common/ChangePasswordForm';
import { useChangePasswordHook } from '../hooks/useChangePasswordHook';

interface RecoverScreenProps {}

export const RecoverScreen: FunctionComponent<RecoverScreenProps> = () => {
  const { t } = useTranslation();
  const [recoveryData, setRecoveryData] = useState(null);
  const { changePasswordParams } = useChangePasswordHook();
  const {
    password,
    isFormWithoutOldPasswordValid,
  } = changePasswordParams;

  const [token, setToken] = useState(null);
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const id = profile?.id;
  const apiKey = profile?.api_key;

  useEffect(() => {
    setTimeout(() => {
      Api.get(`/recover/${getUrlParameterByName('tr')}`).then(({ data }) => {
        setToken(getUrlParameterByName('tr'));
        setRecoveryData(data?.hasOwnProperty('error') ? null : data);
        store.dispatch(loadUserInfo.request(null, null));
      });
    }, 100);
  }, []);

  const $changePass = async () => {
    if (isFormWithoutOldPasswordValid()) {
      try {
        await Api.post(`/recover/${token}`, {
          password,
        }).then(() => {
          if (apiKey) {
            store.dispatch(updateApiKey.request({ userId: id }));
          }
          window.localStorage.clear();
          window.location.href = landingPath;
        });
      } catch (e) {
        console.error(e);
        showNotification(`${t('notification.changePswError')}`, 'error');
      }
    }
  };

  return (
    <Wrapper>
      <Title>{t('profile.setNewPasswordForm')}</Title>
      {recoveryData != null ? (
        <>
          <ChangePasswordForm
            changePasswordParams={changePasswordParams}
          />

          <ButtonLoader
            onClick={() => {
              if (password != null) {
                $changePass();
              }
            }}
          >
            {t('profile.changePsw')}
          </ButtonLoader>
        </>
      ) : (
        <Notoken>{t('profile.recoverTokenInvalid')}</Notoken>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;

const Title = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.largeMedium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

const Notoken = styled.div`
  background-color: ${(props: UIProps) =>
    props.theme.colors.lightGray};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;
