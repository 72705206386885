import { createReducer } from 'typesafe-actions';

import { ServersStore } from '../../../types';
import {
  loadServersList,
  loadUserServers,
} from '../actions/servers';

const initialAppState: ServersStore = {
  data: null,
  userServers: null,
};

const serversReducer = createReducer(initialAppState)
  .handleAction(loadServersList.request, (state: ServersStore) =>
    ({ ...state, loading: true }))
  .handleAction(loadServersList.success, (state: ServersStore, { payload }) =>
    ({ ...state, loading: false, data: payload }))
  .handleAction(loadServersList.failure, (state: ServersStore) =>
    ({ ...state, loading: false }))

  .handleAction(loadUserServers.request, (state: ServersStore) =>
    ({ ...state, loading: true }))
  .handleAction(loadUserServers.success, (state: ServersStore, { payload }) =>
    ({ ...state, loading: false, userServers: payload }))
  .handleAction(loadUserServers.failure, (state: ServersStore) =>
    ({ ...state, loading: false }));

export default serversReducer;
export type ServersState = ReturnType<typeof serversReducer>;
