import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconIpChange } from '../../assets/ipchange-icon.svg';

interface LoadingProgressProps {
  color: string;
}

export const LoadingProgress: FunctionComponent<
LoadingProgressProps
> = (props) => {
  const { color } = props;
  return (
    <LoadingProgressComponent color={color} />
  );
};

const LoadingProgressComponent = styled(IconIpChange)`
  margin: auto;
  display: flex;

  * {
    fill: ${(props) =>
    props.color};
  }
`;
