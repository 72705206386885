import { useState } from 'react';
import { Phone, RenamePhone } from '../types/phones';
import { useTypedSelector } from '../core/store/selectors/type-selector';

export function useMassActionsHook() {
  const [selectedPhones, setSelectedPhones] = useState<Phone[] | RenamePhone[] | null>(null);
  const phones = useTypedSelector(({ connections }) =>
    connections);

  const getPhoneList = (modalParams, rename) => {
    const filteredPhones = phones?.data?.filter((i) =>
      modalParams?.selectedPhones.includes(i?.id));

    if (rename) {
      filteredPhones.map((e) =>
        ({ id: e.id, newName: e.name, name: e.name }));
    }

    setSelectedPhones(filteredPhones);
  };

  const removePhone = (phoneId: string) => {
    const list = (selectedPhones as Phone[])?.filter((i) =>
      i.id !== phoneId);

    setSelectedPhones(list as any);
  };

  return {
    phones,
    selectedPhones,
    setSelectedPhones,
    getPhoneList,
    removePhone,
  };
}
