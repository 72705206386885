import React, {
  useState,
  useEffect,
  FunctionComponent,
  useContext,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  EditModalActionContainer,
  EditModalSave,
  InputLabel,
  InputWrapper,
} from '../../../styles/ui-controls';
import { DropdownElement, InputField } from '../../../elements';
import { numberWithSpaces } from '../../../utils';
import { UIProps } from '../../../types';
import store from '../../../core/store/store';
import {
  removePhone,
  updateConnection,
} from '../../../core/store/actions/connections';
import iconTrash from '../../../assets/trash_icon.svg';
import {
  NotificationStreams,
  useModalHook,
  useNotificatorHook,
} from '../../../hooks';
import {
  updatePhonesGroup,
  removePhonesGroup,
} from '../../../core/store/actions/app';
import { Api } from '../../../core/api';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { ConfirmForm } from '../../common/ConfirmForm';
import ScrollWrapper from '../../common/ScrollWrapper';
import { ConnectionViewContext } from '../../../context/ConnectionViewContext';
import { NON_GROUP, BREAKPOINT_MOBILE } from '../../../utils/constants';

interface BasicPhoneSettingsProps {
  isPhoneOwner?: boolean;
}

export const BasicPhoneSettings: FunctionComponent<BasicPhoneSettingsProps> = (
  props,
) => {
  const { isPhoneOwner = false } = props;

  const context = useContext(ConnectionEditViewContext);
  const { selectedPhones, setSelectedPhones } = useContext(
    ConnectionViewContext,
  );

  const { t } = useTranslation();
  const [phoneName, setPhoneName] = useState('');
  const [phoneDesc, setPhoneDesc] = useState('');
  const [removeConfirmation, setRemoveConfirmation] = useState(false);

  const [removeAmount, setRemoveAmount] = useState(null);
  const modalOptions = useModalHook();
  const notificator = useNotificatorHook();

  const $getMoneyForRefund = async (id: string) =>
    Api.get(`/phone/${id}/remaining_money`).then((d) =>
      d.data.result.amount);

  const onUpdate = () => {
    store.dispatch(
      updateConnection.request({
        id: context?.selectedPhone.id,
        name: phoneName,
        description: phoneDesc,
      } as any),
    );
    notificator.notify(
      `${t('notification.changeUpdated')}`,
      NotificationStreams.CONNECTION_EDIT_FORM,
    );
  };

  const applyGroup = (optionValue) => {
    const { value } = optionValue;

    store.dispatch(
      updatePhonesGroup.request({
        groupId: value === null ? NON_GROUP : value,
        phonesId: [context?.selectedPhone.id],
      }),
    );
  };

  useEffect(() => {
    if (context?.selectedPhone != null) {
      setPhoneDesc(context?.selectedPhone?.description);
      setPhoneName(context?.selectedPhone?.name);
    }
  }, [context]);

  const onApprove = () => {
    const selectedPhone = context?.selectedPhone.id;

    if (selectedPhones) {
      setSelectedPhones(
        selectedPhones.filter((item) =>
          item !== selectedPhone),
      );
    }

    store.dispatch(removePhone.request({ phoneId: selectedPhone }));
    store.dispatch(
      removePhonesGroup.request({
        phonesId: [selectedPhone],
        callback: () => {
          modalOptions.hide();
          setRemoveConfirmation(false);
        },
      }),
    );
  };

  return (
    <ScrollWrapper>
      {removeConfirmation ? (
        <div style={{ marginBottom: 25 }}>
          <ConfirmForm
            hasScrollTo
            text={`${t('proxy.removalConfirmation')} ${t(
              'connection.removalRefund',
              { amount: removeAmount },
            )}`}
            onApprove={() =>
              onApprove()}
            onCancel={() =>
              setRemoveConfirmation(false)}
          />
        </div>
      ) : null}
      <BasicInfo>
        <InputWrapper>
          <InputLabel>{t('connection.name')}</InputLabel>
          <InputField
            value={phoneName}
            disabled={!isPhoneOwner && !context?.permissions?.nameEdit}
            floatLabel
            placeholder={`${t('connection.name')}`}
            onChange={({ target }) =>
              setPhoneName(target.value)}
          />
        </InputWrapper>

        <InputWrapper>
          <InputLabel>{t('connection.description')}</InputLabel>
          <InputField
            value={phoneDesc}
            disabled={!isPhoneOwner && !context?.permissions?.descriptionEdit}
            floatLabel
            placeholder={`${t('connection.description')}`}
            onChange={({ target }) =>
              setPhoneDesc(target.value)}
          />
        </InputWrapper>

        <GroupContainer>
          <GroupSelectbox>
            <InputWrapper>
              <InputLabel>{t('connection.group')}</InputLabel>
              <DropdownElement
                isSearchable={false}
                value={context?.phoneGroupConfig}
                onSelected={(optionValue) => {
                  applyGroup(optionValue);
                }}
                options={context?.groupConfig}
              />
            </InputWrapper>
          </GroupSelectbox>
        </GroupContainer>
      </BasicInfo>

      <EditModalActionContainer>
        <ActionWrapper>
          {!isPhoneOwner && !context?.permissions?.connectionRemove ? null : (
            <RemoveConnection>
              <div
                className="title"
                onClick={() => {
                  $getMoneyForRefund(context?.selectedPhone.id).then((e) => {
                    setRemoveAmount(numberWithSpaces(e));
                    setRemoveConfirmation(true);
                  });
                }}
              >
                {t('connection.removeConnection')}
              </div>
            </RemoveConnection>
          )}
        </ActionWrapper>
        <ActionWrapper>
          <EditModalSave onClick={() =>
            onUpdate()}
          >
            {t('editForm.save')}
          </EditModalSave>
        </ActionWrapper>
      </EditModalActionContainer>
    </ScrollWrapper>
  );
};

const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;

  .commonrow {
    display: flex;
    flex-direction: row;
  }
`;

const GroupContainer = styled.div`
  display: flex;

  .attachbt {
    width: 290px;
    justify-content: flex-end;
    display: flex;
    height: 39px;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  }
`;

const GroupSelectbox = styled.div`
  width: 100%;
`;

const RemoveConnection = styled.div`
  .title {
    position: relative;
    cursor: pointer;
    color: ${(props: UIProps) =>
    props?.theme?.colors?.warning};
    ${(props: UIProps) =>
    props?.theme?.sizes?.font?.small};
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.1s;

    &:hover {
      opacity: 0.8;
    }

    &:before {
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
      margin-top: -3px;
      content: '';
      display: block;
      background-image: url(${iconTrash});
      background-position: center;
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
    }
  }
`;

const ActionWrapper = styled.div`
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &:nth-child(1) {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      order: 1;
      padding: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }

  &:nth-child(2) {
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      order: 0;
    }
  }
`;
