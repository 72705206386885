import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWrapper, InputLabel } from '../../styles/ui-controls';
import { InputField } from '../../elements';
import { NewPasswordChecks } from './NewPasswordChecks';
import { MIN_PASSWORD_LENGTH } from '../../constants/common';

type PasswordError = {
  msg: string;
  hasError: boolean;
};

type PasswordErrors = {
  old: PasswordError;
  new: PasswordError;
  confirm: PasswordError;
};

type PasswordChecks = {
  isLowerCaseChar: boolean;
  isUpperCaseChar: boolean;
  isDigitChar: boolean;
  isSpecialChar: boolean;
  isValidLength: boolean;
};

interface ChangePasswordFormProps {
  changePasswordParams: {
    password: string;
    setPassword: (value: string) => void;
    passwordConfirm: string;
    setPasswordConfirm: (value: string) => void;
    oldPassword: string;
    setOldPassword: (value: string) => void;
    passwordErrors: PasswordErrors;
    newPasswordChecks: PasswordChecks;
    validateNewPasswordChecks: (value: string) => void;
  }
  isProfileChangePasswordForm?: boolean;
}

export const ChangePasswordForm: FunctionComponent<ChangePasswordFormProps> = ({
  changePasswordParams,
  isProfileChangePasswordForm,
}) => {
  const { t } = useTranslation();
  const {
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    oldPassword,
    setOldPassword,
    passwordErrors,
    newPasswordChecks,
    validateNewPasswordChecks,
  } = changePasswordParams;

  return (
    <>
      {
        isProfileChangePasswordForm && (
          <InputWrapper>
            <InputLabel>{t('profile.oldPsw')}</InputLabel>
            <InputField
              value={oldPassword}
              errorMsg={passwordErrors.old.msg}
              error={passwordErrors.old.hasError}
              type="password"
              floatLabel
              placeholder={`${t('profile.password')}`}
              onChange={({ target }) => {
                setOldPassword(target?.value);
              }}
              showPasswordIcon
            />
          </InputWrapper>
        )
      }
      <InputWrapper>
        <InputLabel>{t('profile.newPsw')}</InputLabel>
        <InputField
          value={password}
          type="password"
          errorMsg={passwordErrors.new.msg}
          error={passwordErrors.new.hasError}
          floatLabel
          placeholder={`${t('profile.password')}`}
          onChange={({ target }) => {
            validateNewPasswordChecks(target?.value);
            setPassword(target?.value);
          }}
          showPasswordIcon
        />
        {
            passwordErrors.new.hasError
            && (
            <>
              <NewPasswordChecks
                check={newPasswordChecks.isLowerCaseChar}
                msg={t('profile.lowercase')}
              />
              <NewPasswordChecks
                check={newPasswordChecks.isUpperCaseChar}
                msg={t('profile.uppercase')}
              />
              <NewPasswordChecks
                check={newPasswordChecks.isDigitChar}
                msg={t('profile.digit')}
              />
              <NewPasswordChecks
                check={newPasswordChecks.isSpecialChar}
                msg={t('profile.special')}
              />
              <NewPasswordChecks
                check={newPasswordChecks.isValidLength}
                msg={t('profile.length', { length: MIN_PASSWORD_LENGTH })}
              />
            </>
            )
          }
      </InputWrapper>
      <InputWrapper>
        <InputLabel>{t('profile.confirmPsw')}</InputLabel>
        <InputField
          value={passwordConfirm}
          type="password"
          errorMsg={passwordErrors.confirm.msg}
          error={passwordErrors.confirm.hasError}
          floatLabel
          placeholder={`${t('profile.password')}`}
          onChange={({ target }) =>
            setPasswordConfirm(target?.value)}
          showPasswordIcon
        />
      </InputWrapper>
    </>
  );
};
