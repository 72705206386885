import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EditModalActionContainer,
  EditModalSave,
  InputWrapper,
} from '../styles/ui-controls';
import { Api } from '../core/api';
import { InputLabel } from './UserProfileScreen';
import store from '../core/store/store';
import { loadUserInfo, updateApiKey } from '../core/store/actions/user';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { ApiWarningBlock } from '../components/common/ApiWarningBlock';
import { ApiKeyInput } from '../components/common/ApiKeyInput';
import { useChangePasswordHook } from '../hooks/useChangePasswordHook';
import { ChangePasswordForm } from '../components/common/ChangePasswordForm';
import { ReactMD } from '../components/common/ReactMarkdown';

export interface ChangePasswordProps {
  isProfile?: boolean;
  setChangePassword?: Function;
}

export const ChangePassword: FunctionComponent<ChangePasswordProps> = ({
  isProfile,
  setChangePassword,
}) => {
  const { t } = useTranslation();

  const { changePasswordParams } = useChangePasswordHook();
  const {
    password,
    oldPassword,
    setPasswordErrors,
    isFormWithOldPasswordValid,
  } = changePasswordParams;

  const { id, api_key: apiKey } = useTypedSelector(({ user }) =>
    user?.profile);

  const $changePass = async () => {
    if (isFormWithOldPasswordValid()) {
      try {
        await Api.post(
          '/change_password',
          { oldPassword, newPassword: password },
          null,
          false,
        ).then(() => {
          setChangePassword();
          if (apiKey) {
            store.dispatch(updateApiKey.request({ userId: id }));
          }
          store.dispatch(loadUserInfo.request(null, null));
        });

        setChangePassword(false);
      } catch (e) {
        setPasswordErrors((prevErrors) =>
          ({
            ...prevErrors,
            old: {
              hasError: true,
              msg: `${t('profile.oldPswAlertIncorrect')}`,
            },
          }));
      }
    }
  };

  return (
    <>
      <ChangePasswordForm
        changePasswordParams={changePasswordParams}
        isProfileChangePasswordForm
      />

      <InputWrapper>
        <InputLabel>{isProfile ? t('profile.oldApiKey') : t('profile.apiKey')}</InputLabel>
        <ApiKeyInput apiKey={apiKey || '-'} />
      </InputWrapper>

      <ApiWarningBlock
        components={(
          <ReactMD
            markdown={t('profile.apiKeyWarning')}
          />
        )}
        width="100%"
        padding="0"
        imgSize="40px"
        isWarning
      />

      <EditModalActionContainer style={{ marginTop: '12px' }}>
        <EditModalSave
          disabled={!isFormWithOldPasswordValid()}
          onClick={() =>
            $changePass()}
        >
          {t('editForm.save')}
        </EditModalSave>
      </EditModalActionContainer>
    </>
  );
};
