import React, {
  useState,
  useEffect,
  FunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  InputWrapper,
  InputLabel,
  VideoInstructionBlock,
} from '../../styles/ui-controls';
import { UIProps } from '../../types';
import { getWhiteListIpFaqLink } from '../../utils/instruction-links';
import { windowOpen } from '../../utils';

interface WhitelistIpInputProps {
  ipList?: any;
  onListChange?: Function;
  onError?: Function;
  placeholder?: string;
  label?: string;
  haveInstruction?: boolean;
  noLabel?: boolean;
  disabled?: boolean;
}
const formatIpList = (value) =>
  value?.trim()?.replace(';', '\n')?.replace(',', '\n')?.split('\n');

export const isIncorrectIpsContain = (ipList) => {
  const ipV4 = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  // eslint-disable-next-line max-len
  const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;
  const approvedSymbols = /^[0-9a-zA-Z;.,]+$/;
  const subnetRegex = /^([0-9]|[1-2][0-9]|3[0-2])$/;

  if (ipList != null) {
    return Array.isArray(ipList)
      ? ipList?.find((ip) => {
        const checkIp = ip.split('/')[0];
        const subnet = ip.split('/')[1];
        const checkSubnet = subnet ? subnetRegex.test(subnet) : true;
        return !(
          (ipV4.test(checkIp) || ipv6Regex.test(checkIp))
            && approvedSymbols.test(checkIp)
            && checkSubnet
        );
      }) != null
      : formatIpList(ipList)?.find((ip) => {
        const checkIp = ip.split('/')[0];
        const subnet = ip.split('/')[1];
        const checkSubnet = subnet ? subnetRegex.test(subnet) : true;
        return !(
          (ipV4.test(checkIp) || ipv6Regex.test(checkIp))
            && approvedSymbols.test(checkIp)
            && checkSubnet
        );
      }) != null;
  }
  return false;
};

export const WhitelistIpInput: FunctionComponent<WhitelistIpInputProps> = (
  props,
) => {
  const {
    ipList = null,
    placeholder,
    label,
    onListChange = () => {},
    onError = () => {},
    haveInstruction = false,
    noLabel = false,
    disabled = false,
  } = props;
  const { t, i18n } = useTranslation();

  const [listIps, setListIps] = useState(ipList);
  const [ipError, setIpError] = useState(false);

  useEffect(() =>
    onError(ipError), [ipError]);
  useEffect(() =>
    setListIps(ipList), [ipList]);

  return (
    <Wrapper>
      <InputWrapper>
        {!noLabel && (
          <div className="label-box">
            <InputLabel>
              {label != null ? label : t('editConnection.whiteList')}
            </InputLabel>
            {haveInstruction && (
              <VideoInstructionBlock
                onClick={() =>
                  windowOpen(getWhiteListIpFaqLink(i18n.language))}
              >
                {t('howSetup')}
              </VideoInstructionBlock>
            )}
          </div>
        )}
        <IpListContainer
          hasError={ipError}
          placeholder={
            placeholder != null ? placeholder : t('editConnection.whiteListPl')
          }
          value={listIps}
          onBlur={({ target }) => {
            setListIps(target?.value);
            if (
              target.value == null
              || target.value === ''
              || !isIncorrectIpsContain(formatIpList(target.value))
            ) {
              onListChange(
                target?.value === '' ? null : formatIpList(target?.value),
              );
            } else {
              setIpError(true);
            }
          }}
          onChange={({ target }) => {
            setIpError(false);
            setListIps(target?.value);
            if (
              target.value == null
              || target.value === ''
              || !isIncorrectIpsContain(formatIpList(target.value))
            ) {
              onListChange(
                target?.value === '' ? null : formatIpList(target?.value),
              );
            }
          }}
          disabled={disabled}
        />
        {ipError ? (
          <ErrorInput>{t('editConnection.ipErrorMsg')}</ErrorInput>
        ) : null}
      </InputWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .label-box {
    display: flex;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  }
`;

interface IpListContainerProps extends UIProps {
  hasError?: boolean;
}

export const IpListContainer = styled.textarea<IpListContainerProps>`
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  margin-top: 0px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  width: 100%;
  box-sizing: border-box;
  resize: none;
  border-color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  height: 100px;
  font-family: 'Montserrat', sans-serif !important;
  outline: none;
  :focus {
    border-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
  }

  ${(props: IpListContainerProps) =>
    props?.hasError
    && css`
      border-color: ${(props: UIProps) =>
    props.theme.colors.warning};

      :focus {
        border-color: ${(props: UIProps) =>
    props.theme.colors.warning};
      }
    `}
`;

const ErrorInput = styled.div`
  color: ${(props: UIProps) =>
    props?.theme?.colors?.warning};
  ${(props: UIProps) =>
    props?.theme?.sizes?.font?.smaller};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;
