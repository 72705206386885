import React, { useEffect, FunctionComponent, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import { ConnectionEditViewContext } from '../../context/ConnectionEditViewContext';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { WifiSplit } from './BigDaddyPro/WifiSplit';
import { Fingerprint } from './BigDaddyPro/Fingerprint';
import { UniqueIPTab } from './BigDaddyPro/UniqueIPTab';
import { OpenVpnConnections } from './BigDaddyPro/openvpn/OpenVpnConnections';
import { WarningLabel } from '../../elements/warning-label';

interface BigDaddyProFormProps {}

export const BigDaddyProForm: FunctionComponent<BigDaddyProFormProps> = () => {
  const context = useContext(ConnectionEditViewContext);
  const { t } = useTranslation();

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'open vpn',
      title: 'OpenVPN',
      component: (
        <OpenVpnConnections
          phoneId={context?.selectedPhone?.id}
          udps={context?.selectedPhone?.openVPN}
        />
      ),
    },
    {
      key: 'wi fi split',
      title: 'Wifi Split',
      component: <WifiSplit selectedPhone={context?.selectedPhone} />,
    },
    {
      key: 'pOf',
      title: 'pOf',
      component: (
        <Fingerprint
          activatedFp={context?.selectedPhone?.fingerprint}
          phoneId={context?.selectedPhone?.id}
        />
      ),
    },
    {
      key: 'unique ip',
      title: t('proSettings.uniqueIP'),
      component: <UniqueIPTab />,
    },
  ];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Wrapper>
      <WarningLabel message="policyMsg.exclusive" />

      <ReactTooltip />
      <TabsAtom config={tabsConfig} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
