import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { BasicButton, InputLabel } from '../../../styles/ui-controls';
import { InputField } from '../../../elements';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';

interface AddMemberProps {
  membersList?: string[];
  addMember?: Function;
}

const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

export const AddMember: FunctionComponent<AddMemberProps> = (props) => {
  const { addMember, membersList } = props;

  const { t } = useTranslation();
  const [teamMember, setTeamMember] = useState(null);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const addMemberHandler = () => {
    setInvalidEmail(false);

    if (
      !membersList?.includes(teamMember)
      && !isEmpty(teamMember)
      && EMAIL_REGEX.test(teamMember)
    ) {
      addMember(teamMember);
      setInvalidEmail(false);
    } else if (!EMAIL_REGEX.test(teamMember)) {
      setInvalidEmail(true);
    }
  };

  return (
    <>
      <InputLabel>{t('proSettings.userEmail')}</InputLabel>
      <Wrapper>
        <ElementWrapper>
          {invalidEmail ? (
            <InputField
              value={teamMember}
              placeholder={`${t('proSettings.enterUserEmail')}`}
              onChange={({ target }) =>
                setTeamMember(target?.value)}
              error={!EMAIL_REGEX.test(teamMember)}
              errorMsg={`${t('proSettings.invalidEmail')}`}
            />
          ) : (
            <InputField
              value={teamMember}
              placeholder={`${t('proSettings.enterUserEmail')}`}
              onChange={({ target }) =>
                setTeamMember(target?.value)}
              error={membersList?.includes(teamMember)}
              errorMsg={`${t('proSettings.userExists')}`}
            />
          )}
        </ElementWrapper>
        <BasicButton
          size="small"
          onClick={() =>
            addMemberHandler()}
        >
          {t('proSettings.addUser')}
        </BasicButton>
      </Wrapper>
    </>
  );
};

const ElementWrapper = styled.div`
  width: 80%;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
  }
`;
