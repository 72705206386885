import styled from 'styled-components';
import { BREAKPOINT_MOBILE } from '../../../../utils/constants';
import { UIProps } from '../../../../types';

export const Wrapper = styled.div`
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: start;
  margin-top: 24px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
  }
`;

export const ElementWrapper = styled.div`
  min-width: 240px;
  margin: 0 8px 0 0;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    margin: 0 0 10px 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
