import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../types';

interface WarningLabelProps {
  message: string;
}

export const WarningLabel: FunctionComponent<WarningLabelProps> = (props) => {
  const { message } = props;
  const { t } = useTranslation();

  return (
    <WarningWrapper>
      <Warning>
        {t(`${message}`)}
      </Warning>
    </WarningWrapper>
  );
};

const WarningWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const Warning = styled.p`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  font-weight: 500;
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.warning};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
`;
