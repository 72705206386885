import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import arrowIcon from '../../assets/sort-arrow.svg';
import { UIProps } from '../../types';

interface SortComponentProps {
  title: string;
  className: string;
  orderKey?: string;
  sortingOrder?: {
    order: string;
    orderKey: string;
  };
  dataTip?: string;
  setSortingOrder?: Function;
}

const desc = 'desc';
const asc = 'asc';
const emptyValue = '';
const activeSortClassname = 'activeSort';

export const SortComponent: FunctionComponent<
SortComponentProps
> = ({
  title,
  className,
  orderKey,
  sortingOrder,
  dataTip,
  setSortingOrder,
}) => {
  const isAsc = sortingOrder.order === asc && sortingOrder.orderKey === orderKey;
  const isDesc = sortingOrder.order === desc && sortingOrder.orderKey === orderKey;
  const { t } = useTranslation();

  const handleSortingOrderChange = (condition: boolean, order: string) => {
    if (condition) {
      setSortingOrder({
        order: emptyValue,
        orderKey: emptyValue,
      });
      return;
    }

    setSortingOrder({
      order,
      orderKey,
    });
  };

  return (
    <th className={className}>
      <ReactTooltip />
      <Wrapper>
        <span
          className={dataTip ? 'tooltip-action-container' : ''}
          data-place="left"
          data-tip={dataTip}
        >
          {t(title)}
        </span>
        <ArrowsWrapper>
          <button
            type="button"
            onClick={() =>
              handleSortingOrderChange(isAsc, asc)}
          >
            <img
              src={arrowIcon}
              alt="Arrow icon"
              style={{ rotate: '180deg' }}
              className={
                    isAsc
                      ? activeSortClassname
                      : null
                  }

            />
          </button>
          <button
            type="button"
            onClick={() =>
              handleSortingOrderChange(isDesc, desc)}
          >
            <img
              src={arrowIcon}
              alt="Arrow icon"
              className={
                    isDesc
                      ? activeSortClassname
                      : null
                  }
            />
          </button>
        </ArrowsWrapper>
      </Wrapper>
    </th>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  align-items: center;
`;

const ArrowsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .activeSort {
    opacity: 1;
    color: white;
  }

  button {
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    background: transparent;
    border: none;
    width: 10px;
    height: 10px;
  }

  img {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
`;
