import React, { useState, FunctionComponent, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import store from '../../../core/store/store';
import { CheckBox } from '../../atoms/Checkbox';
import {
  removeAlert,
  updateConnection,
} from '../../../core/store/actions/connections';
import { NotificationStreams, useNotificatorHook } from '../../../hooks';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { PlansType, UIProps } from '../../../types';
import { ConfirmForm } from '../../common/ConfirmForm';
import { ButtonLoader } from '../../atoms/ButtonLoader';
import { CreateAlertForm } from '../CreateAlertForm';
import { AlertTrafficsComponent } from '../AlertTrafficsComponent';
import { getNotificationWarningLink } from '../../../utils/instruction-links';
import { TelegramNotificationsCheckbox } from '../../common/TelegramNotificationsCheckbox';
import {
  BREAKPOINT_MOBILE,
  telegramSupport,
} from '../../../utils/constants';
import { ReactMD } from '../../common/ReactMarkdown';
import theme from '../../../styles/ApplicationTheme';

interface NotificationsProps {}

export const Notifications: FunctionComponent<NotificationsProps> = () => {
  const context = useContext(ConnectionEditViewContext);

  const alert = context?.selectedPhone?.alerts?.[0];

  const notificator = useNotificatorHook();
  const { t, i18n } = useTranslation();

  const [removeConfirmation, setRemoveConfirmation] = useState(false);

  const [createMode, setCreateMode] = useState(false);

  const $save = (options) => {
    store.dispatch(
      updateConnection.request({
        id: context?.selectedPhone.id,
        ...options,
      }),
    );
    notificator.notify(
      `${t('notification.changeUpdated')}`,
      NotificationStreams.CONNECTION_EDIT_FORM,
    );
  };

  const removeAlertLocal = (alertId) =>
    store.dispatch(
      removeAlert.request({
        phoneId: context?.selectedPhone?.id,
        alertId,
      }),
    );

  return (
    <Wrapper>
      <Section>
        {createMode ? (
          <CreateAlertForm
            phoneId={context?.selectedPhone?.id}
            onClose={() =>
              setCreateMode(false)}
          />
        ) : (
          <>
            <TelegramNotificationsCheckbox
              disabled={!context?.isOwner}
              onChangeFlag={$save}
              selectedPhone={context?.selectedPhone}
            />
            {context?.selectedPhone?.activePlans[0]?.id
              === PlansType.BigDaddyPro && (
              <>
                <CheckBoxWrap>
                  <CheckBox
                    disabled
                    value
                    label={`${t('bot.alerts')}`}
                    hasLabel
                  />
                </CheckBoxWrap>
                <WarningBoxList>
                  <ReactMD
                    markdown={t('bot.warningMessage.1')}
                    color={theme.colors.warning}
                    fontSize={theme.sizes.font.smaller}
                  />
                  <ReactMD
                    markdown={t('bot.warningMessage.2')}
                    color={theme.colors.warning}
                    fontSize={theme.sizes.font.smaller}
                  />
                  <WarningBoxWrapper>
                    <ReactMD
                      markdown={t('bot.warningMessage.3', {
                        withoutSplit: `**${t('bot.withoutSplit')}**`,
                        blog: `[${t('bot.blog')}](${getNotificationWarningLink(i18n.language)})`,
                        gb12: `**${t('bot.gb12')}**`,
                      })}
                      color={theme.colors.warning}
                      fontSize={theme.sizes.font.smaller}
                    />
                  </WarningBoxWrapper>
                  <WarningBoxWrapper>
                    <ReactMD
                      markdown={t('bot.warningMessage.4', {
                        techSupport: `[${t('bot.techSupport')}](${telegramSupport})`,
                        withSplit: `**${t('bot.withSplit')}**`,
                        gb6: `**${t('bot.gb6')}**`,
                      })}
                      color={theme.colors.warning}
                      fontSize={theme.sizes.font.smaller}
                    />
                  </WarningBoxWrapper>
                </WarningBoxList>
                {alert ? (
                  removeConfirmation ? (
                    <ConfirmForm
                      hasScrollTo
                      text={t('bot.deleteAlert')}
                      onApprove={() => {
                        removeAlertLocal(alert?.id);
                        setRemoveConfirmation(false);
                      }}
                      onCancel={() => {
                        setRemoveConfirmation(false);
                      }}
                    />
                  ) : (
                    <AlertBox>
                      {alert?.name && <AlertDate>{alert.name}</AlertDate>}
                      {alert?.description && (
                        <AlertDate>{alert.description}</AlertDate>
                      )}
                      <AlertDate>
                        {new Date(alert.from_ts).toLocaleString('ru', {
                          timeZoneName: 'short',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          day: 'numeric',
                          month: 'numeric',
                          year: 'numeric',
                        })}
                        {' '}
                        -
                        {' '}
                        {new Date(alert.to_ts).toLocaleString('ru', {
                          timeZoneName: 'short',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          day: 'numeric',
                          month: 'numeric',
                          year: 'numeric',
                        })}
                        {' '}
                        [
                        {alert.status}
                        ]
                      </AlertDate>
                      <AlertTrafficsComponent alert={alert} />
                      <AlertDeleteBox>
                        <AlertDeleteButton
                          onClick={() =>
                            setRemoveConfirmation(true)}
                        >
                          {t('referral.deleteUrl')}
                        </AlertDeleteButton>
                      </AlertDeleteBox>
                    </AlertBox>
                  )
                ) : (
                  <>
                    <NoAlerts>{t('bot.noAlerts')}</NoAlerts>
                    <ButtonWrapper>
                      <ButtonCreate>
                        <ButtonLoader
                          size="small"
                          onClick={() =>
                            setCreateMode(true)}
                        >
                          {t('bot.createAlert')}
                        </ButtonLoader>
                      </ButtonCreate>
                    </ButtonWrapper>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Section = styled.div`
  margin: 0 0 32px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: 32px 0;
  }
`;

const CheckBoxWrap = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const NoAlerts = styled.div`
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  text-align: center;
  box-sizing: border-box;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const AlertBox = styled.div`
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.spaceGray};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const AlertDate = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const AlertDeleteBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const AlertDeleteButton = styled.div`
${(props: UIProps) =>
    props.theme.sizes.font.small};
  cursor: pointer;
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  :hover {
    opacity: 1;
  }
`;

const ButtonCreate = styled.div`
  width: 160px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  display: flex;
  justify-content: end;
`;

const WarningBoxList = styled.ul`
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const WarningBoxWrapper = styled.li`
  padding-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  position: relative;

  &:before {
    content: '';
    width: 5px;
    height: 5px;
    background-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.round};
    position: absolute;
    top: 10px;
    left: 0;
  }
`;
