import React, {
  useState,
  useEffect,
  FunctionComponent,
  useContext,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  InputLabel,
  InputWrapper,
  VideoInstructionBlock,
} from '../../../styles/ui-controls';
import { DropdownElement } from '../../../elements';
import store from '../../../core/store/store';
import { updateFingerprint } from '../../../core/store/actions/connections';
import { NotificationStreams, useNotificatorHook } from '../../../hooks';
import { FINGERPRINTS_OPTIONS } from '../../../core/config';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { PermissionService } from '../../../services/PermissionService';
import { getPofInstructionLink } from '../../../utils/instruction-links';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';
import { windowOpen } from '../../../utils';

interface FingerprintProps {
  phoneId: any;
  activatedFp: string;
}

///  await PhonesAPI.sendActionPush(selectedPhone.id, 'refresh_fingerprint', {fingerprint: d.fingerprint});
export const Fingerprint: FunctionComponent<FingerprintProps> = (props) => {
  const { phoneId, activatedFp } = props;
  const { t, i18n } = useTranslation();
  const notificator = useNotificatorHook();
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const context = useContext(ConnectionEditViewContext);

  useEffect(() => {
    const opt = [
      {
        value: 'none',
        label: 'None',
      },
      ...FINGERPRINTS_OPTIONS.map((i) =>
        ({
          value: i.value,
          label: i.label,
        })),
    ];
    setOptions(opt);
    if (activatedFp != null && activatedFp !== 'none') {
      const active = opt?.find((i) =>
        i.value === activatedFp);
      setSelected(active);
    }
  }, []);

  return (
    <>
      {!context?.isOwner && !context?.permissions?.pofSetting ? null : (
        <Wrapper>
          <InlineBlock>
            <VideoInstructionBlock
              onClick={() =>
                windowOpen(getPofInstructionLink(i18n.language))}
            >
              {t('howSetup')}
            </VideoInstructionBlock>
          </InlineBlock>
          <InputWrapper>
            <InputLabel>Fingerprint</InputLabel>
            <DropdownElement
              isSearchable={false}
              value={selected}
              onSelected={(value) => {
                setSelected(value);
                store.dispatch(
                  updateFingerprint({
                    phoneId,
                    fingerType: value.value,
                  }),
                );
                notificator.notify(
                  `${t('notification.changeUpdated')}`,
                  NotificationStreams.CONNECTION_EDIT_FORM,
                );
              }}
              options={options}
              disabled={
                !PermissionService.tariffHasFullFeatureAccess(
                  context.selectedPhone?.activePlans[0]?.id,
                )
              }
            />
          </InputWrapper>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  min-height: 370px;
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  display: flex;
  flex-direction: column;
  align-items: end;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const InlineBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;
