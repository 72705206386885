export const dateFormats = {
  dateTime: 'DD.MM.YYYY HH:mm',
  dateTimeSec: 'DD.MM.YYYY HH:mm:ss',
  dateGmt: 'DD.MM.YYYY, HH:mm [GMT]Z',
  shortDate: 'DD.MM.YYYY',
  monthYear: 'MMMM YYYY',
  day: 'D',
  timeSec: 'HH:mm:ss',
  time: 'HH:mm',
  mins: 'mm',
  fileDate: 'DD-MM-YYYY-HH-mm',
  fileDateAlt: 'DD.MM.YYYY_HH_MM_ss',
  longDateTime: 'MMMM D, YYYY HH:mm',
  longDateWithDay: 'ddd, MMMM D, YYYY HH:mm',
};

export const alertMessageUrl = 'https://new-cms.iproxy.online/api/alert-message';

export const NON_GROUP = '___no_group';

export const BREAKPOINT_MOBILE = 720;
export const BREAKPOINT_TABLET = 1210;
export const BREAKPOINT_DESKTOP = 1700;

export const TARGET_BLANK = '_blank';
export const TARGET_SELF = '_self';
export const LINK_REL = 'noopener noreferrer';

export const telegramSupport = 'https://t.me/iproxy_online_support';
export const googlePlay = 'https://play.google.com/store/apps/details?id=com.iproxy.android&pli=1';
export const iproxyDownload = 'https://iproxy.online/android';
export const telegramBot = 'https://t.me/iproxy_online_bot';

export const minRelevantAppVersion = 574;

export const flagsUrl = 'https://hatscripts.github.io/circle-flags/flags';
