import React, { useEffect, useMemo, useState } from 'react';
import { Phone } from '../types/phones';
import { AffiliationPhoneType, Connection } from '../types/connection';
import { getOptions } from '../constants';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { NON_GROUP } from '../utils/constants';

export const ConnectionViewContext = React.createContext<any | null>(null);

const isParamContainSearchKey = (parameter: string, searchKey: string) => {
  const pattern = searchKey?.replace(
    /[\\-\\[\]\\/\\{\\}\\(\\)\\*\\+\\?\\.\\\\^\\$\\|]/g,
    '\\$&',
  );
  const regExp = new RegExp(pattern, 'gi');
  return Array.isArray(parameter?.match(regExp));
};

const searchMatchInConnection = (con: Connection, searchKey: string) =>
  isParamContainSearchKey(con?.password, searchKey)
  || isParamContainSearchKey(con?.login, searchKey)
  || isParamContainSearchKey(String(con?.port), searchKey)
  || isParamContainSearchKey(con?.listen_service, searchKey)
  || isParamContainSearchKey(con?.name, searchKey)
  || isParamContainSearchKey(con?.hostname, searchKey);

const filterDataBySearchKey = (rawPhoneData, searchKey) => {
  if (!rawPhoneData) return [];

  const result = rawPhoneData.filter((phone: Phone) => {
    const matchConnections = phone?.connections?.find((con) =>
      searchMatchInConnection(con, searchKey));
    const matchOVPNConfigs = phone?.openVPN?.find((config) =>
      searchMatchInConnection(config, searchKey));

    return (
      isParamContainSearchKey(phone?.name, searchKey)
      || isParamContainSearchKey(phone?.description, searchKey)
      || isParamContainSearchKey(phone?.id, searchKey)
      || matchConnections // Connection | undefined
      || matchOVPNConfigs
    );
  });

  return result;
};

const filterDataByAffiliation = (rawPhoneData, affiliation, profileId) => {
  if (rawPhoneData === null) return rawPhoneData;

  const results = {
    [AffiliationPhoneType.ALL]: rawPhoneData,
    [AffiliationPhoneType.PRIVATE]: rawPhoneData.filter(
      (phone) =>
        phone.userId === profileId
        && (!phone.sharedUsersEmails || phone.sharedUsersEmails?.length === 0),
    ),
    [AffiliationPhoneType.SHARED_BY_ME]: rawPhoneData.filter(
      (phone) =>
        phone.userId === profileId && phone.sharedUsersEmails?.length > 0,
    ),
    [AffiliationPhoneType.SHARED_WITH_ME]: rawPhoneData.filter(
      (phone) =>
        phone.userId !== profileId,
    ),
  };

  return results[affiliation];
};

const generateGroupedConnectionsList = (connectionsList, groupsList) =>
  connectionsList?.reduce((accum, value) => {
    const group = groupsList?.find((i) =>
      i?.connections?.includes(value?.id)) || null;
    const key = group != null ? group?.name : NON_GROUP;

    if (accum.has(key)) {
      const data = accum.get(key);
      accum.set(key, [...data, { ...value }]);
    } else {
      accum.set(key, [{ ...value }]);
    }
    return accum;
  }, new Map());

const getGroupKeys = (sortedList) => {
  const keyList = sortedList == null ? [] : Array.from(sortedList);
  const all = keyList.find((item) =>
    item[0] === NON_GROUP);
  keyList.push(keyList.splice(keyList.indexOf(all), 1)[0]);
  return keyList;
};

const getGroupConfigByName = (name, groupConfig) =>
  groupConfig?.find((a) =>
    a.name === name);

const sortGroups = (groupKeys, groupConfig) =>
  groupKeys
    ?.map((i) => {
      const find = getGroupConfigByName(i[0], groupConfig);
      return {
        key: i[0],
        id: find == null ? 10000000000000 : find.sortId,
        connections: i[1],
      };
    })
    .sort((a, b) =>
      a.id - b.id);

const getCurrentGroupPhones = (
  phones,
  groupConfig,
  currentGroupKey,
  groups,
) => {
  if (!phones || !currentGroupKey) return null;

  const currentGroup = getGroupConfigByName(currentGroupKey, groupConfig);

  if (currentGroupKey === NON_GROUP) {
    const phonesWithGroups = groups
      ?.map(({ key }) =>
        getGroupConfigByName(key, groupConfig)?.connections)
      ?.filter((con) =>
        !!con)
      ?.flat();

    return phones.filter(
      (phone) =>
        !phonesWithGroups?.includes(phone.id)
        || currentGroup?.connections?.includes(phone.id),
    );
  }

  return phones.filter((phone) =>
    currentGroup?.connections?.includes(phone.id));
};

const processBoardData = (
  phones,
  searchKey,
  groupConfig,
  affiliation,
  profileId,
  currentGroupKey,
  groups,
) => {
  const currentGroupPhones = getCurrentGroupPhones(
    phones,
    groupConfig,
    currentGroupKey,
    groups,
  );

  const hasSearchKey = searchKey != null && searchKey.trim().replace(' ', '') !== '';

  const filteredByAffiliationPhones = filterDataByAffiliation(
    currentGroupPhones,
    affiliation,
    profileId,
  );

  const filteredPhones = hasSearchKey
    ? filterDataBySearchKey(filteredByAffiliationPhones, searchKey)
    : filteredByAffiliationPhones;

  return filteredPhones;
};

export function ConnectionViewProvider({ children }: any) {
  const [phones, setPhones] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [groupKey, setGroupKey] = useState(null);
  const [groups, setGroups] = useState(null);
  const [otherSearchKeyGroups, setOtherSearchKeyGroups] = useState(null);
  const [gridView, setGridView] = useState(false);
  const [allGridView, setAllGridView] = useState(false);
  const [selectedPhones, setSelectedPhones] = useState(null);
  const [groupConfig, setGroupConfig] = useState(null);
  const [affiliation, setAffiliation] = useState(
    getOptions().affiliationOptions[0],
  );

  const profile = useTypedSelector(({ user }) =>
    user.profile);

  const initialContextState = useMemo(
    () =>
      ({
        boardData: processBoardData(
          phones,
          searchKey,
          groupConfig,
          affiliation.value,
          profile?.id,
          groupKey,
          groups,
        ),
        phones,
        searchKey,
        affiliation,
        setAffiliation,
        setPhones,
        setSearchKey,
        gridView,
        setGridView,
        selectedPhones,
        setSelectedPhones,
        setGroupConfig,
        setGroupKey,
        groupKey,
        groups,
        otherSearchKeyGroups,
        allGridView,
        setAllGridView,
      }),
    [
      phones,
      groupConfig,
      searchKey,
      gridView,
      selectedPhones,
      affiliation,
      groupKey,
      groups,
      otherSearchKeyGroups,
      allGridView,
      setAllGridView,
    ],
  );

  useEffect(() => {
    if (phones && phones.length > 0) {
      const sortedList = generateGroupedConnectionsList(phones, groupConfig);
      const keyList = getGroupKeys(sortedList);
      const sortedGroups = sortGroups(keyList, groupConfig);

      setGroups(sortedGroups);

      if (!sortedGroups.some(({ key }) =>
        key === groupKey)) {
        setGroupKey(sortedGroups[0]?.key);
      }
    }
  }, [phones, groupConfig]);

  useEffect(() => {
    const hasSearchKey = searchKey != null && searchKey.trim().replace(' ', '') !== '';

    if (!hasSearchKey) {
      setOtherSearchKeyGroups(null);
      return;
    }
    if (phones && groups) {
      const filteredPhoneIds = filterDataBySearchKey(phones, searchKey).map(
        (phone) =>
          phone.id,
      );

      const groupsLocal = groups?.map(({ key }) =>
        getGroupConfigByName(key, groupConfig));

      const filteredGroupsKey = groupsLocal
        .filter((group) =>
          group?.connections?.some((c) =>
            filteredPhoneIds.includes(c)))
        .map((g) =>
          g.name);

      const phonesWithoutGroups = getCurrentGroupPhones(
        phones,
        groupConfig,
        NON_GROUP,
        groups,
      );

      const otherGroupKeys = phonesWithoutGroups.some((p) =>
        filteredPhoneIds.includes(p.id))
        && !filteredGroupsKey.find((key) =>
          key === NON_GROUP)
        ? [...filteredGroupsKey, NON_GROUP]
        : filteredGroupsKey;

      setOtherSearchKeyGroups(otherGroupKeys);
    }
  }, [groups, searchKey, groupKey]);

  useEffect(() => {
    setAllGridView(gridView);
  }, [gridView]);

  return (
    <ConnectionViewContext.Provider value={initialContextState as any}>
      {children}
    </ConnectionViewContext.Provider>
  );
}
