import React, { useState, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { OnboardButton } from '../../screens/OnboardingScreen';
import iconQr from '../../assets/onb_qrcode.png';
import iconGoogle from '../../assets/onb_gplay.png';
import { QrInput } from '../atoms/qrinput';
import { CheckBox } from '../atoms/Checkbox';
import { CommonAppContext } from '../../context/CommonAppContext';
import { TextInProgressAtom } from '../atoms';
import { UIProps } from '../../types';
import { BREAKPOINT_MOBILE, googlePlay } from '../../utils/constants';
import { windowOpen } from '../../utils';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import store from '../../core/store/store';
import { updateUserProfile } from '../../core/store/actions/user';

interface OnbDownloadFormProps {
  nextScreen?: any;
}

export const OnbDownloadForm: FunctionComponent<OnbDownloadFormProps> = (
  props,
) => {
  const { nextScreen } = props;
  const [installed, setInstalled] = useState(false);
  const { t } = useTranslation();
  const context = React.useContext(CommonAppContext);

  const { profile } = useTypedSelector((state) =>
    state?.user);

  const c = [
    {
      icon: iconGoogle,
      title: t('onboarding.download.step1.title'),
      desc: t('onboarding.download.step1.desc'),
      content: (
        <ActButton
          className="application_download"
          onClick={() =>
            windowOpen(googlePlay)}
        >
          {t('onboarding.download.step1.bt')}
        </ActButton>
      ),
    },
    {
      icon: iconQr,
      title: t('onboarding.download.step2.title'),
      desc: t('onboarding.download.step2.desc'),
      content: <QrInput data={googlePlay} />,
    },
    // TODO: restore SMS when twilio approval ready
    // {
    //     icon: iconSms,
    //     title: t('onboarding.install.step3.title'),
    //     desc: t('onboarding.install.step3.desc'),
    //     content: (<ActButton onClick={() => modalOptions.show('smsapp')}>
    //         {t('onboarding.install.step3.bt')}
    //     </ActButton>)
    // }
  ];

  useEffect(() => {
    if (!profile?.visitedOnboarding?.download) {
      store.dispatch(
        updateUserProfile.request({
          ...profile,
          visitedOnboarding: {
            ...profile?.visitedOnboarding,
            download: Date.now(),
          },
        }),
      );
    }
  }, []);

  return (
    <Wrapper>
      <h2>{t('onboarding.download.title')}</h2>
      <div className="info">{t('onboarding.download.desc')}</div>

      <div className="setup-options-list">
        {c?.map((item) =>
          (
            <div className="setup-option" key={`bb-${item.title}`}>
              <div className="icon">
                <img src={item?.icon} alt="" />
              </div>
              <div className="title">{item?.title}</div>
              <div className="desc">{item?.desc}</div>
              <div className="content-info">{item?.content}</div>
            </div>
          ))}
      </div>

      <div className="actions">
        <div className="aggree">
          <CheckBox
            className="checkbutton_app_downloaded"
            disabled={
              context?.onboarding == null || context?.onboarding?.phone == null
            }
            value={installed}
            onClick={() => {
              setInstalled(!installed);
              context?.$loadUserOnboardingStatus();
            }}
          />
          <DownloadConfirmation>
            {t('onboarding.download.conf')}
          </DownloadConfirmation>
        </div>
        <OnboardButton
          id="bt"
          onClick={() => {
            if (installed) {
              nextScreen();
            }
          }}
          disable={!installed}
        >
          <TextInProgressAtom
            showProgress={
              context?.onboarding == null || context?.onboarding?.phone == null
            }
          >
            {t('onboarding.download.action')}
          </TextInProgressAtom>
        </OnboardButton>
      </div>
    </Wrapper>
  );
};

export const ActButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background: ${(props: UIProps) =>
    props.theme.colors.white};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  font-style: normal;
  ${(props: UIProps) =>
    props.theme.sizes.font.smallerMedium};
  text-align: center;
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  padding: 8px 40px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  .aggree {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23px;

    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.small};
    }
  }

  .info {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    align-items: center;
    text-align: center;
    color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  }

  h2 {
    ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
    align-items: center;
    text-align: center;
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  }

  .actions {
    display: flex;
    margin: auto;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      #bt {
        width: 100%;
      }
    }
  }

  .setup-options-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 50px;
  }

  .setup-option {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
    text-align: center;
    justify-content: space-between;
    min-width: 300px;

    .content-info {
      text-align: center;
      margin: auto;
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    }

    .title {
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
      ${(props: UIProps) =>
    props.theme.sizes.font.smallSemibold};
      align-items: center;
      text-align: center;
      color: ${(props: UIProps) =>
    props.theme.colors.black};
    }

    .desc {
      ${(props: UIProps) =>
    props.theme.sizes.font.small};
      color: ${(props: UIProps) =>
    props.theme.colors.black};
      margin-top: 18px;
    }

    background-color: ${(props: UIProps) =>
    props.theme.colors.softCloud};
    box-sizing: border-box;
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};
    padding: 25px 30px;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    width: 100%;
  }
`;

const DownloadConfirmation = styled.div`
  margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;
