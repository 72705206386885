import React, {
  useState,
  useEffect,
  FunctionComponent,
  useContext, useMemo,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { StatisticChart } from './StatisticChart';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { API_ENDPOINT } from '../../../core/api';
import { BREAKPOINT_TABLET, BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';

interface TrafficDailyProps {
  chosenDayTimestamp: number;
}

const getPhoneDayTrafficPortsStats = async (phoneId, dayTimestamp) => {
  const from = +moment(+dayTimestamp).startOf('day');
  const to = +moment(+dayTimestamp).endOf('day');

  const pld = (
    await axios.get(`${API_ENDPOINT}/phone/${phoneId}/traffic/day/proxies`, {
      params: {
        from,
        to,
      },
    })
  ).data.result;
  return pld;
};

export const TrafficDaily: FunctionComponent<TrafficDailyProps> = (props) => {
  const { chosenDayTimestamp } = props;
  const context = useContext(ConnectionEditViewContext);
  const { t } = useTranslation();

  const [selectedTimestamp, setSelectedTimestamp] = useState(chosenDayTimestamp || +moment().startOf('day'));
  const [dailyPortStats, setDailyPortStats] = useState([]);
  const [selectedPhonePort, setSelectedPhonePort] = useState(0);

  useEffect(() => {
    if (context != null && context?.selectedPhone != null) {
      getPhoneDayTrafficPortsStats(context?.selectedPhone?.id, selectedTimestamp).then(
        (data) => {
          const allPoints = data.reduce((acc, obj) =>
            acc.concat(obj.points), []);

          setDailyPortStats([{ port: t('stats.allPorts'), points: allPoints }, ...data]);
        },
      );
    }
  }, [context, selectedTimestamp]);

  const portOptions = useMemo(() =>
    dailyPortStats?.map((v, i) =>
      ({ value: i, label: v.port })), [dailyPortStats]);

  return (
    <Wrapper>
      <ScrollBarContainer>
        <Scrollbars>
          <div className="container">
            <Block>
              <StatisticChart
                selectedTimestamp={selectedTimestamp}
                points={dailyPortStats[selectedPhonePort]?.points}
                groupingMinutes={60}
                portOptions={portOptions}
                selectedPhonePort={selectedPhonePort}
                setSelectedPhonePort={setSelectedPhonePort}
                setSelectedTimestamp={setSelectedTimestamp}
              />
            </Block>
          </div>
        </Scrollbars>
      </ScrollBarContainer>
    </Wrapper>
  );
};

const ScrollBarContainer = styled.div`
  height: 100%;
  width: 100%;
  min-width: 750px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${BREAKPOINT_TABLET}px) {
    min-width: 100%;
  }
`;

const Block = styled.div`
  position: relative;
  clear: both;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  height: 485px;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    min-height: 100vh;
  }

  .container {
    max-width: 700px;
  }

  .port-select-container {
    display: flex;
    align-items:center;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    background: ${(props: UIProps) =>
    props.theme.colors.iceBlue};
    box-sizing: border-box;
    padding: 8px;
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

    .label{
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }
    .selectbox {
      min-width: 200px;

    }
  }
`;
