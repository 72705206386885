import React, { useState, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../types';
import { BaseModalFormParams } from '../../../modal';
import store from '../../../core/store/store';
import { RotationList } from '../../RotationLIst';
import { PhonesScrollList } from '../../PhonesScrollList';
import {
  NotificationStreams,
  useModalHook,
  useNotificatorHook,
} from '../../../hooks';
import { updatePhoneMass } from '../../../core/store/actions/connections';
import { WrapperScreen } from '../../../styles/ui-controls';
import { FormContainer, Wrapper } from './styles';
import { CheckBox } from '../../atoms/Checkbox';

interface MultiChangeRotationProps extends BaseModalFormParams {}

export const MultiChangeRotation: FunctionComponent<
MultiChangeRotationProps
> = (props) => {
  const { modalParams } = props;

  const modalOptions = useModalHook();
  const notificator = useNotificatorHook();
  const { t } = useTranslation();

  const [status, setStatus] = useState(true);

  const [selectedRotation, setSelectedRotation] = useState<null | SelectOption<number>>({ value: 1, label: '' });

  const submitHandler = (phonesId: string[]) => {
    if (selectedRotation !== null) {
      store.dispatch(
        updatePhoneMass.request({
          phonesId,
          formData: {
            ipChangeMinutesInterval: status ? selectedRotation.value : 1,
            ipChangeEnabled: status,
          },
        }),
      );
    }

    notificator.notify(
      `${t('notification.changeUpdated')}`,
      NotificationStreams.CONNECTION_EDIT_FORM,
    );

    modalOptions.hide();
  };

  return (
    <WrapperScreen>
      <Wrapper>
        <FormContainer>
          <div className="info">
            <div className="title">{t('massActions.changeRotation')}</div>
          </div>
          <PhonesScrollList
            isValid={selectedRotation !== null}
            phonesId={
              modalParams?.selectedPhones !== null
                ? modalParams?.selectedPhones
                : []
            }
            actionText={t(
              `massActions.${status ? 'changeRotation' : 'offRotation'}`,
            )}
            onActionSubmit={submitHandler}
            additionalBlock={(
              <div className="additional-block">
                <CheckBox
                  value={status}
                  onClick={(status) => {
                    setStatus(status);
                  }}
                  label={t('rotation.enableRotation')}
                  hasLabel
                />
                {status && (
                  <RotationList
                    value={selectedRotation}
                    setValue={setSelectedRotation}
                    isMultiChangeRotation
                  />
                )}
              </div>
            )}
          />
        </FormContainer>
      </Wrapper>
    </WrapperScreen>
  );
};
