import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import styled from 'styled-components';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

interface ScrollWrapperProps {
  autoHeightMax?: number;
  children?: React.ReactNode;
  className?: string;
}

export const ScrollWrapper: React.FC<ScrollWrapperProps> = ({
  children,
  autoHeightMax = 485,
  className = '',
}) =>
  (
    <Wrapper className={className}>
      <Scrollbars autoHeight autoHeightMax={autoHeightMax}>
        {children}
      </Scrollbars>
    </Wrapper>
  );

export default ScrollWrapper;

const Wrapper = styled.div`
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    & > div {
      max-height: 100% !important;
    }

    & > div > div {
      position: static !important;
      max-height: 100% !important;
      overflow: scroll !important;
    }

    .uptime > div > div {
      overflow: auto !important;
    }
  }

  &.proxy > div > div {
    overflow-x: hidden !important;
  }
`;
