import React, {
  useState, useEffect, useRef, FunctionComponent,
} from 'react';
import styled from 'styled-components';
import 'moment/locale/ru';
import 'react-calendar/dist/Calendar.css';

import { useOnClickOutside } from '../../hooks';
import { TimePicker } from './TimePicker';
import { DateContainer } from './DateContainer';

interface CustomeTimeInputAtomProps {
  indicator?: boolean;
  value?: any;
  activeData?: boolean;
  onClick?: Function;
  onChange?: Function;
  onBlur?: Function;
  locale?: string;
  disabled?: boolean;
  isUp?: boolean;
}

export const CustomeTimeInputAtom: FunctionComponent<
CustomeTimeInputAtomProps
> = (props) => {
  const {
    indicator = false,
    activeData = false,
    disabled = false,
    value = new Date(),
    onClick = () => {},
    onChange = () => {},
    onBlur = () => {},
    locale,
    isUp = false,
  } = props;

  const containerRef = useRef(null);
  const [data, setDate] = useState(value);
  const [showPicker, setShowPicker] = useState(false);
  const onChangeLocal = (e) => {
    setDate(new Date(e));
    onChange(e);
  };
  useOnClickOutside(containerRef, () => {
    if (showPicker) {
      setShowPicker(false);
    }
  });

  useEffect(() => {
    const newValue = value instanceof Date ? value : new Date(value);
    const newDate = data instanceof Date ? data : new Date(data);

    if (newValue?.getTime() !== newDate?.getTime()) {
      setDate(value);
    }
  }, [value]);

  return (
    <CalendarContainer ref={containerRef}>
      <DateContainer
        indicator={indicator}
        active={activeData}
        disabled={disabled}
        locale={locale}
        value={data}
        format="HH:mm"
        onBlur={(e) =>
          onBlur(e)}
        onClick={(e) => {
          if (disabled) {
            return null;
          }
          setShowPicker(!showPicker);
          return onClick(e);
        }}
      />

      {showPicker && !disabled ? (
        <PickerContainer isUp={isUp}>
          <Wrapper>
            <TimePicker
              value={new Date(data)}
              onChange={(e) => {
                onChangeLocal(e);
                setShowPicker(false);
              }}
            />
          </Wrapper>
        </PickerContainer>
      ) : null}
    </CalendarContainer>
  );
};

const Wrapper = styled.div``;

const CalendarContainer = styled.div`
  position: relative;
`;

interface PickerContainerProps {
  isUp?: boolean;
}

const PickerContainer = styled.div<PickerContainerProps>`
  position: absolute;
  ${(props) =>
    (props.isUp ? 'top' : 'bottom')}: 0;
  z-index: 100;
`;
