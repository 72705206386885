import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UIProps } from '../../types';

import { LangDropDown } from './LangDropDown';
import { ContentComponent } from '../ContentComponent';
import { getTermsAndConditionsLink, getPrivacyPolicyLink } from '../../utils/instruction-links';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

import logo from '../../assets/logo_iproxy.svg';
import { useIsMobileHook } from '../../hooks/useIsMobileHook';
import { windowOpen } from '../../utils';

interface FooterTemplateProps {

}

export const FooterTemplate: FunctionComponent<FooterTemplateProps> = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobileHook();

  return (
    <Footer>
      <ContentComponent>
        <FooterContent>
          <LeftContainer>
            {
              !isMobile && (
                <Logo onClick={() => {
                  window.location.href = '/';
                }}
                />
              )
            }
            <LangDropDown />
          </LeftContainer>
          <LegalMenu>
            <LegalMenuItem onClick={() =>
              windowOpen(getTermsAndConditionsLink(i18n.language))}
            >
              {t('legalMenu.termsAndConditions')}
            </LegalMenuItem>
            <LegalMenuItem onClick={() =>
              windowOpen(getPrivacyPolicyLink(i18n.language))}
            >
              {t('legalMenu.privacyPolicy')}
            </LegalMenuItem>
          </LegalMenu>
        </FooterContent>
      </ContentComponent>
    </Footer>
  );
};

const LeftContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const Footer = styled.div`
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: 10px 5px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    padding: 8px 0;
  }
`;

const Logo = styled.div`
  width: 125px;
  height: 50px;
  margin-right: 60px;

  background-size: contain;
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;

  cursor: pointer;
`;

const LegalMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

const LegalMenuItem = styled.div`
  cursor: pointer;
  display: inline-block;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    text-align: center;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
  }

  :hover {
    text-shadow: 0 0 .65px ${(prop: UIProps) =>
    prop.theme.colors.white}, 0 0 .65px ${(prop: UIProps) =>
  prop.theme.colors.white};
  }
`;
