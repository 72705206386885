import React, {
  useState,
  useEffect,
  FunctionComponent,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import store from '../core/store/store';
import { updateConnection } from '../core/store/actions/connections';
import { CheckBox } from './atoms/Checkbox';
import { Phone } from '../types/phones';
import { debounceCall, debounceLoad, windowOpen } from '../utils';
import { PermissionService } from '../services/PermissionService';
import {
  InputLabel,
  VideoInstructionBlock,
} from '../styles/ui-controls';
import { InputField } from '../elements';
import { getUniqueIPInstructionLink } from '../utils/instruction-links';
import { UIProps } from '../types';

interface UniqueIPProps {
  phone?: Phone;
}

export const UniqueIP: FunctionComponent<UniqueIPProps> = (props) => {
  const { phone } = props;
  const { t, i18n } = useTranslation();
  const [status, setStatus] = useState(false);
  const [attempts, setAttempts] = useState(null);
  const [timeInterval, setTimeInterval] = useState(null);

  useEffect(() => {
    if (phone != null) {
      setStatus(
        phone?.ipChangeBasedOnHistoryEnabled != null
        && phone.ipChangeBasedOnHistoryEnabled,
      );
      setAttempts(
        phone?.ipChangeAttempts != null ? phone?.ipChangeAttempts : 1,
      );
      setTimeInterval(
        phone?.ipChangeHistoryMinutes != null
          ? phone?.ipChangeHistoryMinutes
          : 1,
      );
    }
  }, [phone]);

  const saveParameters = (ipChangeAttempts, ipChangeHistoryMinutes) => {
    debounceLoad(() => {
      store.dispatch(
        updateConnection.request({
          id: phone.id,
          ipChangeAttempts,
          ipChangeHistoryMinutes,
          ipChangeBasedOnHistoryEnabled: true,
        }),
      );
    });
  };

  return (
    <Wrapper>
      <SubWrapper>
        <VideoInstructionBlock
          onClick={() =>
            windowOpen(getUniqueIPInstructionLink(i18n.language))}
        >
          {t('howSetup')}
        </VideoInstructionBlock>
      </SubWrapper>
      <CheckBox
        disabled={
          !PermissionService.tariffHasFullFeatureAccess(
            phone?.activePlans[0]?.id,
          )
        }
        value={status}
        onClick={(status) => {
          setStatus(status);
          store.dispatch(
            updateConnection.request({
              id: phone.id,
              ipChangeBasedOnHistoryEnabled: status,
            }),
          );
        }}
        label={t('proSettings.enableUniqueIP')}
        hasLabel
      />
      {status ? (
        <>
          <BlockWrapper>
            <InputLabel>
              {t('proSettings.uniqueIpAttempts')}
              {' '}
              1 - 10
            </InputLabel>
            <InputField
              value={attempts}
              number
              minimum={1}
              floatLabel
              placeholder={`${t('wallet.amount')}`}
              onBlur={({ target }) => {
                const val = target.value == null
                || target.value?.replaceAll(' ', '') === ''
                  ? 1
                  : +target.value >= 10
                    ? 10
                    : +target.value;
                setAttempts(val);
                if (val > 0 && val <= 10) {
                  debounceCall(() => {
                    saveParameters(val, timeInterval);
                  });
                }
              }}
              onChange={({ target }) => {
                setAttempts(+target.value);
                if (target.value > 0 && target.value <= 10) {
                  debounceCall(() => {
                    saveParameters(+target.value, timeInterval);
                  });
                }
              }}
            />
          </BlockWrapper>
          <BlockWrapper>
            <InputLabel>
              {t('proSettings.uniqueIpAttemptInterval')}
              {' '}
              1 - 64800
            </InputLabel>
            <InputField
              value={timeInterval}
              number
              minimum={1}
              floatLabel
              placeholder={`${t('wallet.amount')}`}
              onBlur={({ target }) => {
                const val = target.value == null
                || target.value?.replaceAll(' ', '') === ''
                  ? 1
                  : +target.value >= 64800
                    ? 64800
                    : +target.value;
                setTimeInterval(val);
                if (val > 0 && val <= 64800) {
                  debounceCall(() => {
                    saveParameters(attempts, val);
                  });
                }
              }}
              onChange={({ target }) => {
                setTimeInterval(+target.value);
                if (target.value > 0 && target.value <= 64800) {
                  debounceCall(() => {
                    saveParameters(attempts, +target.value);
                  });
                }
              }}
            />
          </BlockWrapper>
        </>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

const SubWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const BlockWrapper = styled.div``;
