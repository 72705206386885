import styled from 'styled-components';
import { UIProps } from '../../../types';
import iconLink from '../../../assets/link_icon.svg';
import iconRemove from '../../../assets/phone_icons/remove_icon.svg';
import iconWarning from '../../../assets/warning_icon.svg';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

interface FormContainerProps extends UIProps {
  marginBottom?: string;
  marginBottomAdaptive?: string;
  flexDirection?: string;
  alignItems?: string;
}
export const FormContainer = styled.div<FormContainerProps>`
  margin-bottom: ${(props: FormContainerProps) =>
    props.marginBottom || '35px'};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    margin-bottom: ${(props: FormContainerProps) =>
    props.marginBottomAdaptive || '15px'};
  }

  .additional-block {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.large};

    .shedule-container {
      display: flex;
      flex-direction: column;
      gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    }
  }

  .title {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.largeBold};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    font-weight: 600;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.mediumBold};
    }
  }

  .subtitle {
    color: ${(prop: UIProps) =>
    prop.theme.colors.dark};
  }

  .info {
    display: flex;
    align-items: ${(props: FormContainerProps) =>
    props.alignItems};
    flex-direction: ${(props: FormContainerProps) =>
    props.flexDirection};
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.mediumBold};
    }
  }

  .description {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumMedium};
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smallerMedium};
    }
  }

  .helper {
    position: relative;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    display: flex;
    cursor: pointer;
    font-weight: 600;
    margin-top: -28px;
    margin-left: 21px;

    :before {
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
      content: '';
      display: block;
      width: 28px;
      height: 12px;
      background-image: url(${iconLink});
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        width: 28px;
        height: 12px;
      }
    }
  }

  .drop-down {
    width: 310px;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 100%;
    }
  }

  .period-box {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

    .label {
      padding-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
      margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
      ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumSemibold};
      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
      }
    }
  }

  .code-box {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

    .label {
      padding-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
      margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
      ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumSemibold};
      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
      }
    }
  }

  .warning_msg {
    color: ${(props: UIProps) =>
    props.theme.colors.warning};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    display: flex;
    text-align: end;

    &:before {
      content: '';
      display: block;
      background-image: url(${iconWarning});
      background-position: center;
      background-repeat: no-repeat;
      min-width: 15px;
      height: 15px;
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }
  }
`;

interface WrapperProps extends UIProps {
  minWidth?: string;
  scrollContainerHeigth?: string;
}
export const Wrapper = styled.div<WrapperProps>`
  min-width: ${(props: WrapperProps) =>
    props.minWidth || '630px'};
  box-sizing: border-box;
  min-height: 440px;
  position: relative;
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    min-width: unset;
  }

  .savebt {
    display: flex;
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
    justify-content: end;
  }

  .content {
    width: 94%;
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmall};
    box-sizing: border-box;
    margin: auto;
  }

  .total-goups {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller}
  }

  .scroll-container {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    height: ${(props: WrapperProps) =>
    props.scrollContainerHeigth || '250px'};
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${(props: UIProps) =>
    props.theme.colors.transparentDeepBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      min-height: 350px;
    }
  }

  .processing {
    text-align: center;
  }
`;

interface GroupsListProps extends UIProps {}
export const GroupsList = styled.div<GroupsListProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .close {
    display: none;
    position: absolute;
    width: 10px;
    height: 10px;
    cursor: pointer;
    align-self: center;
    right: 15px;
    background-image: url(${iconRemove});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

interface GroupsItemProps extends UIProps {
  hasCoupon?: boolean;
  itemContentWidth?: string;
}
export const GroupsItem = styled.div<GroupsItemProps>`
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerMedium};
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(props: UIProps) =>
    props.theme.colors.paleBlue};
  min-height: 15px;
  :hover {
    background: rgba(180, 180, 180, 0.15);
    .close {
      display: block;
    }
  }

  .price {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    text-decoration: ${(props: GroupsItemProps) =>
    (props.hasCoupon ? 'line-through' : 'none')};
    color: ${(props: GroupsItemProps) =>
    (props.hasCoupon ? props.theme.colors.darkGray : props.theme.colors.dark)};
    display: inline;
  }

  .discount-price {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    color: ${(props: GroupsItemProps) =>
    props.theme.colors.greenMud};
    display: inline;
    margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .item-content {
    align-self: center;
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    :nth-child(1) {
      width: ${(props: GroupsItemProps) =>
    props.itemContentWidth || '60%'};

      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        width: ${(props: GroupsItemProps) =>
    props.itemContentWidth === '40%' && '30%'};
      }
    }

    :nth-child(2) {
      margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
      width: 40%;
      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        width: 35%;
      }
    }
  }
`;
