import React, { useState, FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import isUrl from 'is-valid-http-url';
import { InputField } from '../../../elements';
import { ButtonLoader } from '../../atoms';
import store from '../../../core/store/store';
import { updateConnection } from '../../../core/store/actions/connections';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';

interface MacrosUrlSettingProps {
  selectedPhone
}

export const MacrosUrlSetting: FunctionComponent<MacrosUrlSettingProps> = (props) => {
  const {
    selectedPhone,
  } = props;
  const { t } = useTranslation();

  const [macrosUrl, setMacrosUrl] = useState(selectedPhone?.macrosUrl || '');
  const isValidUrl = useMemo(() =>
    macrosUrl != null && (isUrl(macrosUrl) || isEmpty(macrosUrl)), [macrosUrl]);

  return (
    <Wrapper>
      <InputWrapper>
        <InputField
          error={isValidUrl == null ? false : !isValidUrl}
          errorMsg={`${t('proSettings.incorrectLink')}`}
          floatLabel
          value={macrosUrl}
          onBlur={() => {
          }}
          placeholder="Macros URL"
          onChange={({ target }) =>
            setMacrosUrl(target?.value)}
        />
      </InputWrapper>
      <ButtonWrapper>
        <ButtonLoader
          size="small"
          onClick={() => {
            if (isValidUrl) {
              store.dispatch(
                updateConnection.request({
                  id: selectedPhone.id,
                  macrosUrl,
                }),
              );
            }
          }}
        >
          {t('editForm.apply')}
        </ButtonLoader>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const InputWrapper = styled.div`
  width: 70%;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;
