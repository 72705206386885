import React, { useState, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalFormParams } from '../../../modal';
import store from '../../../core/store/store';
import { PhonesScrollList } from '../../PhonesScrollList';
import { useModalHook } from '../../../hooks';
import { WrapperScreen } from '../../../styles/ui-controls';
import { Api } from '../../../core/api';
import { loadUserPhones } from '../../../core/store/actions/connections';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { showNotification } from '../../../utils';
import { FormContainer, Wrapper } from './styles';

interface MultiChangeIPProps extends BaseModalFormParams {}

export const MultiChangeIP: FunctionComponent<MultiChangeIPProps> = (props) => {
  const { modalParams } = props;

  const modalOptions = useModalHook();
  const { t } = useTranslation();
  const phones = useTypedSelector(({ connections }) =>
    connections);

  const [selectedPhones, setSelectedPhones] = useState<string[]>(null);

  const submitHandler = (phonesId: string[]) => {
    setSelectedPhones(phonesId);
  };

  useEffect(() => {
    if (selectedPhones != null) {
      const request = selectedPhones.map(async (id) =>
        Api.get(
          `/changeip/${id}/${`${phones?.data.find((sp) =>
            sp.id === id)?.timestamp}`.slice(-4)}`,
          null,
          null,
          false,
        ));

      Promise.all(request).then(() => {
        store.dispatch(loadUserPhones.request(null));
        setTimeout(() => {
          modalOptions.hide();
          showNotification(`${t('connection.commandHasBeenExecuted')}`);
        }, 200);
      });
    }
  }, [selectedPhones]);

  return (
    <WrapperScreen>
      <Wrapper>
        <FormContainer
          marginBottom="100px"
          marginBottomAdaptive="100px"
        >
          <div className="info">
            <div className="title">{t('connection.changeIP')}</div>
          </div>
          <PhonesScrollList
            isValid={!selectedPhones}
            phonesId={
              modalParams?.selectedPhones !== null
                ? modalParams?.selectedPhones
                : []
            }
            actionText={t('connection.changeIP')}
            onActionSubmit={submitHandler}
          />
        </FormContainer>
      </Wrapper>
    </WrapperScreen>
  );
};
