import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/dist/locale/es';
import 'moment/dist/locale/hi';
import 'moment/dist/locale/pt';
import 'moment/dist/locale/ru';
import 'moment/dist/locale/th';
import 'moment/dist/locale/tr';
import 'moment/dist/locale/uk';
import 'moment/dist/locale/vi';
import 'moment/dist/locale/zh-cn';
import { useTranslation } from 'react-i18next';
import { renderStatsTool } from './edit-connection/StatisticsSettings/StatisticCalendar';
import theme from '../styles/ApplicationTheme';
import { LoaderIcon } from './common/LoaderIcon';
import { getPhoneMonthTrafficStats } from './edit-connection/StatisticsSettings/TrafficMonthly';
import { Phone } from '../types/phones';

import { ReactComponent as IconStats } from '../assets/edit modal/statistic.svg';
import { useIsMobileHook } from '../hooks/useIsMobileHook';

interface TrafficComponentProps {
  phone: Phone;
}

export const TrafficComponent: FunctionComponent<TrafficComponentProps> = (props) => {
  const { phone } = props;
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showTraffic, setSetShowTraffic] = useState(false);
  const [currentMonth, setCurrentMonth] = useState('');
  const isMobile = useIsMobileHook();

  const startTimestamp = +moment().startOf('day');

  const handleShowTrafficMonthly = () => {
    setLoading(true);

    getPhoneMonthTrafficStats(phone?.id, startTimestamp, () => {
      setLoading(false);
      setSetShowTraffic(true);
    });
  };

  useEffect(() => {
    moment.locale(i18n.language === 'ua' ? 'uk' : i18n.language);
    setCurrentMonth(moment().format('MMMM'));
  }, [i18n.language]);

  return (
    <>
      {
        loading ? (
          <LoaderIcon />
        )
          : showTraffic ? (
            renderStatsTool(phone?.trafficMonthly?.sumResult, isMobile)
          ) : (
            <IconWrapper
              data-place="left"
              data-tip={`${t('toolTip.showTraffic', { month: currentMonth })}`}
              onClick={() =>
                handleShowTrafficMonthly()}
            >
              <IconStats />
            </IconWrapper>
          )
      }
    </>
  );
};

const IconWrapper = styled.div`
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  svg {
    path {
      fill: ${theme.colors.deepBlue};
    }
  }
`;
