import { useState, useEffect } from 'react';
import { BREAKPOINT_MOBILE } from '../utils/constants';

export function useIsMobileHook() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= BREAKPOINT_MOBILE);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerWidth <= BREAKPOINT_MOBILE);

    window.addEventListener('resize', handleResize);
    return () =>
      window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}
