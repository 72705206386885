import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Phone } from '../../types/phones';
import { VideoInstructionBlock } from '../../styles/ui-controls';
import { CheckBox } from '../atoms/Checkbox';
import { UIProps } from '../../types';
import { windowOpen } from '../../utils';

interface TelegramNotificationsCheckboxProps {
  selectedPhone?: Phone;
  onChangeFlag: (value) => void;
  disabled?: boolean;
}

export const TelegramNotificationsCheckbox: FunctionComponent<
TelegramNotificationsCheckboxProps
> = ({ selectedPhone, onChangeFlag, disabled = false }) => {
  const { t, i18n } = useTranslation();

  const [notificationOptions, setNotificationOptions] = useState({
    telegramChangeIpNotificationEnabled: false,
    telegramStatusNotificationEnabled: false,
  });

  useEffect(() => {
    if (selectedPhone) {
      setNotificationOptions({
        telegramChangeIpNotificationEnabled:
          selectedPhone.telegramChangeIpNotificationEnabled || false,
        telegramStatusNotificationEnabled:
          selectedPhone.telegramStatusNotificationEnabled || false,
      });
    }
  }, [selectedPhone]);

  const setNotificationFlag = (key, value) => {
    const newOptions = {
      ...notificationOptions,
      [key]: value,
    };
    setNotificationOptions(newOptions);
    onChangeFlag(newOptions);
  };

  return (
    <>
      <VideoWrapper>
        <VideoInstructionBlock
          onClick={() =>
            windowOpen(i18n.language === 'ru'
              ? 'https://www.youtube.com/watch?v=ZwazuslSQQo'
              : 'https://www.youtube.com/watch?v=0trziJqITzY')}
        >
          {t('ourProjects.learn')}
        </VideoInstructionBlock>
      </VideoWrapper>
      <CheckBoxWrap>
        <CheckBox
          disabled={disabled}
          value={notificationOptions.telegramChangeIpNotificationEnabled}
          onClick={(status) =>
            setNotificationFlag('telegramChangeIpNotificationEnabled', status)}
          label={`${t('bot.ipChange')}`}
          hasLabel
        />
      </CheckBoxWrap>
      <CheckBoxWrap>
        <CheckBox
          disabled={disabled}
          value={notificationOptions.telegramStatusNotificationEnabled}
          onClick={(status) =>
            setNotificationFlag('telegramStatusNotificationEnabled', status)}
          label={`${t('bot.uptime')}`}
          hasLabel
        />
      </CheckBoxWrap>
    </>
  );
};

const CheckBoxWrap = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;
