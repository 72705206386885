import React, { useState, useEffect, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import moment from 'moment';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import {
  BasicButton,
  WrapperScreen,
} from '../styles/ui-controls';
import store from '../core/store/store';
import { loadUserActivityLogs } from '../core/store/actions/user';
import ScrollWrapper from '../components/common/ScrollWrapper';
import {
  TransactionsContainer,
  TransactionsRow,
  ScreenInfo,
  Error,
  Time,
  ButtonWrapper,
} from './styles';
import { dateFormats, BREAKPOINT_MOBILE } from '../utils/constants';
import { formatDate } from '../utils/formatter-utils';
import { LoaderBlock } from '../components/edit-connection/StatisticsSettings/PhoneUptimePanel';
import { LoaderIcon } from '../components/common/LoaderIcon';
import { UIProps } from '../types';

export interface ActivityLogsScreenProps {}

export const ActivityLogsScreen: FunctionComponent<ActivityLogsScreenProps> = () => {
  const { t } = useTranslation();

  const { activityLogs, id } = useTypedSelector(({ user }) =>
    user?.profile);
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const startDate = +moment().subtract(1, 'week').toDate();
  const endDate = +new Date();

  useEffect(() => {
    setLoading(true);
    store.dispatch(
      loadUserActivityLogs.request({
        userId: id,
        from: startDate,
        to: endDate,
        callback: () =>
          setLoading(false),
      }),
    );
  }, []);

  return (
    <WrapperScreen>
      {
        loading ? (
          <LoaderBlock>
            <LoaderIcon width="150px" height="150px" />
          </LoaderBlock>
        ) : (
          <>
            <ScreenInfo
              marginTop="25px"
            >
              <div className="title">{t('profile.activityLogs')}</div>
            </ScreenInfo>

            {
              activityLogs?.length ? (
                <>
                  <Description>
                    {t('transaction.activity')}
                  </Description>

                  <ScrollWrapper>
                    <TransactionsContainer
                      minWidth="830px"
                      paddingRight="15px"
                    >
                      {activityLogs?.slice(0, 100 * count)?.map(({ timestamp, action }) =>
                        (
                          <TransactionsRow key={timestamp + action}>
                            <div className="right">
                              <Time>
                                {formatDate(timestamp, dateFormats.dateTimeSec)}
                              </Time>
                              <div className="amount">
                                {t(`activityLogs.${action}`)}
                              </div>
                            </div>
                          </TransactionsRow>
                        ))}
                    </TransactionsContainer>
                  </ScrollWrapper>

                  <ButtonWrapper>
                    {Math.ceil((activityLogs?.length ?? 0) / 100) > count && (
                      <BasicButton
                        size="small"
                        onClick={() =>
                          setCount(count + 1)}
                      >
                        {t('transaction.showMore')}
                      </BasicButton>
                    )}
                  </ButtonWrapper>
                </>
              ) : (
                <ScrollWrapper>
                  <TransactionsContainer
                    minWidth="830px"
                    paddingRight="15px"
                  >
                    <Error>
                      {t('activityLogs.error')}
                    </Error>
                  </TransactionsContainer>
                </ScrollWrapper>
              )
            }
          </>
        )
      }
    </WrapperScreen>
  );
};

const Description = styled.div`
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.midsmallZero};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  }
`;
