import React, {
  useState,
  FunctionComponent,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { CheckBox } from '../../atoms/Checkbox';
import store from '../../../core/store/store';
import { updateConnection } from '../../../core/store/actions/connections';
import { InputLabel, VideoInstructionBlock } from '../../../styles/ui-controls';
import { InputField } from '../../../elements';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { debounceLoad, windowOpen } from '../../../utils';
import { UIProps } from '../../../types';
import { PermissionService } from '../../../services/PermissionService';
import { getWiFiSplitInstructionLink } from '../../../utils/instruction-links';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

export const debounceCall = debounce(
  (callback) => {
    callback();
  },
  50,
  {
    leading: false,
    trailing: true,
  },
);

interface WifiSplitProps {
  selectedPhone;
}

export const WifiSplit: FunctionComponent<WifiSplitProps> = (
  props,
) => {
  const { selectedPhone } = props;
  const { t, i18n } = useTranslation();
  const context = useContext(ConnectionEditViewContext);

  const enableDisableWifi = useMemo(
    () =>
      selectedPhone?.noWifiConnectionTimeoutSeconds
      && selectedPhone?.noWifiConnectionTimeoutSeconds <= 60,
    [selectedPhone],
  );

  const enableTurnOnWifi = useMemo(
    () =>
      selectedPhone?.noWifiConnectionRebootTimeoutSeconds
      && selectedPhone?.noWifiConnectionRebootTimeoutSeconds <= 40320,
    [selectedPhone],
  );

  const [showDisableWifi, setShowDisableWifi] = useState(enableDisableWifi);
  const [showTurnOnWifi, setShowTurnOnWifi] = useState(enableTurnOnWifi);
  const [disableWifiValue, setDisableWifiValue] = useState(null);
  const [turnOnWifiValue, setTurnOnWifiValue] = useState(null);

  useEffect(() => {
    if (selectedPhone != null) {
      setDisableWifiValue(
        selectedPhone?.noWifiConnectionTimeoutSeconds != null
          ? selectedPhone?.noWifiConnectionTimeoutSeconds
          : 1,
      );
      setTurnOnWifiValue(
        selectedPhone?.noWifiConnectionRebootTimeoutSeconds != null
          ? selectedPhone?.noWifiConnectionRebootTimeoutSeconds
          : 40320,
      );
    }
  }, [selectedPhone]);

  const saveDisableWifi = (noWifiConnectionTimeoutSeconds) => {
    debounceLoad(() => {
      store.dispatch(
        updateConnection.request({
          id: selectedPhone.id,
          noWifiConnectionTimeoutSeconds,
        }),
      );
    });
  };

  const renderDisableWifi = () =>
    (
      <SubWrapper>
        <InputLabel>
          {t('proSettings.inMinutess')}
          {' '}
          1 - 60
        </InputLabel>
        <InputField
          value={disableWifiValue}
          number
          minimum={1}
          floatLabel
          placeholder={`${t('wallet.amount')}`}
          onBlur={({ target }) => {
            const val = target.value == null || target.value?.replaceAll(' ', '') === ''
              ? 1
              : +target.value >= 60
                ? 60
                : +target.value;
            setDisableWifiValue(val);
            if (val > 0 && val <= 60) {
              debounceCall(() => {
                saveDisableWifi(val);
              });
            }
          }}
          onChange={({ target }) => {
            setDisableWifiValue(+target.value);
            if (target.value > 0 && target.value <= 60) {
              debounceCall(() => {
                saveDisableWifi(+target.value);
              });
            }
          }}
        />
      </SubWrapper>
    );

  const saveTurnOnWifi = (noWifiConnectionRebootTimeoutSeconds) => {
    debounceLoad(() => {
      store.dispatch(
        updateConnection.request({
          id: selectedPhone.id,
          noWifiConnectionRebootTimeoutSeconds,
        }),
      );
    });
  };

  const renderTurnOnWifi = () =>
    (
      <SubWrapper>
        <InputLabel>
          {t('proSettings.inMinutess')}
          {' '}
          1 - 40320
        </InputLabel>
        <InputField
          value={turnOnWifiValue}
          number
          minimum={1}
          floatLabel
          placeholder={`${t('wallet.amount')}`}
          onBlur={({ target }) => {
            const val = target.value == null || target.value?.replaceAll(' ', '') === ''
              ? 1
              : +target.value >= 40320
                ? 40320
                : +target.value;
            setTurnOnWifiValue(val);
            if (val > 0 && val <= 40320) {
              debounceCall(() => {
                saveTurnOnWifi(val);
              });
            }
          }}
          onChange={({ target }) => {
            setTurnOnWifiValue(+target.value);
            if (target.value >= 0 && target.value <= 40320) {
              debounceCall(() => {
                saveTurnOnWifi(+target.value);
              });
            }
          }}
        />
      </SubWrapper>
    );

  return (
    <Wrapper>
      <VideoInstructionBlockWrapper>
        <VideoInstructionBlock
          style={{ textAlign: 'right' }}
          onClick={() =>
            windowOpen(getWiFiSplitInstructionLink(i18n.language))}
        >
          {t('ourProjects.learn')}
        </VideoInstructionBlock>
      </VideoInstructionBlockWrapper>

      <BaseDescription>
        {t('proSettings.wifiSplitMsg')}
      </BaseDescription>

      {!context?.isOwner ? null : (
        <>
          <CheckBox
            disabled={
              !PermissionService.tariffHasFullFeatureAccess(
                selectedPhone?.activePlans[0]?.id,
              )
            }
            value={showDisableWifi}
            onClick={(status) => {
              setShowDisableWifi(status);
              store.dispatch(
                updateConnection.request({
                  id: selectedPhone.id,
                  noWifiConnectionTimeoutSeconds: status
                    ? disableWifiValue
                    : null,
                }),
              );
            }}
            label={`${t('proSettings.disableWiFi')}`}
            hasLabel
          />
          {showDisableWifi ? renderDisableWifi() : null}
          <CheckBox
            disabled={
              !PermissionService.tariffHasFullFeatureAccess(
                selectedPhone?.activePlans[0]?.id,
              )
            }
            value={showTurnOnWifi}
            onClick={(status) => {
              setShowTurnOnWifi(status);
              store.dispatch(
                updateConnection.request({
                  id: selectedPhone.id,
                  noWifiConnectionRebootTimeoutSeconds: status
                    ? turnOnWifiValue
                    : null,
                }),
              );
            }}
            label={`${t('proSettings.turnOnWiFi')}`}
            hasLabel
          />
          {showTurnOnWifi ? renderTurnOnWifi() : null}
        </>
      )}
    </Wrapper>
  );
};

const BaseDescription = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const VideoInstructionBlockWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const SubWrapper = styled.div``;
