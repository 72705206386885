import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';
import { Connection, UIProps } from '../../../types';

import warningIcon from '../../../assets/warning_icon.svg';
import refreshIcon from '../../../assets/refresh.svg';

interface ProxyActionProps {
  connection: Connection;
  onClick: (connection: Connection) => void;
  actionType: 'updateIp' | 'resetPassword';
}

export const ProxyAction: FunctionComponent<ProxyActionProps> = ({
  connection,
  onClick = () => {},
  actionType,
}) => {
  const { t } = useTranslation();
  const isPasswordExpired = actionType === 'resetPassword'
    && moment(connection?.passwordLastUpdateTimestamp).add(90, 'days').valueOf() <= +new Date();

  return (
    <Wrapper>
      <button
        type="button"
        data-tip={t(`proxy.${actionType}`)}
        onClick={() =>
          onClick(connection)}
      >
        <img
          src={refreshIcon}
          alt="Refresh icon"
        />
      </button>

      {(actionType === 'updateIp' || isPasswordExpired) && (
        <img
          data-tip={t(`proxy.${actionType === 'updateIp' ? 'ipOutdated' : 'passwordExpired'}`)}
          src={warningIcon}
          alt="Warning icon"
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  align-items: center;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  button {
    background: none;
    border: none;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
