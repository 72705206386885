import React, {
  useState,
  FunctionComponent,
  useContext,
} from 'react';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { ButtonLoader } from '../../../atoms';
import iconDownload from '../../../../assets/icon_download.svg';
import iconRemove from '../../../../assets/phone_icons/remove_icon.svg';
import iconEdit from '../../../../assets/phone_icons/setting_icon.svg';
import { UIProps } from '../../../../types';
import { OpenVpnEdit } from './OpenVpnEdit';
import iconPlus from '../../../../assets/plus_icon.svg';
import {
  BasicButton,
  InputLabel,
  InputWrapper,
} from '../../../../styles/ui-controls';
import { InputField } from '../../../../elements';
import { ConnectionEditViewContext } from '../../../../context/ConnectionEditViewContext';
import { CheckBox } from '../../../atoms/Checkbox';
import { PermissionService } from '../../../../services/PermissionService';
import store from '../../../../core/store/store';
import {
  createNewOpenVPN,
  removeOpenVPN,
  updateOpenVPN,
} from '../../../../core/store/actions/connections';
import { DNSOvpnAtom } from '../../AdvancedSettings/DNS/DNSOvpnAtom';
import { OpenVPN } from '../../../../types/phones';
import { $downloadConfig } from '../../../../utils';
import { BREAKPOINT_MOBILE } from '../../../../utils/constants';

interface OpenVpnConnectionsProps {
  phoneId?: string;
  udps?: OpenVPN[];
}

export const OpenVpnConnections: FunctionComponent<OpenVpnConnectionsProps> = (
  props,
) => {
  const { phoneId, udps } = props;
  const context = useContext(ConnectionEditViewContext);
  const [ovpn, selectOvpn] = useState(null);
  const [mode, setMode] = useState<'edit' | 'list'>('list');
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isSetOpenVPNDNS, setIsSetOpenVPNDNS] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [isNumericFormatIP, setIsNumericFormatIP] = useState(false);

  const { t } = useTranslation();

  const deleteUdpConnections = (id) =>
    store.dispatch(
      removeOpenVPN.request({
        phoneId: context?.selectedPhone?.id,
        openVPNId: id,
      }),
    );

  const createUdpConnections = (value: string) =>
    store.dispatch(
      createNewOpenVPN.request({
        openVPN: {
          phoneId,
          name: value,
          dns: ['10.180.0.1'],
        },
        callback: () => {
          setIsCreate(false);
          setValue('');
        },
      }),
    );

  const updateConfig = async (phoneId, newConfig, id) => {
    store.dispatch(
      updateOpenVPN.request({
        openVPN: {
          phoneId,
          name: newConfig.name,
          dns: newConfig.dns,
          expiredTimestamp: newConfig.expiredTimestamp,
        },
        id,
        callback: () => {
          setMode('list');
        },
      }),
    );
  };

  return !isCreate && !isSetOpenVPNDNS ? (
    <Wrapper>
      {mode === 'edit' ? (
        <OpenVpnEdit
          ovpnConfig={ovpn}
          onChange={(value) =>
            updateConfig(phoneId, value, ovpn.id)}
          setMode={setMode}
        />
      ) : (
        <div>
          {!context?.isOwner && !context?.permissions?.openVPNCreate ? null : (
            <div className="addConnectionBt">
              <ButtonLoader
                disabled={
                  !PermissionService.tariffHasFullFeatureAccess(
                    context.selectedPhone?.activePlans[0]?.id,
                  )
                }
                size="small"
                onClick={() => {
                  setIsSetOpenVPNDNS(true);
                }}
              >
                {t('openVpnEdit.set')}
              </ButtonLoader>
              <ButtonLoader
                disabled={
                  !PermissionService.tariffHasFullFeatureAccess(
                    context.selectedPhone?.activePlans[0]?.id,
                  )
                }
                size="small"
                onClick={() => {
                  setIsCreate(true);
                }}
              >
                <IconConnection />
                {t('openVpnEdit.create')}
              </ButtonLoader>
            </div>
          )}
          {!!udps?.length && (
            <Section>
              <TitleBlock>
                {t('openVpnEdit.configHostname')}
              </TitleBlock>
              <CheckBoxWrap>
                <CheckBox
                  value={!isNumericFormatIP}
                  onClick={(status) => {
                    setIsNumericFormatIP(!status);
                  }}
                  label={`${t('dashboard.domainName')}`}
                  hasLabel
                />
              </CheckBoxWrap>

              <CheckBoxWrap>
                <CheckBox
                  value={isNumericFormatIP}
                  onClick={(status) => {
                    setIsNumericFormatIP(status);
                  }}
                  label={`${t('dashboard.numericFormat')}`}
                  hasLabel
                />
              </CheckBoxWrap>
            </Section>
          )}

          <div className="scroll">
            <Scrollbars>
              <OvpnLIst>
                {udps?.map((udp) =>
                  (
                    <div className="item" key={udp?.id}>
                      {udp?.name}
                      <div className="action-ctrl">
                        <ActionBt
                          icon={iconDownload}
                          onClick={() => {
                            $downloadConfig(udp?.id, udp?.name, phoneId, isNumericFormatIP);
                          }}
                        />
                        {!context?.isOwner
                      && !context?.permissions?.openVPNRemove ? null : (
                        <ActionBt
                          icon={iconRemove}
                          onClick={() =>
                            deleteUdpConnections(udp?.id)}
                        />
                          )}
                        {!context?.isOwner
                      && !context?.permissions?.openVPNEdit ? null : (
                        <ActionBt
                          icon={iconEdit}
                          onClick={() => {
                            selectOvpn(udp);
                            setMode('edit');
                          }}
                        />
                          )}
                      </div>
                    </div>
                  ))}
              </OvpnLIst>
            </Scrollbars>
          </div>
        </div>
      )}
    </Wrapper>
  ) : isCreate ? (
    <TemporyAddNewBlock>
      <InputWrapper>
        <InputLabel>Name</InputLabel>
        <InputField
          value={value}
          placeholder="Name"
          onChange={({ target }) =>
            setValue(target?.value)}
        />
      </InputWrapper>

      <InputWrapper>
        <BasicButton
          className="button"
          size="small"
          fillWidth
          onClick={async () => {
            if (value) createUdpConnections(value);
          }}
          disable={!value}
        >
          {t('openVpnEdit.create')}
        </BasicButton>
      </InputWrapper>
      <InputWrapper>
        <BasicButton
          className="button"
          color="warning"
          size="small"
          fillWidth
          onClick={() => {
            setIsCreate(false);
            setValue('');
          }}
        >
          {t('updateAppModal.no')}
        </BasicButton>
      </InputWrapper>
    </TemporyAddNewBlock>
  ) : isSetOpenVPNDNS ? (
    <DNSOvpnAtom
      phone={context?.selectedPhone}
      setIsSetOpenVPNDNS={setIsSetOpenVPNDNS}
    />
  ) : null;
};

const Wrapper = styled.div`
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  .addConnectionBt {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    display: flex;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      flex-direction: column;
      gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }
  }

  .scroll {
    height: 300px;
    overflow: hidden;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const OvpnLIst = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  .item {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    border-bottom: 1px solid ${(props: UIProps) =>
    props.theme.colors.ashGray};
    display: flex;
    justify-content: space-between;
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    .action-ctrl {
      display: flex;
    }
  }
`;

interface ActionBtProps extends UIProps {
  icon?: string;
}

export const ActionBt = styled.div<ActionBtProps>`
  width: 15px;
  height: 15px;
  background-image: url(${(props: ActionBtProps) =>
    props.icon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmaller};
  display: flex;
  align-items: center;
  transition: 0.1s;

  :hover {
    opacity: 0.8;
  }
`;

const IconConnection = styled.div`
  background-image: url(${iconPlus});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  left: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;

const TemporyAddNewBlock = styled.div`
  display: flex;
  align-items: end;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const CheckBoxWrap = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;
