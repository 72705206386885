import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import {
  VideoInstructionBlock,
} from '../../../styles/ui-controls';
import { CheckBox } from '../../atoms/Checkbox';
import store from '../../../core/store/store';
import { updateConnection } from '../../../core/store/actions/connections';
import { CouponModel, UIProps, PlansType } from '../../../types';
import { Phone } from '../../../types/phones';
import { TariffButton } from './TariffCommon';
import { Api } from '../../../core/api';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import {
  getOVPNInstructionLink,
  getPofInstructionLink,
  getSMSBotInstructionLink,
  getUniqueIPInstructionLink,
  getWiFiSplitInstructionLink,
} from '../../../utils/instruction-links';
import { analyticsClassnames } from '../../../utils/analytics-utils';
import { formatDate, windowOpen } from '../../../utils';
import {
  LINK_REL,
  TARGET_BLANK,
  dateFormats,
  BREAKPOINT_MOBILE,
} from '../../../utils/constants';

interface TariffsListProps {
  isOwner: boolean;
  selectedPhone: Phone;
  tariffs: any;
  prolongation: boolean;
  onSelectTariff?: Function;
}

export const TariffsList: FunctionComponent<TariffsListProps> = (props) => {
  const {
    isOwner,
    selectedPhone,
    tariffs,
    onSelectTariff = () => {},
    prolongation,
  } = props;
  const { t, i18n } = useTranslation();
  const context = useContext(ConnectionEditViewContext);
  const FEATURES = [
    { feature: 'Wi-Fi Split', link: getWiFiSplitInstructionLink(i18n.language) },
    { feature: '.ovpn', link: getOVPNInstructionLink(i18n.language) },
    { feature: 'pOf', link: getPofInstructionLink(i18n.language) },
    { feature: 'Unique IP', link: getUniqueIPInstructionLink(i18n.language) },
    { feature: 'SMS App', link: getSMSBotInstructionLink(i18n.language) },
  ];

  const [couponDetail, setCouponDetail] = useState<CouponModel>(null);

  useEffect(() => {
    if (selectedPhone.couponCode) {
      const $getCouponDetail = async () => {
        await Api.get(
          `coupon/${selectedPhone.couponCode}`,
          null,
          null,
          false,
        ).then((d) =>
          setCouponDetail(d.data.result));
      };
      $getCouponDetail();
    }
  }, []);

  return (
    <Wrapper>
      <TitleBlock>
        <VideoWrapper>
          <VideoInstructionBlock
            onClick={() =>
              windowOpen('https://www.youtube.com/watch?v=pY2ioCYXJi0')}
          >
            {t('wallet.paymentInstructions')}
          </VideoInstructionBlock>
        </VideoWrapper>
      </TitleBlock>
      <TariffContainer>
        <Tariffs>
          {tariffs
            ?.sort((a, b) =>
              b.price - a.price)
            .map((tariff) => {
              const phoneTariff = selectedPhone?.activePlans[0];
              const tariffExpDate = moment(phoneTariff?.expirationTimestamp);

              return (
                <Tariff key={tariff.id} isActive={phoneTariff?.id === tariff.id}>
                  <TariffTitle>
                    {tariff[`${i18n.language}Title`]
                      ? tariff[`${i18n.language}Title`]
                      : tariff?.enTitle}
                    {' '}
                    (
                    {`$${tariff.usdPrice} / 30 ${t('tariff.days')}`}
                    )
                    {phoneTariff?.id === tariff.id ? (
                      <TariffActive>
                        {t('tariff.activeTill', {
                          date: `${formatDate(tariffExpDate, dateFormats.longDateTime)}`,
                        })}
                      </TariffActive>
                    ) : null}
                  </TariffTitle>
                  <TariffDescriptionWrapper>
                    {!isOwner
                    && !context?.permissions?.tariffStatisticView ? null : (
                      <TarriffInfo>
                        <TariffBlock>
                          <TariffSubtitle>
                            {t('tariff.speedLimit')}
                          </TariffSubtitle>
                          <TariffValue>
                            {t('tariff.speed', {
                              speed: `${tariff.bits_per_sec_in / 1000000}`,
                            })}
                          </TariffValue>
                        </TariffBlock>
                        <TariffBlock>
                          <TariffSubtitle>
                            {t('tariff.numberOfProxyAccesses')}
                          </TariffSubtitle>
                          <TariffValue>
                            {tariff.max_proxies_per_connection}
                          </TariffValue>
                        </TariffBlock>
                        <TariffBlock>
                          <TariffSubtitle>
                            {t('logs.available')}
                          </TariffSubtitle>
                          <TariffValue>
                            {t('tariff.weeks', {
                              count:
                                tariff.id === PlansType.BigDaddyPro
                                  ? 12
                                  : tariff.id === PlansType.BigDaddy
                                    ? 4
                                    : 1,
                            })}
                          </TariffValue>
                        </TariffBlock>
                        {
                          tariff.id === PlansType.BigDaddyPro
                          && (
                          <FeaturesWrapper>
                            {
                              FEATURES.map(({ feature, link }) =>
                                (
                                  <Feature
                                    key={feature}
                                    href={link}
                                    target={TARGET_BLANK}
                                    rel={LINK_REL}
                                  >
                                    {feature}
                                  </Feature>
                                ))
                            }
                          </FeaturesWrapper>
                          )
                        }
                      </TarriffInfo>
                      )}
                    {phoneTariff?.id === tariff.id ? (
                      !isOwner
                      && !context?.permissions?.connectionPayment ? null : (
                        <TariffButton
                          className={`${analyticsClassnames.pushTariff}_${tariff.enTitle.replace(' ', '')}`}
                          isActive={phoneTariff?.id === tariff.id}
                          onClick={() =>
                            onSelectTariff(
                              tariff,
                              phoneTariff == null || phoneTariff?.id === tariff.id,
                            )}
                        >
                          {phoneTariff?.id === tariff.id
                            ? `${t('tariff.renew')}`
                            : selectedPhone.activePlans.length > 0
                              ? `${t('tariff.switch')}`
                              : `${t('tariff.buy')}`}
                        </TariffButton>
                        )
                    ) : !isOwner
                      && !context?.permissions?.connectionChangeTariff ? null : (
                        <TariffButton
                          className={`${analyticsClassnames.pushTariff}_${tariff.enTitle.replace(' ', '')}`}
                          isActive={phoneTariff?.id === tariff.id}
                          onClick={() =>
                            onSelectTariff(
                              tariff,
                              phoneTariff == null || phoneTariff?.id === tariff.id,
                            )}
                        >
                          {phoneTariff?.id === tariff.id
                            ? `${t('tariff.renew')}`
                            : selectedPhone.activePlans.length > 0
                              ? `${t('tariff.switch')}`
                              : `${t('tariff.buy')}`}
                        </TariffButton>
                      )}
                  </TariffDescriptionWrapper>
                </Tariff>
              );
            })}
        </Tariffs>
        <AutopayWrapper>
          <CheckBox
            hasLabel
            value={prolongation}
            onClick={(status) => {
              store.dispatch(
                updateConnection.request({
                  id: selectedPhone.id,
                  prolongationEnabled: status,
                }),
              );
            }}
            label={`${t('toolTip.autoRenewal')}${
              !!selectedPhone.couponCode
                  && (couponDetail?.maxUsagesNumber ?? 0) > 10000
                ? `. ${t('tariff.applyPromo', {
                  coupon: `'${selectedPhone.couponCode}'`,
                  percent: couponDetail.amount,
                })}`
                : ''
            }`}
          />
          <BaseDescription>
            {t('tariff.autoPay')}
          </BaseDescription>
        </AutopayWrapper>
      </TariffContainer>
    </Wrapper>
  );
};

const FeaturesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  flex-wrap: wrap;
`;

const Feature = styled.a`
  background-color: ${(props: TariffProps) =>
    props.theme?.colors?.greenMud};
  color: ${(props: TariffProps) =>
    props.theme?.colors?.white};
  ${(props: TariffProps) =>
    props.theme?.sizes.font.small};
  font-weight: 500;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  height: 100%;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    background-color: ${(props: UIProps) =>
    props.theme.colors.ocenlight};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const AutopayWrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const TitleBlock = styled.div`
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: 12px 12px 0 0;
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const TariffContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 94%;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: 0 12px 12px;
  }
`;

const Tariffs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

interface TariffProps extends UIProps {
  isActive?: boolean;
}

const Tariff = styled.div<TariffProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  padding: 8px 12px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

  background-color: ${(props: TariffProps) =>
    props.theme?.colors?.white};

  ${(props: TariffProps) =>
    props?.isActive
    && css`
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
      background-color: ${(props: TariffProps) =>
    props.theme?.colors?.lightSky};
    `}

  &:last-child {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: 8px;
      padding: 12px;
  }
`;

const TariffDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: block;
  }

  &:hover {
    .description {
      visibility: visible;
    }
  }
`;

const TariffTitle = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  margin-bottom: 8px;
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  }
`;

const TariffActive = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  text-align: right;
`;

const TarriffInfo = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  width: calc(100% - 155px);

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const TariffBlock = styled.div`
  width: 60%;
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

const TariffSubtitle = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  font-weight: 700;
  margin-bottom: 8px;
`;

const TariffValue = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const BaseDescription = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
    font-style: italic;
`;
