import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalFormParams } from '../../../modal';
import { WrapperScreen } from '../../../styles/ui-controls';
import { FormContainer } from './styles';
import { CreateCredentialsFormat } from '../../common/credentials/create-credentials-format';

interface DownloadCredentialsProps extends BaseModalFormParams {}

export const DownloadCredentials: FunctionComponent<DownloadCredentialsProps> = (props) => {
  const { modalParams } = props;
  const { phones, selectedPhones } = modalParams;

  const { t } = useTranslation();

  return (
    <WrapperScreen>
      <FormContainer>
        <div className="title">{t('massActions.downloadCredentials')}</div>

        <CreateCredentialsFormat
          phones={phones.data}
          selectedPhones={selectedPhones}
        />
      </FormContainer>
    </WrapperScreen>
  );
};
