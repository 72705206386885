import React, {
  useState, FunctionComponent, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalFormParams } from '../../../modal';
import store from '../../../core/store/store';
import { PhonesScrollList } from '../../PhonesScrollList';
import { useModalHook } from '../../../hooks';
import { WrapperScreen } from '../../../styles/ui-controls';
import { Api } from '../../../core/api';
import { loadOpenVPN } from '../../../core/store/actions/connections';
import { FormContainer, Wrapper } from './styles';
import { ProgressBarComponent } from './progress-bar-component';
import { DNSOvpnAtom } from '../../edit-connection/AdvancedSettings/DNS/DNSOvpnAtom';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { showNotification } from '../../../utils';

interface MultiSetupOvpnDNSProps extends BaseModalFormParams {}

export const MultiSetupOvpnDNS: FunctionComponent<MultiSetupOvpnDNSProps> = (
  props,
) => {
  const { modalParams } = props;
  const phones = useTypedSelector(({ connections }) =>
    connections);

  const modalOptions = useModalHook();
  const { t } = useTranslation();

  const [selectedPhones, setSelectedPhones] = useState<string[]>(null);
  const [progressView, setProgressView] = useState(false);
  const [buyCount, setBuyCount] = useState(0);

  const [ipList, setIpList] = useState([]);

  const submitHandler = (phonesId: string[]) => {
    setProgressView(true);
    setSelectedPhones(phonesId);
  };

  const udps = useMemo(() =>
    (selectedPhones
      ? phones.data
        .filter((phone) =>
          selectedPhones.includes(phone.id))
        .map((phone) =>
          phone.openVPN)
        .flat()
        .filter((phone) =>
          phone)
      : []), [selectedPhones]);

  useEffect(() => {
    if (progressView && selectedPhones != null) {
      if (!udps?.length) {
        showNotification(`${t('notification.massOpenVpnDnsError')}`, 'error');
        return;
      }

      const requestUdps = udps.map(async (upd) =>
        Api.put(
          `/phones/${upd.phoneId}/uconnections/${upd.id}`,
          {
            ...upd,
            dns: ipList,
          },
          null,
          false,
        ).then(() =>
          setBuyCount((prevCount) =>
            prevCount + 1)));

      const phoneIds = Array.from(new Set(udps.map(({ phoneId }) =>
        phoneId)));

      const phoneNames = phones.data.filter((p) =>
        phoneIds.some((i) =>
          i === p.id)).map((p) =>
        p.name);

      Promise.all(requestUdps).then(() => {
        store.dispatch(loadOpenVPN.request({ phoneIds }));
        setTimeout(() => {
          modalOptions.hide();
          showNotification(
            `${t('notification.massOpenVpnDnsNotification', {
              names: phoneNames.join(', '),
            })}`,
          );
        }, 1200);
      });
    }
  }, [progressView, selectedPhones]);

  return (
    <WrapperScreen>
      <Wrapper scrollContainerHeigth="200px">
        <FormContainer marginBottom="0" marginBottomAdaptive="100px">
          <div className="info">
            <div className="title">{t('massActions.openVPNDNS')}</div>
          </div>
          <PhonesScrollList
            isValid={!progressView}
            phonesId={
              modalParams?.selectedPhones !== null
                ? modalParams?.selectedPhones
                : []
            }
            actionText={t('massActions.openVPNDNS')}
            onActionSubmit={submitHandler}
            additionalBlock={(
              <div className="additional-block">
                <DNSOvpnAtom setIpList={setIpList} />
              </div>
            )}
          />
        </FormContainer>
        {progressView && udps?.length > 0 && (
          <ProgressBarComponent buyCount={buyCount} selectedPhones={udps} />
        )}
      </Wrapper>
    </WrapperScreen>
  );
};
