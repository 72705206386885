import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { InputLabel, InputWrapper } from '../../styles/ui-controls';
import { UIProps } from '../../types';
import { InputField } from '../../elements';
import { NumberInputComponent } from '../common/NumberInputComponent';
import ScrollWrapper from '../common/ScrollWrapper';
import { ButtonLoader, CustomeDateInputAtom } from '../atoms';
import store from '../../core/store/store';
import { createNewAlert } from '../../core/store/actions/connections';
import { CheckBox } from '../atoms/Checkbox';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

interface CreateAlertFormProps {
  onClose: () => void;
  phoneId: string;
}

export const CreateAlertForm: FunctionComponent<CreateAlertFormProps> = ({
  onClose,
  phoneId,
}) => {
  const { t, i18n } = useTranslation();

  const [alert, setAlert] = useState({
    name: '',
    description: '',
    max_in_bytes: 100,
    max_out_bytes: 100,
    max_total_bytes: 100,
    from: +new Date(),
    to: +moment(new Date()).add(1, 'days').startOf('day'),
    stopIfLimitsExceeded: true,
  });

  const onSaveAlert = () => {
    store.dispatch(
      createNewAlert.request({
        alert: {
          ...alert,
          phoneId,
          max_in_bytes: Math.round(alert.max_in_bytes * 1024 ** 2),
          max_out_bytes: Math.round(alert.max_out_bytes * 1024 ** 2),
          max_total_bytes: Math.round(alert.max_total_bytes * 1024 ** 2),
          from:
            alert.from <= Date.now()
              ? +moment(new Date()).add(1, 'second')
              : alert.from,
        },
      }),
    );
    onClose();
  };

  return (
    <Wrapper>
      <ScrollWrapper>
        <BlockWrapper>
          <InputWrapper>
            <InputLabel>{t('connection.name')}</InputLabel>
            <InputField
              value={alert.name}
              floatLabel
              placeholder={`${t('connection.name')}`}
              onChange={({ target }) =>
                setAlert({ ...alert, name: target.value })}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>{t('editConnection.description')}</InputLabel>
            <Description
              onChange={({ target }) =>
                setAlert({
                  ...alert,
                  description: target?.value,
                })}
              value={alert?.description}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>
              Max in MB (
              {(alert.max_in_bytes / 1024).toFixed(2)}
              {' '}
              GB)
            </InputLabel>
            <NumberInputComponent
              value={alert?.max_in_bytes}
              setValue={(value) =>
                setAlert({ ...alert, max_in_bytes: value })}
              from={100}
              to={102400}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>
              Max out MB (
              {(alert.max_out_bytes / 1024).toFixed(2)}
              {' '}
              GB)
            </InputLabel>
            <NumberInputComponent
              value={alert?.max_out_bytes}
              setValue={(value) =>
                setAlert({ ...alert, max_out_bytes: value })}
              from={100}
              to={102400}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>
              Max total MB (
              {(alert.max_total_bytes / 1024).toFixed(2)}
              {' '}
              GB)
            </InputLabel>
            <NumberInputComponent
              value={alert?.max_total_bytes}
              setValue={(value) =>
                setAlert({ ...alert, max_total_bytes: value })}
              from={100}
              to={102400}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel className="label">{t('rotation.from')}</InputLabel>
            <CustomeDateInputAtom
              indicator
              maxDate={alert.to}
              value={alert.from}
              locale={i18n.language}
              onChange={(e) =>
                setAlert({ ...alert, from: +moment(new Date(e)).startOf('day') })}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel className="label">{t('rotation.to')}</InputLabel>
            <CustomeDateInputAtom
              indicator
              value={alert.to}
              locale={i18n.language}
              minDate={alert.from}
              onChange={(e) =>
                setAlert({ ...alert, to: +moment(new Date(e)).startOf('day') })}
            />
          </InputWrapper>
          <InputWrapper>
            <CheckBox
              value={alert.stopIfLimitsExceeded}
              onClick={(status) =>
                setAlert({ ...alert, stopIfLimitsExceeded: status })}
              label={t('bot.limitCheckbox')}
              hasLabel
            />
          </InputWrapper>
          <ButtonLoader
            size="small"
            onClick={onSaveAlert}
          >
            {t('editForm.save')}
          </ButtonLoader>
        </BlockWrapper>
      </ScrollWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Description = styled.textarea`
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  font-family: 'Montserrat', sans-serif !important;
  margin-top: 0px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  width: 100%;
  box-sizing: border-box;
  resize: none;
  border-color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  height: 100px;
  outline: none;
  :focus {
    border-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
  }
`;

const BlockWrapper = styled.div`
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: flex;
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;
