import React from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';

import RoutesStack from './navigation-stack';
import { useAuthGuard } from '../hooks';
import { DashboardTemplate } from '../templates/dashboard-template';
import { PublicTemplate } from '../templates/public-template';
import { basePath } from '../constants';
import { PromocodeBannerProvider } from '../context/PromocodeBannerContext';

interface Props {
  children: any;
  path: any;
}

function PrivateRoute({ children, path }: Props) {
  const authGuard = useAuthGuard();
  return (
    <PromocodeBannerProvider>
      <DashboardTemplate>
        <Route path={path}>
          {
                      authGuard.isAuthorized === true
                        ? children
                        : null
                  }
        </Route>
      </DashboardTemplate>
    </PromocodeBannerProvider>
  );
}

function PublicRoute({ children, path }: Props) {
  return (
    <PublicTemplate>
      <Route path={path}>
        {children}
      </Route>
    </PublicTemplate>
  );
}

function AppNavigator() {
  const routes = RoutesStack.reduce((acc: any, value) =>
    ([
      ...acc,
      ...value.map((stackItem) =>
        (stackItem.private
          ? <PrivateRoute key={stackItem.path} path={stackItem.path}>{stackItem.component}</PrivateRoute>
          : <PublicRoute key={stackItem.path} path={stackItem.path}>{stackItem.component}</PublicRoute>)),
    ]), []);

  return (
    <BrowserRouter
      forceRefresh
      basename={basePath}
    >
      <Switch>
        {routes}
      </Switch>
    </BrowserRouter>
  );
}

export default AppNavigator;
