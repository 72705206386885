import { Epic } from 'redux-observable';
import {
  catchError, filter, switchMap, withLatestFrom,
} from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';
import { from, of } from 'rxjs';

import { Api } from '../../api';
import { idle } from '../actions/app';
import {
  loadServersList,
  loadUserServers,
} from '../actions/servers';
import { ServersHTTPResponse } from '../../../types';

const ApiURLLoadGroupedServerList = () =>
  '/servers/grouped';
const ApiURLLoadUserServers = () =>
  '/my-servers';

export const loadServerListEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadServersList.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLLoadGroupedServerList(), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(loadServersList.success((data as ServersHTTPResponse).result))),
        catchError(() =>
          of(loadServersList.failure(null))),

      )),
    catchError(() =>
      of(idle())),
  );

export const loadUserServersEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadUserServers.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLLoadUserServers(), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(loadUserServers.success((data)))),
        catchError(() =>
          of(loadUserServers.failure(null))),

      )),
    catchError(() =>
      of(idle())),
  );
