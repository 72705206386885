import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import store from '../core/store/store';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { updateUserProfile } from '../core/store/actions/user';
import {
  EditModalActionContainer,
  EditModalSave,
  InputWrapper,
  WrapperScreen,
} from '../styles/ui-controls';
import { InputField } from '../elements';
import { storage } from '../services';
import { UIProps } from '../types';
import { ScreenInfo, LogOut, InfoBlock } from './styles';

import iconLogOut from '../assets/log-out.svg';
import { ChangePassword } from './ChangePassword';
import { NotificationStreams, useModalHook, useNotificatorHook } from '../hooks';
import { showNotification } from '../utils';
import { debounceGetPhone } from '../components/forms';
import { LoadingProgress } from '../components/common/LoadingProgress';
import { BREAKPOINT_TABLET } from '../utils/constants';
import theme from '../styles/ApplicationTheme';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  position: relative;
  height: 80%;

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    width: auto;
  }

  .input-container {
    width: 100%;
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.large};

    :last-child {
      margin-right:${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }

    @media (max-width: ${BREAKPOINT_TABLET}px) {
      margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};

      :last-child {
        margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
      }

      div {
        ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
      }
    }
  }

  .row {
    display: flex;
    @media (max-width: ${BREAKPOINT_TABLET}px) {
      display: block;
    }
  }

  .password-change-link {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    ${(props: UIProps) =>
    props.theme.sizes.font.smallSemibold};
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    cursor: pointer;
    transition: 0.3s;

    :hover {
      opacity: 0.8;
    }
  }

  .email {
    color: ${(props: UIProps) =>
    props.theme.colors.dark};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

export const InputLabel = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
  font-weight: 500;
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  @media (max-width: ${BREAKPOINT_TABLET}px) {
     ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }
`;

export interface UserProfileScreenProps {}

export const UserProfileScreen: FunctionComponent<
UserProfileScreenProps
> = () => {
  const { t } = useTranslation();

  const [changePassword, setChangePassword] = useState(false);
  const notificator = useNotificatorHook();

  const { profile } = useTypedSelector(({ user }) =>
    user);
  const [userInfo, setUserInfo] = useState({
    email: null,
    lastName: null,
    firstName: null,
    telegram: null,
    proxyPrefix: null,
  });
  const [proxyPrefixError, setProxyPrefixError] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const modalOptions = useModalHook();

  const $logOut = () => {
    storage.removeItem('SYSTEM', 'token').then(() => {
      // @ts-ignore
      if (window !== undefined) {
        window.location.href = window.location.origin;
      }
    });
  };

  useEffect(() => {
    notificator._listen((data) => {
      const { detail } = data;
      if (
        detail?.stream
        && detail?.stream === NotificationStreams.PROFILE_EDIT
      ) {
        debounceGetPhone(() => {
          showNotification(<>{detail?.content}</>);
        });
      }
    });
    return () => {};
  }, []);

  const $updateProfile = async () => {
    const { proxyPrefix } = userInfo;
    const REG_EXP = /^[a-zA-Z0-9]{6,}$/;
    const isProxyPrefixValid = proxyPrefix === '' || REG_EXP.test(proxyPrefix);

    if (!isProxyPrefixValid) {
      setProxyPrefixError(true);
      return;
    }

    const modifiedUserInfo = {
      ...userInfo,
      proxyPrefix,
    };

    setProxyPrefixError(false);
    setProcessingRequest(true);
    setUpdateError(false);
    store.dispatch(updateUserProfile.request({
      ...modifiedUserInfo,
      callback: (data) => {
        if (data?.success) {
          notificator.notify(`${t('profile.success')}`, NotificationStreams.PROFILE_EDIT);
          setProcessingRequest(false);
          return;
        }
        setUpdateError(true);
        setProcessingRequest(false);
      },
    }));
  };

  useEffect(() => {
    if (profile != null) {
      setUserInfo({
        email: profile?.email,
        lastName: profile?.lastName,
        firstName: profile?.firstName,
        telegram: profile?.telegram,
        proxyPrefix: profile?.proxyPrefix,
      });
    }
  }, [profile]);

  return (
    <WrapperScreen>
      <ScreenInfo
        marginBottom="25px"
        height="32px"
        justifyContent
        alignItems
        width
        flex
      >
        <div className="title">{t('profile.title')}</div>
        <LogOut
          fontWeight="600"
          marginRight="5px"
          width="16px"
          height="16px"
          icon={iconLogOut}
          red
          onClick={() =>
            $logOut()}
        >
          {t('profile.logOut')}
        </LogOut>
      </ScreenInfo>
      <Content>
        {changePassword ? (
          <ChangePassword
            isProfile
            setChangePassword={setChangePassword}
          />
        ) : (
          <>
            <div className="row">
              <div className="input-container">
                <InputWrapper>
                  <InputLabel>{t('profile.firstName')}</InputLabel>
                  <InputField
                    value={userInfo?.firstName}
                    floatLabel
                    placeholder={`${t('profile.firstName')}`}
                    onChange={({ target }) =>
                      setUserInfo({
                        ...userInfo,
                        firstName: target.value,
                      })}
                  />
                </InputWrapper>
              </div>
              <div className="input-container">
                <InputWrapper>
                  <InputLabel>{t('profile.lastName')}</InputLabel>
                  <InputField
                    value={userInfo?.lastName}
                    floatLabel
                    placeholder={`${t('profile.lastName')}`}
                    onChange={({ target }) =>
                      setUserInfo({
                        ...userInfo,
                        lastName: target.value,
                      })}
                  />
                </InputWrapper>
              </div>
              <div className="input-container">
                <InputWrapper>
                  <InputLabel>{t('profile.telegram')}</InputLabel>
                  <InputField
                    value={userInfo?.telegram}
                    floatLabel
                    placeholder={`${t('profile.telegram')}`}
                    onChange={({ target }) =>
                      setUserInfo({
                        ...userInfo,
                        telegram: target.value,
                      })}
                  />
                </InputWrapper>
              </div>
              <div className="input-container">
                <InputWrapper>
                  <InputLabel>{t('profile.proxyPrefix')}</InputLabel>
                  <InputField
                    value={userInfo?.proxyPrefix}
                    floatLabel
                    placeholder={`${t('profile.proxyPrefix')}`}
                    onChange={({ target }) =>
                      setUserInfo({
                        ...userInfo,
                        proxyPrefix: target.value,
                      })}
                    error={proxyPrefixError}
                    errorMsg={t('profile.proxyPrefixError')}
                  />
                </InputWrapper>
              </div>
            </div>
            <div className="row">
              <div className="input-container">
                <InfoBlock>
                  <InputWrapper>
                    <InputLabel>{t('profile.email')}</InputLabel>
                    <div className="email">{profile?.email}</div>
                  </InputWrapper>
                </InfoBlock>
                {
                  updateError
                  && (
                  <Error>
                    {t('profile.error')}
                  </Error>
                  )
                }
              </div>
              <div className="input-container">
                <InfoBlock>
                  <InputWrapper>
                    <InputLabel>{t('profile.passwordAndApiKey')}</InputLabel>
                    <div
                      className="password-change-link"
                      onClick={() =>
                        setChangePassword(true)}
                    >
                      {t('profile.changePswAndApiKey')}
                    </div>
                    <div
                      className="password-change-link"
                      onClick={() =>
                        modalOptions.show('screen.activity-logs')}
                    >
                      {t('profile.activityLogs')}
                    </div>
                  </InputWrapper>
                </InfoBlock>
              </div>
            </div>
            <EditModalActionContainer>
              <EditModalSave onClick={() =>
                $updateProfile()}
              >
                <ButtonWrapper>
                  {processingRequest && <LoadingProgress color={theme.colors.white} />}
                  {t('editForm.save')}
                </ButtonWrapper>
              </EditModalSave>
            </EditModalActionContainer>
          </>
        )}
      </Content>
    </WrapperScreen>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const Error = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
`;
