export const YEAR_IN_MS = 31536000000;
export const DAY_IN_MS = 86400000;
export const MONTH_IN_MS = 2592000000;
export const SECRET_BAR_NUMBER = 7;
export const MAX_WEEKS_LOGS = 12;
export const DAY_IN_WEEK = 7;
export const MIN_PASSWORD_LENGTH = 16;
export const PASSWORD_REG_EXP = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{${MIN_PASSWORD_LENGTH},}$`,
);
