import { useTranslation } from 'react-i18next';
import { AffiliationPhoneType, SelectOption } from '../types';

export * from './geo';
export * from './configs';

export const getOptions = () => {
  const { t } = useTranslation();

  const periodOptions: SelectOption<any>[] = [
    {
      value: '1',
      label: `30 ${t('tariff.days')}`,
    },
    {
      value: '2',
      label: `60 ${t('tariff.days')}`,
    },
    {
      value: '3',
      label: `90 ${t('tariff.days')}`,
    },
    {
      value: '6',
      label: `180 ${t('tariff.days')}`,
    },
    {
      value: '12',
      label: `360 ${t('tariff.days')}`,
    },
  ];

  const affiliationOptions: SelectOption<AffiliationPhoneType>[] = [
    {
      value: AffiliationPhoneType.ALL,
      label: t('toolTip.showAll'),
    },
    {
      value: AffiliationPhoneType.PRIVATE,
      label: t('toolTip.myPrivate'),
    },
    {
      value: AffiliationPhoneType.SHARED_BY_ME,
      label: t('toolTip.sharedByMe'),
    },
    {
      value: AffiliationPhoneType.SHARED_WITH_ME,
      label: t('toolTip.sharedWithMe'),
    },
  ];

  return { periodOptions, affiliationOptions };
};
