import React, {
  FunctionComponent,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/ru';
import 'react-calendar/dist/Calendar.css';

import { UIProps } from '../../types';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

interface DateContainerProps {
  indicator?: boolean;
  value?: any;
  active?: boolean;
  onClick?: Function;
  onBlur?: Function;
  disabled?: boolean;
  locale?: string;
  format?: string;
}

export const DateContainer: FunctionComponent<
DateContainerProps
> = (props) => {
  const {
    indicator = false,
    active = false,
    disabled = false,
    value = new Date(),
    onClick = () => {},
    onBlur = () => {},
    locale,
    format,
  } = props;

  return (
    <Wrapper
      indicator={indicator}
      active={active}
      disabled={disabled}
      onBlur={(e) =>
        onBlur(e)}
      onClick={(e) =>
        onClick(e)}
    >
      {disabled ? '-' : moment(value).locale(locale).format(format)}
    </Wrapper>
  );
};

interface WrapperProps extends UIProps {
  active?: boolean;
  indicator?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  border: 1px solid
    ${(props: WrapperProps) =>
    (props?.disabled ? props.theme.colors.dark : props.theme.colors.deepBlue)};
  background-color: ${(props: WrapperProps) =>
    (props?.disabled ? '#f2f2f2' : props.theme.colors.white)};
  padding: 8px 10px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  box-sizing: border-box;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: auto;
  }
`;
