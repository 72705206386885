import React, { useState, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../types';
import { BaseModalFormParams } from '../../../modal';
import store from '../../../core/store/store';
import { updatePhonesGroup } from '../../../core/store/actions/app';
import { PhonesScrollList } from '../../PhonesScrollList';
import {
  NotificationStreams,
  useModalHook,
  useNotificatorHook,
} from '../../../hooks';
import { GroupList } from '../../GroupLIst';
import { WrapperScreen } from '../../../styles/ui-controls';
import { FormContainer, Wrapper } from './styles';
import { ProgressBarComponent } from './progress-bar-component';

interface MultiAddGroupProps extends BaseModalFormParams {}

export const MultiAddGroup: FunctionComponent<MultiAddGroupProps> = (props) => {
  const { modalParams } = props;

  const modalOptions = useModalHook();
  const notificator = useNotificatorHook();
  const { t } = useTranslation();

  const [selectedGroup, setSelectedGroup] = useState<null | SelectOption<string>>(null);
  const [progressView, setProgressView] = useState(false);
  const [buyCount, setBuyCount] = useState(0);

  const submitHandler = (phonesId: string[]) => {
    if (selectedGroup?.value != null) {
      setProgressView(true);
      setBuyCount(1);

      store.dispatch(
        updatePhonesGroup.request({
          groupId: selectedGroup?.value,
          phonesId,
          callback: () => {
            setTimeout(() => {
              modalOptions.hide();
            }, 1000);
          },
        }),
      );

      notificator.notify(
        `${t('notification.changeUpdated')}`,
        NotificationStreams.CONNECTION_EDIT_FORM,
      );
    }
  };

  return (
    <WrapperScreen>
      <Wrapper scrollContainerHeigth="200px">
        <FormContainer>
          <div className="info">
            <div className="title">{t('massActions.addToGroup')}</div>
          </div>
          <GroupList
            selectedGroup={selectedGroup}
            onGroupChange={setSelectedGroup}
          />
          <PhonesScrollList
            isValid={selectedGroup?.value != null && !progressView}
            phonesId={
              modalParams?.selectedPhones !== null
                ? modalParams?.selectedPhones
                : []
            }
            actionText={t('connection.addGroupBt')}
            onActionSubmit={submitHandler}
          />
        </FormContainer>
      </Wrapper>
      {progressView && (
        <ProgressBarComponent
          buyCount={buyCount}
          selectedPhones={[selectedGroup.value]}
        />
      )}
    </WrapperScreen>
  );
};
