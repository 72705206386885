import React, { FunctionComponent, Suspense, useMemo } from 'react';
import styled from 'styled-components';

import { Phone } from '../types/phones';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { PhoneGridItemComponent } from './PhoneGridItemComponent';
import { LoaderIcon } from './common/LoaderIcon';
import { UIProps } from '../types';

interface PhonesGridComponentProps {
  phones?: Phone[];
  showOnlyOnline: boolean | null;
  searchPhrase: string | string[] | any;
}

export const PhonesGridComponent: FunctionComponent<
PhonesGridComponentProps
> = (props) => {
  const { phones, searchPhrase, showOnlyOnline = null } = props;
  const dashboardConfigs = useTypedSelector(({ app }) =>
    app?.dashboard_config);
  const appConfigs = useTypedSelector(({ app }) =>
    app);

  if (phones == null) {
    return null;
  }

  const filteredPhones = useMemo(
    () =>
      phones
        ?.filter(
          (phone: Phone) =>
            (showOnlyOnline !== null
              ? showOnlyOnline
                ? phone?.onlineStatus?.online
                : !phone?.onlineStatus?.online
              : true) && !!phone.id,
        )
        ?.sort((a: Phone, b: Phone) =>
          b.timestamp - a.timestamp),
    [phones],
  );

  return (
    <Wrapper>
      <Suspense
        fallback={(
          <ComponentLoaderHolder>
            <LoaderIcon width="150px" height="150px" />
          </ComponentLoaderHolder>
        )}
      >
        <GridContainer>
          {filteredPhones?.map((phone: Phone, index) =>
            (
              <PhoneGridItemComponent
                key={`phone_grid_item${phone?.id || index}`}
                phone={phone}
                searchPhrase={searchPhrase}
                dashboardConfigs={dashboardConfigs}
                appConfigs={appConfigs}
              />
            ))}
        </GridContainer>
      </Suspense>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ComponentLoaderHolder = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 100%;
`;

const GridContainer = styled.div`
  width: 100%;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(15, 1fr);
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  grid-auto-rows: 56px;
  justify-content: start;
  height: auto;
  min-height: 100px;
`;
