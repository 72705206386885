import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { SelectOption, UIProps } from '../types';
import { ButtonLoader } from './atoms';
import { NumberInputComponent } from './common/NumberInputComponent';
import { BREAKPOINT_MOBILE } from '../utils/constants';

interface Value {
  value?: number;
  label?: string;
}

interface RotationListProps {
  onRotationChange?: (selectedRotation: SelectOption<number>) => void;
  value?: Value;
  setValue?: (value) => void;
  isMultiChangeRotation?: boolean;
}

export const RotationList: FunctionComponent<RotationListProps> = (props) => {
  const {
    onRotationChange, value, setValue, isMultiChangeRotation,
  } = props;

  const { t } = useTranslation();

  return (
    <RotationSelectbox>
      <InputWrapper>
        <NumberInputComponent
          from={1}
          to={1439}
          value={value.value}
          setValue={setValue}
          isMultiChangeRotation
          isChangeRotation
        />
        <span style={{ fontSize: '12px' }}>
          {', '}
          {t('connection.inMinutes')}
        </span>
      </InputWrapper>

      {
        isMultiChangeRotation
          ? null
          : (
            <ButtonLoader
              size="small"
              onClick={onRotationChange}
            >
              {t('editForm.apply')}
            </ButtonLoader>
          )
      }
    </RotationSelectbox>
  );
};

const RotationSelectbox = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  display: flex;
  align-items: start;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  width: 70%;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  flex: 1 0 100%;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;
