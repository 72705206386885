import React, {
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../../../types';
import { VideoInstructionBlock } from '../../../styles/ui-controls';
import { TeamControl } from '../TeamControl';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import ScrollWrapper from '../../common/ScrollWrapper';
import { PermissionsForm } from '../PermissionsForm';
import store from '../../../core/store/store';
import { updateConnection } from '../../../core/store/actions/connections';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { windowOpen } from '../../../utils';

interface TeamProps {}

export const Team: FunctionComponent<TeamProps> = () => {
  const context = useContext(ConnectionEditViewContext);
  const [email, setEmail] = useState(null);
  const [showConfirmComponent, setShowConfirmComponent] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {!email ? (
        <ScrollWrapper>
          <Wrapper>
            <ProBlockWrapper>
              <VideoInstructionBlock
                onClick={() =>
                  windowOpen('https://www.youtube.com/watch?v=YdewWpKGY7U&feature=emb_logo')}
              >
                {t('ourProjects.learn')}
              </VideoInstructionBlock>
              <BaseDescription>
                {t('proSettings.teamDescription')}
              </BaseDescription>
            </ProBlockWrapper>

            <TeamControl
              phone={context?.selectedPhone}
              phoneOwner={context?.isOwner}
              emailHandler={setEmail}
            />
          </Wrapper>
        </ScrollWrapper>
      )
        : (
          <Wrapper style={showConfirmComponent ? { backgroundColor: 'rgba(4, 3, 24, 0.6)' } : null}>
            <TitleBlock>
              {t('proSettings.userPermissionsSetup')}
            </TitleBlock>
            <PermissionsForm
              emailAdd={(permission) => {
                const phone = context?.selectedPhone;
                const emailList = phone?.sharedUsersEmails != null
                  ? phone?.sharedUsersEmails
                  : [];

                const newEmailList = emailList.find((e) =>
                  e === email)
                  ? emailList
                  : [...emailList, email];

                const settingList = phone?.sharedUsersSettings != null
                  ? phone?.sharedUsersSettings
                  : [];

                const newSettingList = settingList.find((s) =>
                  s.email === email)
                  ? settingList.map((sl) =>
                    (sl.email === email ? { email, permissions: permission } : sl))
                  : [...settingList, { email, permissions: permission }];

                store.dispatch(
                  updateConnection.request({
                    id: phone.id,
                    sharedUsersEmails: newEmailList,
                    sharedUsersSettings: newSettingList,
                  }),
                );
                setEmail(null);
              }}
              phone={context?.selectedPhone}
              email={email}
              showConfirmComponent={showConfirmComponent}
              setShowConfirmComponent={setShowConfirmComponent}
            />
          </Wrapper>
        )}
    </>
  );
};

const Wrapper = styled.div``;

const BaseDescription = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.midsmallZero};

  a {
    text-decoration: none;
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    transition: 0.1s;

    :hover {
      opacity: 0.8;
    }
  }
`;

const ProBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  &:last-child {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }
`;

const TitleBlock = styled.div`
  box-sizing: border-box;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(props: UIProps) =>
    props?.theme?.sizes?.font.smallMedium};
`;
