import React, { useState, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from 'moment';
import {
  CouponType,
  Plan,
  PlansType,
  SelectOption,
  Tariff,
  UIProps,
} from '../../../types';
import { BaseModalFormParams } from '../../../modal';
import { WrapperScreen } from '../../../styles/ui-controls';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { DropdownElement, InputField } from '../../../elements';
import { TariffButton } from '../../edit-connection/PhoneTariffSettings/TariffCommon';
import { Api } from '../../../core/api';
import { formatDate, numberWithSpaces } from '../../../utils';
import { useModalHook } from '../../../hooks';
import store from '../../../core/store/store';
import { loadUserPhones } from '../../../core/store/actions/connections';
import { loadUserBalance } from '../../../core/store/actions/user';
import { TextInProgressAtom } from '../../atoms';
import { getOptions } from '../../../constants';
import {
  FormContainer, Wrapper, GroupsList, GroupsItem,
} from './styles';
import { ProgressBarComponent } from './progress-bar-component';
import { useMassActionsHook } from '../../../hooks/useMassActionsHook';
import { analyticsClassnames } from '../../../utils/analytics-utils';
import { PromocodeBannerContext } from '../../../context/PromocodeBannerContext';
import { dateFormats, BREAKPOINT_MOBILE } from '../../../utils/constants';

interface MultiPaymentsProps extends BaseModalFormParams {}

export const MultiPayments: FunctionComponent<MultiPaymentsProps> = (props) => {
  const { modalParams } = props;

  const { t } = useTranslation();
  const modalOptions = useModalHook();
  const { selectedPhones, getPhoneList, removePhone } = useMassActionsHook();
  const plans = useTypedSelector(({ plans }) =>
    plans);
  const { profile, balance } = useTypedSelector(({ user }) =>
    user);

  const tariffsOptions: SelectOption<Plan>[] = plans.data
    .sort((a, b) =>
      a.price - b.price)
    .map((item) =>
      ({ value: item, label: item.enName }));

  const [tariffs, setTariffs] = useState<Tariff[]>(
    plans.data.map((p) =>
      ({
        id: p.id,
        price: p.price,
        couponDetails: null,
        couponIsValid: null,
        name: p.enName.split(' (')[0],
      })),
  );
  const [defaultTariff, setDefaultTariff] = useState(
    tariffsOptions.find((plan) =>
      plan.value.id === PlansType.BigDaddyPro),
  );
  const [period, setPeriod] = useState(getOptions().periodOptions[0]);
  const [requestProcessing, setRequestProcessing] = useState(false);
  const [progressView, setProgressView] = useState(false);
  const [buyCount, setBuyCount] = useState(0);
  const { showPromocodeBanner, closePromocodeBanner, promocode } = React.useContext(PromocodeBannerContext);

  useEffect(() => {
    if (modalParams?.selectedPhones != null) {
      getPhoneList(modalParams, false);
    }
  }, [modalParams?.selectedPhones]);

  useEffect(() => {
    if (selectedPhones != null) {
      if (buyCount === selectedPhones.length) {
        store.dispatch(loadUserBalance.request({ userId: profile?.id }));
        store.dispatch(loadUserPhones.request(null));
        setTimeout(() => {
          modalOptions.hide();
        }, 1200);
      }
    }
  }, [buyCount]);

  const resetRequestLock = () => {
    setTimeout(() => {
      setRequestProcessing(false);
    }, 1000);
  };

  const calculatePrice = (tariff, withDiscount = false) => {
    const tprice = period.value * tariff.price;
    if (!withDiscount) {
      return tprice;
    }
    return tariff.couponDetails?.type === CouponType.PERCENT
      ? tprice - (tprice / 100) * tariff.couponDetails.amount
      : tprice - tariff.couponDetails.amount;
  };

  const calculateSummPrice = () => {
    if (selectedPhones == null || !selectedPhones.length) return 0;
    const prices = selectedPhones.map((sp) => {
      const tariff = tariffs.find(
        (t) =>
          t.id
          === (sp.activePlans[0] ? sp.activePlans[0].id : defaultTariff.value.id),
      );
      if (tariff.couponDetails != null && tariff.couponIsValid) {
        return calculatePrice(tariff, true);
      }
      return calculatePrice(tariff);
    });
    const summ = prices.reduce((acc, number) =>
      acc + number);
    return summ;
  };

  const $applyCoupon = async (coupon, id) => {
    if (!requestProcessing) {
      setRequestProcessing(true);
      try {
        const { data } = await Api.get(
          `coupon/${coupon}?planId=${id}`,
          null,
          null,
          false,
        );

        const updateTariffs = tariffs.map((t) =>
          (t.id === id
            ? {
              ...t,
              couponDetails: data?.result == null ? {} : data?.result,
            }
            : t));
        setTariffs(updateTariffs);

        if (data?.hasOwnProperty('result') && data != null && data?.result) {
          setTariffs(
            updateTariffs.map((t) =>
              (t.id === id
                ? { ...t, couponIsValid: data?.result.canUseCoupon }
                : t)),
          );
        } else {
          setTariffs(
            updateTariffs.map((t) =>
              (t.id === id ? { ...t, couponIsValid: false } : t)),
          );
        }
      } catch (e) {
        console.error('Error:> error :=', e);
      }
      resetRequestLock();
    }
  };

  const $buyTariffs = () => {
    if (!requestProcessing) {
      if (balance - calculateSummPrice() < 0) return;

      setRequestProcessing(true);

      setProgressView(true);

      const request = selectedPhones.map(async (sp) => {
        const tariff = tariffs.find(
          (tariff) =>
            tariff.id
            === (sp.activePlans[0] ? sp.activePlans[0].id : defaultTariff.value.id),
        );
        await Api.post(
          `/phone/${sp?.id}/buy_plan`,
          {
            code:
              tariff.couponDetails != null ? tariff.couponDetails.name : null,
            monthsNumber: period.value != null ? period.value : 1,
            planId: tariff.id,
          },
          null,
          false,
        ).then(() =>
          setBuyCount(() =>
            buyCount + 1));
      });

      Promise.all(request).then(() => {
        store.dispatch(loadUserBalance.request({ userId: profile?.id }));
        store.dispatch(loadUserPhones.request(null));
        setTimeout(() => {
          modalOptions.hide();
        }, 1200);
      });

      const isCouponValid = tariffs.some((item) =>
        item.couponIsValid && item.couponDetails?.name?.toLowerCase() === promocode?.code?.toLowerCase());

      if (isCouponValid && showPromocodeBanner) {
        closePromocodeBanner();
      }
    }
  };

  return (
    <WrapperScreen>
      <Wrapper minWidth="900px" scrollContainerHeigth="150px">
        <FormContainer marginBottom="15px">
          <div className="info">
            <div className="title">{t('massActions.multiPayments')}</div>
          </div>
          <WrapperLabels>
            <div className="label">{t('connection.title')}</div>
            <div className="label">{t('multiPayments.expires')}</div>
            <div className="label">{t('tariff.price')}</div>
          </WrapperLabels>
          <div className="scroll-container">
            <Scrollbars>
              <div>
                {selectedPhones != null ? (
                  <GroupsList>
                    {selectedPhones.map((phone) => {
                      const expDate = phone?.activePlans[0]
                        ? moment(phone?.activePlans[0].expirationTimestamp)
                        : null;
                      const tariff = tariffs.find(
                        (tariff) =>
                          tariff.id
                          === (phone.activePlans[0]
                            ? phone.activePlans[0].id
                            : defaultTariff.value.id),
                      );
                      return (
                        <GroupsItem
                          hasCoupon={
                            tariff.couponDetails != null && tariff.couponIsValid
                          }
                          itemContentWidth="40%"
                          key={`phone_list_${phone?.id}`}
                        >
                          <div className="item-content">{phone?.name}</div>
                          <div className="item-content">
                            {expDate
                              && t('tariff.expires', {
                                date: `${formatDate(expDate, dateFormats.dateTime)}`,
                              })}
                          </div>
                          <div className="item-content">
                            <div className="price">
                              {t('wallet.rub')}
                              {calculatePrice(tariff)}
                            </div>
                            {tariff.couponDetails != null
                            && tariff.couponIsValid ? (
                              <div className="discount-price">
                                {t('wallet.rub')}
                                {calculatePrice(tariff, true)}
                              </div>
                              ) : null}
                          </div>
                          <div
                            className="close"
                            onClick={() => {
                              removePhone(phone?.id);
                            }}
                          />
                        </GroupsItem>
                      );
                    })}
                  </GroupsList>
                ) : null}
              </div>
            </Scrollbars>
          </div>
          <DropdownElementsContainer>
            <div className="period-box">
              <div className="label">{t('tariff.period')}</div>
              <div className="drop-down">
                <DropdownElement
                  value={period}
                  isSearchable={false}
                  onSelected={(value) =>
                    setPeriod(value)}
                  options={getOptions().periodOptions}
                />
              </div>
            </div>
            <div className="period-box">
              <div className="label">
                {t('multiPayments.tariffNewConnections')}
              </div>
              <div className="drop-down">
                <DropdownElement
                  value={defaultTariff}
                  isSearchable={false}
                  onSelected={(value) =>
                    setDefaultTariff(value)}
                  options={tariffsOptions}
                />
              </div>
            </div>
          </DropdownElementsContainer>
          <div className="code-box">
            <div className="label">{t('multiPayments.promoCodes')}</div>
            {tariffs
              .sort((a, b) =>
                a.price - b.price)
              .map((tariff) => {
                const [coupon, setCoupon] = useState<string>('');
                const [show, setShow] = useState<boolean>(false);

                useEffect(() => {
                  if (show) {
                    setTimeout(() => {
                      setShow(false);
                    }, 500);
                  }
                }, [show]);
                return (
                  <PromoCodeArea key={tariff.name}>
                    <ApplyPromoInput>
                      <InputField
                        value={coupon}
                        placeholder={`${t('tariff.enterPromoCode')} ${
                          tariff.name
                        }`}
                        onChange={({ target }) => {
                          setCoupon(target.value);
                        }}
                      />
                      {tariff.couponDetails != null ? (
                        tariff.couponIsValid ? (
                          <div className="status-info">
                            {t('tariff.appliedDiscount', {
                              amount: `${tariff.couponDetails?.amount}`,
                              price: tariff.couponDetails?.type === CouponType.PERCENT ? '' : t('wallet.rub'),
                              percent: tariff.couponDetails?.type === CouponType.PERCENT ? '%' : '',
                            })}
                          </div>
                        ) : (
                          <div className="status-info error">
                            {t('tariff.inapplicablePromoCode')}
                          </div>
                        )
                      ) : null}
                    </ApplyPromoInput>
                    <ApplyPromo>
                      <TariffButton
                        isActive={false}
                        onClick={() => {
                          setShow(true);
                          $applyCoupon(coupon, tariff.id);
                        }}
                      >
                        <TextInProgressAtom showProgress={show}>
                          {t('tariff.applyPromoCode')}
                        </TextInProgressAtom>
                      </TariffButton>
                    </ApplyPromo>
                  </PromoCodeArea>
                );
              })}
          </div>
          {selectedPhones != null && plans != null && (
            <ButtonsArea>
              <div className="buttons-box">
                <div className="buttons-item">
                  {t('upcomingChanges.total')}
                  :
                  {' '}
                  <span className="total-summ">
                    {t('wallet.rub')}
                    {numberWithSpaces(calculateSummPrice())}
                  </span>
                </div>
                <div className="buttons-item">
                  {t('upcomingChanges.balance')}
                  :
                  {' '}
                  <span className="total-summ">
                    {t('wallet.rub')}
                    {numberWithSpaces(balance)}
                  </span>
                </div>
                {balance - calculateSummPrice() < 0 ? (
                  <div className="warning_msg">
                    {t('tariff.insufficientFunds', {
                      amount: `${numberWithSpaces(
                        calculateSummPrice() - balance,
                      )}`,
                    })}
                  </div>
                ) : null}
                <div className="buttons">
                  <BuyTariffButton
                    className={analyticsClassnames.topUpBalanceMultipayment}
                    isDisabled={balance - calculateSummPrice() < 0}
                    onClick={() =>
                      $buyTariffs()}
                  >
                    {t('multiPayments.extendConnections')}
                  </BuyTariffButton>
                  {balance - calculateSummPrice() < 0 ? (
                    <TariffButton
                      isActive={false}
                      onClick={() => {
                        modalOptions.show('balance.topUp', {
                          sum: calculateSummPrice() - balance,
                        });
                      }}
                    >
                      {t('wallet.topUp')}
                    </TariffButton>
                  ) : null}
                </div>
              </div>
            </ButtonsArea>
          )}
        </FormContainer>
        {progressView && (
          <ProgressBarComponent
            absolute
            buyCount={buyCount}
            selectedPhones={selectedPhones}
          />
        )}
      </Wrapper>
    </WrapperScreen>
  );
};

const WrapperLabels = styled.div`
  display: flex;
  width: 100%;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerMedium};

  .label {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumSemibold};
    :nth-child(1) {
      width: 40%;

      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        width: 30%;
      }
    }

    :nth-child(2) {
      margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
      width: 40%;
      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        width: 35%;
      }
    }
  }
`;

const PromoCodeArea = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 16px 0;

  :nth-child(2) {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    margin: 24px 0;
  }
`;

const ApplyPromoInput = styled.div`
  width: 100%;
  box-sizing: border-box;

  .status-info {
    color: ${(props: UIProps) =>
    props.theme.colors.greenMud};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    &.error {
      color: ${(props: UIProps) =>
    props.theme.colors.warning};
    }
  }
`;

const ApplyPromo = styled.div`
  width: 100%;
  margin: 0 0 0 16px;

  & > div {
    padding: 13px 16px;
    line-height: 1;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: 16px 0 0;
  }
`;

const ButtonsArea = styled.div`
  display: flex;
  justify-content: end;

  .buttons-item {
    text-align: end;
    ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  }

  .total-summ {
    font-weight: 600;
  }

  .buttons {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    display: flex;
    flex-wrap: wrap;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    justify-content: end;
  }
`;

interface ActivateBtProps extends UIProps {
  isDisabled?: boolean;
}

const BuyTariffButton = styled.div<ActivateBtProps>`
  display: inline-block;
  min-width: 155px;
  position: relative;
  box-sizing: border-box;

  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  background-color: ${(props: ActivateBtProps) =>
    (props.isDisabled
      ? props.theme.colors.spaceGray
      : props.theme.colors.greenMud)};

  text-align: center;
  cursor: pointer;

  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
  }
`;

const DropdownElementsContainer = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;
