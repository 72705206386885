import React, {
  useState, FunctionComponent,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../../../types';
import { InputLabel, InputWrapper } from '../../../styles/ui-controls';
import { InputField } from '../../../elements';
import { ButtonLoader } from '../../atoms';
import { BaseModalFormParams } from '../../../modal';
import store from '../../../core/store/store';
import { updateMultipleConnectionField } from '../../../core/store/actions/connections';
import { useModalHook } from '../../../hooks';

interface MultiEditFormProps extends BaseModalFormParams {
  phonesId?: string[]
}

export const MultiEditForm: FunctionComponent<MultiEditFormProps> = (props) => {
  const {
    modalParams,
  } = props;
  const { phonesId, type } = modalParams;
  const { t } = useTranslation();
  const [indicator, setIndicator] = useState();
  const modalOptions = useModalHook();

  const $onUpdate = () => {
    store.dispatch(updateMultipleConnectionField.request({
      phoneIds: phonesId,
      key: indicator,
      paramKey: type,
    }));

    setTimeout(() => {
      modalOptions.hide();
    }, 1000);
  };

  const $generateTitle = (type) => {
    switch (type) {
      case 'name':
        return t('massAction.updateName');
      case 'description':
        return t('massAction.updateDesc');
      default:
        return '';
    }
  };

  return (
    <Wrapper>
      <Header>
        <div className="container">
          <div className="basic">
            <Title>{$generateTitle(type)}</Title>

          </div>
        </div>
      </Header>

      <div>
        <InputWrapper>
          <InputLabel>{t('massAction.identic')}</InputLabel>
          <InputField
            value={indicator}
            floatLabel
            placeholder=""
            onChange={({ target }) =>
              setIndicator(target?.value)}
          />
        </InputWrapper>
        <div className="updateBt">
          <ButtonLoader onClick={() => {
            $onUpdate();
          }}
          >
            {t('massAction.update')}
          </ButtonLoader>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  min-width: 100%;
  padding: 20px 23px;
  height: 300px;
  box-sizing: border-box;

  .updateBt{
    height: 40px;
  }
`;

const Header = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  .basic {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .container {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
`;

const Title = styled.div`
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumBold};
`;
