import React, {
  useState, useEffect, FunctionComponent,
} from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../../types';
import { showNotification } from '../../utils';
import { Button } from '../Button';
import { CommonAppContext } from '../../context/CommonAppContext';

interface SuportHelpModalProps {

}

export const SuportHelpModal: FunctionComponent<SuportHelpModalProps> = () => {
  const [token, setToken] = useState(null);
  const { t } = useTranslation();
  const context = React.useContext(CommonAppContext);

  useEffect(() => {
    setTimeout(() => {
      const token = window.localStorage.getItem('system/token');
      if (token != null && token !== '') {
        setToken(window?.btoa(token));
      }
    }, 300);
  }, []);

  const $copy = () => {
    copy(token);
    showNotification(`${t('notification.dataCopiedSuccessfully')}`);
  };

  return token != null ? (
    <Wrapper>
      <div className="panel">
        <div className="title">{t('supportToken')}</div>
        <div className="content">
          <div
            className="token"
            onClick={() =>
              $copy()}
          >
            {token}
          </div>
          <div className="btt-close">
            <Button
              size="small"
              onClick={() =>
                context.setShowSupportModal(false)}
              label={t('common.close')}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: ${(props: UIProps) =>
    props.theme?.colors?.clearDark};

  .btt-close{
    display: flex;
    justify-content: center;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .panel {
    background-color: white;
    box-sizing: border-box;
    max-width: 320px;
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    overflow: hidden;
  }

  .title {
    background-color: ${(props: UIProps) =>
    props.theme?.colors?.deepBlue};
    color: white;
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallMedium};
    ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  }

  .content {
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallMedium};
  }

  .token {
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    border: 1px solid ${(props: UIProps) =>
    props.theme.colors.neutralGray};
    padding: 2px;
    cursor: pointer;
    transition: .2s;

    :hover {
      background-color: #f8f6f6;
    }
  }
`;
