import React, { useState, FunctionComponent, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ModalSubtitle, BasicButton } from '../../../styles/ui-controls';
import { CredentialCheckboxSettings } from './credential-checkbox-settings';
import { CopySettings, DashboardConfig } from '../../../types/dashboard';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { UIProps } from '../../../types';
import { Phone } from '../../../types/phones';
import { setDashboardConfig } from '../../../core/store/actions/app';
import store from '../../../core/store/store';
import {
  fileFormats,
  predefinedOptions,
  credentialValues,
} from './constants';
import {
  CredentialDropdown,
  CredentialInput,
} from './components';
import { showNotification, exportCredentials, validateCredentialsFormat } from '../../../utils';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

interface CreateCredentialsFormatProps {
  isCredentialsSettings?: boolean;
  phones?: Phone[];
  selectedPhones?: string[];
}

export const CreateCredentialsFormat: FunctionComponent<CreateCredentialsFormatProps> = (props) => {
  const {
    isCredentialsSettings,
    phones,
    selectedPhones,
  } = props;
  const { t } = useTranslation();

  const appConfigs: DashboardConfig = useTypedSelector(
    ({ app }) =>
      app?.dashboard_config,
  );

  const [copySettings, setCopySettings] = useState<CopySettings | null>(appConfigs?.configs?.copySettings);
  const format = copySettings?.format;
  const [fileFormat, setFileFormat] = useState(fileFormats[0]);
  const [inputValue, setInputValue] = useState(format);
  const [inputError, setInputError] = useState(false);
  const inputRef = useRef(null);

  const connections = phones?.filter((i) =>
    selectedPhones?.includes(i?.id));

  const handleSaveCredentials = () => {
    store.dispatch(
      setDashboardConfig.request({
        configs: {
          ...appConfigs?.configs,
          copySettings,
        },
        callback: () =>
          showNotification(`${t('profile.success')}`),
      }),
    );
  };

  const inputProps = {
    ref: inputRef,
    inputValue,
    inputError,
    copySettings,
    setCopySettings,
    setInputValue,
    setInputError,
    validateCredentialsFormat,
  };

  return (
    <Wrapper>
      <ModalSubtitle>{t('dashboard.createFormat')}</ModalSubtitle>

      <CredentialCheckboxSettings
        copySettings={copySettings}
        setCopySettings={setCopySettings}
      />

      <Formats>
        {
          credentialValues.map((credentialValue) =>
            (
              <Format
                key={credentialValue}
                onClick={() => {
                  setCopySettings({
                    ...copySettings,
                    format: format + credentialValue,
                  });

                  setInputValue(format + credentialValue);
                  inputRef?.current?.focus();
                  validateCredentialsFormat(inputValue + credentialValue, setInputError);
                }}
              >
                {credentialValue}
              </Format>
            ))
        }
      </Formats>

      <InputsWrapper>
        {
          isCredentialsSettings ? (
            <CredentialInput
              inputProps={inputProps}
            />
          ) : (
            <CredentialDropdown
              label={t('massActions.fileFormat')}
              value={fileFormat}
              onSelected={(value) =>
                setFileFormat(value)}
              options={fileFormats}
            />
          )
        }

        <CredentialDropdown
          label={t('dashboard.applyPredefined')}
          placeholder={t('dashboard.selectFormat')}
          value={null}
          onSelected={({ value }) => {
            setCopySettings({
              ...copySettings,
              format: value,
            });
            setInputValue(value);
          }}
          options={predefinedOptions}
        />
      </InputsWrapper>

      {
        !isCredentialsSettings && (
          <CredentialInput
            inputProps={inputProps}
            marginBottom="10px"
          />
        )
      }

      <BasicButton
        size="small"
        onClick={() =>
          (isCredentialsSettings
            ? handleSaveCredentials()
            : exportCredentials({
              format: inputValue,
              phones: connections,
              copySettings,
              isCopy: false,
              inputError,
              fileFormat: fileFormat.value,
            }))}
        disable={inputError || inputValue.length === 0}
      >
        {t(isCredentialsSettings ? 'editForm.save' : 'onboarding.download.step1.bt')}
      </BasicButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 600px;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const InputsWrapper = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-wrap: wrap;
  }
`;

const Formats = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  flex-wrap: wrap;
`;

const Format = styled.div`
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerMedium};
  border: 1px solid ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  cursor: pointer;
  transition: color 0.3s ease, background 0.3s ease, opacity 0.3s ease;
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};

  &:hover {
    color: ${(prop: UIProps) =>
    prop.theme.colors.white};
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  }

  &:active {
    opacity: 0.7;
  }
`;
