import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';
import { DNSAtom } from './DNS/DNSAtom';
import { SchedulerSettings } from './SchedulerSettings';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import ScrollWrapper from '../../common/ScrollWrapper';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';

interface ConfigurationProps {}

export const Configuration: FunctionComponent<ConfigurationProps> = () => {
  const context = useContext(ConnectionEditViewContext);

  return (
    <ScrollWrapper>
      <Wrapper>
        <ProBlockWrapper>
          <SchedulerSettings selectedPhone={context?.selectedPhone} />

          {!context?.isOwner && !context?.permissions?.dnsSetting ? null : (
            <DNSAtom phone={context?.selectedPhone} />
          )}
        </ProBlockWrapper>
      </Wrapper>
    </ScrollWrapper>
  );
};

const Wrapper = styled.div`
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const ProBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  &:last-child {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: 48px;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;
