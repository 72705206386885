import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IpHistoryForm } from './IpHistoryForm';
import { LogsForm } from './LogsForm';
import { TabsAtom } from '../../atoms';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { DublicatedIps } from './DublicatedIps';
import { SmsHistoryForm } from './SmsHistoryForm';
import { PlansType } from '../../../types';

export default function HistoryPanel() {
  const { t } = useTranslation();
  const context = useContext(ConnectionEditViewContext);

  return (
    <Wrapper>
      <TabsAtom
        config={[
          {
            title: t('history.ipHistoryHeading'),
            component:
              !context?.isOwner
              && !context?.permissions?.ipHistoryView ? null : (
                <IpHistoryForm phone={context?.selectedPhone} />
                ),
          },
          {
            title: t('history.logsHistoryHeading'),
            component:
              !context?.isOwner
              && !context?.permissions?.logsDownload ? null : (
                <LogsForm phone={context?.selectedPhone} />
                ),
          },
          {
            title: `${t('history.logsHistoryRepeats')} ( ${
              context?.selectedPhone?.ipDublicates?.length || '-'
            } )`,
            component:
              !context?.isOwner
              && !context?.permissions?.repeatIPView ? null : (
                <DublicatedIps phone={context?.selectedPhone} />
                ),
          },
          {
            title: t('history.smsHistoryHeading'),
            component: (
              <SmsHistoryForm
                phone={context?.selectedPhone}
                hasSmsPermission={
                  context?.selectedPhone?.activePlans[0]?.id === PlansType.BigDaddyPro
                }
              />
            ),
          },
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
