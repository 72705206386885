import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { UIProps } from '../../types';

interface AttentionProps {
  children?: any;
}

export const Attention: FunctionComponent<AttentionProps> = (props) => {
  const {
    children,
  } = props;

  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  background-color: #face3229;
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;
