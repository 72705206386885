import React, { useState, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { UIProps } from '../../types';
import { InputField } from '../../elements';
import {
  BasicButton,
  InputLabel,
  InputWrapper,
  ModalSubtitle,
  VideoInstructionBlock,
  WrapperScreen,
} from '../../styles/ui-controls';
import { PaymentsUtils, windowOpen } from '../../utils';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { BaseModalFormParams } from '../../modal';
import {
  analyticsClassnamePayments,
  analyticsClassnames,
} from '../../utils/analytics-utils';

import iconArrowLeft from '../../assets/left_arrow.svg';
import Btc from '../../assets/payments/btc.svg';
import Trc20Usdt from '../../assets/payments/trc20-usdt.svg';
import Visa from '../../assets/payments/visa.svg';
import Master from '../../assets/payments/master.svg';
import Capitalist from '../../assets/payments/capitalist.svg';
import { SECRET_BAR_NUMBER } from '../../constants/common';
import store from '../../core/store/store';
import { loadUserTransaction } from '../../core/store/actions/user';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../../utils/constants';
import { ReactMD } from './ReactMarkdown';
import { getTermsAndConditionsLink } from '../../utils/instruction-links';
import theme from '../../styles/ApplicationTheme';

interface TopUpFormProps extends BaseModalFormParams {
  defaultValue?: number;
}

const USD_TARIFF = 75;
const MINIMAL_PAYMENT = 5;

export const TopUpForm: FunctionComponent<TopUpFormProps> = (props) => {
  const { modalParams } = props;
  const { t, i18n } = useTranslation();
  const profile = useTypedSelector(({ user }) =>
    user.profile);
  const transactions = useTypedSelector(({ user }) =>
    user.transactions);
  const [amount, setAmount] = useState(modalParams?.defaultValue || MINIMAL_PAYMENT);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [isRuIP, setIsRuIP] = useState(false);
  const isCardTransactions = transactions.data?.find(
    (item) =>
      item.payment_system?.toLowerCase() === 'stripe'
      && item.is_real_replenishment
      && item.unitpayId?.includes('stripe'),
  );
  const [secretBarCount, setSecretBarCount] = useState(0);
  const dateToShowSecretBar = moment('2024-12-01').valueOf();

  const cryprtoPayments = [
    {
      icon: Btc,
      className: analyticsClassnamePayments.btc,
      payment: () =>
        payCapitalist(),
    },
    {
      icon: Trc20Usdt,
      className: analyticsClassnamePayments.trc20usdt,
      payment: () =>
        payCapitalist(),
    },
    {
      icon: Capitalist,
      className: analyticsClassnamePayments.capitalist,
      payment: () =>
        payCapitalist(),
    },
  ];

  const regularPayments = [
    {
      icon: Visa,
      className: analyticsClassnamePayments.visa,
      payment: () =>
        payKeeper(),
    },
    {
      icon: Master,
      className: analyticsClassnamePayments.mastercard,
      payment: () =>
        payKeeper(),
    },
    // todo: uncomment when will able to use
    // {
    //     icon: Paypal,
    //     payment: () => _payPal()
    // },
  ];

  const ruPayments = [
    {
      icon: Visa,
      className: analyticsClassnamePayments.visa,
      payment: () =>
        payKeeper(),
    },
    {
      icon: Master,
      className: analyticsClassnamePayments.mastercard,
      payment: () =>
        payKeeper(),
    },
  ];

  const payKeeper = () => {
    if (profile?.isRuPayments) {
      PaymentsUtils.submitPaykeeperReplenishmentRequest(
        +amount * USD_TARIFF,
        profile?.id,
        `${profile?.id}-${moment().format('DDMMYYYYHHmmss')}`,
      );
      // @ts-ignore
      // window.gtag('event', 'conversion', {
      //   send_to: 'AW-10926004905/Qw6kCLCInMIDEKm19tko',
      //   transaction_id: `${profile?.id}|paykeeper|${amount}`,
      // });
    } else {
      const url = PaymentsUtils.payLink('stripe', profile?.id, +amount * 100);
      windowOpen(url);
      // @ts-ignore
      // window.gtag('event', 'conversion', {
      //   send_to: 'AW-10926004905/Qw6kCLCInMIDEKm19tko',
      //   transaction_id: `${profile?.id}|stripe|${amount}`,
      // });
    }
  };

  // const payPal = () => {
  //   const url = PaymentsUtils.payLink('paypal', profile?.id, +amount);
  //   windowOpen(url);
  //   // @ts-ignore
  //   window.gtag('event', 'conversion', {
  //     send_to: 'AW-10926004905/Qw6kCLCInMIDEKm19tko',
  //     transaction_id: `${profile?.id}|paypal|${amount}`,
  //   });
  // };

  // const payCryptoCom = () => {
  //   const url = PaymentsUtils.getCryptoCom(profile?.id, +amount);
  //   windowOpen(url);
  //   // @ts-ignore
  //   // window.gtag('event', 'conversion', {
  //   //   send_to: 'AW-10926004905/Qw6kCLCInMIDEKm19tko',
  //   //   transaction_id: `${profile?.id}|cryptocom|${amount}`,
  //   // });
  // };

  const payCapitalist = async () => {
    const url = await PaymentsUtils.getCapitalistUrl(profile?.id, +amount);
    windowOpen(url);
    // @ts-ignore
    // window.gtag('event', 'conversion', {
    //   send_to: 'AW-10926004905/Qw6kCLCInMIDEKm19tko',
    //   transaction_id: `${profile?.id}|capitalist|${amount}`,
    // });
  };

  useEffect(() => {
    if (modalParams?.sum) {
      setAmount(Math.ceil(modalParams.sum));
      setShowPaymentOptions(true);
    }

    const getUserIP = async () => {
      const { data } = await axios.get('https://iproxy.online/api-rt/ping');

      if (data?.geo?.country === 'RU') {
        setIsRuIP(true);
      } else {
        setIsRuIP(false);
      }
    };
    getUserIP();

    if (!transactions.data) {
      store.dispatch(loadUserTransaction.request(null, null));
    }
  }, []);

  return (
    <WrapperScreen>
      <TitleBlock>
        {t('wallet.title')}
        {showPaymentOptions ? (
          <div
            className="goback"
            onClick={() =>
              setShowPaymentOptions(false)}
          >
            {t('editForm.goBack')}
          </div>
        ) : null}
      </TitleBlock>

      {showPaymentOptions === false && (
        <>
          <ModalSubtitle>{t('transaction.depositDetail')}</ModalSubtitle>
          <VideoWrapper>
            <VideoInstructionBlock
              onClick={() =>
                windowOpen(i18n.language === 'ru'
                  ? 'https://www.youtube.com/watch?v=_HUVviDfpuY&feature=emb_logo'
                  : 'https://www.youtube.com/watch?v=pY2ioCYXJi0')}
            >
              {t('wallet.paymentInstructions')}
            </VideoInstructionBlock>
          </VideoWrapper>
          <ElementWrapper>
            <InputWrapper>
              <InputLabel>{t('wallet.description')}</InputLabel>
              <InputField
                value={amount}
                number
                minimum={MINIMAL_PAYMENT}
                floatLabel
                placeholder={`${t('wallet.amount')}`}
                onChange={({ target }) =>
                  setAmount(Math.ceil(+target.value))}
              />
              <Info>{t('wallet.minAmount')}</Info>
            </InputWrapper>
          </ElementWrapper>
          <BasicButton
            fillWidth
            className={analyticsClassnames.topUpBalance}
            size="small"
            onClick={() =>
              amount >= 5 && setShowPaymentOptions(true)}
          >
            {t('wallet.topUp')}
          </BasicButton>
        </>
      )}

      {showPaymentOptions ? (
        <>
          <PaymentOptionWrapper>
            <PeymentOption>
              <PaymentTitle
                onClick={() => {
                  setSecretBarCount((secretBarCount) =>
                    secretBarCount + 1);
                }}
              >
                {t('wallet.paymentMethod')}
              </PaymentTitle>
              <RegularPayments>
                {regularPayments?.map((i) =>
                  (
                    <div
                      key={i.icon}
                      className={`${analyticsClassnames.pushPayment}_${i.className}`}
                      onClick={() =>
                        i?.payment()}
                    >
                      <img
                        className="payment"
                        src={i?.icon}
                        alt="payment-icon"
                      />
                    </div>
                  ))}
              </RegularPayments>
            </PeymentOption>

            <PeymentWarning>{t('wallet.turnOffVPN')}</PeymentWarning>

            <ReactMD
              markdown={
                t('wallet.reminder', {
                  link: `[${t('wallet.termsofPayment')}](${getTermsAndConditionsLink(i18n.language)})`,
                })
              }
              fontSize={theme.sizes.font.small}
              maxWidth="900px"
            />

            {
              profile?.createdTimestamp < dateToShowSecretBar ? (
                <>
                  <PeymentOption>
                    <PaymentTitle>
                      {isRuIP
                        ? t('wallet.noSubscription')
                        : !isRuIP && !isCardTransactions
                          ? t('wallet.firstPayment')
                          : t('wallet.noSubscription')}
                    </PaymentTitle>
                    <RegularPayments>
                      {cryprtoPayments?.map((i) =>
                        (
                          <ImgWrapper
                            key={i.icon}
                            className={`${analyticsClassnames.pushCryptoPayment}_${i.className}`}
                            onClick={() =>
                              i?.payment()}
                            style={
                            !isRuIP && !isCardTransactions
                              ? { pointerEvents: 'none', opacity: 0.3 }
                              : null
                            }
                          >
                            <img
                              className="payment"
                              src={i?.icon}
                              alt="cryptopayment-icon"
                            />
                          </ImgWrapper>
                        ))}
                    </RegularPayments>
                  </PeymentOption>

                  {profile?.isRuPayments && (
                    <PeymentOption>
                      <PaymentTitle>{t('wallet.paymentRuCard')}</PaymentTitle>
                      <RegularPayments>
                        {ruPayments?.map((i) =>
                          (
                            <div
                              key={i.icon}
                              className={`payment ${analyticsClassnames.pushPayment}_${i.className}`}
                              onClick={() =>
                                i?.payment()}
                            >
                              <img src={i?.icon} alt="ru-payment-icon" />
                            </div>
                          ))}
                      </RegularPayments>
                    </PeymentOption>
                  )}
                  <CryptoCurrencyBlock>{t('wallet.cryptoNotice')}</CryptoCurrencyBlock>
                </>
              ) : (
                secretBarCount >= SECRET_BAR_NUMBER && (
                  <>
                    <PeymentOption>
                      <PaymentTitle>
                        {isRuIP
                          ? t('wallet.noSubscription')
                          : !isRuIP && !isCardTransactions
                            ? t('wallet.firstPayment')
                            : t('wallet.noSubscription')}
                      </PaymentTitle>
                      <RegularPayments>
                        {cryprtoPayments?.map((i) =>
                          (
                            <ImgWrapper
                              key={i.icon}
                              className={`${analyticsClassnames.pushCryptoPayment}_${i.className}`}
                              onClick={() =>
                                i?.payment()}
                              style={
                              !isRuIP && !isCardTransactions
                                ? { pointerEvents: 'none', opacity: 0.3 }
                                : null
                            }
                            >
                              <img
                                className="payment"
                                src={i?.icon}
                                alt="cryptopayment-icon"
                              />
                            </ImgWrapper>
                          ))}
                      </RegularPayments>
                    </PeymentOption>

                    {profile?.isRuPayments && (
                      <PeymentOption>
                        <PaymentTitle>{t('wallet.paymentRuCard')}</PaymentTitle>
                        <RegularPayments>
                          {ruPayments?.map((i) =>
                            (
                              <div
                                key={i.icon}
                                className={`payment ${analyticsClassnames.pushPayment}_${i.className}`}
                                onClick={() =>
                                  i?.payment()}
                              >
                                <img src={i?.icon} alt="ru-payment-icon" />
                              </div>
                            ))}
                        </RegularPayments>
                      </PeymentOption>
                    )}
                    <CryptoCurrencyBlock>{t('wallet.cryptoNotice')}</CryptoCurrencyBlock>
                  </>
                )
              )
            }
          </PaymentOptionWrapper>
          <hr style={{ margin: 0 }} />
          <FeesForServicesBlock>
            {t('wallet.feesForServices')}
          </FeesForServicesBlock>
        </>
      ) : null}
    </WrapperScreen>
  );
};

const PaymentOptionWrapper = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  .description-box {
    max-width: 900px;
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }
`;

const PeymentOption = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
`;

const PaymentTitle = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
  }
`;

const RegularPayments = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  flex-wrap: wrap;
  width: 470px;
  justify-content: flex-start;

  .payment {
    margin-right: 4px;
    cursor: pointer;
    transition: 0.2s;

    &:last-child {
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }

    &:hover {
      opacity: 0.8;
      box-shadow: 0 0 10px 0px #5d5d5d24;
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

const VideoWrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    position: relative;
    top: 2px;
    align-items: baseline;
  }

  .goback {
    display: flex;
    align-items: center;
    margin: 0 0 0 8px;

    color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
    ${(props: UIProps) =>
    props.theme.sizes.font.medium};
    font-weight: normal;
    cursor: pointer;



    @media (max-width: ${BREAKPOINT_TABLET}px) {
      justify-content: space-between;
      margin-right: 48px;
      ${(props: UIProps) =>
    props.theme.sizes.font.small};
    }

    &:before {
      content: '';
      display: block;
      background-image: url(${iconArrowLeft});
      background-position: center;
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }
  }
`;

const Info = styled.div`
  margin-top: 8px;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const ElementWrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

const PeymentWarning = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  margin: 10px 0 15px 0;
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
  max-width: 900px;
`;

const CryptoCurrencyBlock = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  margin: 10px 0 15px 0;
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
`;

const FeesForServicesBlock = styled.div`
  margin: 10px 0 15px 0;
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
`;

const ImgWrapper = styled.div`
  max-height: 35px;
`;
