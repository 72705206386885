import { CoreStoreState } from './app-store';

export interface Plan {
  description?: string;
  name?: string;
  bits_per_sec_in?: number;
  bits_per_sec_out?: number;
  logsVisibleWeeksNumber?: number;
  conn_per_sec?: number;
  conn_per_min?: number;
  parallel_conn_num?: number;
  price?: number;
  daysNumber?: number;
  enDescription?: string;
  enName?: string;
  enTitle?: string;
  ruTitle?: string;
  ruPrice?: number;
  usdPrice?: number;
  max_proxies_per_connection?: number;
  id?: string;
  timestamp?: any;
  createdTimestamp?: any;
  updatedTimestamp?: any;
}

export interface ResponseAllPlans {
  result?: Plan[];
}

export interface PlanStoreState extends CoreStoreState<Plan[]> {

}

export enum CouponType {
  PERCENT = 'percent',
  AMOUNT = 'amount',
}

export interface CouponModel {
  emails?: any[];
  plansIds?: string[];
  description?: string;
  usagesNumberExceeded?: boolean;
  name?: string;
  code?: string;
  amount?: number;
  type?: CouponType;
  maxUsagesNumber?: number;
  expirationTimestamp?: number;
  id?: string;
  timestamp?: number;
  createdTimestamp?: number;
  updatedTimestamp?: number;
  canUseCoupon?: boolean;
}
