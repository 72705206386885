import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import { API_ENDPOINT } from '../core/api';

const PK_PAYMENT_URL = 'https://iproxy.server.paykeeper.ru/create/';
const PAYKEEPER_TAXES_FEE = 0.04;

const sendPost = (path, params, method = 'post') => {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;
  form.target = '_blank';

  // eslint-disable-next-line no-restricted-syntax
  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];
      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

const enotSignIn = async (order_amount, payment_id) => {
  const pld = (await axios.get(`${API_ENDPOINT}/enot/signature`, {
    params: {
      order_amount: `${(+order_amount).toFixed(2)}`,
      payment_id,
    },
  })).data;
  if (pld.error !== undefined) {
    throw pld.error;
  }
  return pld.result;
};

const getCapitalistSign = async (amount, number) => {
  const pld = (await axios.get(`${API_ENDPOINT}/capitalist/signature`, {
    params: {
      amount: `${(+amount).toFixed(2)}`,
      currency: 'USD',
      number,
      description: 'iproxy',
    },
  })).data;
  if (pld.error !== undefined) {
    throw pld.error;
  }
  return pld.result;
};

// eslint-disable-next-line import/prefer-default-export
export class PaymentsUtils {
  static async enotPayment(userId, sum) {
    const paymentId = `t-${+new Date()}`;
    const sign = await enotSignIn(sum, paymentId);
    const params = {
      s: sign,
      oa: (`${(+sum).toFixed(2)}`),
      m: '4729',
      o: paymentId,
      cf: userId,
    };
    const queryString = Object.keys(params).map((key) =>
      `${key}=${params[key]}`).join('&');
    const url = `https://enot.io/pay?${queryString}`;
    return url;
  }

  static payLink(service, userId, amount) {
    const orderId = `${userId}_${+new Date()}`;

    return `${API_ENDPOINT}/${service}/create-checkout-session?orderId=${orderId}&sum=${amount}&userId=${userId}`
      + `${service === 'stripe' && `&t=${+new Date()}`}`;
  }

  static submitPaykeeperReplenishmentRequest(
    sum,
    clientId: string | number = +new Date(),
    orderId: string | number = +new Date(),
  ) {
    const updSum = Math.ceil((+sum) * (1.0 + PAYKEEPER_TAXES_FEE));
    const params = {
      sum: updSum,
      orderid: orderId,
      service_name: 'Использование программы iProxy',
      clientid: clientId,
      user_result_callback: window.location.href,
      cart: JSON.stringify([
        {
          item_type: 'service',
          payment_type: 'prepay',
          name: 'Использование программы iProxy',
          price: +(+updSum).toFixed(2),
          sum: +(+updSum).toFixed(2),
          quantity: 1,
          tax: 'none',
        },
      ]),
    };
    sendPost(PK_PAYMENT_URL, params);
  }

  static async getCapitalistUrl(userId, amount) {
    const number = `${userId}_${+new Date()}`;
    const sign = await getCapitalistSign(+amount, number);
    // eslint-disable-next-line max-len
    return `https://capitalist.net/merchant/pay?lang=en&merchantid=100547&number=${number}&amount=${(+amount).toFixed(2)}&currency=USD&description=iproxy&sign=${sign}`;
  }

  static getCryptoCom(userId, amount) {
    const orderId = `${userId}_${+new Date()}`;
    return `${API_ENDPOINT}/cryptocom/create-checkout-session?orderId=${orderId}&sum=${amount}&userId=${userId}`;
  }
}
