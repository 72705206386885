import React, { FunctionComponent, Ref } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { InputField, DropdownElement } from '../../../elements';
import { InputLabel } from '../../../styles/ui-controls';
import { CopySettings } from '../../../types/dashboard';
import { SelectOption } from '../../../types';

interface CredentialInputProps {
  inputProps: {
    ref: Ref<HTMLInputElement>;
    inputValue: string;
    inputError: boolean;
    copySettings: CopySettings;
    setCopySettings: (settings: CopySettings) => void;
    setInputValue: (value: string) => void;
    setInputError: (value: boolean) => void;
    validateCredentialsFormat: (value: string, setInputError: Function) => void;
  }
  marginBottom?: string;
}

export const CredentialInput: FunctionComponent<CredentialInputProps> = (props) => {
  const {
    marginBottom,
    inputProps,
  } = props;
  const { t } = useTranslation();
  const {
    ref,
    inputValue,
    inputError,
    copySettings,
    setCopySettings = () => {},
    setInputValue = () => {},
    setInputError = () => {},
    validateCredentialsFormat = () => {},
  } = inputProps;

  return (
    <Wrapper
      marginBottom={marginBottom}
    >
      <InputLabel>{t('massActions.format')}</InputLabel>
      <InputField
        ref={ref}
        value={inputValue}
        onChange={({ target }) => {
          const { value } = target;

          setCopySettings({
            ...copySettings,
            format: value,
          });
          setInputValue(value);
          validateCredentialsFormat(value, setInputError);
        }}
        error={inputError}
        errorMsg={t('massActions.formatError')}
      />
    </Wrapper>
  );
};

interface CredentialDropdownProps {
  label: string;
  placeholder?: string;
  value: SelectOption<any>;
  onSelected: (value: SelectOption<any>) => void;
  options: SelectOption<any>[];
}

export const CredentialDropdown: FunctionComponent<CredentialDropdownProps> = (props) => {
  const { label, placeholder } = props;

  return (
    <Wrapper>
      <InputLabel>{label}</InputLabel>
      <DropdownElement
        placeholder={placeholder}
        {...props}
      />
    </Wrapper>
  );
};

interface WrapperProps {
  marginBottom?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom}
`;
