import React, {
  useState, useRef, FunctionComponent,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Phone } from '../types/phones';
import { useOnClickOutside } from '../hooks';
import { InputLabel } from '../styles/ui-controls';
import { ButtonLoader, CustomeDateInputAtom } from './atoms';
import { API_ENDPOINT } from '../core/api';
import { UIProps } from '../types';
import { windowOpen } from '../utils';

interface LogsComponentProps {
  phone?: Phone;
}

// TODO: remove in next couple releases

const downloadLogsUrl = (phoneId: string, from: number, to: number) =>
  `${API_ENDPOINT}/phone/${phoneId}/logs-csv?from=${from}&to=${to}&name=report.csv`;

export const LogsComponent: FunctionComponent<LogsComponentProps> = (props) => {
  const {
    phone,
  } = props;
  const [startDate, setStartDate] = useState(+new Date(moment().subtract(1, 'weeks').startOf('isoWeek').toISOString()));
  const [endDate, setEndDate] = useState(+new Date());
  const dateContainer = useRef(null);
  const [activeData, setActiveData] = useState({
    start: false,
    end: false,
  });
  const { t, i18n } = useTranslation();

  const resetActiveData = () =>
    setActiveData({ start: false, end: false });

  const downloadLogs = () =>
    windowOpen(downloadLogsUrl(phone?.id, startDate, endDate));

  useOnClickOutside(dateContainer, () =>
    resetActiveData());

  return (
    <Wrapper>
      <ControlBoard>
        <TimePickerContent ref={dateContainer}>
          <div className="date_wrap">
            <InputLabel className="label">{t('logs.from')}</InputLabel>
            <CustomeDateInputAtom
              indicator
              value={new Date(startDate)}
              activeData={activeData.start}
              locale={i18n.language}
              onChange={(e) =>
                setStartDate(+new Date(e))}
            />
          </div>

          <div className="date_wrap">
            <InputLabel className="label">{t('logs.to')}</InputLabel>
            <CustomeDateInputAtom
              indicator
              value={new Date(endDate)}
              activeData={activeData.end}
              locale={i18n.language}
              minDate={startDate}
              onChange={(e) =>
                setEndDate(+new Date(e))}
            />
          </div>
        </TimePickerContent>

      </ControlBoard>
      <div className="downloadbt">
        <ButtonLoader onClick={() =>
          downloadLogs()}
        >
          {t('logs.download')}
        </ButtonLoader>
      </div>

    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  margin-bottom: 25px;

  .downloadbt {
    height: 45px;
    margin-top: -15px;
  }
`;

const ControlBoard = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;

  width: 100%;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
`;

const TimePickerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  box-sizing: border-box;

  .date_wrap {
    width: 50%;
    box-sizing: border-box;

    &:first-child {
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    }
  }

  .label {
    white-space: normal;
  }
`;
