import React, {
  FunctionComponent, useContext, useMemo, useState,
} from 'react';
import styled from 'styled-components';

import { Plan } from '../../../types';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';

import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { TariffsList } from './TariffsList';
import { TariffUpdate } from './TariffUpdate';
import { TariffExtend } from './TariffExtend';

interface TariffFormProps {}

type TariffScreen = 'main' | 'extend' | 'upgrade';

export const TariffForm: FunctionComponent<TariffFormProps> = () => {
  const context = useContext(ConnectionEditViewContext);

  const { balance } = useTypedSelector(({ user }) =>
    user);
  const tariffPlans = useTypedSelector(({ plans }) =>
    plans.data);

  const [selectedTariff, setSelectedTariff] = useState<Plan>(null);
  const phoneTariff = useMemo(
    () =>
      tariffPlans?.find(
        (plan: Plan) =>
          plan?.id === context?.selectedPhone?.activePlans[0]?.id,
      ) || null,
    [tariffPlans, context?.selectedPhone],
  );
  const [tariffSettingScreen, setTariffSettingScreen] = useState<TariffScreen>('main');

  const goBack = () => {
    setTariffSettingScreen('main');
  };

  const renderScreen = (type) => {
    switch (type) {
      case 'upgrade':
        return (
          <TariffUpdate
            goBack={() =>
              goBack()}
            selectedTariff={selectedTariff}
            phoneTariff={phoneTariff}
            selectedPhone={context?.selectedPhone}
          />
        );
      case 'extend':
        return (
          <TariffExtend
            goBack={() =>
              goBack()}
            userBalance={balance}
            selectedTariff={selectedTariff}
            selectedPhone={context?.selectedPhone}
          />
        );
      default:
        return (
          <TariffsList
            prolongation={
              context?.selectedPhone?.prolongationEnabled === undefined
                ? true
                : context?.selectedPhone?.prolongationEnabled
            }
            tariffs={tariffPlans}
            isOwner={context?.isOwner}
            onSelectTariff={(tariff, isExtend) => {
              setSelectedTariff(tariff);
              setTariffSettingScreen(isExtend ? 'extend' : 'upgrade');
            }}
            selectedPhone={context?.selectedPhone}
          />
        );
    }
  };

  return <Wrapper>{renderScreen(tariffSettingScreen)}</Wrapper>;
};

const Wrapper = styled.div`
  height: 100%;

  .loading-period {
    display: flex;
  }

  .locked-size {
    height: 30px;
    display: flex;
  }
`;
