export interface Connection {
  login?: string;
  password?: string;
  allow_from?: string[] | null;
  hostname?: string;
  listen_service?: string;
  auth_type?: string;
  userId?: string;
  phoneId?: string;
  name?: string;
  description?: string;
  address?: string;
  port?: number;
  expirationTimestamp?: number;
  slaveServerId?: string;
  active?: boolean;
  id?: string;
  timestamp?: number;
  createdTimestamp?: number;
  updatedTimestamp?: number;
  passwordLastUpdateTimestamp?: number;
}

export interface NewPhone {
  name: string;
  description?: string;
  phoneNumber?: string;
  slaveServerId: string;
  userId: string;
}

export type ConnectionAuthType = 'noauth' | 'userpass';
export type ConnectionType = 'socks5' | 'http';

export interface NewConnection {
  auth_type: ConnectionAuthType;
  description?: string;
  expirationTimestamp: number;
  listen_service: ConnectionType;
  phoneId: string;
  allow_from?: string[];
}

export interface UprateConnectionRequest {
  allow_from: [] | null;
  connectionId: string;
  phoneId: string;
  expirationTimestamp?: number;
  auth_type: string;
  listen_service: string;
  login: string;
  password: string;
  description?: string;
}

export enum ShedulType {
  Days = 'days',
  Hours = 'hours',
}

export enum RegionType {
  All,
  America,
  Europe,
  Australia,
  Asia,
}

export const RegionTypeLabel = {
  [RegionType.All]: 'All locations',
  [RegionType.America]: 'America',
  [RegionType.Europe]: 'Europe',
  [RegionType.Australia]: 'Australia',
  [RegionType.Asia]: 'Asia',
};

export enum PlansType {
  Baby = 'nlUs1D8PKL',
  BigDaddy = 'M7Fq2RKexi',
  BigDaddyPro = 'BNyW1yBaln',
}

export const MaxProxyCount = {
  [PlansType.Baby]: 3,
  [PlansType.BigDaddy]: 5,
  [PlansType.BigDaddyPro]: 15,
};

export enum AffiliationPhoneType {
  ALL = 'all',
  PRIVATE = 'private',
  SHARED_BY_ME = 'sharedByMe',
  SHARED_WITH_ME = 'sharedWithMe',
}

export enum PhonesStatusTabsType {
  All = 0,
  Online = 1,
  Offline = 2,
}
