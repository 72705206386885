import React, {
  useState,
  useContext,
  useMemo,
  FunctionComponent,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { DropdownElement } from '../../../elements';
import { Connection, UIProps } from '../../../types';
import { Api } from '../../../core/api';
import { ButtonLoader } from '../../atoms';
import { debounceCall } from '../../../utils';
import { LoaderIcon } from '../../common/LoaderIcon';
import { InputLabel, InputWrapper } from '../../../styles/ui-controls';
import { ItemContent, ProxyConnectionsListRow, RowContent } from '../ConnectionComponents';
import ScrollWrapper from '../../common/ScrollWrapper';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

interface ConnectionListProps {
  connections?: Connection[];
  exportedIds?: string[] | null;
  onSync: Function;
}

const ConnectionList: FunctionComponent<ConnectionListProps> = (props) => {
  const { connections, exportedIds, onSync } = props;
  const { t } = useTranslation();

  return (
    <ConnectionListContainer>
      {connections?.map((connection) => {
        const isExported = exportedIds != null
            && exportedIds?.find((id) =>
              id === connection?.id);
        return (
          <ProxyConnectionsListRow
            key={connection?.id}
            flexDirection="column"
          >
            <RowContent isEdit={false}>
              <ItemContent
                content={`${t('proxy.type')}`}
              >
                <span>{connection?.listen_service}</span>
              </ItemContent>
              <ItemContent
                content={`${t('proxy.ip')}`}
              >
                <span>{connection?.hostname || connection?.address}</span>
              </ItemContent>
              <ItemContent
                content={`${t('proxy.port')}`}
              >
                <span>{connection?.port}</span>
              </ItemContent>
              <ItemContent content={`${t('profile.login')}`}>
                <span>{connection?.auth_type === 'noauth' ? '-' : connection?.login || '-'}</span>
              </ItemContent>
              <ItemContent content={`${t('profile.password')}`}>
                <span>{connection?.auth_type === 'noauth' ? '-' : connection?.password || '-'}</span>
              </ItemContent>
            </RowContent>
            <div className="action-container">
              <ButtonLoader
                size="small"
                color={isExported ? 'greenMud' : 'deepBlue'}
                onClick={() =>
                  onSync(connection)}
              >
                {isExported ? 'RESYNC' : 'SYNC'}
              </ButtonLoader>
            </div>
          </ProxyConnectionsListRow>
        );
      })}
    </ConnectionListContainer>
  );
};

export function ExportConnectionProps() {
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const context = useContext(ConnectionEditViewContext);
  const [exportedConnections, setExportedConnections] = useState(null);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState(null);

  const providerOptions = useMemo(() => {
    const options = [];
    if (profile?.octobrowserApiKey != null) {
      options.push({
        label: 'Octo Browser',
        value: 'octobrowser',
      });
    }

    if (profile?.scenumUserToken != null) {
      options.push({
        label: 'Scenum',
        value: 'scenum',
      });
    }
    return options;
  }, [profile]);

  useEffect(() => {
    setLoading(false);
  }, [exportedConnections]);

  const getSyncedData = async (provider) => {
    if (provider != null) {
      try {
        const result = (await Api.get(`integrations/${provider?.value}/proxies`))
          ?.data;
        setExportedConnections(result?.map((c) =>
          c?.external_id));
      } catch (e) {
        console.error('Error:> [ExportConnection.tsx] e:=', e);
        setExportedConnections(null);
      }
    }
  };

  const $syncData = async (data) => {
    try {
      if (provider != null) {
        Api.post(`integrations/${provider?.value}/proxies/${data?.id}/sync`).then(
          async () =>
            getSyncedData(provider),
        );
      }
    } catch (e) {
      console.error('Error:> [ExportConnection.tsx]--- :=', e);
    }
  };

  return (
    <Wrapper>
      <InputWrapper>
        <InputLabel>API Provider</InputLabel>
        <DropdownElement
          isSearchable={false}
          value={provider}
          options={providerOptions}
          onSelected={(e) => {
            debounceCall(() => {
              setProvider(e);
              setLoading(true);
              getSyncedData(e);
            });
          }}
        />
      </InputWrapper>

      {loading ? (
        <div className="loader-container">
          <LoaderIcon width="100px" height="100px" />
        </div>
      ) : null}

      {provider != null && !loading ? (
        <ScrollWrapper>
          <ConnectionList
            onSync={(e) =>
              debounceCall(() =>
                $syncData(e))}
            exportedIds={exportedConnections}
            connections={context?.selectedPhone?.connections}
          />
        </ScrollWrapper>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 370px;

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 320px;
  }
`;

const ConnectionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  .action-container {
    margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    }
  }
`;
