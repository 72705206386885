import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logoIcon from '../assets/onb_technical.png';
import { storage } from '../services';
import iconLogOut from '../assets/log-out.svg';
import { UIProps } from '../types';
import { useIsMobileHook } from '../hooks/useIsMobileHook';

const LogOut = styled.div`
  display: flex;
  align-items: center;

  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  font-weight: 400;

  transition: 0.2s;
  cursor: pointer;

  /* ${(props: UIProps) =>
    props.theme.sizes.font.small};
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  cursor: pointer;
  transition: .2s;
  align-items: center; */

  &:hover {
    opacity: 0.7;
  }

  &:before {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    margin-top: -1px;
    width: 40px;
    height: 40px;
    display: block;
    content: '';
    background-image: url(${iconLogOut});
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${(props: UIProps) =>
    props.theme.colors.white};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.round};
  }
`;

const Wrapper = styled.div`
  color: white;
  height: 50px;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  display: flex;
  align-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  cursor: pointer;
  transition: 0.3s;
  width: 250px;
  justify-content: space-between;

  &.mobile {
    margin-top: 7px;
    position: relative !important;
    bottom: 0 !important;
  }
`;

const Support = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  :hover {
    opacity: 0.7;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

export interface SideMenuActionsProps {}

export const SideMenuActions: FunctionComponent<SideMenuActionsProps> = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobileHook();

  const logOut = () => {
    storage.removeItem('SYSTEM', 'token').then(() => {
      // @ts-ignore
      if (window !== undefined) {
        window.location.href = window.location.origin;
      }
    });
  };

  const openChat = () => {
    document?.querySelector<HTMLElement>('.woot-widget-bubble').click();

    if (!document.querySelector('.woot-exit-support-chat')) {
      const container = document.querySelector('.woot-widget-holder');
      const newElement = document.createElement('div');
      newElement.textContent = 'Exit';
      newElement.className = 'woot-exit-support-chat';
      newElement.addEventListener('click', () => {
        document?.querySelector<HTMLElement>('.woot-widget-bubble').click();
      });
      container.appendChild(newElement);
    }
  };

  return (
    <Wrapper className={isMobile && 'mobile'}>
      <Support onClick={openChat}>
        <Icon>
          <img src={logoIcon} width="40px" alt="" />
        </Icon>
        <Label>
          {t('onboarding.support')}
        </Label>
      </Support>
      <LogOut onClick={() =>
        logOut()}
      >
        {t('profile.logOut')}
      </LogOut>
    </Wrapper>
  );
};
