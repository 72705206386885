import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../../types';
import { BasicButton, ModalSubtitle } from '../../styles/ui-controls';
import { analyticsClassnames } from '../../utils/analytics-utils';
import { removeURLParams } from '../../utils/formatter-utils';
import { paymentSuccessful } from '../../constants';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

interface PaymentSuccesfulModalProps {
  setIsPaymentSuccessful: (value: boolean) => void;
}

export const PaymentSuccesfulModal: FunctionComponent<PaymentSuccesfulModalProps> = ({
  setIsPaymentSuccessful,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FormContainer>
        <ModalSubtitle>
          {t('paymentSuccessful.title')}
        </ModalSubtitle>

        <BasicButton
          className={analyticsClassnames.paymentSuccess}
          onClick={() => {
            const newUrl = removeURLParams(paymentSuccessful);

            setIsPaymentSuccessful(false);
            window.history.pushState(null, null, newUrl);
          }}
        >
          {t('paymentSuccessful.button')}
        </BasicButton>
      </FormContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.clearDark};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmall};
`;

const FormContainer = styled.div`
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};
  max-height: 140px;
  position: relative;
  top: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    max-height: 160px;
  }
`;
