import React, {
  useState, useEffect, FunctionComponent, useRef,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { BasicButton } from '../../styles/ui-controls';
import { Permission, Phone } from '../../types/phones';
import { CheckBox } from '../atoms/Checkbox';
import { UIProps } from '../../types';
import ScrollWrapper from '../common/ScrollWrapper';
import { useOnClickOutside } from '../../hooks';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

interface PermissionsFormProps {
  phone?: Phone;
  email?: string;
  emailAdd?: Function;
  saveButtonText?: string;
  showConfirmComponent?: boolean;
  setShowConfirmComponent?: Function;
}

export const PermissionsForm: FunctionComponent<PermissionsFormProps> = (
  props,
) => {
  const {
    phone,
    email,
    emailAdd,
    saveButtonText,
    showConfirmComponent,
    setShowConfirmComponent,
  } = props;

  const { t } = useTranslation();

  const defaultPermission: Permission = {
    airplaneMode: false,
    authorizationPIN: false,
    batteryLevelLower: false,
    connectionChangeTariff: false,
    connectionPayment: false,
    connectionRemove: false,
    descriptionEdit: false,
    dnsSetting: false,
    enableUniqueIP: false,
    findPhone: false,
    fixLTE: false,
    ipHistoryView: false,
    logsDownload: false,
    macrosURL: false,
    nameEdit: false,
    openVPNCreate: false,
    openVPNEdit: false,
    openVPNRemove: false,
    pofSetting: false,
    proxiesAreDisconnected: false,
    proxyCreate: false,
    proxyEdit: false,
    proxyRemove: false,
    proxyView: false,
    rebootDevice: false,
    repeatIPView: false,
    rotationSetting: false,
    scheduledReboot: false,
    smsControl: false,
    tariffStatisticView: false,
    toggleAirplaneMode: false,
    uptimeView: false,
    urlChangeIPAdd: false,
    urlChangeIPRemove: false,
    newTeamMembersAdd: false,
  };

  const [permission, setPermission] = useState<Permission>(defaultPermission);
  const confirmRef = useRef(null);
  const isSaveNotAllowed = !Object.values(permission).some((value) =>
    value === true);

  useOnClickOutside(confirmRef, () => {
    setShowConfirmComponent(false);
    switchAllPermissions(false);
  });

  useEffect(() => {
    if (phone != null && phone?.sharedUsersSettings != null) {
      const setting = phone.sharedUsersSettings.find((s) =>
        s.email === email);

      if (setting != null) setPermission(setting.permissions);
    }
  }, [phone]);

  const updateTeam = () => {
    emailAdd(permission);
    setShowConfirmComponent(false);
  };

  const enableAllPermissions = () => {
    switchAllPermissions(true);
    setShowConfirmComponent(true);
  };

  const switchAllPermissions = (flag: boolean) => {
    const exceptions = ['connectionRemove', 'connectionPayment', 'connectionChangeTariff', 'newTeamMembersAdd'];

    const updatedPermissions: Permission = Object.keys(permission).reduce((acc, key) => {
      acc[key as keyof Permission] = exceptions.includes(key) ? false : flag;

      return acc;
    }, {} as Permission);

    setPermission(updatedPermissions);
    setShowConfirmComponent(false);
  };

  const renderConfirmComponent = () =>
    (
      <ConfirmWrapper>
        <ConfirmBlock ref={confirmRef}>
          <BaseDescription>
            {t('proSettings.confirmMessage')}
          </BaseDescription>

          <Buttons>
            <BasicButton
              size="small"
              fillWidth
              onClick={updateTeam}
            >
              {t('editForm.confirmRemoval')}
            </BasicButton>
            <BasicButton
              size="small"
              fillWidth
              color="warning"
              onClick={() =>
                switchAllPermissions(false)}
            >
              {t('editForm.cancelRemoval')}
            </BasicButton>
          </Buttons>
        </ConfirmBlock>
      </ConfirmWrapper>
    );

  return (
    <Wrapper>
      <ScrollWrapper autoHeightMax={450}>
        <CheckBoxWrap>
          <CheckBox
            value={permission.nameEdit}
            onClick={(status) => {
              setPermission({ ...permission, nameEdit: status });
            }}
            label={t('proSettings.editConnectionName')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.descriptionEdit}
            onClick={(status) => {
              setPermission({ ...permission, descriptionEdit: status });
            }}
            label={t('proSettings.editConnectionDescription')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.connectionRemove}
            onClick={(status) => {
              setPermission({ ...permission, connectionRemove: status });
            }}
            label={t('connection.removeConnection')}
            hasLabel
            disabled
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.authorizationPIN}
            onClick={(status) => {
              setPermission({ ...permission, authorizationPIN: status });
            }}
            label={t('editForm.pinCode')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.proxyView}
            onClick={(status) => {
              setPermission({ ...permission, proxyView: status });
            }}
            label={t('proSettings.viewProxyAccesses')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.proxyEdit}
            onClick={(status) => {
              setPermission({ ...permission, proxyEdit: status });
            }}
            label={t('proSettings.editingProxyAccesses')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.proxyRemove}
            onClick={(status) => {
              setPermission({ ...permission, proxyRemove: status });
            }}
            label={t('proSettings.deletionOfProxyAccesses')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.proxyCreate}
            onClick={(status) => {
              setPermission({ ...permission, proxyCreate: status });
            }}
            label={t('proSettings.addingProxyAccesses')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.openVPNCreate}
            onClick={(status) => {
              setPermission({ ...permission, openVPNCreate: status });
            }}
            label={t('proSettings.creationOfOpenVPNConfiguration')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.openVPNEdit}
            onClick={(status) => {
              setPermission({ ...permission, openVPNEdit: status });
            }}
            label={t('proSettings.editingOpenVPNConfiguration')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.openVPNRemove}
            onClick={(status) => {
              setPermission({ ...permission, openVPNRemove: status });
            }}
            label={t('proSettings.deletionOfOpenVPNConfiguration')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.smsControl}
            onClick={(status) => {
              setPermission({ ...permission, smsControl: status });
            }}
            label="SMS app"
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.uptimeView}
            onClick={(status) => {
              setPermission({ ...permission, uptimeView: status });
            }}
            label={`${t('proSettings.view')} Uptime`}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.pofSetting}
            onClick={(status) => {
              setPermission({ ...permission, pofSetting: status });
            }}
            label={`${t('proSettings.view')} pOf`}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.rotationSetting}
            onClick={(status) => {
              setPermission({ ...permission, rotationSetting: status });
            }}
            label={t('proSettings.rotationSettings')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.urlChangeIPAdd}
            onClick={(status) => {
              setPermission({ ...permission, urlChangeIPAdd: status });
            }}
            label={t('proSettings.addingURLForIPChange')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.urlChangeIPRemove}
            onClick={(status) => {
              setPermission({ ...permission, urlChangeIPRemove: status });
            }}
            label={t('proSettings.removingURLForIPChange')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.ipHistoryView}
            onClick={(status) => {
              setPermission({ ...permission, ipHistoryView: status });
            }}
            label={t('proSettings.viewIPHistory')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.logsDownload}
            onClick={(status) => {
              setPermission({ ...permission, logsDownload: status });
            }}
            label={t('proSettings.downloadingLogs')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.repeatIPView}
            onClick={(status) => {
              setPermission({ ...permission, repeatIPView: status });
            }}
            label={t('proSettings.viewInfoAboutRepeatingIPs')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.connectionPayment}
            onClick={(status) => {
              setPermission({ ...permission, connectionPayment: status });
            }}
            label={t('proSettings.payForTheConnection')}
            disabled
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.connectionChangeTariff}
            onClick={(status) => {
              setPermission({ ...permission, connectionChangeTariff: status });
            }}
            label={t('proSettings.changeTheTariff')}
            hasLabel
            disabled
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.tariffStatisticView}
            onClick={(status) => {
              setPermission({ ...permission, tariffStatisticView: status });
            }}
            label={t('proSettings.viewTrafficStatistics')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.dnsSetting}
            onClick={(status) => {
              setPermission({ ...permission, dnsSetting: status });
            }}
            label={`${t('onboarding.steps.setup')} DNS`}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.rebootDevice}
            onClick={(status) => {
              setPermission({ ...permission, rebootDevice: status });
            }}
            label={t('proSettings.rebootDevice')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.fixLTE}
            onClick={(status) => {
              setPermission({ ...permission, fixLTE: status });
            }}
            label={t('proSettings.fixLTE')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.findPhone}
            onClick={(status) => {
              setPermission({ ...permission, findPhone: status });
            }}
            label={t('proSettings.findPhone')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.macrosURL}
            onClick={(status) => {
              setPermission({ ...permission, macrosURL: status });
            }}
            label="Macros URL"
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.scheduledReboot}
            onClick={(status) => {
              setPermission({ ...permission, scheduledReboot: status });
            }}
            label={t('proSettings.rebootOnSchedule')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.airplaneMode}
            onClick={(status) => {
              setPermission({ ...permission, airplaneMode: status });
            }}
            label={t('proSettings.changeAirplaneModeTime')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.batteryLevelLower}
            onClick={(status) => {
              setPermission({ ...permission, batteryLevelLower: status });
            }}
            label={t('proSettings.rebootOnBatteryDrop')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.proxiesAreDisconnected}
            onClick={(status) => {
              setPermission({ ...permission, proxiesAreDisconnected: status });
            }}
            label={t('proSettings.rebootOnProxyOff')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.toggleAirplaneMode}
            onClick={(status) => {
              setPermission({ ...permission, toggleAirplaneMode: status });
            }}
            label={t('proSettings.mobileNetworkIdle')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.enableUniqueIP}
            onClick={(status) => {
              setPermission({ ...permission, enableUniqueIP: status });
            }}
            label={t('proSettings.enableUniqueIP')}
            hasLabel
          />
        </CheckBoxWrap>
        <CheckBoxWrap>
          <CheckBox
            value={permission.newTeamMembersAdd}
            onClick={(status) => {
              setPermission({ ...permission, newTeamMembersAdd: status });
            }}
            label={t('proSettings.addingNewPeopleToTheTeam')}
            hasLabel
            disabled
          />
        </CheckBoxWrap>
      </ScrollWrapper>
      <ButtonsWrapper className={saveButtonText ? 'share' : 'save'}>
        <BasicButton
          size="small"
          fillWidth
          disable={showConfirmComponent}
          onClick={enableAllPermissions}
        >
          {t('editForm.enableAll')}
        </BasicButton>
        <BasicButton
          size="small"
          fillWidth
          disable={showConfirmComponent}
          onClick={() =>
            switchAllPermissions(false)}
        >
          {t('editForm.resetAll')}
        </BasicButton>
        <BasicButton
          size="small"
          fillWidth
          disable={isSaveNotAllowed || showConfirmComponent}
          onClick={updateTeam}
        >
          {saveButtonText ?? t('editForm.save')}
        </BasicButton>
      </ButtonsWrapper>

      {
        showConfirmComponent
        && renderConfirmComponent()
      }
    </Wrapper>
  );
};

const ConfirmWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const ConfirmBlock = styled.div`
  max-width: 500px;
  background-color: ${(props: UIProps) =>
    props.theme.colors.white};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;

const BaseDescription = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
    ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const Buttons = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
`;

const Wrapper = styled.div`
  position: relative;

  .share {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    width: 100%;
  }

  .save {
    position: absolute;
    top: -7%;
    right: 0;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
      width: 100%;
      position: relative;
    }
  }
`;

const CheckBoxWrap = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;
