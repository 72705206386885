import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { getNumEditing } from '../../../utils';
import { SliderBar } from '../../../elements/slider-bar';
import store from '../../../core/store/store';
import { setDashboardConfig } from '../../../core/store/actions/app';
import { DashboardConfig } from '../../../types/dashboard';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { UIProps } from '../../../types';
import { ModalSubtitle } from '../../../styles/ui-controls';
import { CheckBox } from '../../atoms/Checkbox';
import {
  FormContainer,
  CheckBoxWrap,
} from './common';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

interface SystemSettingsFormProps {}

export const SystemSettingsForm: FunctionComponent<
SystemSettingsFormProps
> = () => {
  const { t } = useTranslation();
  const appConfigs: DashboardConfig = useTypedSelector(
    ({ app }) =>
      app?.dashboard_config,
  );
  const [customNotificationTime, setCustomNotificationTime] = useState<any>();
  const [ipUniqnesMonitorTime, setIpUniqnesMonitorTime] = useState<any>(1);
  const [ipNotChangeTime, setIpNotChangeTime] = useState<any>(0);
  const [isNumericFormatIP, setIsNumericFormatIP] = useState<any>();

  useEffect(() => {
    if (
      appConfigs != null
      && appConfigs?.configs?.tariffExpNotification != null
    ) {
      setCustomNotificationTime(appConfigs?.configs?.tariffExpNotification);
      setIpUniqnesMonitorTime(appConfigs?.configs?.ipUniqnesNotification);
      setIpNotChangeTime(appConfigs?.configs?.ipNotChangeTime);
      setIsNumericFormatIP(appConfigs?.configs?.isNumericFormatIP);
    }
  }, []);

  return (
    <Wrapper>
      <FormContainer>
        <SliderContainer>
          <div className="timer-label">
            {t('dashboard.expTariff')}
            {' '}
            <span>
              {`${customNotificationTime} ${getNumEditing(
                customNotificationTime,
                i18n.language,
                'hours',
              )}`}
            </span>
          </div>
          <SliderBar
            from={24}
            to={120}
            step={24}
            value={customNotificationTime}
            onSlideEnd={(value) => {
              setCustomNotificationTime(value);
              store.dispatch(
                setDashboardConfig.request({
                  configs: {
                    ...appConfigs?.configs,
                    tariffExpNotification: value,
                  },
                }),
              );
            }}
          />
        </SliderContainer>

        <SliderContainer>
          <div className="timer-label">
            {t('dashboard.ipRepeat')}
            {' '}
            <span>
              {`${
                ipUniqnesMonitorTime != null && ipUniqnesMonitorTime !== 0
                  ? ipUniqnesMonitorTime
                  : '-'
              } ${
                ipUniqnesMonitorTime != null && ipUniqnesMonitorTime !== 0
                  ? getNumEditing(ipUniqnesMonitorTime, i18n.language, 'hours')
                  : ''
              }`}
            </span>
          </div>
          <SliderBar
            from={1}
            to={24}
            step={1}
            value={ipUniqnesMonitorTime}
            onSlideEnd={(value) => {
              setIpUniqnesMonitorTime(value);
              store.dispatch(
                setDashboardConfig.request({
                  configs: {
                    ...appConfigs?.configs,
                    ipUniqnesNotification: value,
                  },
                }),
              );
            }}
          />
        </SliderContainer>

        <SliderContainer>
          <div className="timer-label">
            {t('dashboard.notChanged')}
            {' '}
            <span>
              {`${
                ipNotChangeTime != null && ipNotChangeTime !== 0
                  ? ipNotChangeTime
                  : '-'
              } ${
                ipNotChangeTime != null && ipNotChangeTime !== 0
                  ? getNumEditing(ipNotChangeTime, i18n.language, 'minutes')
                  : ''
              }`}
            </span>
          </div>
          <SliderBar
            from={0}
            to={1440}
            step={1}
            value={ipNotChangeTime}
            onSlideEnd={(value) => {
              setIpNotChangeTime(value);
              store.dispatch(
                setDashboardConfig.request({
                  configs: {
                    ...appConfigs?.configs,
                    ipNotChangeTime: value,
                  },
                }),
              );
            }}
          />
        </SliderContainer>

        <Section>
          <ModalSubtitle>{t('dashboard.displayHostname')}</ModalSubtitle>
          <div>
            <CheckBoxWrap>
              <CheckBox
                value={!isNumericFormatIP}
                onClick={(status) => {
                  setIsNumericFormatIP(!status);
                  store.dispatch(
                    setDashboardConfig.request({
                      configs: {
                        ...appConfigs?.configs,
                        isNumericFormatIP: !status,
                      },
                    }),
                  );
                }}
                label={`${t('dashboard.domainName')}`}
                hasLabel
              />
            </CheckBoxWrap>

            <CheckBoxWrap>
              <CheckBox
                value={isNumericFormatIP}
                onClick={(status) => {
                  setIsNumericFormatIP(status);
                  store.dispatch(
                    setDashboardConfig.request({
                      configs: {
                        ...appConfigs?.configs,
                        isNumericFormatIP: status,
                      },
                    }),
                  );
                }}
                label={`${t('dashboard.numericFormat')}`}
                hasLabel
              />
            </CheckBoxWrap>
          </div>
        </Section>
      </FormContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const SliderContainer = styled.div`
  width: 100%;

  .timer-label {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    span {
      font-weight: 500;
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmall};
  }
`;

const Section = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }
`;
