import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import iconAndroid from '../../assets/onb_android.png';
import { OnboardButton } from '../../screens/OnboardingScreen';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { UIProps } from '../../types';
import { OnbWelcomeForm } from './onb-welcome-form';
import store from '../../core/store/store';
import { loadUserInfo, updateUserProfile } from '../../core/store/actions/user';
import { Api } from '../../core/api';
import {
  TARGET_BLANK,
  LINK_REL,
  telegramSupport,
  BREAKPOINT_MOBILE,
} from '../../utils/constants';

interface OnbEmailFormProps {
  onNext?: Function;
}

export const OnbEmailForm: FunctionComponent<OnbEmailFormProps> = (props) => {
  const { onNext = () => {} } = props;
  const { t, i18n } = useTranslation();
  const { email, emailVerified, visitedOnboarding } = useTypedSelector(
    (state) =>
      state?.user?.profile,
  );

  const { profile } = useTypedSelector((state) =>
    state?.user);

  const [emailNotConfirmed, setEmailNotConfirmed] = useState(false);

  const onCheckConfirm = async () => {
    await Api.get('/user/token/[TOKEN]', null, null, false).then((res) =>
      checkEmailConfirmation(res?.data?.emailVerified));
  };

  const checkEmailConfirmation = (value) => {
    if (value) {
      runOnboarding(true);
      setEmailNotConfirmed(false);
      store.dispatch(loadUserInfo.request(null, null));
    } else {
      setEmailNotConfirmed(true);
    }
  };

  const [onboarding, runOnboarding] = useState(false);

  useEffect(() => {
    if (!visitedOnboarding?.confirmEmail) {
      store.dispatch(
        updateUserProfile.request({
          ...profile,
          visitedOnboarding: {
            ...visitedOnboarding,
            confirmEmail: Date.now(),
          },
        }),
      );
    }
  }, []);

  return (
    <>
      {!onboarding ? (
        <Wrapper>
          <div className="icon">
            <img src={iconAndroid} alt="" />
          </div>

          {emailVerified ? (
            <Text>
              Email
              {' '}
              <span className="email">{email}</span>
              {' '}
              is verified!
            </Text>
          ) : i18n.language === 'tr' || i18n.language === 'hi' ? (
            <Text>
              {t('onboarding.email.title_0')}
              {' '}
              <span className="email">{email}</span>
              {' '}
              {t('onboarding.email.title_1')}
            </Text>
          ) : (
            <Text>
              {t('onboarding.email.title')}
              {' '}
              <span className="email">{email}</span>
            </Text>
          )}

          {emailNotConfirmed && (
            <p className="warning">{t('onboarding.email.warning')}</p>
          )}

          <div className="actions">
            {!emailVerified && (
              <Link href={telegramSupport} target={TARGET_BLANK} rel={LINK_REL}>
                {t('onboarding.email.sendEmailAgain')}
              </Link>
            )}
            <OnboardButton
              id="bt"
              className="e-mail_confirmation"
              onClick={onCheckConfirm}
            >
              {t('onboarding.download.action')}
            </OnboardButton>
          </div>
        </Wrapper>
      ) : (
        <OnbWelcomeForm
          nextScreen={() => {
            onNext();
          }}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  .actions {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  }

  .icon {
    margin-bottom: 40px;
  }

  .email {
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    font-weight: 700;
  }

  .warning {
    margin-bottom: 0;
    color: ${(props: UIProps) =>
    props.theme.colors.warning};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    font-weight: 500;
  }

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    text-align: left;
    margin-top: 50px;

    .actions {
      width: 100%;
      #bt {
        width: 100% !important;
      }
    }

    .icon {
      img {
        width: 100px;
      }
    }
  }
`;

const Text = styled.p`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  align-items: center;
  text-align: center;
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  margin: 0;
`;

const Link = styled.a`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  box-sizing: content-box;
  transition: 0.3s;
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
