import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getCityByCode } from '../../utils';
import { BREAKPOINT_MOBILE, flagsUrl } from '../../utils/constants';
import { ServerGeo } from '../../types/phones';
import { UIProps } from '../../types';

interface FlagComponentProps {
  serverGeo: ServerGeo;
  code?: string;
  width: string;
  height: string;
}

export const FlagComponent: FunctionComponent<FlagComponentProps> = (props) => {
  const {
    serverGeo,
    code,
    width,
    height,
  } = props;

  return (
    <>
      <Zone
        src={`${flagsUrl}/${serverGeo?.country}.svg`}
        width={width}
        height={height}
      />

      <div className="country_name">
        {getCityByCode(serverGeo?.city)}
      </div>

      {
        code && (
          <div className="country_code">
            {code}
          </div>
        )
      }
    </>
  );
};

interface ZoneProps {
  src?: string;
  width: string;
  height: string;
}

const Zone = styled.div<ZoneProps>`
  width: ${(props) =>
    props.width};
  height: ${(props) =>
    props.height};
  overflow: hidden;
  background-size: cover;
  margin: auto;
  background-position: center;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.round};
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  background-image: url(${(props: ZoneProps) =>
    props.src});

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: 0;
    width: 30px;
    height: 30px;
  }
`;
