import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import iconBattery from '../../assets/battery.svg';
import { UIProps } from '../../types';
import theme from '../../styles/ApplicationTheme';

interface BatteryStatusProps {
  value?: number;
}

export const BatteryStatus: FunctionComponent<BatteryStatusProps> = (props) => {
  const {
    value = 0,
  } = props;

  const getProgressColor = (value: number) => {
    if (value < 20) {
      return theme.colors.pinkRed;
    } if (value >= 20 && value < 40) {
      return '#FACE32';
    } if (value >= 40 && value < 75) {
      return theme.colors.deepBlue;
    }
    return '#1AB5A2';
  };

  return (
    <Wrapper value={value}>
      <ValueContainer>
        <Progress
          color={getProgressColor(value)}
          value={value}
        />
      </ValueContainer>
    </Wrapper>
  );
};

interface WrapperProps {
  value?: number;
}

const Wrapper = styled.div<WrapperProps>`
  background-image: url(${iconBattery});
  width: 18px;
  height: 9px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  :after {
    content: '${(props:WrapperProps) =>
    props.value}%';
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    position: absolute;
    left: 22px;
    top: -4px;
  }
`;

const ValueContainer = styled.div`
  position: absolute;
  display: flex;
  height: 6px;
  top: 4px;
  left: 1px;
  transform: translateY(-50%);
  width: 14px;
  overflow: hidden;
`;

interface ProgressProps {
  color?: string;
  value?: number;
}

const Progress = styled.div<ProgressProps>`
 width: ${(props:ProgressProps) =>
    props.value}%;
 background-color: ${(props:ProgressProps) =>
    props.color};
`;
