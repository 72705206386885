import React, {
  useState,
  useEffect,
  FunctionComponent,
  useContext,
} from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import store from '../../core/store/store';
import { Phone } from '../../types/phones';
import { UIProps } from '../../types';
import { updateConnection } from '../../core/store/actions/connections';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { compareString } from '../../utils';
import iconRemove from '../../assets/phone_icons/remove_icon.svg';
import iconRemoveActive from '../../assets/remove_icon_active.svg';
import iconEdit from '../../assets/phone_icons/setting_icon.svg';
import { ConnectionEditViewContext } from '../../context/ConnectionEditViewContext';
import { AddMember } from './AdvancedSettings/AddMember';

interface TeamControlProps {
  phone?: Phone;
  phoneOwner?: boolean;
  emailHandler?: Function;
}

export const TeamControl: FunctionComponent<TeamControlProps> = (props) => {
  const { phone, phoneOwner = true, emailHandler } = props;

  const { t } = useTranslation();
  const [membersList, setMembersList] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const profile = useTypedSelector(({ user }) =>
    user.profile);

  const context = useContext(ConnectionEditViewContext);

  useEffect(() => {
    if (phone != null) {
      setMembersList(
        phone?.sharedUsersEmails != null ? phone?.sharedUsersEmails : [],
      );
    }
  }, [phone]);

  const updateTeam = (list, settings) => {
    store.dispatch(
      updateConnection.request({
        id: phone.id,
        sharedUsersEmails: list,
        sharedUsersSettings: settings,
      }),
    );
  };

  const removeMember = () => {
    const newMemberList = membersList?.filter((a) =>
      a !== selectedMember);

    const settingList = phone?.sharedUsersSettings != null ? phone?.sharedUsersSettings : [];

    const newSettingList = settingList?.filter(
      (e) =>
        e.email !== selectedMember,
    );

    setSelectedMember(null);
    updateTeam(newMemberList, newSettingList);
  };

  return (
    <Wrapper>
      {!phoneOwner && !context?.permissions?.newTeamMembersAdd ? null : (
        <AddMember
          addMember={(value) => {
            emailHandler((value as string).toLowerCase());
            setSelectedMember(null);
          }}
          membersList={membersList}
        />
      )}
      {membersList?.length ? (
        <TeamMembersWrapper>
          <UserAddTitle>{t('proSettings.connectedUsers')}</UserAddTitle>
          <TeamMembersList>
            {membersList?.map((user) =>
              (
                <TeamMember
                  key={user}
                  selected={!isEmpty(user) && user === selectedMember}
                >
                  <Email>
                    {user}
                    {!phoneOwner
                  && !context?.permissions?.newTeamMembersAdd ? null : (
                    <div className="icon-container">
                      <ActionBt
                        icon={iconEdit}
                        onClick={() =>
                          emailHandler(user)}
                      />
                      <Close onClick={() =>
                        setSelectedMember(user)}
                      />
                    </div>
                      )}
                    {compareString(profile?.email, user) && (
                    <Close onClick={() =>
                      setSelectedMember(user)}
                    />
                    )}
                  </Email>
                  {!isEmpty(user) && user === selectedMember ? (
                    <EditMode>
                      <Confirm>{t('proSettings.removalConfirmation')}</Confirm>
                      <ActionWrapper>
                        <ActionYep
                          onClick={() => {
                            removeMember();
                          }}
                        >
                          {t('editForm.confirmRemoval')}
                        </ActionYep>
                        <ActionCancel onClick={() =>
                          setSelectedMember(null)}
                        >
                          {t('editForm.cancelRemoval')}
                        </ActionCancel>
                      </ActionWrapper>
                    </EditMode>
                  ) : null}
                </TeamMember>
              ))}
          </TeamMembersList>
        </TeamMembersWrapper>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .icon-container {
    display: flex;
    align-items: center;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const TeamMembersWrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;

const TeamMembersList = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserAddTitle = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

interface TeamMemberProps extends UIProps {
  selected?: boolean;
}

const TeamMember = styled.div<TeamMemberProps>`
  position: relative;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  cursor: pointer;

  &:last-child {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  &:hover {
    background: rgba(180, 180, 180, 0.15);
  }

  ${(props: TeamMemberProps) =>
    props?.selected
    && css`
      background: ${(props: UIProps) =>
    props.theme.colors.neutralGray};
      box-sizing: border-box;
    `}
`;

const Email = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  font-weight: '600';
  color: ${(props: TeamMemberProps) =>
    props.theme.colors.dark};
`;

const Close = styled.div`
  width: 12px;
  height: 12px;

  background-image: url(${(props: TeamMemberProps) =>
    (props?.selected ? iconRemove : iconRemoveActive)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  cursor: pointer;
`;

const EditMode = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  position: absolute;
  bottom: 85%;
  left: 0;
  background-color: ${(props: UIProps) =>
    props.theme.colors.neutralGray};
  width: 100%;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  padding: 0 10px 15px 10px;
`;

const Confirm = styled.div`
  margin: 16px 0 8px;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
`;

const ActionYep = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  cursor: pointer;
`;

const ActionCancel = styled.div`
  margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  cursor: pointer;
`;

interface ActionBtProps extends UIProps {
  icon?: string;
}

const ActionBt = styled.div<ActionBtProps>`
  width: 15px;
  height: 15px;
  background-image: url(${(props: ActionBtProps) =>
    props.icon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.1s;

  :hover {
    opacity: 0.8;
  }
`;
