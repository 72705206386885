import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Phone } from '../types/phones';

import iconDownload from '../assets/icon_download.svg';
import { getLogsWeeks } from '../utils/core-utils';
import { UIProps } from '../types';
import { BREAKPOINT_MOBILE } from '../utils/constants';

interface LogsWeeklyToolProps {
  logsVisibleWeeksNumber?: number;
  ports?: string[] | number[];
  phone: Phone;
  phoneTariff?: any;
}

export const LogsWeeklyTool: FunctionComponent<LogsWeeklyToolProps> = (
  props,
) => {
  const {
    logsVisibleWeeksNumber, ports = [], phoneTariff, phone,
  } = props;

  const portsStringPart = ports?.map((x) =>
    `proxy_ports[]=${x}`).join('&');
  const { t } = useTranslation();

  const logsForWeeks = phoneTariff == null ? 1 : logsVisibleWeeksNumber;

  return (
    <Wrapper>
      {getLogsWeeks().map(({
        from, to, label, download,
      }, i) => {
        const isVisible = i < +logsForWeeks;

        return moment(to)?.isSameOrAfter(phone?.timestamp) ? (
          <WeekRow
            key={`week_${from}_${to}`}
            style={{
              opacity: isVisible === true ? 1 : 0.3,
              cursor: isVisible === true ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (isVisible !== false) {
                download(phone?.id, portsStringPart);
              }
            }}
          >
            <span>{label}</span>

            <span style={{ marginRight: 0 }}>
              <img
                src={iconDownload}
                style={{ height: 14, marginLeft: 10, marginRight: 10 }}
                alt=""
              />

              {isVisible === true ? null : (
                <span
                  style={{
                    marginLeft: 20,
                    fontStyle: 'italic',
                    color: 'red',
                    fontSize: 10,
                  }}
                >
                  {t('proSettings.logsLock')}
                </span>
              )}
            </span>
          </WeekRow>
        ) : null;
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};
`;

const WeekRow = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  border-bottom: 1px solid ${(props: UIProps) =>
    props.theme.colors.ashGray};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
  justify-content: space-between;

  :hover {
    opacity: 0.85;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerZero};
  }
`;
