import React, { useState, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalFormParams } from '../../../modal';
import store from '../../../core/store/store';
import { PhonesScrollList } from '../../PhonesScrollList';
import { useModalHook } from '../../../hooks';
import { WrapperScreen } from '../../../styles/ui-controls';
import { Api } from '../../../core/api';
import { loadUserBalance } from '../../../core/store/actions/user';
import { loadUserPhones } from '../../../core/store/actions/connections';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { FormContainer, Wrapper } from './styles';
import { ProgressBarComponent } from './progress-bar-component';
import { removePhonesGroup } from '../../../core/store/actions/app';

interface MultiRemoveProps extends BaseModalFormParams {}

export const MultiRemove: FunctionComponent<MultiRemoveProps> = (props) => {
  const { modalParams } = props;

  const modalOptions = useModalHook();
  const { t } = useTranslation();
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const [selectedPhones, setSelectedPhones] = useState<string[]>(null);
  const [progressView, setProgressView] = useState(false);
  const [buyCount, setBuyCount] = useState(0);

  const submitHandler = (phonesId: string[]) => {
    setProgressView(true);
    setSelectedPhones(phonesId);
  };

  useEffect(() => {
    if (progressView && selectedPhones != null) {
      let counter = 0;

      const request = selectedPhones.map(async (id) =>
        Api.post(
          '/phones/smart_delete',
          {
            id,
          },
          null,
          false,
        ).then(() => {
          counter += 1;

          setBuyCount(counter);
        }));

      Promise.all(request).then(() => {
        store.dispatch(loadUserBalance.request({ userId: profile?.id }));
        store.dispatch(loadUserPhones.request(null));
        store.dispatch(
          removePhonesGroup.request({
            phonesId: selectedPhones,
          }),
        );

        setTimeout(() => {
          modalOptions.hide();
          if (modalParams?.onClear !== null) {
            modalParams?.onClear();
          }
        }, 1200);
      });
    }
  }, [progressView, selectedPhones]);

  return (
    <WrapperScreen>
      <Wrapper>
        <FormContainer
          marginBottom="15px"
        >
          <div className="info">
            <div className="title">{t('massActions.multiRemove')}</div>
          </div>
          <PhonesScrollList
            isValid={!progressView}
            phonesId={
              modalParams?.selectedPhones !== null
                ? modalParams?.selectedPhones
                : []
            }
            actionText={t('massActions.multiRemove')}
            onActionSubmit={submitHandler}
          />
        </FormContainer>
        {progressView && (
          <ProgressBarComponent
            absolute
            buyCount={buyCount}
            selectedPhones={selectedPhones}
          />
        )}
      </Wrapper>
    </WrapperScreen>
  );
};
