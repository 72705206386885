import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import parser from 'cron-parser';
import { useTranslation } from 'react-i18next';

import store from '../../core/store/store';
import { InputLabel, InputWrapper } from '../../styles/ui-controls';
import { DropdownElement } from '../../elements';
import { SelectOption, ShedulType, UIProps } from '../../types';
import { updateConnection } from '../../core/store/actions/connections';
import { Phone } from '../../types/phones';
import { ButtonLoader } from '../atoms';
import { debounceCall, formatDate } from '../../utils';
import { dateFormats, BREAKPOINT_MOBILE } from '../../utils/constants';

const getNextCronExecutions = (cronString, n = 5) => {
  if (cronString == null) return null;
  const interval = parser.parseExpression(cronString);
  const arr = [];
  for (let i = 0; i < n; i += 1) {
    const s = formatDate(interval.next().getTime(), dateFormats.longDateWithDay);
    arr.push(s);
  }
  return arr;
};

interface RebootByScheduleProps {
  phone?: Phone;
  isOwner?: boolean;
  withoutApply?: boolean;
  changeCron?: (value: string) => void;
}

interface InputComponentProps {
  number: string;
  disabled: boolean;
  value: number;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputComponent = ({
  number,
  disabled,
  value,
  placeholder,
  onChange = () => {},
}: InputComponentProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InputWrapper style={{ margin: 0 }}>
        <InputLabel>{t('proSettings.number', { number })}</InputLabel>
        <MiniInput
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={(e) =>
            onChange(e)}
        />
      </InputWrapper>
    </Wrapper>
  );
};

interface DropdownComponentProps {
  disabled: boolean;
  value: SelectOption<any>;
  options: SelectOption<any>[];
  onSelected: (value) => void;
}

const DropdownComponent = ({
  disabled,
  value,
  options,
  onSelected = () => {},
}: DropdownComponentProps) =>
  (
    <Wrapper>
      <InputWrapper style={{ margin: 0 }}>
        <DropdownElement
          disabled={disabled}
          value={value}
          onSelected={(value) =>
            onSelected(value)}
          options={options}
          isSearchable={false}
        />
      </InputWrapper>
    </Wrapper>
  );

interface DaysTypeComponentProps extends HoursTypeComponentProps {
  inputEveryItem?: number;
  setInputEveryItem?: (value: number) => void;
  setEveryItem?: (value: number) => void;
}

interface HoursTypeComponentProps {
  isOwner: boolean;
  selectType: SelectOption<any>;
  typeOptions: SelectOption<any>[];
  hr: number;
  minutes: number;
  setType: (value: ShedulType) => void;
  setSelectType: (value: SelectOption<any>) => void;
  setHr: (value: number) => void;
  setMinutes: (value: number) => void;
}

const DaysTypeComponent = ({
  isOwner,
  inputEveryItem,
  selectType,
  typeOptions,
  hr,
  minutes,
  setInputEveryItem = () => {},
  setEveryItem = () => {},
  setType = () => {},
  setSelectType = () => {},
  setHr = () => {},
  setMinutes = () => {},
}: DaysTypeComponentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <InputLabel>{t('proSettings.every')}</InputLabel>
      <InputComponent
        number="1-30"
        disabled={!isOwner}
        value={inputEveryItem}
        placeholder="1"
        onChange={(e) => {
          let value = e.target.value.replace(/ /g, '');
          if (value === '') {
            value = '1';
            setInputEveryItem(+value);
            return;
          }

          if (!Number.isNaN(+value) && +value >= 1 && +value <= 30) {
            setInputEveryItem(+value);
            setEveryItem(+value);
          }
        }}
      />
      <DropdownComponent
        disabled={!isOwner}
        value={selectType}
        onSelected={(value) => {
          setType(value.value);
          setSelectType(value);
          if (value.value === ShedulType.Days) {
            setHr(0);
          }
        }}
        options={typeOptions}
      />

      <InputLabel>{t('proSettings.at')}</InputLabel>

      <InputComponent
        number="0-23"
        disabled={!isOwner}
        value={hr}
        placeholder="0"
        onChange={({ target }) => {
          let value = target.value.replace(/ /g, '');
          if (Number.isNaN(+value) || +value > 23 || +value < 0) {
            return;
          }
          if (value === '') {
            value = '0';
          }
          setHr(+value);
        }}
      />

      <InputLabel>{t('proSettings.hours')}</InputLabel>

      <InputComponent
        number="0-59"
        disabled={!isOwner}
        value={minutes}
        placeholder="0"
        onChange={({ target }) => {
          let value = target.value.replace(/ /g, '');
          if (Number.isNaN(+value) || +value > 59 || +value < 0) {
            return;
          }
          if (value === '') {
            value = '0';
          }
          setMinutes(+value);
        }}
      />
      <InputLabel>{t('proSettings.minutes')}</InputLabel>
    </>
  );
};

const HoursTypeComponent = ({
  isOwner,
  selectType,
  typeOptions,
  hr,
  minutes,
  setType = () => {},
  setSelectType = () => {},
  setHr = () => {},
  setMinutes = () => {},
}: HoursTypeComponentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <InputComponent
        number="0-23"
        disabled={!isOwner}
        value={hr}
        placeholder="0"
        onChange={({ target }) => {
          let value = target.value.replace(/ /g, '');
          if (Number.isNaN(+value) || +value > 23 || +value < 0) {
            return;
          }
          if (value === '') {
            value = '0';
          }
          setHr(+value);
        }}
      />

      <DropdownComponent
        disabled={!isOwner}
        value={selectType}
        onSelected={(value) => {
          setType(value.value);
          setSelectType(value);
          if (value.value === ShedulType.Days) {
            setHr(0);
          }
        }}
        options={typeOptions}
      />

      <InputLabel>{t('proSettings.at')}</InputLabel>
      <InputComponent
        number="0-59"
        disabled={!isOwner}
        value={minutes}
        placeholder="0"
        onChange={({ target }) => {
          let value = target.value.replace(/ /g, '');
          if (Number.isNaN(+value) || +value > 59 || +value < 0) {
            return;
          }
          if (value === '') {
            value = '0';
          }
          setMinutes(+value);
        }}
      />
      <InputLabel>{t('proSettings.minutes')}</InputLabel>
    </>
  );
};

export const RebootBySchedule: FunctionComponent<RebootByScheduleProps> = (
  props,
) => {
  const {
    phone, isOwner = true, withoutApply = false, changeCron,
  } = props;

  const { t } = useTranslation();

  const typeOptions: SelectOption<any>[] = [
    {
      value: ShedulType.Days,
      label: t('proSettings.days'),
    },
    {
      value: ShedulType.Hours,
      label: t('proSettings.everyHours'),
    },
  ];

  const [inputEveryItem, setInputEveryItem] = useState(1);
  const [type, setType] = useState(ShedulType.Days);

  const [selectType, setSelectType] = useState(typeOptions[0]);

  const [cron, setCron] = useState(null);
  const [minutes, setMinutes] = useState(0);
  const [hr, setHr] = useState(0);
  const [everyItem, setEveryItem] = useState(1);
  const [planedDays, setPlanedDays] = useState(null);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    if (everyItem === 0) {
      return;
    }

    if (minutes != null && hr != null && everyItem != null) {
      const modifiedHr = hr > 0 ? `*/${hr}` : '*';

      const cronString = type === ShedulType.Days
        ? `${minutes} ${hr} */${everyItem} * *`
        : `${minutes} ${modifiedHr} * * *`;

      setCron(cronString);

      if (changeCron) {
        changeCron(cronString);
      }
    }
  }, [minutes, hr, everyItem, type]);

  useEffect(() => {
    if (cron != null) {
      setPlanedDays(getNextCronExecutions(cron));
    }
  }, [cron]);

  useEffect(() => {
    if (phone?.rebootCronString) {
      try {
        const split = phone?.rebootCronString.split(' ');
        const result = split
          ?.map((c) =>
            c?.replaceAll('*', '').replaceAll('/', ''))
          .slice(0, 3)
          .map((i) =>
            (i === '' ? 0 : i));
        if (result[2]) {
          setMinutes(result[0] as any);
          setHr(result[1] as any);
          setEveryItem(result[2] as any);
          setInputEveryItem(result[2] as any);
        } else {
          setMinutes(result[0] as any);
          setEveryItem(result[1] as any);
          setHr(result[1] as any);
          setInputEveryItem(result[1] as any);
          setType(ShedulType.Hours);
          setSelectType(typeOptions[1]);
        }
      } catch (e) {
        console.error('Log_error:> [RebootBySchedule.tsx] :=', e);
      }
    }
  }, [phone]);

  return (
    <>
      <FormWrapper>
        {
        type === ShedulType.Days
          ? (
            <DaysTypeComponent
              isOwner={isOwner}
              inputEveryItem={inputEveryItem}
              selectType={selectType}
              typeOptions={typeOptions}
              hr={hr}
              minutes={minutes}
              setInputEveryItem={setInputEveryItem}
              setEveryItem={setEveryItem}
              setType={setType}
              setSelectType={setSelectType}
              setHr={setHr}
              setMinutes={setMinutes}
            />
          )
          : (
            <HoursTypeComponent
              isOwner={isOwner}
              selectType={selectType}
              typeOptions={typeOptions}
              hr={hr}
              minutes={minutes}
              setType={setType}
              setSelectType={setSelectType}
              setHr={setHr}
              setMinutes={setMinutes}
            />
          )
      }
        {isOwner && !withoutApply ? (
          <SaveDaysFilter
            onClick={() => {
              setShowProgress(true);
              debounceCall(() => {
                setTimeout(() => {
                  setShowProgress(false);
                }, 300);
              });
              store.dispatch(
                updateConnection.request({
                  id: phone.id,
                  rebootCronString: cron,
                }),
              );
            }}
          >
            <ButtonLoader
              size="small"
              showProgress={showProgress}
            >
              {t('editForm.apply')}
            </ButtonLoader>
          </SaveDaysFilter>
        ) : null}
      </FormWrapper>
      <Hilighted>{t('proSettings.rebootSchedule')}</Hilighted>
      <PlanForChanges>
        {planedDays?.map((item) =>
          (
            <PlannedItem key={item}>{item}</PlannedItem>
          ))}
      </PlanForChanges>
    </>
  );
};

const FormWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    align-items: start;
  }
`;

const SaveDaysFilter = styled.div`
  display: inline-block;
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  font-weight: 400;
  cursor: pointer;

  & > div {
    text-align: left;

    & > svg {
      right: -34px;
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

const MiniInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 38px;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  outline: none;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};

  :focus {
    border-color: ${(props: UIProps) =>
    props.theme.colors.darkBlue};
  }
`;

const Hilighted = styled.div`
  font-weight: 600;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const PlanForChanges = styled.div``;

const PlannedItem = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const Wrapper = styled.div`
  width: 100px;
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;
