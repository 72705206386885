import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TimezoneSelect, {
  ITimezoneOption,
  allTimezones,
} from 'react-timezone-select';
import { InputLabel, InputWrapper } from '../../../styles/ui-controls';
import {
  ButtonLoader,
  CustomeDateInputAtom,
  CustomeTimeInputAtom,
} from '../../atoms';
import { UIProps } from '../../../types';
import { CheckBox } from '../../atoms/Checkbox';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

interface SetCommonExpirationDateProps {
  onSave: Function;
}

export const SetCommonExpirationDate: FunctionComponent<
SetCommonExpirationDateProps
> = (props) => {
  const { onSave } = props;
  const { t, i18n } = useTranslation();

  const [expirationTimestamp, setExpirationTimestamp] = useState(null);

  const [selectedTimezone, setSelectedTimezone] = useState<
  ITimezoneOption | string
  >(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const [showProgress, setShowProgress] = useState(false);

  return (
    <Wrapper>
      <InputContainer>
        <InputWrapper>
          <ExpereationDateWrapper>
            <div className="date-box">
              <div className="date-time">
                <InputLabel>{t('openVpnEdit.date')}</InputLabel>
                <CustomeDateInputAtom
                  indicator
                  value={expirationTimestamp}
                  activeData={false}
                  disabled={!expirationTimestamp}
                  locale={i18n.language}
                  minDate={+new Date()}
                  onChange={(date) =>
                    setExpirationTimestamp(+new Date(date))}
                />
                <div className="time-box">
                  <CustomeTimeInputAtom
                    indicator
                    value={expirationTimestamp}
                    onChange={(date) => {
                      setExpirationTimestamp(+new Date(date));
                    }}
                    disabled={!expirationTimestamp}
                    activeData={false}
                    locale={i18n.language}
                    isUp
                  />
                </div>
              </div>
              {expirationTimestamp != null && (
                <div className="selectTimezone-box">
                  <InputLabel>{t('openVpnEdit.timezone')}</InputLabel>
                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                    timezones={allTimezones}
                    maxMenuHeight={150}
                  />
                </div>
              )}
            </div>
            <div className="activation-box">
              <CheckBox
                value={expirationTimestamp != null}
                onClick={(status) =>
                  setExpirationTimestamp(status ? new Date() : null)}
                label={t('openVpnEdit.checkbox')}
                hasLabel
              />
            </div>
          </ExpereationDateWrapper>
        </InputWrapper>
      </InputContainer>

      <div className="bt-save">
        <ButtonLoader
          size="small"
          onClick={async () => {
            setShowProgress(true);
            const timezone = selectedTimezone as ITimezoneOption;

            if (timezone.offset !== undefined) {
              const shift = timezone.offset
                - new Date(expirationTimestamp).getTimezoneOffset() / -60;
              const expiredTimestampTZ = expirationTimestamp - shift * 60 * 60 * 1000;

              onSave(expiredTimestampTZ);

              return;
            }
            onSave(expirationTimestamp);
          }}
          showProgress={showProgress}
        >
          {t('editForm.save')}
        </ButtonLoader>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const InputContainer = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  height: 280px;
`;

export const ExpereationDateWrapper = styled.div`
  .activation-box {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .selectTimezone-box {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    margin-left: 50px;
    width: 65%;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
      width: 100%;
    }
  }

  .css-1s2u09g-control {
    border: 1px solid ${(props: UIProps) =>
    props.theme.colors.darkGray};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  }

  .css-1pahdxg-control {
    border-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue} !important;
  }

  .date-box {
    display: flex;
    justify-content: space-between;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      flex-direction: column;
    }
  }

  .css-1kwwvb1-ValueContainer2 {
    padding: 4px 8px;
  }

  .time-box {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
    }
  }
`;
