import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../../types';
import { OnboardButton } from '../../screens/OnboardingScreen';
import { COUNTRY_MAP } from '../../constants';
import { InputField } from '../../elements';
import { Api } from '../../core/api';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

interface DownloadSmsLinkProps {

}

export const DownloadSmsLink: FunctionComponent<DownloadSmsLinkProps> = () => {
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const { t } = useTranslation();

  const config = COUNTRY_MAP?.reduce((acc, i) =>
    ({
      ...acc,
      [i?.code]: { primary: i?.code, secondary: i?.phoneCode, phoneCode: i?.phoneCode },
    }), {});

  const $sendSmsByLink = async (phone) => {
    try {
      await Api.post('/sms/onb/download', { phoneNumber: phone });
    } catch (e) {
      console.error('Error:> Error [download-sms-link.tsx] :=', e);
    }
  };

  return (
    <Wrapper>
      <FormContainer>
        <div className="info">
          <div className="title">{t('onboarding.sms.title')}</div>
        </div>
        <div className="instruction">
          {t('onboarding.sms.desc')}
        </div>
        <NumberContainer>

          <ReactFlagsSelect
            className="flags"
            searchable
            fullWidth={false}
            placeholder="-"
            showOptionLabel
            onSelect={(code) =>
              setCode(code)}
            selected={code}
            customLabels={config}
          />
          <InputField
            style={{ height: '54px', width: '220px' }}
            value={phone?.replace(/(\d{3})/g, '$1 ')}
            disabled={code === '' || code === undefined}
            placeholder="00 000 000"
            onChange={({ target }) => {
              setPhone(target.value.replace(/\D/g, ''));
            }}
          />
        </NumberContainer>
        <div className="actions">
          <OnboardButton onClick={() => {
            $sendSmsByLink((config[code]?.phoneCode) ?? 0 + phone);
          }}
          >
            {t('onboarding.sms.action')}
          </OnboardButton>
        </div>
      </FormContainer>
    </Wrapper>
  );
};

const NumberContainer = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  display: flex;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  .flags{
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    #rfs-btn{
      height: 54px;
      width: 150px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 450px;
  padding: 10px;

  .instruction{
    ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
    display: flex;
    align-items: center;
  }
`;

const FormContainer = styled.div`
  margin-bottom: 35px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  }

  .title {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.largeBold};
    margin-bottom: 12px;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(prop: UIProps) =>
    prop.theme.sizes.font.smallerBold};
    }
  }

  .info {
    display: flex;
    align-items: center;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
    }
  }

  .description {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumMedium};
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(prop: UIProps) =>
    prop.theme.sizes.font.smallerMedium};
    }
  }
`;
