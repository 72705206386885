import { createReducer } from 'typesafe-actions';

import { loadPlanList } from '../actions/plans';
import { PlanStoreState } from '../../../types';

const initialAppState: PlanStoreState = {
  data: [],
  error: null,
  loading: false,
};

const plansReducer = createReducer(initialAppState)
  .handleAction(loadPlanList.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(loadPlanList.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: payload,
    }))
  .handleAction(loadPlanList.failure, (state) =>
    ({ loading: false, ...state }));

export default plansReducer;
export type PlansState = ReturnType<typeof plansReducer>;
