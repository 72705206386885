import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { UIProps } from '../../types';

interface LabelCounterProps {
  isActive?: boolean;
  title?: string;
  bubbleText?: string;
}

export const LabelCounter: FunctionComponent<LabelCounterProps> = (props) => {
  const {
    isActive = false,
    title,
    bubbleText,
  } = props;
  return (
    <Wrapper active={isActive}>
      {title}
      {
                isActive ? <div className="bubble">{bubbleText}</div> : null
            }
    </Wrapper>
  );
};

interface WrapperProps extends UIProps {
  active?: boolean;
  ss?:any;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: fit-content;
  display: flex;
  z-index: 2;
  align-items: center;
  color: ${(props: WrapperProps) =>
    (props.active ? props.theme.colors.white : props.theme.colors.azureBlue)};
  font-weight:  ${(props: WrapperProps) =>
    (props.active ? '500' : '400')};



  .bubble{
    color: #2C75D3;
    ${(props: UIProps) =>
    props.theme.sizes.font.smallerSemibold};
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-sizing: border-box;
    width: 32px;
    height: 24px;
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.round};
    text-align: center;
    top: -3px;
    right: -40px;
  }

  ${(props: WrapperProps) =>
    props.active && css`
    :after{
     display: none !important;
    }
  `}
`;
