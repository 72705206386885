import React, { useState, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { BaseModalFormParams } from '../../../modal';
import { WrapperScreen } from '../../../styles/ui-controls';
import store from '../../../core/store/store';
import { loadUserPhones } from '../../../core/store/actions/connections';
import { useModalHook } from '../../../hooks';
import { Api } from '../../../core/api';
import { AddMember } from '../../edit-connection/AdvancedSettings/AddMember';
import { PermissionsForm } from '../../edit-connection/PermissionsForm';
import {
  FormContainer, Wrapper, GroupsList, GroupsItem,
} from './styles';
import { ProgressBarComponent } from './progress-bar-component';
import { useMassActionsHook } from '../../../hooks/useMassActionsHook';

interface MultiTeamControlProps extends BaseModalFormParams {}

export const MultiTeamControl: FunctionComponent<MultiTeamControlProps> = (
  props,
) => {
  const { modalParams } = props;

  const modalOptions = useModalHook();

  const { t } = useTranslation();
  const { selectedPhones, getPhoneList, removePhone } = useMassActionsHook();
  const [progressView, setProgressView] = useState(false);
  const [buyCount, setBuyCount] = useState(0);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    if (modalParams?.selectedPhones != null) {
      getPhoneList(modalParams, false);
    }
  }, [modalParams?.selectedPhones]);

  return (
    <WrapperScreen>
      <Wrapper>
        <FormContainer
          marginBottom="100px"
        >
          <div className="info">
            <div className="title">{t('massActions.shareTeam')}</div>
          </div>
          {email ? (
            <PermissionsForm
              emailAdd={(permission) => {
                if (selectedPhones === null) return;
                setProgressView(true);

                const request = selectedPhones.map(async (phone) => {
                  if (
                    phone?.sharedUsersEmails?.find(
                      (userEmail) =>
                        userEmail === email,
                    )
                  ) {
                    setBuyCount(() =>
                      buyCount + 1);
                    return null;
                  }

                  const emailList = phone?.sharedUsersEmails ?? [];

                  const settingList = phone?.sharedUsersSettings ?? [];

                  return Api.post(
                    '/phone/update',
                    {
                      id: phone.id,
                      sharedUsersEmails: [...emailList, email],
                      sharedUsersSettings: [
                        ...settingList,
                        { email, permissions: permission },
                      ],
                    },
                    null,
                    false,
                  ).then(() =>
                    setBuyCount(() =>
                      buyCount + 1));
                });

                Promise.all(request).then(() => {
                  store.dispatch(loadUserPhones.request(null));
                  setTimeout(() => {
                    modalOptions.hide();
                  }, 1200);
                });
              }}
              email={email}
              saveButtonText={t('massActions.shareTeam')}
            />
          ) : (
            <div className="scroll-container">
              <Scrollbars>
                <div>
                  {selectedPhones != null ? (
                    <GroupsList>
                      {selectedPhones.map((phone) =>
                        (
                          <GroupsItem
                            key={`phone_list_${phone.id}`}
                          >
                            <div className="item-content">{phone?.name}</div>
                            <div
                              className="close"
                              onClick={() => {
                                removePhone(phone?.id);
                              }}
                            />
                          </GroupsItem>
                        ))}
                    </GroupsList>
                  ) : null}
                </div>
              </Scrollbars>
              <div className="total-goups">
                {`${t('connection.total')}: ${
                  selectedPhones?.length != null ? selectedPhones?.length : 0
                }`}
              </div>

              <AddMember
                addMember={(value) =>
                  setEmail(value)}
                membersList={[]}
              />
            </div>
          )}
        </FormContainer>
        {progressView && (
          <ProgressBarComponent
            buyCount={buyCount}
            selectedPhones={selectedPhones}
          />
        )}
      </Wrapper>
    </WrapperScreen>
  );
};
