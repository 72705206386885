import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';

import { UIProps } from '../../types';
import checkboxIcon from '../../assets/checkbox.svg';

interface CheckBoxProps {
  onClick?: Function;
  hasBorder?: boolean,
  value?: boolean;
  hasLabel?: boolean;
  disabled?: boolean;
  label?: string;
  className?: string;
}
export const CheckBox: FunctionComponent<CheckBoxProps> = (props) => {
  const {
    onClick = () => {},
    hasBorder = false,
    value = null,
    hasLabel = false,
    disabled = false,
    label = 'demo label',
    className = '',
  } = props;
  const [active, setActive] = useState(value);

  useEffect(() => {
    setActive(value);
  }, [value]);
  return (
    <Wrapper hasLabel={hasLabel} disabled={disabled}>
      <Checkbox
        className={className}
        hasBorder={hasBorder}
        active={active}
        onClick={() => {
          if (disabled) return;
          setActive(!active);
          onClick(!active);
        }}
      />

      {hasLabel ? (<Label>{label}</Label>) : null}
    </Wrapper>
  );
};

interface CheckboxWrapperProps extends UIProps {
  active?: boolean;
  hasBorder?: boolean;
}

const Label = styled.div`
  margin-left: 12px;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

interface WrapperProps extends UIProps {
  hasLabel?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: ${(prop: WrapperProps) =>
    (prop.hasLabel ? 'flex' : 'block')};
  opacity: ${(prop: WrapperProps) =>
    (prop.disabled ? 0.7 : 1)};

  align-items: center;
`;

const Checkbox = styled.div<CheckboxWrapperProps>`
  position: relative;
  cursor: pointer;
  background-color:  ${(prop: CheckboxWrapperProps) =>
    prop.theme.colors.white};
  border: 1px solid ${(prop: CheckboxWrapperProps) =>
    prop.theme.colors.deepBlue};
  width: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  opacity: ${(props: CheckboxWrapperProps) =>
    (props.active ? 1 : 0.7)};

  &:before {
    display: ${(props: CheckboxWrapperProps) =>
    (props.active ? 'block' : 'none')};
    content: '';
    background-image: ${(props: CheckboxWrapperProps) =>
    (props.active ? `url(${checkboxIcon})` : '')};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 70%;
    height: 70%;
  }
`;
