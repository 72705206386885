import styled from 'styled-components';
import { UIProps } from '../../types';
import { BREAKPOINT_MOBILE } from '../../utils/constants';

export const EmptyMessage = styled.div`
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  text-align: center;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;
