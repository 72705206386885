import React from 'react';
import styled from 'styled-components';

import Hotkeys from 'react-hot-keys';
import { useTranslation } from 'react-i18next';
import { ConnectionsBoard } from '../components';
import { SuportHelpModal } from '../components/common/SuportHelpModal';
import { CommonAppContext } from '../context/CommonAppContext';
import { UIProps } from '../types';
import { showNotification } from '../utils';
import { BREAKPOINT_MOBILE } from '../utils/constants';

import widgetIcon from '../assets/widget_button_icon.svg';

const wootWidgetBubble = '.woot-widget-bubble';
const wootExitSupportChat = 'woot-exit-support-chat';
const wootWidgetHolder = '.woot-widget-holder';

export function DashboardScreen() {
  const context = React.useContext(CommonAppContext);
  const { t } = useTranslation();

  const openChat = () => {
    const chatBubble = document.querySelector<HTMLElement>(wootWidgetBubble);

    if (!chatBubble) {
      showNotification(`${t('notification.reloadPage')}`, 'error');
      return;
    }

    chatBubble.click();

    if (!document.querySelector(`.${wootExitSupportChat}`)) {
      const container = document.querySelector(wootWidgetHolder);

      if (!container) return;

      const newElement = document.createElement('div');
      newElement.textContent = 'Exit';
      newElement.className = wootExitSupportChat;
      newElement.addEventListener('click', () => {
        const newChatBubble = document.querySelector<HTMLElement>(wootWidgetBubble);

        if (newChatBubble) newChatBubble.click();
      });
      container.appendChild(newElement);
    }
  };
  // shift+alt+h  or shift+option+h
  return (
    <Hotkeys
      keyName="shift+alt+h"
      onKeyDown={() =>
        context.setShowSupportModal(true)}
    >
      {context?.showSupportModal ? <SuportHelpModal /> : null}
      <Wrapper>
        <ConnectionsBoard />
        <WrapperWidget>
          <WrapperButton>
            <WidgetOpenButton
              src={widgetIcon}
              onClick={() => {
                openChat();
              }}
            />
          </WrapperButton>
        </WrapperWidget>
      </Wrapper>
    </Hotkeys>
  );
}

const Wrapper = styled.div`
  width: 98%;
  margin: auto;
`;

const WrapperWidget = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 60px;
  flex-direction: row;

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    bottom: 40px;
  }
`;

const WrapperButton = styled.div`
  display: absolute;
  z-index: 100;
  margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  bottom: 0;
`;
interface WidgetOpenButtonProps {
  src: string;
}

const WidgetOpenButton = styled.div<WidgetOpenButtonProps>`
  width: 78px;
  height: 78px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.round};
  ${(props: UIProps) =>
    props.theme.gradients.darkBlue};
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;

  :hover {
    opacity: 0.7;
  }

  :before {
    content: '';
    display: flex;
    width: 25px;
    height: 25px;
    background: url(${(props: WidgetOpenButtonProps) =>
    props.src}) no-repeat;
  }

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 45px;
    height: 45px;
    :before {
      content: '';
      display: flex;
      width: 14px;
      height: 14px;
      background: url($${(props: WidgetOpenButtonProps) =>
    props.src}) no-repeat;
    }
  }
`;
