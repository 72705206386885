import React, { useState, useEffect, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AlertsService } from '../../services/AlertsService';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { MessageTypes, UIProps } from '../../types';
import iconRemove from '../../assets/phone_icons/remove_icon_gray.svg';
import store from '../../core/store/store';
import { updateUserProfile } from '../../core/store/actions/user';
import { BREAKPOINT_MOBILE } from '../../utils/constants';
import { ReactMD } from './ReactMarkdown';

interface SystemAlertComponentProps {}

const messageCache = new Map();

export const SystemAlertComponent: FunctionComponent<
SystemAlertComponentProps
> = () => {
  const [messages, setMessages] = useState(null);
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const profile = useTypedSelector(({ user }) =>
    user.profile);

  const haveMessage = profile?.message?.show && profile?.message?.text;

  const $updateProfile = async () => {
    store.dispatch(
      updateUserProfile.request({
        ...profile,
        message: {
          ...profile.message,
          show: false,
        },
      }),
    );
  };

  useEffect(() => {
    setMessages(null);
    if (!messageCache.has(lang)) {
      AlertsService.loadMessages(lang).then((e) => {
        const alertMessage = e?.data?.attributes?.alers;

        if (e?.hasOwnProperty('data')) {
          setMessages(alertMessage);
          messageCache.set(lang, alertMessage);
        }
      });
    } else {
      setMessages(messageCache.get(lang));
    }
  }, [lang]);

  return messages?.length || haveMessage ? (
    <Wrapper>
      {messages?.map(({ id, description, type }) =>
        (
          <MsgBox type={type} key={id}>
            <ReactMD markdown={description} />
          </MsgBox>
        ))}
      {haveMessage && (
        <MsgBox type={profile.message.type} key={profile.message.text}>
          <ReactMD markdown={profile.message.text} />
          <Close onClick={$updateProfile} />
        </MsgBox>
      )}
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

interface MsgBoxProps {
  type?: MessageTypes;
}

const MsgBox = styled.div<MsgBoxProps>`
  position: relative;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  box-sizing: border-box;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};

  a {
    font-weight: bold;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    max-height: 100px;
    overflow: scroll;
  }

  ${(props) => {
    switch (props?.type) {
      case 'info':
        return css`
          box-shadow: 0px 0px 6px rgb(36 78 177 / 20%);
          color: ${(props: UIProps) =>
    props.theme.colors.indigo};
          background-color: rgb(36 78 177/5%);
          border: 1px solid ${(props: UIProps) =>
    props.theme.colors.indigo};

          a {
            color: ${(props: UIProps) =>
    props.theme.colors.indigo};
            :hover {
              opacity: 0.7;
            }
          }
        `;
      case 'warning':
        return css`
          box-shadow: 0px 0px 6px rgb(255 54 55 / 20%);
          color: rgb(255 54 55);
          background-color: rgb(255 54 55/5%);
          border: 1px solid rgb(255 54 55);
          a {
            color: rgb(255 54 55);
          }
        `;
      case 'urgent':
        return css`
          box-shadow: 0px 0px 6px rgb(234 193 17 / 20%);
          color: rgb(234 193 17);
          background-color: rgb(234 193 17/5%);
          border: 1px solid rgb(234 193 17);
          a {
            color: rgb(234 193 17);
          }
        `;

      default:
        return css`
          box-shadow: 0px 0px 6px rgb(36 78 177 / 20%);
          color: ${(props: UIProps) =>
    props.theme.colors.indigo};
          background-color: rgb(36 78 177/5%);
          border: 1px solid ${(props: UIProps) =>
    props.theme.colors.indigo};

          a {
            color:${(props: UIProps) =>
    props.theme.colors.indigo};
            :hover {
              opacity: 0.7;
            }
          }
        `;
    }
  }}
`;

const Close = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  cursor: pointer;
  align-self: center;
  right: 7px;
  top: 5px;
  background-image: url(${iconRemove});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
