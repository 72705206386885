import React, {
  useEffect, FunctionComponent, useContext, useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { About } from './ChangeIP/About';
import { Rotation } from './ChangeIP/Rotation';
import { Url } from './ChangeIP/URL';
import { UniqueIPTab } from './BigDaddyPro/UniqueIPTab';
import { useWidgetPolicyHook } from '../../hooks/useWidgetPolicyHook';
import { ConnectionEditViewContext } from '../../context/ConnectionEditViewContext';
import { UIProps } from '../../types';

interface ChangeIpFormProps {
}

export const ChangeIpForm: FunctionComponent<ChangeIpFormProps> = () => {
  const context = useContext(ConnectionEditViewContext);
  const { t } = useTranslation();
  const { hasWidgetPermission } = useWidgetPolicyHook(context?.selectedPhone);
  const isPermitted = hasWidgetPermission('bigDaddyPro');
  const [isUniqueIP, setIsUniqueIP] = useState(false);

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'url tab',
      title: 'URL',
      component: (<Url />),
    },
    {
      key: 'rotation',
      title: t('rotation.title'),
      component: (<Rotation />),
    },
    {
      key: 'unique ip',
      title: t('proSettings.uniqueIP'),
      component: (<UniqueIPTab />),
    },
    {
      key: 'about',
      title: t('connection.about'),
      component: (<About />),
    },
  ];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const onChangeUniqueIPTab = (tab) => {
    if (tab === 'unique ip') {
      setIsUniqueIP(true);
    } else {
      setIsUniqueIP(false);
    }
  };

  return (
    <Wrapper>
      {
        !isPermitted && isUniqueIP
        && (
          <WarningWrapper>
            <Warning>
              {t('policyMsg.exclusive')}
            </Warning>
          </WarningWrapper>
        )
      }
      <ReactTooltip />
      <TabsAtom
        config={tabsConfig}
        handleTabWithWarning={onChangeUniqueIPTab}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const WarningWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const Warning = styled.p`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  font-weight: 500;
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.warning};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
`;
