import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CopySettings } from '../../../types/dashboard';
import { CheckBox } from '../../atoms/Checkbox';
import { CheckBoxWrap } from '../../forms/settings/common';

interface CredentialCheckboxSettingsProps {
  copySettings: CopySettings;
  setCopySettings: (setting: CopySettings) => void;
}

export const CredentialCheckboxSettings: FunctionComponent<CredentialCheckboxSettingsProps> = (props) => {
  const {
    copySettings,
    setCopySettings,
  } = props;
  const { t } = useTranslation();

  const handleCopySettingsChange = (value: string, status: boolean) => {
    const settings = {
      ...copySettings,
      [value]: status,
    };

    setCopySettings(settings);
  };

  return (
    <Section>
      <CheckBoxWrap>
        <CheckBox
          value={copySettings?.ipChangeLink}
          onClick={(status: boolean) =>
            handleCopySettingsChange('ipChangeLink', status)}
          label={t('dashboard.changeIpLink')}
          hasLabel
        />
      </CheckBoxWrap>
      <CheckBoxWrap>
        <CheckBox
          value={copySettings?.ipChangeLinkInFormat}
          onClick={(status: boolean) =>
            handleCopySettingsChange('ipChangeLinkInFormat', status)}
          label={t('dashboard.changeIpLinkInFormat')}
          hasLabel
        />
      </CheckBoxWrap>
      <CheckBoxWrap>
        <CheckBox
          value={copySettings?.autoIPRotation}
          onClick={(status: boolean) =>
            handleCopySettingsChange('autoIPRotation', status)}
          label={t('copySettings.autoIPRotation')}
          hasLabel
        />
      </CheckBoxWrap>
    </Section>
  );
};

const Section = styled.div``;
