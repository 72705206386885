import axios from 'axios';
import { alertMessageUrl } from '../utils/constants';

export class AlertsService {
  static async loadMessages(lang) {
    try {
      return (await axios.get(alertMessageUrl, {
        params: {
          locale: lang,
          populate: {
            alers: {
              filters: {
                active: {
                  $eq: true,
                },
                for: {
                  $in: ['all', 'app'],
                },
              },
            },
          },
        },
      }))?.data;
    } catch (e: any) {
      console.error('Error:> [AlertsService.ts] :=', e?.message);
      return e;
    }
  }
}
