import React, {
  useState,
  useEffect,
  FunctionComponent,
  useContext,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { StatisticCalendar } from './StatisticCalendar';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import store from '../../../core/store/store';
import { getTrafficMonthly } from '../../../core/store/actions/connections';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { LoaderBlock } from './PhoneUptimePanel';
import { LoaderIcon } from '../../common/LoaderIcon';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../../../utils/constants';
import { UIProps } from '../../../types';

interface TrafficMonthlyProps {
  handleChangeDailyTrafficTab: Function;
  handleChooseDay: Function;
  setChosenDayTimestamp: Function;
}

export const getPhoneMonthTrafficStats = (phoneId: string, monthTimestamp: number, callback: Function = () => {}) => {
  const from = +moment(monthTimestamp)
    .startOf('month')
    .startOf('day');

  const to = +moment(monthTimestamp)
    .endOf('month')
    .endOf('day');

  return store.dispatch(
    getTrafficMonthly.request({
      phoneId,
      from,
      to,
      callback: () =>
        callback(),
    }),
  );
};

export const TrafficMonthly: FunctionComponent<TrafficMonthlyProps> = (props) => {
  const { handleChangeDailyTrafficTab, handleChooseDay, setChosenDayTimestamp } = props;
  const context = useContext(ConnectionEditViewContext);
  const phoneId = context?.selectedPhone?.id;
  const [selectedTimestamp, setSelectedTimestamp] = useState(
    +moment().startOf('day'),
  );
  const [loading, setLoading] = useState(false);

  const phone = useTypedSelector(({ connections }) =>
    connections.data.find((item) =>
      item.id === phoneId));
  const trafficMonthly = phone?.trafficMonthly;

  useEffect(() => {
    if (context != null && context?.selectedPhone != null && !trafficMonthly) {
      setLoading(true);

      getPhoneMonthTrafficStats(phoneId, selectedTimestamp, () =>
        setLoading(false));
    }
  }, [context, selectedTimestamp]);

  useEffect(() => {
    setChosenDayTimestamp(null);
  }, []);

  const onChangeDailyTrafficTab = (tab) => {
    handleChangeDailyTrafficTab(tab);
  };

  const onChooseDay = (timestamp) => {
    handleChooseDay(timestamp);
  };

  return (
    <Wrapper>
      <ScrollBarContainer>
        <div className="container">
          <Block>
            {
              loading ? (
                <LoaderBlock>
                  <LoaderIcon />
                  <div>Loading...</div>
                </LoaderBlock>
              ) : (
                <StatisticCalendar
                  selectedTimestamp={selectedTimestamp}
                  onDaySelect={(r) => {
                    setSelectedTimestamp(r);
                  }}
                  onChangeDate={(e) => {
                    setSelectedTimestamp(e);
                  }}
                  dailyStatsMap={trafficMonthly?.result}
                  handleChangeDailyTrafficTab={onChangeDailyTrafficTab}
                  handleChooseDay={onChooseDay}
                />
              )
            }
          </Block>
        </div>
      </ScrollBarContainer>
    </Wrapper>
  );
};

const ScrollBarContainer = styled.div`
  height: 100%;
  width: 100%;
  min-width: 750px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${BREAKPOINT_TABLET}px) {
    min-width: 100%;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    overflow-x: auto;
  }
`;

const Block = styled.div`
  position: relative;
  clear: both;
  min-height: 500px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  height: 485px;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};


  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    min-height: 100vh;
  }

  .chart-container{
    margin-top: 40px;
  }

  .port-select-container {
    display: flex;
    align-items:center;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    background: ${(props: UIProps) =>
    props.theme.colors.iceBlue};
    box-sizing: border-box;
    padding: 8px;
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

    .label{
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }
    .selectbox {
      min-width: 200px;

    }
  }
`;
