import React, {
  FunctionComponent,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { VideoInstructionBlock, StyledProBlockWrapper } from '../../../styles/ui-controls';
import { UIProps } from '../../../types';
import { getSMSBotInstructionLink } from '../../../utils/instruction-links';
import { ReactMD } from '../../common/ReactMarkdown';
import theme from '../../../styles/ApplicationTheme';
import { windowOpen } from '../../../utils';

import { InnerWrapper } from '../TelegramBot/About';

interface AboutProps {
}

export const About: FunctionComponent<AboutProps> = () => {
  const { t, i18n } = useTranslation();

  const ITEMS = [
    { text: 'sms.download', link: 'https://drive.google.com/file/d/1uZDXtTFOYq5PTcIClDdZURcs6lZTBBUy/view' },
    { text: 'sms.install' },
    { text: 'sms.botStart', link: 'https://t.me/iproxy_online_sms_bot' },
    { text: 'sms.authorize' },
    { text: 'sms.example' },
  ];

  return (
    <Wrapper>
      <VideoInstructionBlockWrapper>
        <VideoInstructionBlock
          style={{ textAlign: 'right' }}
          onClick={() =>
            windowOpen(getSMSBotInstructionLink(i18n.language))}
        >
          {t('ourProjects.learn')}
        </VideoInstructionBlock>
      </VideoInstructionBlockWrapper>

      <InnerWrapper>
        <BaseDescription>
          {t('sms.about')}
        </BaseDescription>

        <InstructionBlock>
          {
            ITEMS.map(({ text, link }) =>
              (
                <StyledProBlockWrapper key={text}>
                  <ReactMD
                    markdown={
                      text === 'sms.botStart'
                        ? t(`${text}`, {
                          bot: `[${t('sms.bot')}](${link})`,
                        })
                        : text === 'sms.authorize'
                          ? t(`${text}`, {
                            login: `**${t('sms.login')}**`,
                          })
                          : text === 'sms.download'
                            ? `[${t(`${text}`)}](${link})`
                            : t(`${text}`)
                    }
                    fontSize={theme.sizes.font.small}
                    color={theme.colors.darkGray}
                  />
                </StyledProBlockWrapper>
              ))
          }
        </InstructionBlock>
      </InnerWrapper>
    </Wrapper>
  );
};

const BaseDescription = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const VideoInstructionBlockWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

export const InstructionBlock = styled.ul`
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
`;
