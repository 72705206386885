import { combineEpics, Epic } from 'redux-observable';
import { RootAction, RootState } from 'typesafe-actions';
import { catchError } from 'rxjs/operators';

import * as app from './app';
import * as user from './user';
import * as connections from './connections';
import * as plans from './plans';
import * as servers from './servers';
import * as billing from './billing';

const rootEpic: Epic<RootAction, RootAction, RootState> = (action$, store$, dependencies) =>
  combineEpics(
    ...Object.values(app),
    ...Object.values(user),
    ...Object.values(connections),
    ...Object.values(servers),
    ...Object.values(plans),
    ...Object.values(billing),
  )(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    }),
  );

export default rootEpic;
