import React, { useState, useEffect, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownElement } from '../../../../elements';
import { SelectOption } from '../../../../types';
import { Phone } from '../../../../types/phones';
import { ButtonLoader } from '../../../atoms';
import { VideoInstructionBlock } from '../../../../styles/ui-controls';
import { getGlobalDNSLink } from '../../../../utils/instruction-links';
import {
  ButtonWrapper,
  CheckboxWrapper,
  ElementWrapper,
  FormWrapper,
  Wrapper,
} from './Common';
import store from '../../../../core/store/store';
import { updateOpenVPNMass } from '../../../../core/store/actions/connections';
import { showNotification, windowOpen } from '../../../../utils';

interface DNSOvpnAtomProps {
  phone?: Phone;
  setIpList?: Function;
  setIsSetOpenVPNDNS?: Function;
}

export const DNSOvpnAtom: FunctionComponent<DNSOvpnAtomProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { phone, setIpList, setIsSetOpenVPNDNS } = props;

  const dnsOptions: SelectOption<any>[] = [
    {
      value: '10.180.0.1',
      label: '10.180.0.1',
    },
    {
      value: '8.8.8.8',
      label: 'Google DNS -> 8.8.8.8',
    },
    {
      value: '1.1.1.1',
      label: 'Cloudflare DNS -> 1.1.1.1',
    },
  ];

  const [dnsOption, setDnsOption] = useState(dnsOptions[0]);
  const [isChangedOpenVPNDns, setIsChangedOpenVPNDns] = useState(false);

  useEffect(() => {
    if (setIpList) {
      setIpList([dnsOption?.value]);
    }
  }, [dnsOption?.value]);

  const save = () => {
    const openVpn = phone.openVPN;

    if (!openVpn?.length) {
      showNotification(`${t('notification.ovpnError')}`, 'error');
      return;
    }

    const ipList = [dnsOption?.value];

    setIsChangedOpenVPNDns(true);
    store.dispatch(
      updateOpenVPNMass.request({
        data: openVpn.map((upd) =>
          ({ ...upd, dns: ipList })),
        callback: () => {
          setIsChangedOpenVPNDns(false);
          setIsSetOpenVPNDNS(false);
        },
      }),
    );
  };

  return (
    <Wrapper>
      <>
        <CheckboxWrapper>
          <VideoInstructionBlock
            onClick={() =>
              windowOpen(getGlobalDNSLink(i18n.language))}
          >
            {t('proSettings.instruction')}
          </VideoInstructionBlock>
        </CheckboxWrapper>
        <FormWrapper>
          <ElementWrapper>
            <DropdownElement
              value={dnsOption}
              onSelected={(value) => {
                setDnsOption(value);
              }}
              options={dnsOptions}
            />
          </ElementWrapper>
          {!setIpList && (
            <ButtonWrapper>
              <ButtonLoader
                size="small"
                onClick={() =>
                  save()}
                showProgress={isChangedOpenVPNDns}
              >
                {t('editForm.apply')}
              </ButtonLoader>

              <ButtonLoader
                size="small"
                color="warning"
                onClick={() =>
                  setIsSetOpenVPNDNS(false)}
              >
                {t('editForm.cancelRemoval')}
              </ButtonLoader>
            </ButtonWrapper>
          )}
        </FormWrapper>
      </>
    </Wrapper>
  );
};
