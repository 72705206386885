import { Epic } from 'redux-observable';
import {
  catchError, filter, switchMap, withLatestFrom,
} from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';
import { concat, from, of } from 'rxjs';

import { idle, modalConfirmation } from '../actions/app';
import { buyPhoneTariff } from '../actions/billing';
import { updateConnection } from '../actions/connections';
import { Api } from '../../api';

const ApiURLBuyPhoneTariff = (phoneId: string) =>
  `phone/${phoneId}/buy_plan`;

export const buyPhoneTariffEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(buyPhoneTariff)),
    withLatestFrom(state$),
    switchMap(([{ payload }]) =>
      from(Api.post(ApiURLBuyPhoneTariff(payload.phoneId), { planId: payload.planId }, null, false)).pipe(
        switchMap(({ data }) =>
          concat(
            of(updateConnection.success(data?.result)),
            of(modalConfirmation({ show: false })),
          )),
        catchError(() =>
          of(idle())),

      )),
    catchError(() =>
      of(idle())),
  );
