import { createReducer } from 'typesafe-actions';

import { AppStore } from '../../../types';
import {
  loadDashboardConfig,
  loadLatestAppVersion,
  modalConfirmation,
  modalMonitor, setDashboardConfig,
  updateCredentials,
} from '../actions/app';

const initialAppState: AppStore = {
  modal: {
    data: null,
    loading: false,
    error: null,
    active: false,
    confirmation: false,
  },
  app_version: null,
  dashboard_config: null,
  login_stamp: null,

};

const appReducer = createReducer(initialAppState)
  .handleAction(modalMonitor, (state, { payload }) =>
    ({
      ...state,
      modal: {
        ...state.modal,
        ...payload,
      },
    }))
  .handleAction(modalConfirmation, (state, { payload }) =>
    ({
      ...state, modal: { ...state.modal, confirmation: payload.show },
    }))

  .handleAction(updateCredentials, (state) =>
    ({ ...state, login_stamp: +new Date() }))

// load app version
  .handleAction(loadLatestAppVersion.request, (state) =>
    ({ ...state }))
  .handleAction(loadLatestAppVersion.success, (state, { payload }) =>
    ({
      ...state, app_version: payload,
    }))
  .handleAction(loadLatestAppVersion.failure, (state) =>
    ({ ...state }))

// load dashboard config
  .handleAction(loadDashboardConfig.request, (state) =>
    ({ ...state }))
  .handleAction(loadDashboardConfig.success, (state, { payload }) =>
    ({
      ...state,
      dashboard_config: payload,
    }))
  .handleAction(loadDashboardConfig.failure, (state) =>
    ({ ...state }))

// set dashboard config
  .handleAction(setDashboardConfig.request, (state) =>
    ({ ...state }))
  .handleAction(setDashboardConfig.success, (state, { payload }) =>
    ({
      ...state,
      dashboard_config: payload,
    }))
  .handleAction(setDashboardConfig.failure, (state) =>
    ({ ...state }));

export default appReducer;
export type AppState = ReturnType<typeof appReducer>;
