import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ReactComponent as ProgressIcon } from '../../assets/ipchange-icon.svg';
import { UIProps } from '../../types';

type Position = 'right' | 'center' | 'left';

type Color = 'white' | 'dark';

interface TextInProgressAtomProps {
  showProgress?: boolean
  align?: Position
  color?: Color
  children: any
}

export const TextInProgressAtom: FunctionComponent<TextInProgressAtomProps> = (props) => {
  const {
    showProgress,
    color = 'white',
    align = 'center',
    children,
  } = props;

  return (
    <Wrapper
      align={align}
    >
      <span className="progress-content">{children}</span>
      <Progress show={showProgress || false} color={color} />
    </Wrapper>
  );
};

interface WrapperProps {
  align: string;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  text-align: ${(props: WrapperProps) =>
    props?.align};

  .progress-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
interface ProgressProps extends UIProps {
  color?: Color;
  show?: boolean | null;
}

const Progress = styled(ProgressIcon)<ProgressProps>`
  position: absolute;
  margin: auto auto auto 5px;
  right: -28px;
  display: ${(props: ProgressProps) =>
    (!props?.show ? 'none' : 'flex')};
  * {
    fill: ${(props: ProgressProps) =>
    (props?.color === 'white' ? props.theme.colors.white : props.theme.colors.darkBlue)};
  }
`;
