import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import store from '../../core/store/store';
import {
  ConnectionAuthType, ConnectionType, NewConnection, SelectOption, UIProps,
} from '../../types';
import { BaseModalFormParams } from '../../modal';
import { BasicButton } from '../../styles/ui-controls';
import { addNewConnection } from '../../core/store/actions/connections';

interface AddConnectionFormProps extends BaseModalFormParams {

}

const CON_TYPE: SelectOption<ConnectionType>[] = [
  {
    value: 'socks5',
    label: 'socks5',
  },
  {
    value: 'http',
    label: 'http',
  },
];

export const AddConnectionForm: FunctionComponent<AddConnectionFormProps> = ({
  modalParams = null,
}) => {
  const { t } = useTranslation();
  const AUTH_TYPE: SelectOption<ConnectionAuthType>[] = [
    {
      value: 'userpass',
      label: `${t('proxy.userpass')}`,
    },
    {
      value: 'noauth',
      label: `${t('proxy.noauth')}`,
    },
  ];
  const [connectionData, setConnectionData] = useState<NewConnection>({
    description: null,
    auth_type: null,
    expirationTimestamp: +new Date(),
    listen_service: null,
    phoneId: modalParams?.phoneId,
  });
  const [selectedAuth, setSelectedAuth] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    setConnectionData({
      ...connectionData,
      auth_type: selectedAuth?.value,
      listen_service: selectedType?.value,
    });
  }, [selectedType, selectedAuth]);

  return (
    <Wrapper>
      <Select
        value={selectedType}
        onChange={(value) => {
          setSelectedType(value);
        }}
        options={CON_TYPE}
      />
      <Select
        value={selectedAuth}
        onChange={(value) => {
          setSelectedAuth(value);
        }}
        options={AUTH_TYPE}
      />
      <Comment
        placeholder={`${t('connection.anyContent')}`}
        onChange={(e) => {
          setConnectionData({
            ...connectionData,
            description: e.target.value,
          });
        }}
      />
      <BasicButton onClick={() => {
        store.dispatch(addNewConnection.request({ connection: connectionData }));
      }}
      >
        {t('connection.addConnection')}
      </BasicButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`

`;

const Comment = styled.textarea`
 width: 100%;
 min-height: 100px;
 ${(props: UIProps) =>
    props.theme.sizes.font.medium};
`;
