import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import store from '../../../core/store/store';
import {
  updateConnection,
} from '../../../core/store/actions/connections';
import { NotificationStreams, useNotificatorHook } from '../../../hooks';

import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { RotationList } from '../../RotationLIst';
import { CheckBox } from '../../atoms/Checkbox';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';

interface RotationProps {}

export const Rotation: FunctionComponent<RotationProps> = () => {
  const context = useContext(ConnectionEditViewContext);

  const notificator = useNotificatorHook();
  const { t } = useTranslation();

  const [status, setStatus] = useState(context?.selectedPhone.ipChangeEnabled);
  const [rotationValue, setRotationValue] = useState({ value: context?.selectedPhone.ipChangeMinutesInterval, label: '' });

  const updateIpChangeTime = () => {
    store.dispatch(
      updateConnection.request({
        id: context?.selectedPhone.id,
        ipChangeMinutesInterval: rotationValue.value,
      }),
    );
    notificator.notify(
      `${t('notification.changeUpdated')}`,
      NotificationStreams.CONNECTION_EDIT_FORM,
    );
  };

  useEffect(() => {
    setRotationValue(status ? { value: context?.selectedPhone.ipChangeMinutesInterval, label: '' } : { value: 1, label: '' });
  }, []);

  return (
    <Wrapper>
      <CheckBox
        value={status}
        onClick={(status) => {
          setStatus(status);
          if (!status) {
            setRotationValue({ value: 1, label: '' });
          }
          store.dispatch(
            updateConnection.request({
              id: context?.selectedPhone.id,
              ipChangeMinutesInterval: 1,
              ipChangeEnabled: status,
            }),
          );
        }}
        label={t('rotation.enableRotation')}
        hasLabel
      />
      {(!context?.isOwner && !context?.permissions?.rotationSetting) || !status ? null : (
        <RotationList
          value={rotationValue}
          setValue={setRotationValue}
          onRotationChange={updateIpChangeTime}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-top: 32px;
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  .add_url_bt {
    width: 250px;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 100%;
    }
  }
`;
