import React, {
  useContext, useState, useEffect, FunctionComponent,
} from 'react';
import styled from 'styled-components';

import { PhoneDayUptimePanel } from './PhoneDayUptimePanel';
import { UIProps } from '../../../types';

import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { Calendar } from './Calendar';
import ScrollWrapper from '../../common/ScrollWrapper';
import { LoaderIcon } from '../../common/LoaderIcon';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

interface PhoneUptimePanelProps {
  id: string;
  setChosenDayTimestamp: Function;
}

export const PhoneUptimePanel: FunctionComponent<PhoneUptimePanelProps> = (props) => {
  const { id, setChosenDayTimestamp } = props;

  const context = useContext(ConnectionEditViewContext);
  const [dayTimestamp, setDayTimestamp] = useState(+new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChosenDayTimestamp(null);
    setDayTimestamp(+new Date());
  }, []);

  const onChangeTimestamp = (timestamp) => {
    setDayTimestamp(timestamp);
  };

  return (
    <>
      {!context?.isOwner && !context?.permissions?.uptimeView ? null : (
        <Wrapper>
          <Calendar
            currentDayTimestamp={dayTimestamp}
            handleTimestamp={onChangeTimestamp}
          />

          {
            loading
            && (
            <LoaderBlock>
              <LoaderIcon />
              <div>Loading...</div>
            </LoaderBlock>
            )
          }

          <SlotsPlaceholder>
            <ScrollWrapper className="uptime">
              <PhoneDayUptimePanel
                id={id}
                dayTimestamp={dayTimestamp}
                loading={loading}
                setLoading={setLoading}
              />
            </ScrollWrapper>
          </SlotsPlaceholder>
        </Wrapper>
      )}
    </>
  );
};

const SlotsPlaceholder = styled.div`
  height: 385px;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 760px;
  box-sizing: border-box;
  padding: 4px;
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.lightGray};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    max-width: auto;
  }
`;

export const LoaderBlock = styled.div`
  background-color: ${(props: UIProps) =>
    props.theme.colors.lightGray};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;
