import React, {
  useState, FunctionComponent,
} from 'react';
import styled from 'styled-components';
import 'moment/locale/ru';

import { UIProps } from '../../types';
import { ButtonLoader } from './ButtonLoader';

interface CustomeTimeInputAtomProps {
  value?: any
  onChange?: Function
}

export const TimePicker: FunctionComponent<CustomeTimeInputAtomProps> = (props) => {
  const {
    value = new Date(),
    onChange = () => {},
  } = props;

  const [hour, setHour] = useState(value.getHours());
  const [minut, setMinut] = useState(value.getMinutes());

  const HOURS = Array.from({ length: 24 }).map((a, i) =>
    +i);
  const MINUTES = Array.from({ length: 60 }).map((a, i) =>
    +i);

  return (
    <TimePickerContainer>
      <div className="box">
        <div className="item-time">
          <Label>
            Hours
          </Label>
          <HoursOrMinutesContainer>
            {HOURS.map((h) =>
              (
                <HourOrMinut
                  key={h}
                  onClick={() => {
                    setHour(h);
                  }}
                  active={h === hour}
                >
                  {h < 10 ? `0${h}` : h}
                </HourOrMinut>
              ))}
          </HoursOrMinutesContainer>
        </div>
        <div className="item-time">
          <Label>
            Minutes
          </Label>
          <HoursOrMinutesContainer>
            {MINUTES.map((m) =>
              (
                <HourOrMinut
                  key={m}
                  onClick={() => {
                    setMinut(m);
                  }}
                  active={m === minut}
                >
                  {m < 10 ? `0${m}` : m}
                </HourOrMinut>
              ))}
          </HoursOrMinutesContainer>
        </div>
      </div>
      <TimeBlock>
        {hour < 10 ? `0${hour}` : hour}
        :
        {minut < 10 ? `0${minut}` : minut}
      </TimeBlock>
      <div className="button-box">
        <ButtonLoader
          color="greenMud"
          size="small"
          onClick={() => {
            const date = value;
            date.setHours(hour);
            date.setMinutes(minut);
            onChange(date);
          }}
        >
          Ok
        </ButtonLoader>
      </div>
    </TimePickerContainer>
  );
};

const TimePickerContainer = styled.div`
background-color: white;
border: 1px solid
  ${(props: UIProps) =>
    props.theme.colors.darkGray};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

.box {
  display: flex;
  margin-bottom: 0px;
  padding: 10px 10px 0 10px;
}

.item-time {
  width: 90px;
}

.button-box {
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
}
`;

const HoursOrMinutesContainer = styled.div`
${(props: UIProps) =>
    props.theme.sizes.font.medium};
height: 130px;
overflow-y: auto;
-ms-overflow-style: none;
scrollbar-width: none;

::-webkit-scrollbar {
  width: 0;
  height: 0;
}
`;

const Label = styled.div`
text-align: center;
${(props: UIProps) =>
    props.theme.sizes.font.medium};
`;

const TimeBlock = styled.div`
${(props: UIProps) =>
    props.theme.sizes.font.medium};
text-align: center;
background-color: ${(props: UIProps) =>
    props.theme.colors.ocenlight};
padding: 3px 0;
`;

interface HourOrMinutProps extends UIProps {
  active: boolean;
}

const HourOrMinut = styled.div<HourOrMinutProps>`
text-align: center;
cursor: pointer;
padding: 2px 0;
background-color: ${(props: HourOrMinutProps) =>
    (props?.active ? props.theme.colors.deepBlue : '')};
color: ${(props: HourOrMinutProps) =>
    (props?.active ? 'white' : '')};
border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
`;
