import Axios, { AxiosInstance, Method } from 'axios';

import { AnyJson, JsonMap } from '../types';
import { storage } from '../services';

export const API_ENDPOINT = 'https://iproxy.online/api-rt';

class Api {
  private static instance: Api;

  private axios!: AxiosInstance;

  constructor() {
    if (Api.instance) { return; }
    Api.instance = this;
    this.initialize();
  }

  public get = async (url: string, params: AnyJson = null, headers: JsonMap = null, isPublic: boolean = false) =>
    this.request(isPublic ? url : this.addToken(url), params, null, 'get', headers);

  public post = async (url: string, data: AnyJson = null, headers: JsonMap = null, isPublic: boolean = false) =>
    this.request(isPublic ? url : this.addToken(url), null, data, 'post', headers);

  public put = async (url: string, data: AnyJson = null, headers: JsonMap = null, isPublic: boolean = false) =>
    this.request(isPublic ? url : this.addToken(url), null, data, 'put', headers);

  public delete = async (url: string, data: AnyJson = null, headers: JsonMap = null, isPublic: boolean = false) =>
    this.request(isPublic ? url : this.addToken(url), null, data, 'delete', headers);

  public request = async (
    url: string,
    params: AnyJson,
    data: AnyJson,
    method: Method,
    headers: JsonMap,
  ) => {
    if (!this.axios) {
      throw Error('Api module has not initialized');
    }
    const requestConfig: any = {
      method,
      url,
      params,
      data,
      headers: {
        ...headers,
      },
    };
    return this.axios(requestConfig);
  };

  private addToken = (url): string => {
    if (!this.axios) {
      throw Error('Api module has not initialized');
    }
    const token = window.localStorage.getItem('system/token');
    return url?.includes('?') ? `${url}&token=${JSON.parse(token)}` : `${url}?token=${JSON.parse(token)}`;
  };

  private initialize() {
    this.axios = Axios.create({
      baseURL: API_ENDPOINT,
      timeout: 60000,
    });

    this.axios.interceptors.request.use(async (config) => {
      // Do something before request is sent
      const token = await storage.getItem('SYSTEM', 'token');
      const newConfig = { ...config };
      if (config.url.includes('[TOKEN]')) {
        newConfig.url = config.url.replaceAll('[TOKEN]', token as string);
      }
      return newConfig;
    }, (error) =>
    // Do something with request error
      Promise.reject(error));

    this.axios.interceptors.response.use(
      (response) =>
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
        response,
      (error) =>
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
        Promise.reject(error),
    );
  }
}

const api = new Api();
export { api, api as Api };
