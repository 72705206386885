import React, { useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { ExportConnectionProps } from './AdvancedSettings/ExportConnection';
import { Configuration } from './AdvancedSettings/Configuration';
import { Actions } from './AdvancedSettings/Actions';
import { Team } from './AdvancedSettings/Team';

interface AdvancedSettingsFormProps {
}

export const AdvancedSettingsForm: FunctionComponent<AdvancedSettingsFormProps> = () => {
  const { t } = useTranslation();

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'configuration',
      title: `${t('proSettings.configuration')}`,
      component: (<Configuration />),
    },
    {
      key: 'actions',
      title: `${t('proSettings.actions')}`,
      component: (<Actions />),
    },
    {
      key: 'team',
      title: `${t('proSettings.team')}`,
      component: (<Team />),
    },
    {
      key: 'export connections',
      title: 'Connection Export',
      component: (<ExportConnectionProps />),
    },
  ];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Wrapper>
      <ReactTooltip />
      <TabsAtom config={tabsConfig} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
