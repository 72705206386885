import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { InputWrapper } from '../styles/ui-controls';
import { SelectOption, UIProps } from '../types';

import { DropdownElement } from '../elements';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { DashboardConfig } from '../types/dashboard';
import { NON_GROUP, BREAKPOINT_MOBILE } from '../utils/constants';

interface GroupListProps {
  selectedGroup: null | SelectOption<string>;
  onGroupChange: (selectedGroup: SelectOption<string>) => void;
}

export const GroupList: FunctionComponent<GroupListProps> = (props) => {
  const { selectedGroup, onGroupChange } = props;

  const { t } = useTranslation();
  const [groupList, setGroupList] = useState([]);

  const dashboardConfigs: DashboardConfig = useTypedSelector(
    ({ app }) =>
      app?.dashboard_config,
  );

  const setGroupOptions = () => {
    if (
      dashboardConfigs?.configs?.connectionGroups != null
      && dashboardConfigs?.configs?.connectionGroups?.length
    ) {
      const list = dashboardConfigs?.configs?.connectionGroups
        ?.filter(
          (item) =>
            item?.name != null && item?.name !== '' && item?.name !== NON_GROUP,
        )
        .map((item) =>
          ({
            value: item?.name,
            label: item?.name,
          }));

      list.unshift({ label: `${t('connection.noGroup')}`, value: NON_GROUP });

      setGroupList(list);
    }
  };

  useEffect(() => {
    setGroupOptions();
  }, []);

  return (
    <InputContainer>
      <InputWrapper>
        <InputLabel>{t('connection.addGroup')}</InputLabel>
        <DropdownElement
          isSearchable={false}
          value={selectedGroup}
          options={groupList}
          onSelected={onGroupChange}
        />
      </InputWrapper>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const InputLabel = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumMedium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smallerMedium};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }
`;
