import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ProgressBar from '@ramonak/react-progress-bar';
import { BaseModalFormParams } from '../../../modal';
import theme from '../../../styles/ApplicationTheme';
import { Phone, RenamePhone } from '../../../types/phones';
import { UIProps } from '../../../types';

interface ProgressBarComponentProps extends BaseModalFormParams {
  buyCount?: number;
  selectedPhones?: string[] | RenamePhone[] | Phone[];
  absolute?: boolean;
}

export const ProgressBarComponent: FunctionComponent<ProgressBarComponentProps> = (props) => {
  const { buyCount, selectedPhones, absolute } = props;

  return (
    <Wrapper absolute={absolute}>
      <ProgressBar
        completed={+((buyCount / selectedPhones.length) * 100).toFixed(2)}
        animateOnRender
        bgColor={theme.colors.deepBlue}
        margin="0 0 10px 0"
        height="35px"
      />
      <Processing>{`Processing (${buyCount}/${selectedPhones?.length})`}</Processing>
    </Wrapper>
  );
};

interface WrapperProps extends UIProps {
  absolute?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: ${(props: WrapperProps) =>
    props.absolute && 'absolute'};
  width: 100%;
  bottom: ${(props: WrapperProps) =>
    props.absolute && '-21px'};
`;

const Processing = styled.div`
  text-align: center;
`;
