import { debounce } from 'lodash';
import { ToastOptions, toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { Api } from '../core/api';
import { saveToFile } from './core-utils';
import { credentialValues, CredentialFields } from '../components/common/credentials/constants';
import { Phone } from '../types/phones';
import { CopySettings } from '../types/dashboard';
import { TARGET_BLANK, minRelevantAppVersion } from './constants';

export const debounceLoad = debounce((callback) => {
  callback();
}, 300, {
  leading: false,
  trailing: true,
});

export const showNotification = (message, type = 'info') => {
  const options = {
    position: 'top-right',
    autoClose: 1000,
    icon: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'colored',
  } as ToastOptions;

  return type === 'info' ? toast.info(message, options) : toast.error(message, options);
};

export const selectTarget = (currentTarget) => {
  const range = document.createRange();
  range.selectNodeContents(currentTarget);
  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
};

export const $downloadConfig = async (id, name, phoneId, isNumericFormatIP) => {
  try {
    const { data } = await Api.get(
      `/phones/${phoneId}/uconnections/${id}/config${
        isNumericFormatIP ? '?noFqdns=1' : ''
      }`,
    );
    const d = window.atob(data?.config_base64);
    saveToFile(`${name}.ovpn`, d);
  } catch (e) {
    console.error('Error:> [Download oVPN Config] :=', e);
  }
};

export const validateCredentialsFormat = (value: string, setInputError: Function) => {
  const REG_EXP = /(?<!type:)\/\/|[^a-zA-Zа-яА-Я0-9]+/g;
  const LAST_VALUE_REG_EXP = /[@:|/]/;
  const splittedFormat = value.split(REG_EXP).filter(Boolean);
  const delimiters = value.match(REG_EXP) || [];
  let isError = false;

  const finalFormat = [];
  splittedFormat.forEach((element, index) => {
    finalFormat.push(element);

    if (delimiters[index]) finalFormat.push(delimiters[index]);
  });

  finalFormat.forEach((element, index) => {
    const isEvenElement = index % 2 === 0;

    const isValidElement = isEvenElement
      ? !credentialValues.includes(element)
      : element.length !== 1 && element !== '://';

    isError = isValidElement || isError;
  });

  const lastValue = finalFormat[finalFormat.length - 1];

  if (!credentialValues.includes(lastValue) || LAST_VALUE_REG_EXP.test(lastValue)) isError = true;

  if (value.length === 0) isError = false;

  setInputError(isError);
};

export const exportCredentials = async ({
  format,
  phones,
  copySettings,
  isCopy,
  index,
  inputError,
  fileFormat,
}: {
  format: string;
  phones: Phone[];
  copySettings: CopySettings;
  isCopy: boolean;
  index?: number;
  inputError?: boolean;
  fileFormat?: string;
}) => {
  try {
    const filename = `credentials_${+new Date()}`;
    const splittedFormat = format.split(/([a-zA-Z]+)/).filter(Boolean);

    const modifiedFormat = splittedFormat.map((format) => {
      if (format === CredentialFields.IP) return 'address';
      if (format === CredentialFields.TYPE) return 'listen_service';

      return format;
    }).join('');

    const connections = phones.map((value) =>
      value?.connections.map((c) =>
        (c.allow_from ? { ...c, login: '', password: '' } : c))).flat();

    const formattedConnections = connections.map((obj) => {
      let formattedString = modifiedFormat;

      Object.keys(obj).forEach((key) => {
        const value = obj[key] || '-';

        formattedString = key === 'name'
          ? formattedString
          : formattedString.replace(new RegExp(key, 'g'), value);
      });

      return isCopy ? formattedString : { [obj.phoneId]: formattedString };
    });

    if (index !== undefined) {
      return copy(formattedConnections[index] as string);
    }

    const modifiedPhones = phones.map((value) =>
      ({
        [value.id]: {
          changeIpLinks: value?.changeIPKeys?.map((key) =>
            `https://i.fxdx.in/api-rt/changeip/${value.id}/${key}`),
          autoIpRotation: value.ipChangeMinutesInterval,
        },
      }));

    const credentials = formattedConnections.map((item) => {
      const isCustomCredentials = copySettings.ipChangeLink
        || copySettings.ipChangeLinkInFormat
        || copySettings.autoIPRotation;
      const isAutoIpRotationLineBreak = !copySettings.ipChangeLinkInFormat ? '\n' : '';
      const isIpChangeLinkLineBreak = !copySettings.ipChangeLinkInFormat && !copySettings.autoIPRotation ? '\n' : '';

      const [connectionKey, commonCredentials] = isCopy ? [null, item] : Object.entries(item)[0];
      let customCredentials = commonCredentials;

      const modifiedPhone = modifiedPhones.find(
        (item) =>
          isCopy || Object.keys(item)[0] === connectionKey,
      );

      if (modifiedPhone) {
        const { changeIpLinks, autoIpRotation } = Object.values(modifiedPhone)[0];

        if (copySettings.ipChangeLinkInFormat) {
          const lastUrlValue = changeIpLinks[changeIpLinks.length - 1];
          customCredentials += `[${lastUrlValue}]\n`;
        }

        if (copySettings.autoIPRotation) {
          const autoIpRotationValue = autoIpRotation ? `Auto IP Rotation: ${autoIpRotation} min.` : 'Auto IP Rotation: off';
          customCredentials += `${isAutoIpRotationLineBreak}${autoIpRotationValue}\n`;
        }

        if (copySettings.ipChangeLink) {
          const links = changeIpLinks.map((value) =>
            `${value}\n`).join('');
          customCredentials += `${isIpChangeLinkLineBreak}${links}`;
        }
      }

      return isCustomCredentials ? `${customCredentials}\n` : `${commonCredentials}\n`;
    }).join('\n').replace(/,/g, '');

    const result = `Here are your login credentials:\n${credentials}`;

    if (!isCopy && !inputError && format.length !== 0) {
      return saveToFile(filename, result, fileFormat);
    }

    return copy(result);
  } catch (e) {
    console.error('Error:> Export credentials error: :=', e);
  }
  return null;
};

export const getExpiredPhoneConnections = (phoneConnections: Phone[]) =>
  phoneConnections?.filter((item) =>
    moment().isSameOrAfter(item?.expDate));

export const windowOpen = (url: string) =>
  window.open(url, TARGET_BLANK, 'noopener,noreferrer');

export const getRelevantVersion = (appVersion: string) =>
  Number(appVersion) >= minRelevantAppVersion;
