import React, {
  useState, useEffect, FunctionComponent, useMemo,
} from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { InputLabel, InputWrapper } from '../../../styles/ui-controls';
import { getNumEditing } from '../../../utils';
import { Plan, UIProps } from '../../../types';
import { Api } from '../../../core/api';
import store from '../../../core/store/store';
import { changeTariffPlan } from '../../../core/store/actions/connections';
import { NotificationStreams, useNotificatorHook } from '../../../hooks';
import { TariffButton, DetailsHeadline } from './TariffCommon';
import { analyticsClassnames } from '../../../utils/analytics-utils';
import { LoadingProgress } from '../../common/LoadingProgress';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import theme from '../../../styles/ApplicationTheme';

interface TariffUpdateProps {
  selectedTariff: Plan,
  selectedPhone,
  phoneTariff,
  goBack: Function
}

interface ErrorData {
  proxiesDiff?: number;
  changeIpKeysDiff?: number;
  ovpnConfigsDiff?: number;
}

const $getSwitchDaysCalculation = async (phoneId, planId) => {
  const { data } = await Api.get(`/phone/${phoneId}/estimate-plan-transition/${planId}`, null, null, false);
  return data;
};

export const TariffUpdate: FunctionComponent<TariffUpdateProps> = (props) => {
  const {
    selectedTariff,
    phoneTariff,
    selectedPhone,
    goBack = () => {},
  } = props;
  const { t, i18n } = useTranslation();
  const [newTariffPeriod, setNewTariffPeriod] = useState(null);
  const [processingRequest, setProcessingRequest] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const [errorData, setErrorData] = useState<ErrorData>({});
  const notificator = useNotificatorHook();

  const selectedTariffName = i18n.language === 'ru' ? selectedTariff?.ruTitle : selectedTariff?.enTitle;

  const daysToPriceEq = useMemo(() => {
    const daysLeft = moment(selectedPhone?.activePlans[0]?.expirationTimestamp).diff(moment(+new Date()), 'days', true);
    const r = Math.round(((phoneTariff?.usdPrice ?? 0) / 30) * Math.floor(daysLeft));
    return r < 0 ? 0 : r;
  }, [selectedTariff, selectedPhone, phoneTariff]);

  useEffect(() => {
    $getSwitchDaysCalculation(selectedPhone?.id, selectedTariff?.id).then(({ result }) => {
      setNewTariffPeriod(result);
    });
  }, [selectedTariff, selectedPhone]);

  const $updateTariff = () => {
    if (!processingRequest) {
      setProcessingRequest(true);
      store.dispatch(changeTariffPlan.request({
        phoneId: selectedPhone?.id,
        planId: selectedTariff?.id,
        callback: (data) => {
          if (data?.success) {
            setTimeout(() => {
              setProcessingRequest(false);
              notificator.notify(`${t('notification.planSwitch', {
                phoneName: `${selectedPhone?.name}`,
                tariffName: `${selectedTariffName}`,
              })}`, NotificationStreams.CONNECTION_EDIT_FORM);
              goBack();
            }, 1200);
            return;
          }

          const { code, errorData } = data.response.data;
          if (code === 'PLAN_SWITCH_ERROR') {
            setShowValidationError(true);
            setErrorData(errorData);
            setProcessingRequest(false);
          }
        },
      }));
    }
  };

  return (
    <Wrapper>
      <DetailsHeadline>
        <div
          className="goback"
          onClick={() =>
            goBack()}
        >
          {t('editForm.goBack')}
        </div>
        <div className="title">{t('tariff.switchPlan', { plan: `«${selectedTariffName}»` })}</div>
      </DetailsHeadline>
      <DetailsContainer hasCoupon={false}>
        <InputWrapper>
          <InputLabel>{t('tariff.remains', { phone: '' })}</InputLabel>
          <div className="price">
            {t('wallet.rub')}
            {daysToPriceEq}
          </div>
        </InputWrapper>
        <InputWrapper>
          <InputLabel>{t('tariff.equivalent', { plan: `«${selectedTariffName}»` })}</InputLabel>
          <div
            className="price locked-size"
          >
            {
              newTariffPeriod == null
                ? (<div className="loading-period"><LoadingProgress color={theme.colors.deepBlue} /></div>)
                : `${newTariffPeriod?.daysNumber} ${getNumEditing(newTariffPeriod?.daysNumber, i18n.language, 'days')}`
            }
          </div>
        </InputWrapper>
        <TariffButton
          isActive
          className={`${analyticsClassnames
            .switchTariffPrefix}_${selectedTariff.enTitle.replace(' ', '')}_${analyticsClassnames
            .switchTariffPostfix}`}
          onClick={() =>
            $updateTariff()}
        >
          <ButtonWrapper>
            {processingRequest && <LoadingProgress color={theme.colors.white} />}
            {t('tariff.switchTo', { plan: `«${selectedTariffName}»` })}
          </ButtonWrapper>
        </TariffButton>
        {
          showValidationError
          && (
          <ErrorWrapper>
            <ValidationError>
              {t('tariff.switchPlanError.unable', {
                tariff: selectedTariffName,
              })}
            </ValidationError>
            <ValidationError>
              {t('tariff.switchPlanError.reduce')}
            </ValidationError>
            {
              errorData?.proxiesDiff
              && (
              <ValidationError>
                {t('tariff.switchPlanError.proxies', {
                  count: errorData?.proxiesDiff,
                })}
              </ValidationError>
              )
            }
            {
              errorData?.changeIpKeysDiff
              && (
              <ValidationError>
                {t('tariff.switchPlanError.changeIp', {
                  count: errorData?.changeIpKeysDiff,
                })}
              </ValidationError>
              )
            }
            {
              errorData?.ovpnConfigsDiff
              && (
              <ValidationError>
                {t('tariff.switchPlanError.configs', {
                  count: errorData?.ovpnConfigsDiff,
                })}
              </ValidationError>
              )
            }
          </ErrorWrapper>
          )
        }
        <ReactTooltip />
      </DetailsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`

`;

interface DetailsContainerProps extends UIProps {
  hasCoupon: boolean
}

const DetailsContainer = styled.div<DetailsContainerProps>`
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: flex;
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .price {
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumSemibold};
    text-decoration: ${(props: DetailsContainerProps) =>
    (props.hasCoupon ? 'line-through' : 'none')};
    color: ${(props: DetailsContainerProps) =>
    (props.hasCoupon ? props.theme.colors.darkGray : props.theme.colors.dark)};
  }
`;

const ValidationError = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
`;

const ErrorWrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;
