import React, {
  useState,
  FunctionComponent,
  useContext,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ReactFlagsSelect from 'react-flags-select';
import { Api, API_ENDPOINT } from '../../../core/api';
import { NotificationStreams, useNotificatorHook } from '../../../hooks';

import { InputField } from '../../../elements';
import { ButtonLoader } from '../../atoms';
import {
  InputWrapper,
  InputLabel,
  VideoInstructionBlock,
} from '../../../styles/ui-controls';
import { COUNTRY_MAP } from '../../../constants';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { getSMSBotInstructionLink } from '../../../utils/instruction-links';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';
import { windowOpen } from '../../../utils';

interface SendProps {
  phoneId?: string;
  hasSmsPermission?: boolean;
}

export const Send: FunctionComponent<SendProps> = (props) => {
  const { hasSmsPermission = true, phoneId } = props;
  const [command, setCommand] = useState(null);
  const notificator = useNotificatorHook();
  const context = useContext(ConnectionEditViewContext);
  const { t, i18n } = useTranslation();

  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [sms, setSms] = useState(null);

  const config = COUNTRY_MAP?.reduce((acc, i) =>
    ({
      ...acc,
      [i?.code]: { primary: i?.code, secondary: i?.phoneCode, phoneCode: i?.phoneCode },
    }), {});

  return (
    <>
      {!context?.isOwner && !context?.permissions?.smsControl ? null : (
        <>
          <Wrapper>
            <InputContainer>
              <InlineBlock>
                <VideoInstructionBlock
                  onClick={() =>
                    windowOpen(getSMSBotInstructionLink(i18n.language))}
                >
                  {t('howSetup')}
                </VideoInstructionBlock>
              </InlineBlock>
              <InputWrapper>
                <InputLabel>
                  <Ussd>{t('onboarding.sms.ussd')}</Ussd>
                </InputLabel>
                <InputField
                  value={command}
                  disabled={!hasSmsPermission}
                  floatLabel
                  placeholder={t('onboarding.sms.ussd')}
                  onChange={({ target }) =>
                    setCommand(target?.value)}
                />
              </InputWrapper>
              <ButtonLoader
                size="small"
                disabled={!hasSmsPermission}
                onClick={async () => {
                  if (hasSmsPermission) {
                    await Api.post(
                      `${API_ENDPOINT}/sms/send-ussd-push`,
                      {
                        phoneId,
                        ussd: command,
                      },
                      null,
                      false,
                    );
                    notificator.notify(
                      `${t('notification.sms')}`,
                      NotificationStreams.CONNECTION_EDIT_FORM,
                    );
                  }
                }}
              >
                Send
              </ButtonLoader>
            </InputContainer>
            <SmsContainer>
              <InputLabel>
                <Ussd>SMS</Ussd>
              </InputLabel>
              <InputField
                disabled={!hasSmsPermission}
                value={sms}
                floatLabel
                onChange={({ target }) =>
                  setSms(target?.value)}
              />
              <NumberContainer>
                <ReactFlagsSelect
                  className="flags"
                  searchable
                  disabled={!hasSmsPermission}
                  fullWidth={false}
                  placeholder="-"
                  showOptionLabel
                  onSelect={(code) =>
                    setCode(code)}
                  selected={code}
                  customLabels={config}
                />
                <InputField
                  value={phone?.replace(/(\d{3})/g, '$1 ')}
                  disabled={code === '' || code === undefined}
                  placeholder="00 000 000"
                  onChange={({ target }) => {
                    setPhone((phone.length > 0 && phone.length % 3 === 0 && phone.length
                    === target.value.replaceAll(' ', '').length)
                      ? target.value.slice(0, target.value.length - 1).replace(/\D/g, '') : target.value.replace(/\D/g, ''));
                  }}
                />
              </NumberContainer>
              <ButtonLoader
                size="small"
                disabled={!sms || !phone}
                onClick={async () => {
                  if (!!sms && !!phone) {
                    await Api.post(
                      `${API_ENDPOINT}/sms/send-sms-push`,
                      {
                        phoneId,
                        text: sms,
                        phoneNumber: config[code].phoneCode + phone,
                      },
                      null,
                      false,
                    );
                    notificator.notify(
                      `${t('notification.sms')}`,
                      NotificationStreams.CONNECTION_EDIT_FORM,
                    );
                  }
                }}
              >
                Send
              </ButtonLoader>
            </SmsContainer>
          </Wrapper>
        </>
      )}
    </>
  );
};

const Ussd = styled.span`
  font-weight: 500;
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const Wrapper = styled.div``;

const InlineBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  .flags{
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    #rfs-btn{
      width: 150px;
    }
  }
`;

const SmsContainer = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;
