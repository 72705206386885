import React, { useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import { Notifications } from './TelegramBot/Notifications';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { About } from './TelegramBot/About';

interface TelegramBotFormProps {
}

export const TelegramBotForm: FunctionComponent<TelegramBotFormProps> = () => {
  const { t } = useTranslation();

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'about',
      title: t('connection.about'),
      component: (<About />),
    },
    {
      key: 'notifications',
      title: t('bot.subTitle'),
      component: (<Notifications />),
    },
  ];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Wrapper>
      <ReactTooltip />
      <TabsAtom config={tabsConfig} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
