import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UIProps } from '../../types';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import {
  getDevicesLink,
  getSellProxiesLink,
  getGuideForSettingUpProxiesLink,
} from '../../utils/instruction-links';
import { BREAKPOINT_MOBILE, telegramSupport } from '../../utils/constants';
import { windowOpen } from '../../utils';

interface InformationMenuProps {}

export const InformationMenu: FunctionComponent<InformationMenuProps> = () => {
  const connectionsStore = useTypedSelector(({ connections }) =>
    connections);
  const [loadingPhones, setLoadingPhones] = useState(true);
  useEffect(() => {
    setLoadingPhones(connectionsStore?.data == null);
  }, [connectionsStore]);

  const { t, i18n } = useTranslation();

  return !loadingPhones ? (
    <InformationBlock>
      <InformationItem
        onClick={() =>
          windowOpen(getSellProxiesLink(i18n.language))}
      >
        {t('information.phoneSetup')}
      </InformationItem>
      <InformationItem
        onClick={() =>
          windowOpen(getGuideForSettingUpProxiesLink(i18n.language))}
      >
        {t('information.proxySetup')}
      </InformationItem>
      <InformationItem
        onClick={() =>
          windowOpen(getDevicesLink(i18n.language))}
      >
        {t('information.recommendedDevices')}
      </InformationItem>
      <InformationItem
        onClick={() =>
          windowOpen(telegramSupport)}
      >
        {t('information.moreQuestions')}
      </InformationItem>
    </InformationBlock>
  ) : null;
};

const InformationBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0 10px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    margin: 10px 5px;
  }
`;

const InformationItem = styled.div`
  width: 100%;
  background-color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
  color: ${(prop: UIProps) =>
    prop.theme.colors.white};
  cursor: pointer;
  padding: 10px 20px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
  min-height: 55px;
  box-sizing: border-box;
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  transition: 0.3s;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    min-height: 0;
    padding: 11px 24px;
    margin: 0 0 10px;

    &:last-child {
      margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }

  :hover {
    opacity: 0.9;
  }

  :last-child {
    margin-right: 0px;
  }
`;
