export function useLoaderHook() {
  return {
    show: () => {
      document.dispatchEvent(new CustomEvent('loaderEvent', {
        detail: {
          status: true,
        },
      }));
    },
    hide: () => {
      document.dispatchEvent(new CustomEvent('loaderEvent', {
        detail: {
          status: false,
        },
      }));
    },
    listen: (callback) => {
      document.addEventListener('loaderEvent', callback);
    },
    removeEvent: () =>
      document.removeEventListener('loaderEvent', () => {}),
  };
}
