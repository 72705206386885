import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { showNotification } from '../utils';
import {
  Wrapper,
  ContentLayer,
  Text,
  CloseButton,
} from '../styles/system-banners';
import theme from '../styles/ApplicationTheme';
import { PromocodeBannerContext } from '../context/PromocodeBannerContext';

export const PromocodeBanner = () => {
  const { t } = useTranslation();
  const {
    promocode,
    closePromocodeBanner,
  } = React.useContext(PromocodeBannerContext);

  return (
    <Wrapper background={theme.colors.greenMud}>
      <ContentLayer>
        <Container>
          <Text>
            {`${t('promocodeBanner.text.1')} `}
            <Code
              onClick={() => {
                showNotification(`${t('notification.promocodeCopiedSuccessfully')}`);
                copy(promocode?.code?.toUpperCase());
              }}
            >
              {promocode?.code?.toUpperCase()}
            </Code>
            {` ${t('promocodeBanner.text.2')}`}
          </Text>

          <ButtonsWrapper>
            <CloseButton
              onClick={() => {
                closePromocodeBanner();
              }}
            />
          </ButtonsWrapper>
        </Container>
      </ContentLayer>
    </Wrapper>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Code = styled.span`
  font-weight: 600;
  transition: opacity 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
