import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NON_GROUP } from '../utils/constants';

export const ConnectionEditViewContext = React.createContext<any | null>(null);

const generateGroupConfigList = (connectionGroups, translate) => {
  if (connectionGroups == null || !connectionGroups?.length) {
    return [];
  }
  const list = connectionGroups
    ?.filter(
      (item) =>
        item?.name != null && item?.name !== '' && item?.name !== NON_GROUP,
    )
    .map((item) =>
      ({
        value: item?.name,
        label: item?.name,
      }));
  list.unshift({
    value: null,
    label: translate('connection.noGroup'),
  });

  return list;
};

const getPhoneGroupConfig = (phoneId, groupConfig, translate) => {
  const group = groupConfig
    ?.filter((item) =>
      item?.name !== NON_GROUP)
    ?.find((item) =>
      item?.connections.includes(phoneId));
  return {
    value: group != null ? group?.name : null,
    label: group != null ? group?.name : translate('connection.noGroup'),
  };
};

export function ConnectionEditViewProvider({ children, ...props }: any) {
  const {
    selectedPhone,
    isOwner = false,
    dashboardConfig,
    permissions,
  } = props;

  const { t } = useTranslation();

  const [groupConfig, setGroupConfig] = useState([]);
  const [phoneGroupConfig, setPhoneGroupConfig] = useState(null);

  useEffect(() => {
    setGroupConfig(
      generateGroupConfigList(dashboardConfig?.connectionGroups, t),
    );
    setPhoneGroupConfig(
      getPhoneGroupConfig(
        selectedPhone?.id,
        dashboardConfig?.connectionGroups,
        t,
      ),
    );
  }, [dashboardConfig, selectedPhone]);

  const initialContextState = useMemo(
    () =>
      ({
        selectedPhone,
        groupConfig,
        phoneGroupConfig,
        dashboardConfig,
        isOwner,
        permissions,
      }),
    [selectedPhone, groupConfig, isOwner, permissions],
  );

  return (
    <ConnectionEditViewContext.Provider value={initialContextState as any}>
      {children}
    </ConnectionEditViewContext.Provider>
  );
}
