import React, { useState, useEffect, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { DropdownElement } from '../elements';
import {
  BasicButton,
  InputLabel,
  WrapperScreen,
} from '../styles/ui-controls';
import store from '../core/store/store';
import { loadUserBalance, loadUserTransaction } from '../core/store/actions/user';
import ScrollWrapper from '../components/common/ScrollWrapper';
import { TransactionsContainer, TransactionsRow, ScreenInfo } from './styles';
import { dateFormats, BREAKPOINT_MOBILE } from '../utils/constants';
import { formatDate } from '../utils/formatter-utils';
import { UIProps } from '../types';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const FilterContainer = styled.div`
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.midsmallZero};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    align-items: start;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

    .description {
      ${(props: UIProps) =>
    props.theme.sizes.font.medium};
    }

    div {
      ${(props: UIProps) =>
    props.theme.sizes.font.small};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  div {
    margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

export interface TransactionScreenProps {}

export const TransactionScreen: FunctionComponent<TransactionScreenProps> = () => {
  const { t } = useTranslation();

  const { transactions, profile } = useTypedSelector(({ user }) =>
    user);
  const connections = useTypedSelector(({ connections }) =>
    connections.data);
  const [selected, setSelected] = useState(null);
  const [count, setCount] = useState<number>(1);
  const options = [
    {
      value: null,
      label: `${t('transaction.all')}`,
    },
    {
      value: 'PAYMENT',
      label: `${t('transaction.payment')}`,
    },
    {
      value: 'REPLENISHMENT',
      label: `${t('transaction.deposit')}`,
    },
  ];

  useEffect(() => {
    setSelected(options[0]);
    store.dispatch(loadUserBalance.request({ userId: profile?.id }));
    store.dispatch(loadUserTransaction.request(null, null));

    setCount(1);
  }, []);

  const renderRowMsg = (item, phone) => {
    if (item?.type === 'PAYMENT') {
      return (
        <div>
          {item?.description?.replace('Малютка', 'Baby')}
          {' '}
          <span className="phone-name">
            «
            {`${phone?.name || 'Phone was deleted'}`}
            »
          </span>
        </div>
      );
    } if (item?.type === 'REPLENISHMENT') {
      const info = item?.description ? item.description.slice(
        item.description.indexOf('"') + 1,
        item.description.lastIndexOf('"'),
      ) : null;
      return (
        <div>
          <span className="phone-name">
            {item.payment_system
              ? t('transaction.replenishment') + item.payment_system
              : `${t('transaction.refund')}«${info || '---'}»`}
          </span>
        </div>
      );
    }
    return t('transaction.depositDetail');
  };

  return (
    <WrapperScreen>
      <ScreenInfo
        marginTop="25px"
      >
        <div className="title">{t('transaction.title')}</div>
      </ScreenInfo>
      <FilterContainer>
        <div className="description">{t('transaction.activity')}</div>
        <Wrapper>
          <InputLabel>{t('transaction.onlyShow')}</InputLabel>
          <DropdownElement
            isSearchable={false}
            value={selected}
            onSelected={(value) => {
              setSelected(value);
              setCount(1);
            }}
            options={options}
          />
        </Wrapper>
      </FilterContainer>

      <ScrollWrapper>
        <TransactionsContainer
          minWidth="1000px"
          paddingRight="15px"
        >
          {transactions?.data
            ?.sort((a, b) =>
              b.timestamp - a.timestamp)
            ?.filter((item) =>
              (selected?.value != null ? item?.type === selected.value : item))
            ?.slice(0, 100 * count)
            ?.map((item) => {
              const phone = connections?.find((i) =>
                i?.id === item?.phoneId);
              renderRowMsg(item, phone);

              return (
                <TransactionsRow key={item?.id} isNegative={item?.type === 'PAYMENT'}>
                  <div className="right">
                    <div className="title">
                      <div>{renderRowMsg(item, phone)}</div>
                    </div>
                  </div>

                  <div className="left">
                    <div className="time">
                      {formatDate(item?.updatedTimestamp, dateFormats.dateTimeSec)}
                    </div>
                    <div className="amount">
                      {`${item?.type === 'PAYMENT' ? '-' : '+'} ${t('wallet.RUB')}${item?.amount}`}
                    </div>
                  </div>
                </TransactionsRow>
              );
            })}
        </TransactionsContainer>
        <ButtonWrapper>
          {Math.ceil(transactions?.data
            ? transactions.data.filter((item) =>
              (selected?.value != null
                ? item.type === selected.value : item)).length : 0 / 100)
            > count && (
              <BasicButton
                size="small"
                onClick={() =>
                  setCount(count + 1)}
              >
                {t('transaction.showMore')}
              </BasicButton>
          )}
        </ButtonWrapper>
      </ScrollWrapper>
    </WrapperScreen>
  );
};
