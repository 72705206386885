import React, { useState, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { BaseModalFormParams } from '../../../modal';
import { PhonesScrollList } from '../../PhonesScrollList';
import { useModalHook } from '../../../hooks';
import {
  InputLabel,
  InputWrapper,
  WrapperScreen,
} from '../../../styles/ui-controls';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { getLogsWeeks, LogsWeek, showNotification } from '../../../utils';
import { FormContainer, Wrapper } from './styles';
import { DropdownElement } from '../../../elements';
import { SelectOption } from '../../../types';
import { ProgressBarComponent } from './progress-bar-component';

interface MultiDownloadLogsProps extends BaseModalFormParams {}

export const MultiDownloadLogs: FunctionComponent<MultiDownloadLogsProps> = (
  props,
) => {
  const { modalParams } = props;

  const periodOptions = getLogsWeeks().map((item) =>
    ({
      value: item,
      label: item.label,
    }));

  const modalOptions = useModalHook();
  const { t } = useTranslation();
  const phones = useTypedSelector(({ connections }) =>
    connections);
  const plans = useTypedSelector(({ plans }) =>
    plans?.data);

  const [selectedPhones, setSelectedPhones] = useState<string[]>(null);
  const [period, setPeriod] = useState<SelectOption<LogsWeek>>(
    periodOptions[0],
  );
  const [progressView, setProgressView] = useState(false);
  const [progressBarLength, setProgressBarLength] = useState(1);
  const [buyCount, setBuyCount] = useState(0);

  const submitHandler = (phonesId: string[]) => {
    setSelectedPhones(phonesId);
  };

  useEffect(() => {
    if (selectedPhones != null) {
      const filteredPhones = selectedPhones.filter((id) => {
        const phone = phones?.data.find((sp) =>
          sp.id === id);
        const plan = plans?.find((p) =>
          p.id === phone?.activePlans[0]?.id);

        return (
          moment(period.value.to)?.isSameOrAfter(phone?.timestamp)
          && (plan?.logsVisibleWeeksNumber || 1) > period.value.index
        );
      });

      setProgressBarLength(filteredPhones.length);
      setProgressView(true);

      filteredPhones.forEach((id, i) => {
        setTimeout(() => {
          period.value.download(id);
          setBuyCount(i + 1);

          if (i === filteredPhones.length - 1) {
            setTimeout(() => {
              modalOptions.hide();
              showNotification(`${t('connection.commandHasBeenExecuted')}`);
            }, 200);
          }
        }, 1000 * i);
      });
    }
  }, [selectedPhones]);

  return (
    <WrapperScreen>
      <Wrapper>
        <FormContainer marginBottom="100px" marginBottomAdaptive="100px">
          <div className="info">
            <div className="title">{t('logs.download')}</div>
          </div>
          <PhonesScrollList
            isValid={!selectedPhones}
            phonesId={
              modalParams?.selectedPhones !== null
                ? modalParams?.selectedPhones
                : []
            }
            actionText={t('logs.download')}
            onActionSubmit={submitHandler}
            additionalBlock={(
              <div className="additional-block">
                <InputWrapper>
                  <InputLabel>{t('tariff.period')}</InputLabel>
                  <DropdownElement
                    isSearchable={false}
                    value={period}
                    options={periodOptions}
                    onSelected={setPeriod}
                  />
                </InputWrapper>
              </div>
            )}
          />
        </FormContainer>
      </Wrapper>
      {progressView && (
        <ProgressBarComponent
          buyCount={buyCount}
          selectedPhones={Array.from({ length: progressBarLength }).fill('')}
        />
      )}
    </WrapperScreen>
  );
};
