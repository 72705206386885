import styled from 'styled-components';
import { UIProps } from '../../types';

interface ModalTitleProps extends UIProps {
  fontSize: string;
}

export const ModalTitle = styled.div<ModalTitleProps>`
    color: ${(prop) =>
    prop.theme.colors.deepBlue};
    ${(prop) =>
    prop.theme.sizes.font[prop.fontSize]};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;
