import { createAction, createAsyncAction } from 'typesafe-actions';

import { ModalStore } from '../../../types';
import { DashboardConfig, DConfig } from '../../../types/dashboard';

const IDLE = 'app/IDLE';
const HI = 'app/HI';
const APP_INIT_ATHORIZED = 'app/APP_INIT_ATHORIZED';

export const idle = createAction(IDLE)<undefined>();
export const initAuthorizedApp = createAction(APP_INIT_ATHORIZED)<undefined>();
export const hi = createAction(HI)<undefined>();

const MODAL_MONITOR = 'app/MODAL_MONITOR';
export const modalMonitor = createAction(MODAL_MONITOR)<ModalStore>();

const SET_SYSTEM_LANGUAGE = 'app/SET_SYSTEM_LANGUAGE';
export const setSystemLanguage = createAction(SET_SYSTEM_LANGUAGE)<string>();

const MODAL_CONFIRMATION = 'app/MODAL_CONFIRMATION';
export const modalConfirmation = createAction(MODAL_CONFIRMATION)<{ show: boolean }>();

const UPDATE_AUTH_CRED = 'app/UPDATE_AUTH';
export const updateCredentials = createAction(UPDATE_AUTH_CRED)<{ show: boolean }>();

const HIDE_PRELOADER = 'app/HIDE_PRELOADER';
export const hidePeloader = createAction(HIDE_PRELOADER)<undefined>();

const SET_PHONES_GROUP = 'app/SET_PHONES_GROUP';
const SET_PHONES_GROUP_SUCCESS = 'app/SET_PHONES_GROUP_SUCCESS';
const SET_PHONES_GROUP_FAILURE = 'app/SET_PHONES_GROUP_FAILURE';

export const updatePhonesGroup = createAsyncAction(
  SET_PHONES_GROUP,
  SET_PHONES_GROUP_SUCCESS,
  SET_PHONES_GROUP_FAILURE,
)<{ phonesId: string[], groupId: string, callback?: Function }>();

const REMOVE_PHONES_GROUP = 'app/REMOVE_PHONES_GROUP';
const REMOVE_PHONES_GROUP_SUCCESS = 'app/REMOVE_PHONES_GROUP_SUCCESS';
const REMOVE_PHONES_GROUP_FAILURE = 'app/REMOVE_PHONES_GROUP_FAILURE';

export const removePhonesGroup = createAsyncAction(
  REMOVE_PHONES_GROUP,
  REMOVE_PHONES_GROUP_SUCCESS,
  REMOVE_PHONES_GROUP_FAILURE,
)<{ phonesId: string[], callback?: Function }>();

const GET_LATEST_APP_VERSION = 'app/GET_LATEST_APP_VERSION';
const GET_LATEST_APP_VERSION_SUCCESS = 'app/GET_LATEST_APP_VERSION_SUCCESS';
const GET_LATEST_APP_VERSION_FAILURE = 'app/GET_LATEST_APP_VERSION_FAILURE';

export const loadLatestAppVersion = createAsyncAction(
  GET_LATEST_APP_VERSION,
  GET_LATEST_APP_VERSION_SUCCESS,
  GET_LATEST_APP_VERSION_FAILURE,
)<null, string, Error>();

const GET_DASHBOARD_CONFIG = 'app/GET_DASHBOARD_CONFIG';
const GET_DASHBOARD_CONFIG_SUCCESS = 'app/GET_DASHBOARD_CONFIG_SUCCESS';
const GET_DASHBOARD_CONFIG_FAILURE = 'app/GET_DASHBOARD_CONFIG_FAILURE';

export const loadDashboardConfig = createAsyncAction(
  GET_DASHBOARD_CONFIG,
  GET_DASHBOARD_CONFIG_SUCCESS,
  GET_DASHBOARD_CONFIG_FAILURE,
)<null, DashboardConfig, Error>();

const SET_DASHBOARD_CONFIG = 'app/SET_DASHBOARD_CONFIG';
const SET_DASHBOARD_CONFIG_SUCCESS = 'app/SET_DASHBOARD_CONFIG_SUCCESS';
const SET_DASHBOARD_CONFIG_FAILURE = 'app/SET_DASHBOARD_CONFIG_FAILURE';

export const setDashboardConfig = createAsyncAction(
  SET_DASHBOARD_CONFIG,
  SET_DASHBOARD_CONFIG_SUCCESS,
  SET_DASHBOARD_CONFIG_FAILURE,
)<{ configs: DConfig, callback?: Function }, DashboardConfig, Error>();
