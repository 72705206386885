import React, {
  useState, useEffect, FunctionComponent, useContext,
} from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { TooltipElement } from '../../elements';

import { ConnectionsList } from './BasicSettings/ConnectionsList';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { ConnectionEditViewContext } from '../../context/ConnectionEditViewContext';
import { BasicPhoneSettings } from './BasicSettings/BasicPhoneSettings';
import { Auth } from './BasicSettings/Auth';
import { WarningLabel } from '../../elements/warning-label';

interface PrimarySettingsFormProps {

}

export const PrimarySettingsForm: FunctionComponent<PrimarySettingsFormProps> = () => {
  const context = useContext(ConnectionEditViewContext);
  const [saveIndicator, setSaveIndicator] = useState(false);
  const { t } = useTranslation();
  const [isAuth, setIsAuth] = useState(false);
  const saving = debounce(
    () => {
      setTimeout(() =>
        setSaveIndicator(false), 1500);
    },
    1000,
    {
      leading: true,
      trailing: false,
    },
  );

  useEffect(() => {
    if (saveIndicator) {
      saving();
    }
  }, [saveIndicator]);

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'connections',
      title: `${t('proxy.title')}`,
      component: (
        <ConnectionsList
          isOwner={context?.isOwner}
          phone={context?.selectedPhone}
        />
      ),
    },
    {
      key: 'auth',
      title: `${t('proxy.authorization')}`,
      component: (
        <Auth
          isPhoneOwner={context?.isOwner}
          phoneId={context?.selectedPhone?.id}
        />
      ),
    },
    {
      key: 'basic_settings',
      title: `${t('connection.title')}`,
      component: (
        <BasicPhoneSettings isPhoneOwner={context?.isOwner} />
      ),
    },
  ];

  const onChangeAuthTab = (tab) => {
    if (tab === 'auth') {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  };

  return context?.selectedPhone != null ? (
    <Wrapper>
      {
        isAuth
        && <WarningLabel message="editForm.temporary" />
      }
      <TooltipElement />
      <TabsAtom
        config={tabsConfig}
        handleTabWithWarning={onChangeAuthTab}
        onTabChange={() => {

        }}
      />
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  height: 100%;
`;
