type SortedData = {
  id: string;
  sortIndex: number;
  content: any;
};

type GetData = {
  sortedData: SortedData[];
  dataForSave: string[];
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const formatDnDList = (data): SortedData[] =>
  data?.map((d, i) =>
    ({
      id: `dnd-item-${i}`,
      sortIndex: i,
      content: d,
    }));

export const getData = (result: any, phones: SortedData[], connections: boolean): GetData => {
  if (!result.destination) {
    return null;
  }

  const sortedData: any = reorder(
    phones,
    result.source.index,
    result.destination.index,
  );

  const dataForSave = sortedData?.map((item: any, i) => {
    if (connections) {
      const { content } = item;
      return content?.id;
    }
    return {
      ...item.content,
      sortId: i,
    };
  });

  return {
    sortedData,
    dataForSave,
  };
};
