import { useEffect, useState } from 'react';

import { storage } from '../services';

export const useAuthGuard = () => {
  const [authorized, setAuthorized] = useState(false);
  const [token, setToken] = useState(null);
  const [tokenTime, setTokenTime] = useState(null);

  const updateToken = () => {
    document.dispatchEvent(new CustomEvent('iproxy-update-token-layer', null));
  };

  useEffect(() => {
    document.addEventListener('iproxy-update-token-layer', () => {
      setTokenTime(+new Date());
    });
  }, []);

  useEffect(() => {
    storage.getItem('SYSTEM', 'token')
      .then((value) => {
        if (value != null) {
          setAuthorized(true);
          setToken(value);
        }
      })
      .catch(() => {
        setAuthorized(false);
      });
  }, [tokenTime]);

  return {
    isAuthorized: authorized,
    getToken: token,
    tokenTime,
    triggerUpdate: updateToken,
  };
};
