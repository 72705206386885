import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { UIProps } from '../../types';
import { InputWrapper, WrapperScreen } from '../../styles/ui-controls';
import { DropdownElement, InputField } from '../../elements';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import store from '../../core/store/store';
import { setDashboardConfig } from '../../core/store/actions/app';
import { DnDGroupList } from '../../DnDGroupList';
import { debounceLoad } from '../../utils';
import {
  ButtonLoader,
  TabsAtom,
  TabsAtomConfig,
} from '../atoms';
import { DnDPHoneGroupList } from '../../DnDPHoneGroupList';
import { NON_GROUP, BREAKPOINT_MOBILE } from '../../utils/constants';

interface AddConnectionGroupFormProps {}

export const AddConnectionGroupForm: FunctionComponent<AddConnectionGroupFormProps> = () => {
  const { t } = useTranslation();
  const appConfigs = useTypedSelector(({ app }) =>
    app);
  const [groupName, setGroupName] = useState('');
  const [groups, setGroups] = useState([]);
  const [isSend, setIsSend] = useState(false);
  const [groupOptions, setGroupOptions] = useState<any>();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectGroup, setSelectGroup] = useState(null);

  const phoneConnections = useTypedSelector(
    ({ connections }) =>
      connections.data,
  );

  const saveGroupConfig = (groupList) => {
    store.dispatch(
      setDashboardConfig.request({
        configs: {
          ...appConfigs?.dashboard_config?.configs,
          connectionGroups: groupList,
        },
      }),
    );
  };

  const addGroup = () => {
    setIsSend(true);

    if (!hasError(groupName) && groupName != null && groupName !== '') {
      let list = [...groups];
      list.push({ name: groupName, connections: [] });
      list = list?.map((group, i) =>
        ({
          ...group,
          sortId: i,
        }));
      setGroups(list);
      setGroupName(null);
      setTimeout(() => {
        saveGroupConfig(list);
      }, 10);
      setIsSend(false);
      setGroupOptions(generateGroupOptions(list));
    }
  };

  const changeGroupName = () => {
    setIsSend(true);

    if (!hasError(selectGroup.content?.name, selectGroup.content?.sortId)
      && selectGroup != null && selectGroup.content?.name !== '') {
      let list = [...groups];
      list = list?.map((group) =>
        ({
          ...group,
          name: group.sortId === selectGroup.content?.sortId ? selectGroup.content?.name : group.name,
        }));

      setGroups(list);
      setTimeout(() => {
        saveGroupConfig(list);
      }, 10);
      setIsSend(false);
      setTimeout(() => {
        setGroupOptions(generateGroupOptions(list));
        setSelectGroup(null);
        setEditMode(false);
      }, 1000);
    }
  };

  const removeItem = (groupName) => {
    const list = [...groups].filter((item) =>
      item.name !== groupName);

    setGroups(list);
    saveGroupConfig(list);
  };

  const renderInputError = (groupName, sortId?) => {
    const groupExist = groups?.find(
      (g) =>
        g.name?.toLowerCase() === groupName?.toLowerCase() && sortId !== g.sortId,
    );
    if (groupName === '' || groupName == null) {
      return t('errors.emptyField');
    } if (groupExist) {
      return t('errors.groupExist');
    }
    return '';
  };

  const hasError = (groupName, sortId?) => {
    const groupExist = groups?.find(
      (g) =>
        g.name?.toLowerCase() === groupName?.toLowerCase() && sortId !== g.sortId,
    );
    const isEmpty = isSend && (groupName === '' || groupName == null);
    return groupExist || isEmpty;
  };

  const renderAddGroupView = () =>
    (
      editMode && selectGroup ? (
        <InputContainer>
          <InputWrapper>
            <InputLabel>{t('groupForm.groupName')}</InputLabel>
            <InputField
              value={selectGroup?.content?.name}
              floatLabel
              onBlur={() =>
                setIsSend(false)}
              placeholder={`${t('groupForm.groupName')}`}
              error={hasError(selectGroup?.content?.name, selectGroup?.content?.sortId)}
              errorMsg={renderInputError(selectGroup?.content?.name, selectGroup?.content?.sortId)}
              onChange={({ target }) =>
                setSelectGroup({ ...selectGroup, content: { ...selectGroup.content, name: target?.value } })}
            />
          </InputWrapper>
          <div className="bt">
            <ButtonLoader
              size="small"
              onClick={() =>
                changeGroupName()}
            >
              Save
            </ButtonLoader>
          </div>
        </InputContainer>
      ) : (
        <>
          <InputContainer>
            <InputWrapper>
              <InputLabel>{t('connection.name')}</InputLabel>
              <InputField
                value={groupName}
                floatLabel
                onBlur={() =>
                  setIsSend(false)}
                placeholder={`${t('connection.name')}`}
                error={hasError(groupName)}
                errorMsg={renderInputError(groupName)}
                onChange={({ target }) =>
                  setGroupName(target?.value)}
              />
            </InputWrapper>
            <div className="bt">
              <ButtonLoader
                size="small"
                onClick={() =>
                  addGroup()}
              >
                {t('groupForm.add')}
              </ButtonLoader>
            </div>
          </InputContainer>
          <div className="scroll-container">
            <Scrollbars>
              <DnDGroupList
                onRemove={(groupName) =>
                  removeItem(groupName)}
                onSave={(data) => {
                  debounceLoad(() => {
                    saveGroupConfig(data);
                  });
                }}
                onEdit={(g) => {
                  setEditMode(true);
                  setSelectGroup(g);
                }}
                groupList={groups?.filter((i) =>
                  i?.name !== NON_GROUP) as any}
              />
            </Scrollbars>
            <div className="total-goups">
              {`${t('connection.totalGroups')}: ${
                groups?.length != null ? groups?.length : 0
              }`}
            </div>
          </div>
        </>
      )
    );

  const renderPhoneGroupView = () =>
    (
      <>
        <InputContainer>
          <DropdownElement
            isSearchable
            placeholder={t('groupForm.sortDdPlace')}
            value={selectedGroup}
            onSelected={(optionValue) =>
              setSelectedGroup(optionValue)}
            options={groupOptions}
          />
        </InputContainer>

        <div className="scroll-container">
          {selectedGroup == null ? (
            <div className="empty">{t('groupForm.noGroup')}</div>
          ) : null}
          <Scrollbars>
            <DnDPHoneGroupList
              onSave={(data) => {
                const updatedGroup = groups?.map((i) => {
                  const newI = { ...i };
                  if (i?.name === selectedGroup?.value) {
                    newI.connections = data;
                  }
                  return newI;
                });

                const hasNonGroup = groups.find((i) =>
                  i.name === NON_GROUP);
                if (hasNonGroup == null) {
                  updatedGroup.push({
                    name: NON_GROUP,
                    sortId: 1000000000,
                    connections: data,
                  });
                }

                if (updatedGroup != null) {
                  debounceLoad(() => {
                    saveGroupConfig(updatedGroup);
                  });
                }
              }}
              selectedGroup={selectedGroup}
              rawPhones={phoneConnections}
              rawGroup={groups}
            />
          </Scrollbars>
        </div>
      </>
    );

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'add group',
      title: `${t('groupForm.add')}`,
      component: renderAddGroupView(),
    },
    {
      key: 'sort connections',
      title: `${t('groupForm.sortPhone')}`,
      component: renderPhoneGroupView(),
    },
  ];

  useEffect(() => {
    if (
      appConfigs?.dashboard_config != null
        && appConfigs?.dashboard_config?.configs?.connectionGroups != null
    ) {
      const gr = appConfigs?.dashboard_config.configs.connectionGroups;
      setGroups([...gr]);
      setGroupOptions(generateGroupOptions(gr));
    }
  }, []);

  const generateGroupOptions = (gr) => {
    const data = gr?.map((i) =>
      ({
        value: i?.name,
        label: i?.name === NON_GROUP ? `${t('connection.noGroup')}` : `${i?.name}`,
      }));
    const hasNonGroup = data?.find((i) =>
      i?.value === NON_GROUP);
    if (hasNonGroup == null) {
      data.push({
        value: NON_GROUP,
        label: t('connection.noGroup'),
      });
    }
    return data;
  };

  return (
    <WrapperScreen>
      <Wrapper>
        <FormContainer>
          <div className="title">{t('connection.addGroup')}</div>

          <TabsAtom
            onTabChange={() =>
              setEditMode(false)}
            config={tabsConfig}
          />
        </FormContainer>
      </Wrapper>
    </WrapperScreen>
  );
};

const Wrapper = styled.div`
  min-width: 630px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    min-width: unset;
  }

  .total-goups {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
  }

  .scroll-container {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    height: 200px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${(props: UIProps) =>
    props.theme.colors.transparentDeepBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      min-height: 350px;
    }
  }

  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

`;

const FormContainer = styled.div`
  margin-bottom: 35px;
  ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  }

  .title {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.largeBold};
    margin-bottom: 12px;
  }
`;

const InputContainer = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  .bt {
    height: 40px;
  }
`;

const InputLabel = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumMedium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smallerMedium};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }
`;
