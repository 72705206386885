import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { Plan, UIProps } from '../../types';
import { BaseModalFormParams } from '../../modal';
import {
  BasicButton, EditModalActionContainer, EditModalSave, EditModalStatusLabel,
} from '../../styles/ui-controls';
import { TariffPurchaseConfirmationForm } from './tariff-purchase-confirmation-form';
import { Phone } from '../../types/phones';

interface TariffFormProps extends BaseModalFormParams {

}

export const TariffForm: FunctionComponent<TariffFormProps> = (props) => {
  const {
    modalParams,
    initConfirmation,
  } = props;
  const { t } = useTranslation();

  const phoneConnections = useTypedSelector(({ connections }) =>
    connections.data);
  const tariffPlans = useTypedSelector(({ plans }) =>
    plans.data);

  const [activeTariff, setActiveTariff] = useState<Plan>(null);
  const [activeHelp, setActiveHelp] = useState(false);
  const [selectTariff, setSelectTariff] = useState(null);
  const [selectedPhone, setSelectedPhone] = useState(null);

  useEffect(() => {
    if (phoneConnections != null) {
      const phone = phoneConnections.find((p: Phone) =>
        p.id === modalParams?.phoneId);
      setSelectedPhone(phone);
    }
  }, [phoneConnections]);

  useEffect(() => {
    if (selectTariff != null) {
      initConfirmation(<TariffPurchaseConfirmationForm />, {
        data: { plan: selectTariff, phone: selectedPhone },
      });
    }
  }, [selectTariff]);

  useEffect(() => {
    if (modalParams != null && selectedPhone != null) {
      const active = tariffPlans.find((plan: Plan) =>
        plan?.id === selectedPhone?.activePlans[0]?.id);
      setActiveTariff(active);
    }
  }, [modalParams, selectedPhone]);

  const renderHelpVideo = () =>
    (
      <Iframe
        src="https://www.youtube.com/embed/_HUVviDfpuY"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  return (
    <Wrapper>
      <PlansContainer>
        {tariffPlans.map((plan : Plan) => {
          const label = plan.id === activeTariff?.id ? (
            <>
              {t('tariff.renew')}
              {' '}
              :
              <PriceLabel>
                {plan.price}
                <SubInfoLabel>{t('wallet.RUB')}</SubInfoLabel>
                {' '}
                /
                {plan.daysNumber}
                <SubInfoLabel>дней</SubInfoLabel>
              </PriceLabel>
            </>
          ) : (
            <>
              {t('tariff.buy')}
              :
              <PriceLabel>
                {plan.price}
                <SubInfoLabel>{t('wallet.RUB')}</SubInfoLabel>
                {' '}
                /
                {plan.daysNumber}
                <SubInfoLabel>{t('tariff.days')}</SubInfoLabel>
              </PriceLabel>
            </>
          );
          return (
            <PlanRow key={plan.id} active={plan.id === activeTariff?.id}>
              <ActiveLabel active={plan.id === activeTariff?.id}>{t('tariff.active')}</ActiveLabel>

              <TariffTitle>
                {plan.name}
              </TariffTitle>
              <TariffDescription>
                {plan.description}
              </TariffDescription>

              <BasicButton onClick={() =>
                setSelectTariff(plan)}
              >
                {label}
              </BasicButton>
            </PlanRow>
          );
        })}
      </PlansContainer>
      {
                !activeHelp ? (
                  <HelpNote>
                    {t('information.haveQuestions')}
                    {' '}
                    <HelpLink onClick={() =>
                      setActiveHelp(true)}
                    >
                      {t('information.readPaymentInstructions')}
                    </HelpLink>
                  </HelpNote>
                ) : null
            }

      {activeHelp ? renderHelpVideo() : null}

      <EditModalActionContainer>
        <EditModalStatusLabel>{t('notification.changeUpdated')}</EditModalStatusLabel>
        <EditModalSave onClick={() => {}}>{t('editForm.save')}</EditModalSave>
      </EditModalActionContainer>
    </Wrapper>
  );
};

interface ActiveLabelProps extends UIProps {
  active: boolean;
}

const ActiveLabel = styled.div<ActiveLabelProps>`
  color: ${(props: ActiveLabelProps) =>
    props.theme.colors.white};
  display: ${(props: ActiveLabelProps) =>
    (props.active ? 'block' : 'none')};
  background-color: ${(props: ActiveLabelProps) =>
    props.theme.colors.deepBlue};
  width: fit-content;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  position: absolute;
  top: 0;
  right: 0;
  ${(props: ActiveLabelProps) =>
    props.theme.sizes.font.smaller};
`;

const Wrapper = styled.div`
  height: 100%;
`;
const PlansContainer = styled.div`
    display: flex;
  flex-direction: row;
`;

interface PlanRowProps extends UIProps {
  active: boolean;
}

const PlanRow = styled.div<PlanRowProps>`
  position: relative;
  width: 340px;
  min-width: 340px;
  border: 1px solid ${(props: PlanRowProps) =>
    (props.active
      ? props.theme.colors.deepBlue
      : props.theme.colors.spaceGray)
};
  box-sizing: border-box;
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  transition: .3s;
  cursor: pointer;

  :first-child {
    margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
  :last-child {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const TariffTitle = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  ${(props: UIProps) =>
    props.theme.sizes.font.largeMedium};
`;
const TariffDescription = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
`;

const PriceLabel = styled.div`
 margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const SubInfoLabel = styled.span`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const HelpNote = styled.div`
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};

`;

const HelpLink = styled.span`
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    cursor: pointer;
`;

const Iframe = styled.iframe`
  width: 100%;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  height: 400px;
`;
