import React, {
  useState,
  useEffect,
  FunctionComponent,
  useMemo,
  useContext,
} from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import {
  ConnectionAuthType,
  ConnectionType,
  NewConnection,
  Plan,
  SelectOption,
  UIProps,
} from '../../../types';
import {
  addNewConnection,
  loadUserConnections,
  removeConnection,
  updateConnectionCred,
  updateConnectionIp,
} from '../../../core/store/actions/connections';
import store from '../../../core/store/store';
import { Phone } from '../../../types/phones';
import { MaxProxyCount } from '../../../types/connection';
import {
  generateRandomPassword,
  selectTarget,
  showNotification,
  exportCredentials,
} from '../../../utils';

import iconRemove from '../../../assets/phone_icons/remove_icon.svg';
import iconPlus from '../../../assets/plus_icon.svg';
import { ReactComponent as IconSettings } from '../../../assets/phone_icons/setting_icon.svg';
import iconCopy from '../../../assets/coppy_icon.svg';
import iconInfo from '../../../assets/info_icon.svg';

import { EditConnection } from './EditConnection';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { DropdownElement } from '../../../elements';
import {
  EditModalSave,
  InputLabel,
  InputWrapper,
} from '../../../styles/ui-controls';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import {
  isIncorrectIpsContain,
  WhitelistIpInput,
} from '../../common/WhitelistIpInput';
import { ConfirmForm } from '../../common/ConfirmForm';
import ScrollWrapper from '../../common/ScrollWrapper';
import {
  ItemContent,
  ProxyConnectionsList,
  ProxyConnectionsListRow,
  RowContent,
} from '../ConnectionComponents';
import { LoaderIcon } from '../../common/LoaderIcon';
import { SetCommonExpirationDate } from './SetCommonExpirationDate';
import { Api } from '../../../core/api';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import theme from '../../../styles/ApplicationTheme';
import { ProxyAction } from './ProxyAction';

interface ConnectionsListProps {
  phone: Phone;
  isOwner: boolean;
}

const defaultMaxProxyCount = 0;

export const ConnectionsList: FunctionComponent<ConnectionsListProps> = (
  props,
) => {
  const { phone, isOwner = true } = props;
  const context = useContext(ConnectionEditViewContext);

  const connections = useTypedSelector(({ connections }) =>
    connections.data.find((item) =>
      item.id === phone.id))?.connections;

  const appConfigs = useTypedSelector(({ app }) =>
    app);

  const servers = useTypedSelector(({ servers }) =>
    servers?.userServers);
  const proxyServer = servers?.find((item) =>
    item.id === phone.slaveServerId);

  const alert = phone?.alerts?.[0];

  const isLimit = alert?.stopIfLimitsExceeded
    && (alert?.current_in_bytes >= alert?.max_in_bytes
      || alert?.current_out_bytes >= alert?.max_out_bytes
      || alert?.current_total_bytes >= alert?.max_total_bytes);

  const { t } = useTranslation();
  const tariffPlans = useTypedSelector(({ plans }) =>
    plans.data);
  const {
    copySettings,
  } = useTypedSelector(
    ({ app }) =>
      app.dashboard_config?.configs,
  );
  const phoneTariff = useMemo(
    () =>
      tariffPlans?.find(
        (plan: Plan) =>
          plan?.id === context?.selectedPhone?.activePlans[0]?.id,
      ) || null,
    [tariffPlans, context?.selectedPhone],
  );

  const CON_TYPE: SelectOption<ConnectionType>[] = [
    {
      value: 'socks5',
      label: 'socks5',
    },
    {
      value: 'http',
      label: 'http',
    },
  ];
  const AUTH_TYPE: SelectOption<ConnectionAuthType>[] = [
    {
      value: 'userpass',
      label: `${t('proxy.userpass')}`,
    },
    {
      value: 'noauth',
      label: `${t('proxy.noauth')}`,
    },
  ];

  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [selectedAuth, setSelectedAuth] = useState(AUTH_TYPE[0]);
  const [selectedType, setSelectedType] = useState(CON_TYPE[1]);
  const [connectionId, setConnectionId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [expDateMode, setExpDateMode] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [listIps, setListIps] = useState(null);
  const [updatedConnections, setUpdatedConnections] = useState(connections);

  const maxCountProxy = useMemo(
    () =>
      (phoneTariff?.id ? MaxProxyCount[phoneTariff.id] : defaultMaxProxyCount),
    [phoneTariff],
  );

  const [connectionData, setConnectionData] = useState<NewConnection>({
    description: null,
    auth_type: null,
    expirationTimestamp: null,
    listen_service: null,
    phoneId: null,
    allow_from: null,
  });

  useEffect(() => {
    setConnectionData({
      ...connectionData,
      phoneId: phone?.id,
      auth_type: selectedAuth?.value,
      expirationTimestamp: +moment().add(3000, 'months'),
      listen_service: selectedType?.value,
      allow_from: listIps,
    });
  }, [selectedType, selectedAuth, phone, listIps]);

  const removeConnectionLocal = (connectionId) => {
    store.dispatch(
      removeConnection.request({
        phoneId: phone?.id,
        connectionId,
      }),
    );
  };

  const addNewConnectionLocal = () => {
    setAddMode(false);
    store.dispatch(
      addNewConnection.request({
        connection: connectionData,
        callback: () => {
          setConnectionData({
            description: null,
            auth_type: null,
            expirationTimestamp: null,
            listen_service: null,
            phoneId: null,
            allow_from: null,
          });
          setSelectedAuth(AUTH_TYPE[0]);
          setSelectedType(CON_TYPE[1]);
          setListIps(null);
        },
      }),
    );
  };

  const resetAllPasswords = () => {
    try {
      const request = connections.map(async (connection) =>
        Api.post(
          `/connections/${connection.id}/update`,
          {
            ...connection,
            password: generateRandomPassword(),
          },
          null,
          false,
        ));

      Promise.all(request).then(() => {
        store.dispatch(loadUserConnections.request(null));
      });
    } catch (e) {
      console.error('Error:> Reset all passwords error: :=', e);
    }
  };

  const resetProxyPassword = (connection) => {
    store.dispatch(
      updateConnectionCred.request({
        ...connection,
        connectionId: connection?.id,
        password: generateRandomPassword(),
      }),
    );
    showNotification(`${t('connection.resetPasswordSuccess')}`);
  };

  const updateProxyIp = (connection) => {
    store.dispatch(
      updateConnectionIp.request({
        phoneId: connection?.phoneId,
        connectionId: connection?.id,
        callback: () =>
          showNotification(`${t('connection.updateIpSuccess')}`),
      }),
    );
  };

  const copyData = (value: string) => {
    copy(value);
    showNotification(`${t('notification.dataCopiedSuccessfully')}`);
  };

  useEffect(() => {
    setUpdatedConnections(connections);
  }, [connections]);

  return (
    <Wrapper>
      <ScrollWrapper className="proxy">
        <ProxyConnectionsContainer>
          {!editMode && !expDateMode ? (
            <div className="headline">
              {!!connections?.length && (
                <EditModalSave onClick={() => {
                  setExpDateMode(true);
                  setAddMode(false);
                }}
                >
                  {t('connection.commonExpirationDate')}
                </EditModalSave>
              )}
              {!!connections?.length && (
                <EditModalSave
                  onClick={() => {
                    resetAllPasswords();
                    showNotification(
                      `${t('connection.resetAllPasswordsSuccess')}`,
                    );
                  }}
                >
                  {t('connection.resetAllPasswords')}
                </EditModalSave>
              )}
              {!isOwner && !context?.permissions?.proxyCreate ? null : (
                <EditModalSave
                  disabled={connections?.length >= maxCountProxy}
                  onClick={() => {
                    if (!(connections?.length >= maxCountProxy)) {
                      setAddMode(!addMode);
                      setEditMode(false);
                    }
                  }}
                >
                  {addMode ? (
                    <>{t('proxy.showProxy')}</>
                  ) : (
                    <>
                      <IconConnection />
                      {t('proxy.addProxy')}
                    </>
                  )}
                </EditModalSave>
              )}
            </div>
          ) : null}

          <div className="content">
            {!addMode ? (
              !isOwner && !context?.permissions?.proxyView ? null : (
                <ProxyConnectionsList>
                  {connections != null && connections?.length ? (
                    expDateMode ? (
                      <SetCommonExpirationDate
                        onSave={(date) => {
                          const request = connections.map(async (connection) =>
                            Api.post(
                              `/connections/${connection.id}/update`,
                              {
                                ...connection,
                                expirationTimestamp:
                                  date !== null ? +date : null,
                              },
                              null,
                              false,
                            ));

                          Promise.all(request).then(() => {
                            store.dispatch(loadUserConnections.request(null));
                            setExpDateMode(false);
                          });
                        }}
                      />
                    ) : editMode ? (
                      <EditConnection
                        selectedTariffId={phoneTariff}
                        onSave={() =>
                          setEditMode(false)}
                        connection={selectedConnection}
                        phone={phone}
                      />
                    ) : (
                      <>
                        <ReactTooltip />
                        {updatedConnections?.map((value, index) => {
                          const isNumericFormatIp = appConfigs?.dashboard_config?.configs
                            ?.isNumericFormatIP;

                          const ip = isNumericFormatIp
                            ? value?.address
                            : value?.hostname || value?.address;

                          const isExpiredProxyIp = proxyServer
                            ? proxyServer.ip !== value?.address
                            : false;

                          return (
                            <ProxyWrapper key={value?.id}>
                              <ProxyConnectionsListRow
                                style={
                                  value.expirationTimestamp && {
                                    marginBottom: 0,
                                  }
                                }
                              >
                                {removeConfirmation
                                && value?.id === connectionId ? (
                                  <ConfirmForm
                                    hasScrollTo
                                    text={t('proxy.removalConfirmation')}
                                    onApprove={() => {
                                      removeConnectionLocal(connectionId);
                                      setRemoveConfirmation(false);
                                      setTimeout(
                                        () =>
                                          setConnectionId(null),
                                        0,
                                      );
                                    }}
                                    onCancel={() => {
                                      setRemoveConfirmation(false);
                                      setConnectionId(null);
                                    }}
                                  />
                                  ) : (
                                    <>
                                      <RowContent
                                        data-class="cus_tooltip_basic"
                                        data-place="top"
                                        data-type="dark"
                                        isEdit={
                                        editMode
                                        && selectedConnection?.id === value?.id
                                      }
                                      >
                                        <ItemContent
                                          content={`${t('proxy.type')}`}
                                        >
                                          <span
                                            data-tip={`${t('toolTip.copy')}`}
                                            onClick={(e) => {
                                              if (!editMode) {
                                                selectTarget(e?.currentTarget);
                                                copyData(value?.listen_service);
                                              }
                                            }}
                                          >
                                            {value?.listen_service}
                                          </span>
                                        </ItemContent>
                                        <ItemContent content={`${t('proxy.ip')}`}>
                                          <span
                                            data-tip={`${t('toolTip.copy')}`}
                                            onClick={(e) => {
                                              if (!editMode) {
                                                selectTarget(e?.currentTarget);
                                                copyData(ip);
                                              }
                                            }}
                                          >
                                            {ip}
                                          </span>
                                          {
                                            isExpiredProxyIp && (
                                            <ProxyActionWrapper>
                                              <ProxyAction
                                                connection={value}
                                                onClick={updateProxyIp}
                                                actionType="updateIp"
                                              />
                                            </ProxyActionWrapper>
                                            )
                                          }
                                        </ItemContent>
                                        <ItemContent
                                          content={`${t('proxy.port')}`}
                                        >
                                          <span
                                            data-tip={`${t('toolTip.copy')}`}
                                            onClick={(e) => {
                                              if (!editMode) {
                                                selectTarget(e?.currentTarget);
                                                copyData(value?.port?.toString());
                                              }
                                            }}
                                          >
                                            {value?.port}
                                          </span>
                                        </ItemContent>
                                        <ItemContent
                                          content={`${t('profile.login')}`}
                                        >
                                          {value?.auth_type === 'noauth' ? (
                                            '-'
                                          ) : (
                                            <span
                                              data-tip={`${t('toolTip.copy')}`}
                                              onClick={(e) => {
                                                if (!editMode) {
                                                  selectTarget(e?.currentTarget);
                                                  copyData(value?.login);
                                                }
                                              }}
                                            >
                                              {value?.login}
                                            </span>
                                          )}
                                        </ItemContent>
                                        <ItemContent
                                          isEdit={
                                          editMode
                                          && selectedConnection?.id === value?.id
                                        }
                                          contentEditable={
                                          editMode
                                          && selectedConnection?.id === value?.id
                                        }
                                          content={`${t('profile.password')}`}
                                        >
                                          {value?.auth_type === 'noauth' ? (
                                            '-'
                                          ) : (
                                            <>
                                              <span
                                                data-tip={`${t('toolTip.copy')}`}
                                                onClick={(e) => {
                                                  if (!editMode) {
                                                    selectTarget(
                                                      e?.currentTarget,
                                                    );
                                                    copyData(value?.password);
                                                  }
                                                }}
                                              >
                                                {value?.password}
                                              </span>
                                              <ProxyActionWrapper>
                                                <ProxyAction
                                                  connection={value}
                                                  onClick={resetProxyPassword}
                                                  actionType="resetPassword"
                                                />
                                              </ProxyActionWrapper>
                                            </>
                                          )}
                                        </ItemContent>
                                      </RowContent>
                                      <ControlBlock>
                                        <div
                                          data-class="cus_tooltip_basic"
                                          data-place="top"
                                          data-type="dark"
                                          data-tip={`${
                                            value?.description != null
                                          && value?.description !== ''
                                          && value?.description
                                            !== 'this proxy was created automatically'
                                              ? value?.description
                                              : '-'
                                          }`}
                                        >
                                          <ActionBt
                                            icon={iconInfo}
                                            style={{
                                              width: '18px',
                                              height: '18px',
                                            }}
                                          />
                                        </div>
                                        {!editMode ? (
                                          <div
                                            data-class="cus_tooltip_basic"
                                            data-place="top"
                                            data-type="dark"
                                            data-tip={t('toolTip.copy')}
                                          >
                                            <ActionBt
                                              icon={iconCopy}
                                              onClick={async () => {
                                                await exportCredentials({
                                                  format: copySettings.format,
                                                  phones: [phone],
                                                  copySettings,
                                                  isCopy: true,
                                                  index,
                                                });
                                                showNotification(`${t('notification.dataCopiedSuccessfully')}`);
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                        {!editMode ? (
                                          !isOwner
                                        && !context?.permissions
                                          ?.proxyEdit ? null : (
                                            <div
                                              data-class="cus_tooltip_basic"
                                              data-place="top"
                                              data-type="dark"
                                              data-tip={t('toolTip.edit')}
                                            >
                                              <ActionBt
                                                icon=""
                                                onClick={() => {
                                                  setEditMode(true);
                                                  setSelectedConnection(value);
                                                }}
                                              >
                                                <IconSettings fill={theme.colors.deepBlue} />
                                              </ActionBt>
                                            </div>
                                            )
                                        ) : null}

                                        {!editMode ? (
                                          !isOwner
                                        && !context?.permissions
                                          ?.proxyRemove ? null : (
                                            <div
                                              data-class="cus_tooltip_basic"
                                              data-place="top"
                                              data-type="dark"
                                              data-tip={t('toolTip.remove')}
                                            >
                                              <ActionBt
                                                icon={iconRemove}
                                                onClick={() => {
                                                  setRemoveConfirmation(true);
                                                  setConnectionId(value?.id);
                                                }}
                                              />
                                            </div>
                                            )
                                        ) : null}
                                      </ControlBlock>
                                    </>
                                  )}
                              </ProxyConnectionsListRow>
                              {value.expirationTimestamp && (
                                <ExpirationDateBlock
                                  expirated={
                                    Date.now() > value.expirationTimestamp
                                  }
                                >
                                  {t('connection.expirationDate')}
                                  :
                                  {' '}
                                  {new Date(
                                    value?.expirationTimestamp,
                                  ).toLocaleString('ru', {
                                    timeZoneName: 'short',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                  })}
                                  {isLimit && t('connection.trafficLimit')}
                                </ExpirationDateBlock>
                              )}
                              {value.description
                                && value.description
                                  !== 'this proxy was created automatically' && (
                                  <DescriptionBlock>
                                    {value.description}
                                  </DescriptionBlock>
                              )}
                            </ProxyWrapper>
                          );
                        })}
                        {phone?.isProxyCreating || phone?.isProxyDeleting ? (
                          <ActionPreset
                            removeConnectionLoading={phone?.isProxyDeleting}
                          />
                        ) : phone?.isProxyDeletingError ? (
                          <Warning>{t('status.removingError')}</Warning>
                        ) : null}
                      </>
                    )
                  ) : phone?.isProxyCreating || phone?.isProxyDeleting ? (
                    <ActionPreset
                      removeConnectionLoading={phone?.isProxyDeleting}
                    />
                  ) : (
                    <NoConnections>{t('proxy.none')}</NoConnections>
                  )}
                </ProxyConnectionsList>
              )
            ) : (
              <TemporyAddNewBlock>
                <div className="desc">{t('proxy.selectParameters')}</div>
                <div className="options-list">
                  <InputWrapper>
                    <InputLabel>{`${t('connection.title')}`}</InputLabel>
                    <DropdownElement
                      isSearchable={false}
                      value={selectedType}
                      placeholder={`${t('connection.title')}`}
                      options={CON_TYPE}
                      onSelected={(target) =>
                        setSelectedType(target)}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <InputLabel>{`${t('proxy.authorization')}`}</InputLabel>
                    <DropdownElement
                      isSearchable={false}
                      value={selectedAuth}
                      placeholder={`${t('proxy.authorization')}`}
                      options={AUTH_TYPE}
                      onSelected={(target) =>
                        setSelectedAuth(target)}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <EditModalSave
                      className="button"
                      disabled={
                        selectedAuth?.value === 'noauth'
                          ? ((listIps == null || listIps.length === 0)
                              && !isIncorrectIpsContain(listIps))
                            || connections?.length >= maxCountProxy
                          : connections?.length >= maxCountProxy
                      }
                      onClick={() => {
                        if (
                          selectedAuth?.value === 'noauth'
                          && (listIps == null || listIps.length === 0)
                          && !isIncorrectIpsContain(listIps)
                        ) {
                          return null;
                        }
                        if (connections?.length >= maxCountProxy) {
                          return null;
                        }
                        addNewConnectionLocal();
                        return null;
                      }}
                    >
                      {t('proxy.addProxyButton')}
                    </EditModalSave>
                  </InputWrapper>
                </div>

                {selectedAuth.value === 'noauth' ? (
                  <WhitelistContainer>
                    <WhitelistIpInput
                      onListChange={(list) => {
                        setListIps(list);
                      }}
                      ipList=""
                    />
                  </WhitelistContainer>
                ) : null}
              </TemporyAddNewBlock>
            )}
          </div>
        </ProxyConnectionsContainer>
      </ScrollWrapper>
    </Wrapper>
  );
};

interface ActionPresetProps {
  removeConnectionLoading: boolean;
}

const ActionPreset: FunctionComponent<ActionPresetProps> = (props) => {
  const { removeConnectionLoading } = props;
  const { t } = useTranslation();

  return (
    <ActonPreset>
      <div className="info">
        <LoaderIcon />
        <div className="process-title">
          {removeConnectionLoading
            ? t('status.removing')
            : t('status.addingProxy')}
        </div>
      </div>
    </ActonPreset>
  );
};

const WhitelistContainer = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;

const TemporyAddNewBlock = styled.div`
  display: flex;
  flex-direction: column;

  .desc {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    font-weight: 500;
    color: ${(props: UIProps) =>
    props.theme.colors.dark};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .options-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      flex-direction: column;
    }
  }

  .button {
    height: 38px;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      height: 48px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  .bt-save {
    height: 45px;
  }

  .content {
    span {
      cursor: pointer;
    }
  }
`;

const NoConnections = styled.div`
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  text-align: center;
  box-sizing: border-box;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  margin-top: -10px;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;

const ProxyConnectionsContainer = styled.div`
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  padding-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.large};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  .content {
    box-sizing: border-box;
    width: 100%;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      max-height: unset;
    }
  }

  .headline {
    display: flex;
    align-items: center;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin: 4px 0 24px;
      flex-direction: column;
      row-gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }
  }
`;

const ControlBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

interface ActionBtProps extends UIProps {
  icon?: string;
}

const ActionBt = styled.div<ActionBtProps>`
  display: flex;
  align-items: center;

  width: 15px;
  height: 15px;
  margin:  ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmaller};

  background-image: url(${(props: ActionBtProps) =>
    props.icon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  cursor: pointer;
  transition: 0.1s;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const IconConnection = styled.div`
  background-image: url(${iconPlus});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  left: 20px;
`;

interface ExpirationDateBlockProps extends UIProps {
  expirated?: boolean;
}

const ExpirationDateBlock = styled.div<ExpirationDateBlockProps>`
  color: ${(props: ExpirationDateBlockProps) =>
    (props.expirated ? props.theme.colors.warning : props.theme.colors.dark)};
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const DescriptionBlock = styled.div`
  color: ${(props: ExpirationDateBlockProps) =>
    props.theme.colors.dark};
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
`;

const ActonPreset = styled.div`
  position: relative;
  height: 70px;
  width: 100%;

  .info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }

  .process-title {
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  }
`;

const Warning = styled.p`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  text-align: center;
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  font-weight: 500;
`;

const ProxyActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  position: absolute;
  top: -55%;
  right: 5px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    top: -60%;
  }
`;

const ProxyWrapper = styled.div`
@media (max-width: ${BREAKPOINT_MOBILE}px) {
  background-color: ${(props: UIProps) =>
    props.theme.colors.skyBlueTint};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
}
`;
