import React, { useMemo, useState } from 'react';
import { Api } from '../core/api';

export const CommonAppContext = React.createContext<any | null>(null);

export function CommonAppProvider({ children }: any) {
  const [showSupportModal, setShowSupportModal] = useState(null);
  const [systemAlerts, setSystemAlerts] = useState(null);
  const [onboarding, setOnboarding] = useState(null);

  const $loadUserOnboardingStatus = async () => {
    await Api.get('/onboarding/info', null, null, false).then(
      ({ data }) => {
        setOnboarding(data);
      },
    );
  };

  const initialContextState = useMemo(() =>
    ({
      showSupportModal,
      setShowSupportModal,
      setSystemAlerts,
      onboarding,
      setOnboarding,
      systemAlerts,
      $loadUserOnboardingStatus,
    }), [showSupportModal, onboarding, systemAlerts]);

  return (
    <CommonAppContext.Provider value={initialContextState as any}>
      {children}
    </CommonAppContext.Provider>
  );
}
