import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useModalHook } from '../../hooks';
import { UIProps } from '../../types';
import bgFone from '../../assets/bg-empty-connections.svg';
import {
  googlePlay,
  iproxyDownload,
  telegramSupport,
  BREAKPOINT_MOBILE,
} from '../../utils/constants';
import { windowOpen } from '../../utils';

export default function WelcomePanel() {
  const modalOptions = useModalHook();

  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <Heading>
        {t('welcome.heading')}
        {' '}
        {' '}
      </Heading>

      <SubHeading>
        {t('welcome.subHeading')}
      </SubHeading>

      <TopInstruction>
        {t('welcome.topInstruction')}
      </TopInstruction>

      <Row>
        <NumSpan>
          1.
        </NumSpan>
        <RowContent>
          <RowText>
            <RawLink
              onClick={() =>
                modalOptions.show('phone.new')}
            >
              {t('welcome.step1')}
            </RawLink>
          </RowText>
        </RowContent>
      </Row>

      <Row>
        <NumSpan>
          2.
        </NumSpan>
        <RowContent>
          <div>
            <RowText>
              <RawLink
                onClick={() =>
                  modalOptions.show('balance.topUp')}
              >
                {t('welcome.step2_0')}
              </RawLink>
              {' '}
              {t('welcome.step2_1')}
              <RawLink
                onClick={() =>
                  windowOpen(i18n.language === 'en'
                    ? 'https://youtu.be/pY2ioCYXJi0'
                    : t('welcome.replenishment_learn_more_link')).focus()}
              >
                {' '}
                {t('welcome.step2_2')}
              </RawLink>
            </RowText>
          </div>

        </RowContent>
      </Row>

      <Row>
        <NumSpan>
          3.
        </NumSpan>
        <RowContent>
          <div>
            <RowText>
              {t('welcome.step3_0')}
              <RawLink
                onClick={() =>
                  windowOpen(googlePlay).focus()}
              >
                {' Play Market '}
              </RawLink>
              {t('welcome.step3_01')}
              <RawLink
                onClick={() =>
                  windowOpen(iproxyDownload).focus()}
              >
                {' APK '}
              </RawLink>
              {t('welcome.step3_1')}
            </RowText>
          </div>
        </RowContent>
      </Row>

      <Row>
        <NumSpan>
          4.
        </NumSpan>
        <RowContent>
          <div>
            <RowText>
              {t('welcome.step4_0')}
              <RawLink
                onClick={() =>
                  windowOpen(t('welcome.step4_link')).focus()}
              >
                {t('welcome.step4_1')}
              </RawLink>
              {' '}
            </RowText>
          </div>
        </RowContent>
      </Row>

      <Row>
        <NumSpan>
          5.
        </NumSpan>
        <RowContent>
          <div>
            <RowText>
              {t('welcome.step5_0')}
              {' '}
              <RawLink
                onClick={() =>
                  windowOpen(telegramSupport).focus()}
              >
                Telegram
              </RawLink>
              {' '}
              {t('welcome.step5_1')}
            </RowText>
          </div>
        </RowContent>
      </Row>
      <BackgroundFon />
    </Wrapper>
  );
}

const BackgroundFon = styled.div`
  max-width: 730px;
  width: 100%;
  height: 500px;
  background-image: url(${bgFone});
  position: absolute;
  top: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  min-height: 450px;
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const Row = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const RowContent = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.black};
  ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const NumSpan = styled.span`
  color: ${(props: UIProps) =>
    props.theme.colors.black};
  ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const RowText = styled.span`
${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const RawLink = styled.span`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  font-weight: 500;
  cursor: pointer;

  :hover {
    text-decoration: underline;
    opacity: 0.85;
  }
`;

const TopInstruction = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
  color: ${(props: UIProps) =>
    props?.theme?.colors?.dark};
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
`;

const SubHeading = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: UIProps) =>
    props?.theme?.colors?.darkGray};
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
`;

const Heading = styled.div`
  color: ${(props: UIProps) =>
    props?.theme?.colors?.deepBlue};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumBold};
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;
