import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Handles, Slider, Tracks } from 'react-compound-slider';

import iconDirection from '../assets/bullet-direction.svg';
import { UIProps } from '../types';

interface SliderBarProps {
  onSlideEnd: Function;
  value?: number;
  from?: number;
  to?: number;
  step?: number;
}

function Handle({ handle: { id, value, percent }, getHandleProps }: any) {
  return (
    <Bullet value={value} percent={percent} {...getHandleProps(id)}>
      <div className="value">
        {' '}
        {value}
        {' '}
      </div>
    </Bullet>
  );
}

function Track({ source, target, getTrackProps }: any) {
  return (
    <TackLine
      left={source.percent}
      width={target.percent - source.percent}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
}

export const SliderBar: FunctionComponent<SliderBarProps> = (props) => {
  const {
    onSlideEnd = () => {}, value = 0, from = 0, to = 100, step = 1,
  } = props;

  const sliderStyle = {
    // Give the slider some width
    position: 'relative',
    width: '100%',
    height: 50,
    display: 'flex',
  };

  const railStyle: any = {
    position: 'absolute',
    width: '100%',
    height: 5,
    marginTop: 15,
    borderRadius: 9,
    background: 'rgba(133,152,202, 0.5)',
  };

  return (
    <Wrapper>
      <Slider
        rootStyle={sliderStyle}
        domain={[from, to]}
        step={step}
        mode={2}
        values={[value]}
        onSlideEnd={(value) =>
          onSlideEnd(Array.isArray(value) ? value[0] : value)}
      >
        <div style={railStyle} />
        <Handles>
          {({ handles, getHandleProps }) =>
            (
              <div className="slider-handles">
                {handles.map((handle) =>
                  (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      getHandleProps={getHandleProps}
                    />
                  ))}
              </div>
            )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) =>
            (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) =>
                  (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
              </div>
            )}
        </Tracks>
      </Slider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

interface TackLineProps {
  left: number;
  width: number;
}

const TackLine = styled.div<TackLineProps>`
  position: absolute;
  height: 5px;
  z-index: 1;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  background-color: rgba(135, 155, 205, 1);
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  cursor: pointer;
  left: ${(props: TackLineProps) =>
    props.left}%;
  width: ${(props: TackLineProps) =>
    props.width}%;
`;

interface BulletProps {
  percent: string;
  value?: number;
}

const Bullet = styled.div<BulletProps>`
  left ${(props: BulletProps) =>
    `${props.percent}%`};
  position: absolute;
  margin-left: -15px;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  z-index: 2;
  width: 28px;
  height: 28px;
  border: 0;
  text-align: center;
  cursor: pointer;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.round};
  background: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  box-shadow: 0 0 6px rgba(36, 78, 178, 0.5);
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

    :hover {
      opacity: .9;
    }
   :after {
     content: '';
     background-image: url(${iconDirection});
     background-repeat: no-repeat;
     background-size: contain;
     width: 13px;
     height: 13px;
     position: absolute;
     right: -20px;
   }

  :before {
    content: '';
    background-image: url(${iconDirection});
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
    width: 13px;
    height: 13px;
    position: absolute;
    left: -20px;
  }

  .value {
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    display: flex;
    align-items: center;
    color: ${(props: UIProps) =>
    props.theme.colors.white};
  }
`;
