import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BaseModalFormParams } from '../../modal';
import { BasicButton } from '../../styles/ui-controls';
import { InputField } from '../../elements';

interface ChangeConnectionNameFormProps extends BaseModalFormParams {

}

export const ChangeConnectionNameForm: FunctionComponent<ChangeConnectionNameFormProps> = () => {
  const { t } = useTranslation();
  const [massKey, setMassKey] = useState('');

  const onSave = () => {
    // if(selectedData?.length > 0){
    //     store.dispatch(updateMultipleConnectionField.request({
    //         phoneIds: selectedPhones,
    //         key: massKey,
    //     }))
    // } else {
    //
    // }
  };

  return (
    <Wrapper>
      <InputField
        floatLabel
        placeholder={`${t('editForm.key')}`}
        value={massKey}
        onChange={({ target }) =>
          setMassKey(target.value)}
      />

      <BasicButton onClick={() =>
        onSave()}
      >
        {t('editForm.save')}
      </BasicButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    max-width: 500px;
    min-width: 300px;
    width: 100%;
`;
