import React, {
  FunctionComponent,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DropdownElement } from '../../../elements';
import { Port } from '../../../types/phones';
import { UIProps } from '../../../types';

interface PortStatisticsProps {
  portOptions: Port[];
  selectedPhonePort: number;
  setSelectedPhonePort: Function;
}

export const PortStatistics: FunctionComponent<PortStatisticsProps> = (props) => {
  const { portOptions, selectedPhonePort, setSelectedPhonePort } = props;
  const { t } = useTranslation();

  return (
    <Wrapper>
      {t('blackWhiteList.port')}

      <DropdownWrapper>
        <DropdownElement
          isSearchable={false}
          value={portOptions[selectedPhonePort]}
          onSelected={({ value }) => {
            setSelectedPhonePort(value);
          }}
          options={portOptions}
        />
      </DropdownWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
`;

const DropdownWrapper = styled.div`
  width: 150px;
`;
