import React, { useState, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalFormParams } from '../../../modal';
import store from '../../../core/store/store';
import { PhonesScrollList } from '../../PhonesScrollList';
import { useModalHook } from '../../../hooks';
import { WrapperScreen } from '../../../styles/ui-controls';
import { rebootPhones } from '../../../core/store/actions/connections';
import { showNotification } from '../../../utils';
import { FormContainer, Wrapper } from './styles';

interface MultiRebootDeviceProps extends BaseModalFormParams {}

export const MultiRebootDevice: FunctionComponent<MultiRebootDeviceProps> = (
  props,
) => {
  const { modalParams } = props;

  const modalOptions = useModalHook();
  const { t } = useTranslation();

  const [selectedPhones, setSelectedPhones] = useState<string[]>(null);

  const submitHandler = (phonesId: string[]) => {
    setSelectedPhones(phonesId);
  };

  useEffect(() => {
    if (selectedPhones != null) {
      store.dispatch(
        rebootPhones({
          phoneIds: selectedPhones,
          callback: () => {
            setTimeout(() => {
              modalOptions.hide();
              showNotification(`${t('connection.commandHasBeenExecuted')}`);
            }, 200);
          },
        }),
      );
    }
  }, [selectedPhones]);

  return (
    <WrapperScreen>
      <Wrapper>
        <FormContainer marginBottom="100px" marginBottomAdaptive="100px">
          <div className="info">
            <div className="title">{t('proSettings.rebootDevice')}</div>
          </div>
          <PhonesScrollList
            isValid={!selectedPhones}
            phonesId={
              modalParams?.selectedPhones !== null
                ? modalParams?.selectedPhones
                : []
            }
            actionText={t('proSettings.rebootDevice')}
            onActionSubmit={submitHandler}
          />
        </FormContainer>
      </Wrapper>
    </WrapperScreen>
  );
};
