import styled, { css } from 'styled-components';

import { UIProps } from '../types';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../utils/constants';
import iconPlay from '../assets/play_icon.svg';

type FontSize = 'smaller' | 'small' | 'smallBold' | 'medium' | 'mediumBold' | 'largeBold' | 'large';
type FontPosition = 'center' | 'left' | 'right';
export type BasicButtonColor = 'deepBlue' | 'warning' | 'greenMud';
interface BasicButtonProps extends UIProps {
  customeStyle?: string;
  fillWidth?: boolean;
  fillHeight?: boolean;
  color?: BasicButtonColor;
  size?: FontSize;
  align?: FontPosition;
  icon?: string;
  disable?: boolean;
}

export const BasicButton = styled.div<BasicButtonProps>`
border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  background-color: ${(props) =>
    (!props.disable
      ? props?.color ? props.theme.colors[props?.color] : props.theme.colors.deepBlue
      : props.theme.colors.spaceGray)
};
  color: ${(props) =>
    props.theme.colors.white};
  width: ${(props: BasicButtonProps) =>
    (props.fillWidth ? '100%' : 'fit-content')};
  height: ${(props: BasicButtonProps) =>
    (props.fillHeight ? '100%' : 'auto')};
  position: relative;
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  cursor: pointer;
  display: flex;
  justify-content: ${(props:BasicButtonProps) =>
    (props.align != null ? props.align : 'center')};
  align-items: center;
  transition: .3s;
  pointer-events: ${(props:BasicButtonProps) =>
    (props.disable ? 'none' : '')};

  :hover {
    opacity: .8;
  }

  :before {
    content: '';
    display: ${(props:BasicButtonProps) =>
    (props.icon != null ? 'block' : 'none')};
    background-image: url('${(props:BasicButtonProps) =>
    props.icon}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 15px;
    height: 15px;
    left: 20px;
  }

  ${(props) =>
    props && css`
        ${(prop: BasicButtonProps) =>
    prop.customeStyle};
        ${(prop: BasicButtonProps) =>
    prop.size != null && prop.theme.sizes.font[prop.size]};
        ${(prop: BasicButtonProps) =>
    (prop.icon != null ? 'padding-left: 50px' : '')};
    `}

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    width: 100%;
  }
`;

export const Box = styled.div`
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.mediumZero};
  box-sizing: border-box;
`;

interface InputWrapperProps extends UIProps {
  width?: string;
  marginBottom?: string;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: ${(props) =>
    props.marginBottom || '12px'};
  width: ${(props) =>
    props.width || '100%'};
  font: 112.5%/1.45em 'Montserrat', sans-serif;

  @media(max-width: ${BREAKPOINT_MOBILE}px){
    margin-bottom: ${(props) =>
    props.marginBottom || props.theme.sizes.gap.small};
  }
`;

export const InputLabel = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  /* white-space: nowrap; */

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }
`;

export const EditModalActionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
  }
`;

interface EditModalSaveProp extends UIProps {
  disabled?: boolean;
}

export const EditModalSave = styled.div<EditModalSaveProp>`
  display: flex;
  align-items: center;

  width: fit-content;
  height: 48px;
  padding: 0 25px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

  box-sizing: border-box;
  text-align: center;

  background-color: ${(props: EditModalSaveProp) =>
    (props.disabled ? props.theme.colors.spaceGray : props.theme.colors.deepBlue)};

  color: ${(props: UIProps) =>
    props.theme.colors.white};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  cursor: pointer;
  transition: .3s;

  &:hover {
    opacity: .9;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    justify-content: center;
  }
`;

export const EditModalStatusLabel = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;

export const ModalSubtitle = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
  color: ${(props: UIProps) =>
    props.theme.colors.dark};

  @media (max-width: ${BREAKPOINT_MOBILE}px){
    ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
  }
`;

export const VideoInstructionBlock = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  /* padding-bottom: 7px;
  padding-left: 40px; */
  padding-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  position: relative;
  display: flex;
  cursor: pointer;

  :before {
    position: relative;
    left: -12px;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background-image: url(${iconPlay});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

interface WrapperScreenProps {
  noFullHeight?: boolean;
}

export const WrapperScreen = styled.div<WrapperScreenProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px;

  ${(props: WrapperScreenProps) =>
    (props.noFullHeight ? '' : `@media (max-width: ${BREAKPOINT_TABLET}px) {min-height: 100vh;}`)}
`;

interface DnDListWrapperProps extends UIProps {
  icon?: string;
}

export const DnDListWrapper = styled.div<DnDListWrapperProps>`
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  .group-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .item {
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerMedium};
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid ${(props: UIProps) =>
    props.theme.colors.paleBlue};
    min-height:15px;

    :hover {
      transition: .2s;
      background: rgba(180, 180, 180, 0.15);

      .close {
        display: block;
      }

      .edit {
        display: block;
      }
    }
  }

  .close {
    display: none;
    position: absolute;
    width: 10px;
    height: 10px;
    cursor: pointer;
    top: 13px;
    right: 15px;
    background-image: url(${(props: DnDListWrapperProps) =>
    props.icon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .edit {
    display: none;
    position: absolute;
    width: 10px;
    height: 10px;
    cursor: pointer;
    top: 5px;
    right: 40px;
  }

  .empty-list {
    width: 100%;
    height: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
  }
`;

export const ProBlockWrapper = styled.li`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};

  &:before {
    content: '';
    width: 7px;
    height: 7px;
    min-width: 7px;
    background-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.round};
  }

  &:last-child {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

interface StyledProBlockWrapperProps {
  isTelegramBot?: boolean;
}

export const StyledProBlockWrapper = styled(ProBlockWrapper)<StyledProBlockWrapperProps>`
  ${(props) =>
    props.isTelegramBot && `
    &:nth-child(3),
    &:nth-child(4) {
      &:before {
        opacity: 0;
      }
    }
  `}

  &:last-child {
    ${(props) =>
    props.isTelegramBot && `
      p {
        font-weight: 700;
      }
    `}

    &:before {
      opacity: 0;
    }
  }
`;
