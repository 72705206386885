import React, {
  useMemo, useState, useEffect,
} from 'react';

const promocodeLocalStorage = 'promocode';

export const PromocodeBannerContext = React.createContext<any | null>(null);

export function PromocodeBannerProvider({ children }: any) {
  /* eslint-disable no-undef */
  const windowGlobal = typeof window !== 'undefined' && window;
  const storedPromocode = windowGlobal?.localStorage?.getItem(promocodeLocalStorage);
  const [promocode, setPromocode] = useState(storedPromocode ? JSON.parse(storedPromocode) : null);
  const [showPromocodeBanner, setShowPromocodeBanner] = useState(promocode?.flag === 'non-closed');

  const closePromocodeBanner = () => {
    const updatedPromocode = { code: promocode?.code, flag: 'closed' };
    windowGlobal.localStorage?.setItem(promocodeLocalStorage, JSON.stringify(updatedPromocode));
    setPromocode(updatedPromocode);
  };

  useEffect(() => {
    setShowPromocodeBanner(promocode?.flag === 'non-closed');
  }, [promocode]);

  const initialContextState = useMemo(() =>
    ({
      showPromocodeBanner,
      promocode,
      setShowPromocodeBanner,
      closePromocodeBanner,
    }), [showPromocodeBanner, promocode, setShowPromocodeBanner, closePromocodeBanner]);

  return (
    <PromocodeBannerContext.Provider value={initialContextState as any}>
      {children}
    </PromocodeBannerContext.Provider>
  );
}
