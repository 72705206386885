import { createReducer } from 'typesafe-actions';

import moment from 'moment';
import {
  addNewConnection,
  buyPhonePhonePlan,
  changeTariffPlan,
  createNewConnection,
  getPhonePinCode,
  getPhoneRealInfo,
  loadPhoneIpHistory,
  loadUserConnections,
  loadUserPhones,
  updateConnectionCred,
  removeConnection,
  removePhone,
  updateConnection,
  removeChangeIpUrl,
  addChangeIpUrl,
  loadPhonesIpHistory,
  showNotRotatedConnections,
  updatePhoneMass,
  loadPhoneSmsHistory,
  loadUserAlerts,
  removeAlert,
  createNewAlert,
  loadOpenVPN,
  loadAllOpenVPNs,
  removeOpenVPN,
  createNewOpenVPN,
  updateOpenVPN,
  updateOpenVPNMass,
  getTrafficMonthly,
  updateConnectionIp,
} from '../actions/connections';
import { Connection } from '../../../types';
import {
  Alert, OpenVPN, Phone, PhonesStoreState,
} from '../../../types/phones';

const initialAppState: PhonesStoreState = {
  loading: false,
  data: null,
};

const connectionReducer = createReducer(initialAppState)
  .handleAction(loadUserPhones.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(loadUserPhones.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: payload,
    }))
  .handleAction(loadUserPhones.failure, (state) =>
    ({ ...state, loading: false }))

  .handleAction(loadUserAlerts.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(loadUserAlerts.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: attachAlerts(payload, state.data),
    }))
  .handleAction(loadUserAlerts.failure, (state) =>
    ({ loading: true, ...state }))

  .handleAction(removeAlert.request, (state, { payload }) =>
    ({ loading: true, data: $deleteAlert(payload.phoneId, payload.alertId, state.data) }))
  .handleAction(removeAlert.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $deleteAlert(payload.phoneId, payload.alertId, state.data),
    }))
  .handleAction(removeAlert.failure, (state) =>
    ({ loading: true, ...state }))

  .handleAction(createNewAlert.request, (state) =>
    ({
      ...state,
      loading: true,
    }))
  .handleAction(createNewAlert.success, (state) =>
    ({
      ...state,
      loading: false,
    }))
  .handleAction(createNewAlert.failure, (state) =>
    ({ loading: true, ...state }))

  .handleAction(loadOpenVPN.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(loadOpenVPN.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: attachOpenVPN(payload, state.data),
    }))
  .handleAction(loadOpenVPN.failure, (state) =>
    ({ loading: true, ...state }))

  .handleAction(loadAllOpenVPNs.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(loadAllOpenVPNs.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: attachOpenVPN(payload, state.data),
    }))
  .handleAction(loadAllOpenVPNs.failure, (state) =>
    ({ loading: true, ...state }))

  .handleAction(removeOpenVPN.request, (state, { payload }) =>
    ({ loading: true, data: $deleteOpenVPN(payload.openVPNId, state.data) }))
  .handleAction(removeOpenVPN.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $deleteOpenVPN(payload.id, state.data),
    }))
  .handleAction(removeOpenVPN.failure, (state) =>
    ({ loading: true, ...state }))

  .handleAction(createNewOpenVPN.request, (state) =>
    ({
      ...state,
      loading: true,
    }))
  .handleAction(createNewOpenVPN.success, (state) =>
    ({
      ...state,
      loading: false,
    }))
  .handleAction(createNewOpenVPN.failure, (state) =>
    ({ loading: true, ...state }))

  .handleAction(updateOpenVPN.request, (state) =>
    ({ ...state, loading: true }))
  .handleAction(updateOpenVPN.success, (state) =>
    ({ ...state, loading: false }))
  .handleAction(updateOpenVPN.failure, (state) =>
    ({ ...state, loading: false }))

  .handleAction(updateOpenVPNMass.request, (state) =>
    ({ ...state, loading: true }))
  .handleAction(updateOpenVPNMass.success, (state) =>
    ({ ...state, loading: false }))
  .handleAction(updateOpenVPNMass.failure, (state) =>
    ({ ...state, loading: false }))

  .handleAction(loadUserConnections.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(loadUserConnections.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: attachConnections(payload, state.data),
    }))
  .handleAction(loadUserConnections.failure, (state) =>
    ({ loading: true, ...state }))

  .handleAction(createNewConnection.request, (state) =>
    ({
      ...state,
      loading: true,
      data: $addPhonePlaceholder(state.data || []),
    }))
  .handleAction(createNewConnection.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: addPhone(state.data, payload),
    }))
  .handleAction(createNewConnection.failure, (state) =>
    ({ loading: true, ...state }))

// Remove connection from phone
  .handleAction(removeConnection.request, (state, { payload }) =>
    ({
      loading: true,
      phoneId: payload.phoneId,
      data: $deleteConnectionPlaceholder(payload.phoneId, state.data),
    }))
  .handleAction(removeConnection.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $deleteConnection(payload.phoneId, payload.connectionId, state.data),
    }))
  .handleAction(removeConnection.failure, (state) =>
    ({
      ...state,
      loading: true,
      data: $deleteConnectionError(state.phoneId, state.data),
    }))

// Add new connection
  .handleAction(addNewConnection.request, (state, { payload }) =>
    ({
      ...state,
      loading: true,
      data: $addConnectionPlaceHolder(payload.connection, state.data),
    }))
  .handleAction(addNewConnection.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $addConnection(payload, state.data),
    }))
  .handleAction(addNewConnection.failure, (state) =>
    ({ loading: true, ...state }))

  .handleAction(updateConnection.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(updateConnection.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $updatePhones(state.data, [payload]),
    }))
  .handleAction(updateConnection.failure, (state) =>
    ({ loading: false, ...state }))

// mass update
  .handleAction(updatePhoneMass.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(updatePhoneMass.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $updatePhones(state.data, payload),
    }))
  .handleAction(updatePhoneMass.failure, (state) =>
    ({ loading: false, ...state }))

// phone ip history
  .handleAction(loadPhoneIpHistory.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(loadPhoneIpHistory.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $attachIpHistory(state.data, payload),
    }))
  .handleAction(loadPhoneIpHistory.failure, (state) =>
    ({ loading: false, ...state }))

// phone sms history
  .handleAction(loadPhoneSmsHistory.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(loadPhoneSmsHistory.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $attachSmsHistory(state.data, payload),
    }))
  .handleAction(loadPhoneSmsHistory.failure, (state) =>
    ({ loading: false, ...state }))

// phone ip history
  .handleAction(loadPhonesIpHistory.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(loadPhonesIpHistory.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $attachIpDuplicatesIpHistory(state.data, payload),
    }))
  .handleAction(loadPhonesIpHistory.failure, (state) =>
    ({ loading: false, ...state }))

// phone ip history
  .handleAction(showNotRotatedConnections.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(showNotRotatedConnections.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $hilightIfIpDidNotChnaged(state.data, payload),
    }))
  .handleAction(showNotRotatedConnections.failure, (state) =>
    ({ loading: false, ...state }))

// get phone pin
  .handleAction(getPhonePinCode.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(getPhonePinCode.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $attachPhonePin(state.data, payload),
    }))
  .handleAction(getPhonePinCode.failure, (state) =>
    ({ loading: false, ...state }))

// get real status
  .handleAction(getPhoneRealInfo.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(getPhoneRealInfo.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $attachOnlineStatsAndPhoneInfo(state.data, payload),
    }))
  .handleAction(getPhoneRealInfo.failure, (state) =>
    ({ loading: false, ...state }))

// get traffic monthly
  .handleAction(getTrafficMonthly.request, (state) =>
    ({ loading: true, ...state }))
  .handleAction(getTrafficMonthly.success, (state, { payload }) =>
    ({
      ...state,
      loading: false,
      data: $attachTrafficMonthly(state.data, payload),
    }))
  .handleAction(getTrafficMonthly.failure, (state) =>
    ({ loading: false, ...state }))

// remove phone
  .handleAction(removePhone.request, (state, { payload }) =>
    ({ ...state, loading: true, data: $removePhonePreset(state.data, payload.phoneId) }))
  .handleAction(removePhone.success, (state, { payload }) =>
    ({ ...state, loading: false, data: $removePhone(state.data, payload.phoneId) }))
  .handleAction(removePhone.failure, (state) =>
    ({ loading: false, ...state }))

// add phone tariff
  .handleAction(buyPhonePhonePlan.request, (state) =>
    ({ ...state, loading: true }))
  .handleAction(buyPhonePhonePlan.success, (state, { payload }) =>
    ({ ...state, loading: false, data: $updatePhoneTariff(state.data, payload.phone) }))
  .handleAction(buyPhonePhonePlan.failure, (state) =>
    ({ ...state, loading: false }))

// update phone tariff
  .handleAction(changeTariffPlan.request, (state) =>
    ({ ...state, loading: true }))
  .handleAction(changeTariffPlan.success, (state, { payload }) =>
    ({ ...state, loading: false, data: $updatePhoneTariff(state.data, payload.phone) }))
  .handleAction(changeTariffPlan.failure, (state, { payload }) =>
    ({ ...state, loading: false, error: payload }))

// update connection acl
  .handleAction(updateConnectionCred.request, (state) =>
    ({ ...state, loading: true }))
  .handleAction(updateConnectionCred.success, (state, { payload }) =>
    ({ ...state, loading: false, data: $updateConnection(payload.phoneId, state.data, payload?.connection) }))
  .handleAction(updateConnectionCred.failure, (state) =>
    ({ ...state, loading: false }))

// update connection ip
  .handleAction(updateConnectionIp.request, (state) =>
    ({ ...state, loading: true }))
  .handleAction(updateConnectionIp.success, (state, { payload }) =>
    ({ ...state, loading: false, data: $updateConnection(payload.phoneId, state.data, payload?.connection) }))
  .handleAction(updateConnectionIp.failure, (state) =>
    ({ ...state, loading: false }))

// remove change ip url
  .handleAction(removeChangeIpUrl.request, (state) =>
    ({ ...state, loading: true }))
  .handleAction(removeChangeIpUrl.success, (state, { payload }) =>
    ({ ...state, loading: false, data: $updateChangeIpKeys(payload.phoneId, state.data, payload?.changeIPKeys) }))
  .handleAction(removeChangeIpUrl.failure, (state) =>
    ({ ...state, loading: false }))

// remove change ip url
  .handleAction(addChangeIpUrl.request, (state) =>
    ({ ...state, loading: true }))
  .handleAction(addChangeIpUrl.success, (state, { payload }) =>
    ({ ...state, loading: false, data: $updateChangeIpKeys(payload.phoneId, state.data, payload?.changeIPKeys) }))
  .handleAction(addChangeIpUrl.failure, (state) =>
    ({ ...state, loading: false }));

export default connectionReducer;
export type ConnectionState = ReturnType<typeof connectionReducer>;

const $updatePhoneTariff = (phones: Phone[], phoneUpdates: Phone) =>
  phones?.map((phone: Phone) =>
    (phone.id === phoneUpdates?.id ? { ...phone, activePlans: phoneUpdates?.activePlans } : phone));

const $removePhonePreset = (phones: Phone[], phoneId: string) =>
  phones?.map((phone: Phone) =>
    (phone.id === phoneId ? { ...phone, uiActivityStatus: ('removed' as any) } : phone));

const $removePhone = (phones: Phone[], phoneId: string) =>
  phones?.filter((phone: Phone) =>
    phone.id !== phoneId);

const $attachOnlineStatsAndPhoneInfo = (phones: Phone[], statusInfo) =>
  phones?.map((phone: Phone) => {
    const temp = phone;
    if (!temp?.hasOwnProperty('deviceMetrics')) {
      temp.deviceMetrics = {};
    }
    temp.deviceMetrics.batteryLevel = statusInfo[phone.id]?.battery;
    temp.onlineStatus = statusInfo[phone.id];
    return temp;
  });

const attachConnections = (connections: Connection[], phones: Phone[]) =>
  phones?.map((phone: Phone) => {
    const temp = phone;
    temp.connections = connections.filter((connection: Connection) =>
      connection.phoneId === phone.id);
    return temp;
  });

const attachAlerts = (alerts: Alert[], phones: Phone[]) =>
  phones?.map((phone: Phone) => {
    const temp = phone;
    temp.alerts = alerts.filter((alert: Alert) =>
      alert.cn === phone.id);
    return temp;
  });

const attachOpenVPN = (openVpn: OpenVPN[], phones: Phone[]) =>
  phones?.map((phone: Phone) => {
    const temp = phone;
    const filteredOpenVPN = openVpn.filter((ovpn: OpenVPN) =>
      ovpn.phoneId === phone.id);
    if (filteredOpenVPN.length > 0) {
      temp.openVPN = filteredOpenVPN;
      return temp;
    }
    return temp;
  });

const addPhone = (phones: Phone[], newPhone: Phone) => {
  const updatePhones = phones?.filter((i) =>
    i.uiActivityStatus == null);
  updatePhones.push(newPhone);
  return updatePhones;
};

const $addPhonePlaceholder = (phones: Phone[]) => {
  const preset: Phone = { uiActivityStatus: 'new', timestamp: +new Date() };
  return [...phones, preset];
};

const $addConnection = (connection: Connection, phones: Phone[]) =>
  phones?.map((phone: Phone) => {
    const newPhone = { ...phone };
    if (phone.id === connection.phoneId) {
      if (phone.connections != null) {
        const connections = phone.connections.slice(0, phone.connections.length);
        connections.push(connection);
        newPhone.connections = connections;
      } else {
        newPhone.connections = [connection];
      }
      newPhone.isProxyCreating = false;
    }
    return newPhone;
  });

const $addConnectionPlaceHolder = (connection: Connection, phones: Phone[]) =>
  phones?.map((phone: Phone) => {
    const newPhone = { ...phone };

    if (phone.id === connection.phoneId) {
      newPhone.isProxyCreating = true;
    }
    return newPhone;
  });

const $deleteConnection = (phoneId: string, connectionId: string, phones: Phone[]) =>
  phones?.reduce((accum, value) => {
    if (value.id === phoneId) {
      const phone = { ...value };
      const connections = phone.connections.slice(0, phone.connections.length);
      phone.connections = connections.filter((connection) =>
        connection.id !== connectionId);
      accum.push(phone);
      phone.isProxyDeleting = false;
      phone.isProxyDeletingError = false;
      return accum;
    }
    accum.push(value);
    return accum;
  }, []);

const $deleteConnectionPlaceholder = (phoneId: string, phones: Phone[]) =>
  phones?.map((phone: Phone) => {
    const newPhone = { ...phone };

    if (phone.id === phoneId) {
      newPhone.isProxyDeleting = true;
    }
    return newPhone;
  });

const $deleteConnectionError = (phoneId: string, phones: Phone[]) =>
  phones?.map((phone: Phone) => {
    const newPhone = { ...phone };

    if (phone.id === phoneId) {
      newPhone.isProxyDeleting = false;
      newPhone.isProxyDeletingError = true;
    }
    return newPhone;
  });

const $deleteAlert = (phoneId: string, alertId: string, phones: Phone[]) =>
  phones?.reduce((accum, value) => {
    if (value.id === phoneId) {
      const phone = { ...value };
      const alerts = phone.alerts.slice(0, phone.alerts.length);
      phone.alerts = alerts.filter((alert) =>
        alert.id !== alertId);
      accum.push(phone);
      return accum;
    }
    accum.push(value);
    return accum;
  }, []);

const $deleteOpenVPN = (openVPNId: string, phones: Phone[]) =>
  phones?.reduce((accum, value) => {
    if (value.openVPN?.find((o) =>
      o.id === openVPNId)) {
      const phone = { ...value };
      const openVPN = phone.openVPN.slice(0, phone.openVPN.length);
      phone.openVPN = openVPN.filter((ovpn) =>
        ovpn.id !== openVPNId);
      accum.push(phone);
      return accum;
    }
    accum.push(value);
    return accum;
  }, []);

const $updatePhones = (phone: Phone[], updatedPhones: Phone[]) => {
  const ids = updatedPhones.map((it) =>
    it.id);
  const s = phone?.map((phone: Phone) => {
    const index = ids.indexOf(phone.id);
    if (index >= 0) {
      return {
        ...phone,
        ...updatedPhones[index],
      };
    }
    return phone;
  });
  return s;
};

const $updateChangeIpKeys = (phoneId: string, phones: Phone[], changeIPKeys) =>
  phones?.map((phone: Phone) =>
    (phone?.id === phoneId && phone?.connections != null ? ({ ...phone, changeIPKeys }) : phone));

const $updateConnection = (phoneId: string, phones: Phone[], connection: Connection) =>
  phones?.map((phone: Phone) => {
    const newPhone = { ...phone };
    if (phone?.id === phoneId && phone?.connections != null) {
      newPhone.connections = phone?.connections.map((con) => {
        let newCon = { ...con };
        if (con?.id === connection?.id) {
          newCon = connection;
        }
        return newCon;
      });
    }
    return newPhone;
  });

const $attachIpHistory = (phones: Phone[], { phoneId, history }) =>
  phones?.map((phone: Phone) =>
    (phone.id === phoneId
      ? { ...phone, ipHistory: history }
      : phone));

const $attachSmsHistory = (phones: Phone[], { phoneId, history }) =>
  phones?.map((phone: Phone) =>
    (phone.id === phoneId
      ? { ...phone, smsHistory: history }
      : phone));

const $attachIpDuplicatesIpHistory = (phones: Phone[], { ipDublicates }) =>
  phones?.map((phone) => {
    const hasDublicates = ipDublicates?.find(({ phoneId }) =>
      phoneId === phone?.id);
    return hasDublicates != null
      ? { ...phone, ipDublicates: hasDublicates?.ips }
      : phone;
  });

const $hilightIfIpDidNotChnaged = (phones: Phone[], phoneWithIpIssues) =>
  phones?.map((phone) => {
    const hasIssue = phoneWithIpIssues?.find(({ phoneId }) =>
      phoneId === phone?.id);
    return hasIssue != null
      ? {
        ...phone,
        ipNotChnagedForSomeTime: true,
        lastIpChangeTimestamp: moment(hasIssue?.lastIpChangeTimestamp).diff(new Date(), 'minute'),
      }
      : phone;
  });

const $attachPhonePin = (phones: Phone[], { phoneId, pin }) =>
  phones?.map((phone: Phone) =>
    (phone.id === phoneId
      ? { ...phone, devicePin: pin }
      : phone));

const $attachTrafficMonthly = (phones: Phone[], { phoneId, trafficMonthly }) =>
  phones?.map((phone: Phone) =>
    (phone.id === phoneId
      ? { ...phone, trafficMonthly }
      : phone));
