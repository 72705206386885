import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Phone } from '../../../types/phones';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { Plan } from '../../../types';
import { LogsWeeklyTool } from '../../LogsWeeklyTool';
import ScrollWrapper from '../../common/ScrollWrapper';
import { EmptyMessage } from '../../common/EmptyMessage';

interface LogsFormProps {
  phone?: Phone;
}

export const LogsForm: FunctionComponent<LogsFormProps> = (props) => {
  const { phone } = props;
  const tariffPlans = useTypedSelector(({ plans }) =>
    plans.data);
  const { profile } = useTypedSelector(({ user }) =>
    user);

  const { t } = useTranslation();

  const selectTariff = useMemo(
    () =>
      tariffPlans?.find(
        (plan: Plan) =>
          plan?.id === phone?.activePlans[0]?.id,
      ) || null,
    [phone, tariffPlans],
  );

  return !profile?.isLogsForbidden ? (
    <ScrollWrapper>
      <LogsWeeklyTool
        phone={phone}
        phoneTariff={selectTariff as any}
        logsVisibleWeeksNumber={selectTariff?.logsVisibleWeeksNumber || 1}
      />
    </ScrollWrapper>
  ) : (
    <EmptyMessage>{t('logs.none')}</EmptyMessage>
  );
};
