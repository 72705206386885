import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import store from '../core/store/store';
import theme from '../styles/ApplicationTheme';
import AppNavigator from '../navigation/app-navigator';
import { CommonAppProvider } from '../context/CommonAppContext';
import { ConnectionViewProvider } from '../context/ConnectionViewContext';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CommonAppProvider>
          <ConnectionViewProvider>
            <AppNavigator />
          </ConnectionViewProvider>
        </CommonAppProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
