import React from 'react';

import { NavigationStack } from '../../types';
import { DashboardScreen } from '../../screens';
import { routes } from '../routes';

export const commonStack: NavigationStack = [
  {
    private: true,
    path: routes.common.dashboard,
    component: (<DashboardScreen />),
  },
];
