import React, {
  FunctionComponent,
  useContext,
  useMemo,
} from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import iconRemove from '../../../assets/phone_icons/remove_icon.svg';
import iconCopy from '../../../assets/coppy_icon.svg';

import { UIProps } from '../../../types';
import store from '../../../core/store/store';
import {
  addChangeIpUrl,
  removeChangeIpUrl,
} from '../../../core/store/actions/connections';
import { showNotification } from '../../../utils';

import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { ButtonLoader } from '../../atoms';
import ScrollWrapper from '../../common/ScrollWrapper';
import {
  BREAKPOINT_MOBILE,
  LINK_REL,
  TARGET_BLANK,
} from '../../../utils/constants';

interface UrlProps {}

export const Url: FunctionComponent<UrlProps> = () => {
  const context = useContext(ConnectionEditViewContext);

  const { t } = useTranslation();

  const urlList = useMemo(() =>
    context?.selectedPhone?.changeIPKeys?.map((v) => {
      const changeIpUrl = `https://i.fxdx.in/api-rt/changeip/${context?.selectedPhone.id}/${v}`;
      return (
        <URL key={v}>
          <a
            href={changeIpUrl}
            target={TARGET_BLANK}
            rel={LINK_REL}
          >
            {changeIpUrl}
          </a>
          <Icons>
            <URLIcon
              url={iconCopy}
              onClick={() => {
                showNotification(`${t('notification.urlCopied')}`);
                copy(`${changeIpUrl}`);
              }}
            />
            {context?.selectedPhone?.changeIPKeys?.length > 1 ? (
              !context?.isOwner
              && !context?.permissions?.urlChangeIPRemove ? null : (
                <URLIcon
                  url={iconRemove}
                  onClick={() => {
                    store.dispatch(
                      removeChangeIpUrl.request({
                        phoneId: context?.selectedPhone?.id,
                        code: v,
                      }),
                    );
                  }}
                />
                )
            ) : null}
          </Icons>
        </URL>
      );
    }), [context?.selectedPhone?.changeIPKeys]);

  return (
    <Wrapper>
      <ChangeUrlContainer>
        <Title>{t('rotation.url')}</Title>
        {!context?.isOwner && !context?.permissions?.urlChangeIPAdd ? null : (
          <div className="add_url_bt">
            <ButtonLoader
              size="small"
              timer={300}
              onClick={() => {
                store.dispatch(
                  addChangeIpUrl.request({
                    phoneId: context?.selectedPhone?.id,
                  }),
                );
              }}
            >
              {t('rotation.addNewUrl')}
            </ButtonLoader>
          </div>
        )}
      </ChangeUrlContainer>
      <ScrollWrapper autoHeightMax={440}>
        <UrlListWrapper>{urlList}</UrlListWrapper>
      </ScrollWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  position: relative;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-top: 32px;
  }

  .add_url_bt {
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 100%;
    }
  }
`;

const UrlListWrapper = styled.div`
  padding-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const Title = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
`;

const ChangeUrlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    align-items: start;
  }
`;

const URL = styled.div`
  position: relative;
  width: 100%;
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  display: flex;
  align-items: center;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-top: 24px;
  }

  a {
    display: inline-block;
    width: 80%;

    text-decoration: none;
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    transition: 0.1s;

    :hover {
      opacity: 0.8;
    }
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
  flex: 1 1;
`;
interface URLIconProps {
  url: any;
}

const URLIcon = styled.div<URLIconProps>`
  width: 16px;
  height: 16px;
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  background-image: url(${(props: URLIconProps) =>
    props?.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  cursor: pointer;

  &:last-child {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  &:hover {
    box-shadow: 0 0 10px ${(props: UIProps) =>
    props.theme.colors.deepIndigo};
  }
`;
