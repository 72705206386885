import React, {
  FunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { telegramBot } from '../../../utils/constants';
import { ReactMD } from '../../common/ReactMarkdown';
import theme from '../../../styles/ApplicationTheme';
import { InstructionBlock, Wrapper } from '../SMSApp/About';
import { ProBlockWrapper, StyledProBlockWrapper } from '../../../styles/ui-controls';
import { UIProps } from '../../../types';

interface AboutProps {
}

export const About: FunctionComponent<AboutProps> = () => {
  const { t } = useTranslation();

  const WHAT_CAN_BOT_DO_ITEMS = [
    { text: 'bot.about.send', subtext: 'uptime' },
    { text: 'bot.about.notify', subtext: 'ip' },
    { text: 'bot.about.allow', subtext: 'changeIp' },
  ];

  const HOW_TO_SET_UP_ITEMS = [
    { text: 'bot.about.onTelegram', subtext: 'find' },
    { text: 'bot.about.command', subtext: 'login' },
    { text: 'sms.login' },
    { text: 'sms.example', subtext: 'example' },
    { text: 'bot.about.depending', subtext: 'wait' },
    { text: 'bot.about.once' },
    { text: 'bot.about.message' },
    { text: 'bot.about.welcome' },
  ];

  return (
    <Wrapper>
      <InnerWrapper>
        <ReactMD
          markdown={t('bot.about.whatCanBotDo', {
            link: '**@iproxy_online_bot**',
          })}
          fontSize={theme.sizes.font.small}
          color={theme.colors.darkGray}
        />

        <InstructionBlock>
          {
            WHAT_CAN_BOT_DO_ITEMS.map(({ text, subtext }) =>
              (
                <ProBlockWrapper key={text}>
                  <ReactMD
                    markdown={
                      t(text, {
                        [subtext]: `**${t(`bot.about.${subtext}`)}**`,
                      })
                    }
                    fontSize={theme.sizes.font.small}
                    color={theme.colors.darkGray}
                  />
                </ProBlockWrapper>
              ))
          }
        </InstructionBlock>
      </InnerWrapper>

      <InnerWrapper>
        <ReactMD
          markdown={t('bot.about.howToSetUp')}
          fontSize={theme.sizes.font.small}
          color={theme.colors.darkGray}
        />

        <InstructionBlock>
          {
            HOW_TO_SET_UP_ITEMS.map(({ text, subtext }) =>
              (
                <StyledProBlockWrapper
                  key={text}
                  isTelegramBot
                >
                  <ReactMD
                    markdown={
                      t(text, {
                        [subtext]: `**${t(`bot.about.${subtext}`)}**`,
                        link: `[@iproxy_online_bot](${telegramBot})`,
                      })
                    }
                    fontSize={theme.sizes.font.small}
                    color={theme.colors.darkGray}
                  />
                </StyledProBlockWrapper>
              ))
          }
        </InstructionBlock>
      </InnerWrapper>
    </Wrapper>
  );
};

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;
