import React, { FunctionComponent, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Scrollbars from 'react-custom-scrollbars-2';
import { UIProps } from '../../../types';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { BasicButton, ModalSubtitle } from '../../../styles/ui-controls';
import { Api } from '../../../core/api';
import store from '../../../core/store/store';
import { loadPaymentMethods } from '../../../core/store/actions/user';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { windowOpen } from '../../../utils';

interface PaymentSettingsFormProps {}

export const PaymentSettingsForm: FunctionComponent<
PaymentSettingsFormProps
> = () => {
  const user = useTypedSelector(({ user }) =>
    user);
  const paymentMethods = useMemo(() =>
    user.paymentMethods, [user]);

  const { t } = useTranslation();

  const onClickHandler = async () => {
    const { data } = await Api.get(
      '/stripe-billing-portal-url?token=[TOKEN]',
      null,
      null,
      false,
    );

    if (data && data.url) {
      windowOpen(data.url);
    }
  };

  useEffect(() => {
    store.dispatch(loadPaymentMethods.request(null, null));
  }, []);

  return (
    <Wrapper>
      <FormContainer>
        <div className="info">
          <ModalSubtitle>{t('dashboard.stripePaymentMethods')}</ModalSubtitle>
        </div>
        <div className="scroll-container">
          <Scrollbars>
            <GroupsList>
              {paymentMethods.data?.map((pm) =>
                (
                  <div className="list-item">
                    <div>
                      {pm.card.brand}
                      [
                      {pm.card.country}
                      ] XXXX-XXXX-XXXX-
                      {pm.card.last4}
                      {' '}
&nbsp;&nbsp;
                      {pm.card.exp_month}
                      /
                      {pm.card.exp_year}
                    </div>
                    <div>
                      Address:
                      {' '}
                      {pm.billing_details.address.country}
                      ,
                      {' '}
                      {pm.billing_details.address.city ?? 'null'}
                      ,
                      {' '}
                      {pm.billing_details.address.line1 ?? 'null'}
                      ,
                      {' '}
                      {pm.billing_details.address.line2 ?? 'null'}
                      , Postal Code
                      =
                      {pm.billing_details.address.postal_code ?? 'null'}
                    </div>
                  </div>
                ))}
            </GroupsList>
          </Scrollbars>
        </div>
        <BasicButton
          size="small"
          onClick={onClickHandler}
        >
          {t('dashboard.managePaymentMethods')}
        </BasicButton>
      </FormContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .scroll-container {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    height: 170px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${(props: UIProps) =>
    props.theme.colors.transparentDeepBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      min-height: 350px;
    }
  }
`;

const FormContainer = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  }

  .title {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.largeBold};
    margin-bottom: 12px;
    font-weight: 600;
  }

  .info {
    display: flex;
    align-items: center;
  }
`;

const GroupsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .list-item {
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;
