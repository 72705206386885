import styled from 'styled-components';
import { UIProps } from '../../../types';
import iconArrowLeft from '../../../assets/left_arrow.svg';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

export const DetailsHeadline = styled.div`
  .goback {
    color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    margin: 8px 0 16px;

    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin: 24px 0;
    }

    &:before {
      content: '';
      display: block;
      background-image: url(${iconArrowLeft});
      background-position: center;
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      margin-right: 8px;
    }

    &:hover {
      color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    }
  }

  .title {
    margin-bottom: 16px;
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumSemibold};
    line-height: 1;
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  }
`;

interface ActivateBtProps extends UIProps {
  isActive?: boolean;
}

export const TariffButton = styled.div<ActivateBtProps>`
  display: inline-block;
  min-width: 155px;
  position: relative;
  box-sizing: border-box;

  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  background-color: ${(props: ActivateBtProps) =>
    (props.isActive ? props.theme.colors.greenMud : props.theme.colors.deepBlue)};

  text-align: center;
  cursor: pointer;

  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  transition: .2s;

  &:hover {
    opacity: .8;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
  }
`;
