import React from 'react';
import { UpdateAppConfirmation } from './components/modals';
import {
  AddConnectionForm,
  AddConnectionGroupForm,
  AddPhoneForm,
  ChangeConnectionNameForm,
  DashboardSettingsForm,
  DownloadCredentials,
  MultiEditForm,
  PhoneEditForm,
  TariffForm,
} from './components/forms';
import { TopUpForm } from './components/common/top-up-form';
import { UpcomingChargesScreen } from './screens/UpcomingChargesScreen';
import { MultiAddGroup } from './components/forms/mass actions/multi-add-group';
import { MultiChangeRotation } from './components/forms/mass actions/multi-change-rotation';
import { MultiPayments } from './components/forms/mass actions/multi-payments-connections';
import { MultiSetupOvpnDNS } from './components/forms/mass actions/multi-setupOvpnDNS-connections';
import { MultiSetupRebootInterval } from './components/forms/mass actions/multi-setupRebootInterval-connections';
import { MultiRemove } from './components/forms/mass actions/multi-remove-connections';
import { MultiSetupNotificationsTg } from './components/forms/mass actions/multi-setupNotificationsTg-connections';
import { MultiSetupDNS } from './components/forms/mass actions/multi-setupDNS-connections';
import { MultiRename } from './components/forms/mass actions/multi-rename-connections';
import { MultiDownloadLogs } from './components/forms/mass actions/multi-download-logs';
import { MultiRebootDevice } from './components/forms/mass actions/multi-reboot-device';
import { MultiChangeIP } from './components/forms/mass actions/multi-change-ip';
import { ExpiredConnectionsScreen } from './screens/ExpiredConnectionsScreen';
import { ActivityLogsScreen } from './screens/ActivityLogsScreen';
import { DownloadSmsLink } from './components/modals/download-sms-link';
import { MultiTeamControl } from './components/forms/mass actions/multi-team-control';
import { TransactionScreen } from './screens/TransactionScreen';
import { APIScreen, ReferralScreen } from './screens';
import { UserProfileScreen } from './screens/UserProfileScreen';

export type ModalStackType =
  | 'demo'
  | 'connection.add'
  | 'connection.rename'
  | 'connection.group'
  | 'phone.tariff'
  | 'phone.edit'
  | 'balance.topUp'
  | 'phone.new'
  | 'screen.referal'
  | 'screen.news'
  | 'screen.upcomingCharges'
  | 'dashboard.settings'
  | 'screen.profile'
  | 'multi-group'
  | 'multi-rotation'
  | 'multi-payments'
  | 'multi-remove'
  | 'multi-rename'
  | 'multi-changeIP'
  | 'multi-downloadLogs'
  | 'multi-setupDNS'
  | 'multi-setupOvpnDNS'
  | 'multi-setupRebootInterval'
  | 'multi-setupNotificationsTg'
  | 'multi-rebootDevice'
  | 'screen.transactions'
  | 'phone.update.app'
  | 'multiedit'
  | 'smsapp'
  | 'screen.api'
  | 'multi-teamControl'
  | 'download-credentials'
  | 'screen.activity-logs'
  | 'screen.expired-connections';

export interface ConfirmationModalParams {
  onAccept?: Function;
  onDecline?: Function;
  acceptLabel?: string;
  declineLabel?: string;
  data?: any;
}

export interface BaseModalFormParams {
  modalParams?: any;
  initConfirmation?: (component, params?: ConfirmationModalParams) => {};
  _modalOptions?: any;
}

const MODAL_STACK = {
  demo: <div>2323</div>,
  'connection.add': <AddConnectionForm />,
  'connection.rename': <ChangeConnectionNameForm />,
  'connection.group': <AddConnectionGroupForm />,
  'balance.topUp': <TopUpForm />,
  'phone.tariff': <TariffForm />,
  'phone.edit': <PhoneEditForm />,
  'phone.update.app': <UpdateAppConfirmation />,
  'phone.new': <AddPhoneForm />,
  multiedit: <MultiEditForm />,
  'screen.upcomingCharges': <UpcomingChargesScreen />,
  'multi-group': <MultiAddGroup />,
  'multi-rotation': <MultiChangeRotation />,
  'multi-payments': <MultiPayments />,
  'multi-remove': <MultiRemove />,
  'multi-setupDNS': <MultiSetupDNS />,
  'multi-setupOvpnDNS': <MultiSetupOvpnDNS />,
  'multi-setupRebootInterval': <MultiSetupRebootInterval />,
  'multi-setupNotificationsTg': <MultiSetupNotificationsTg />,
  'multi-rename': <MultiRename />,
  'multi-changeIP': <MultiChangeIP />,
  'multi-rebootDevice': <MultiRebootDevice />,
  'multi-downloadLogs': <MultiDownloadLogs />,
  'screen.referal': <ReferralScreen />,
  'screen.transactions': <TransactionScreen />,
  'screen.profile': <UserProfileScreen />,
  'screen.api': <APIScreen />,
  'dashboard.settings': <DashboardSettingsForm />,
  'multi-teamControl': <MultiTeamControl />,
  'download-credentials': <DownloadCredentials />,
  smsapp: <DownloadSmsLink />,
  'screen.activity-logs': <ActivityLogsScreen />,
  'screen.expired-connections': <ExpiredConnectionsScreen />,
};

export default MODAL_STACK;
