import React, {
  useEffect,
  FunctionComponent, useMemo,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Phone } from '../../../types/phones';
import store from '../../../core/store/store';
import { loadPhoneIpHistory } from '../../../core/store/actions/connections';
import { DashboardConfig } from '../../../types/dashboard';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { getNumEditing } from '../../../utils';
import ScrollWrapper from '../../common/ScrollWrapper';
import { UIProps } from '../../../types';

interface DublicatedIpsProps {
  phone?: Phone;
}

export const DublicatedIps: FunctionComponent<DublicatedIpsProps> = (props) => {
  const { phone } = props;
  const { t, i18n } = useTranslation();

  const appConfigs: DashboardConfig = useTypedSelector(({ app }) =>
    app?.dashboard_config);

  useEffect(() => {
    const fromDate = moment().subtract(appConfigs?.configs?.ipUniqnesNotification || 1, 'hours').startOf('hours').toISOString();
    store.dispatch(
      loadPhoneIpHistory.request({
        phoneId: phone.id,
        from: +new Date(fromDate),
        to: +new Date(),
      }),
    );
  }, [appConfigs]);

  const totalDuplicatedTimes = useMemo(() =>
    phone?.ipDublicates?.reduce((acc, value) =>
      acc + (value?.count ?? 0), 0), [phone]);
  return (
    <ScrollWrapper>
      <Wrapper>
        <div className="description">
          <div>
            { t('history.uniq24Desc', {
              time: appConfigs?.configs?.ipUniqnesNotification || 1,
              date: getNumEditing(appConfigs?.configs?.ipUniqnesNotification || 1, i18n.language, 'hours'),
            })}
          </div>
        </div>
        <div className="stats">
          <div>
            { t('history.uniq24-item1')}
            {' '}
            <span>{phone?.ipDublicates?.length || '-'}</span>
          </div>
          <div>
            { t('history.uniq24-item2')}
            {' '}
            <span>{totalDuplicatedTimes || '-'}</span>
          </div>
          <div>
            { t('history.uniq24-item3', {
              time: appConfigs?.configs?.ipUniqnesNotification || 1,
              date: getNumEditing(appConfigs?.configs?.ipUniqnesNotification || 1, i18n.language, 'hours'),
            })}
            {' '}
            <span>{phone?.ipHistory?.length || '-'}</span>
          </div>

        </div>
        <IpList>
          <IpItem className="header-table">
            <div className="ip">IP</div>
            <div className="count">Total</div>
          </IpItem>
          { phone?.ipDublicates?.map((value) =>
            (
              <IpItem key={value?.ip}>
                <div className="ip">{value?.ip}</div>
                <div className="count">{value?.count}</div>
              </IpItem>
            ))}
        </IpList>
      </Wrapper>
    </ScrollWrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

  .stats {
    border: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

    span {
      ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
    }
  }

  .description{
    ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerZero};
    text-decoration:  underline;
  }

  .header-table{
    background-color: #e9eef878;
    font-weight: 500;
  }

  .content {
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    box-sizing: border-box;
    margin: auto;
  }
`;

const IpList = styled.div`
  display: flex;
  flex-direction: column;
`;

const IpItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d7d7d7;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;
