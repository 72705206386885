import React from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { InputField } from '../../elements';
import { showNotification } from '../../utils';

interface ApiKeyInputProps {
  apiKey: string;
  marginBottom?: string;
}

export function ApiKeyInput({
  apiKey,
  marginBottom = '0px',
}: ApiKeyInputProps) {
  const { t } = useTranslation();

  return (
    <InputField
      value={apiKey}
      style={{
        cursor: 'pointer',
        marginBottom,
      }}
      onClick={() => {
        showNotification(`${t('notification.dataCopiedSuccessfully')}`);
        copy(apiKey);
      }}
      readOnly
    />
  );
}
