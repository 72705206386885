import React, { useState, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Stepper } from '../components/common/Stepper';
import { OnbDownloadForm } from '../components/onboarding/onb-download-form';
import { OnbSetupForm } from '../components/onboarding/onb-setup-form';
import { OnbConnectionForm } from '../components/onboarding/onb-connection-form';
import { OnbFinalForm } from '../components/onboarding/onb-final-form';
import { CommonAppContext } from '../context/CommonAppContext';
import { OnbEmailForm } from '../components/onboarding/onb-email-form';
import logo from '../assets/logo_iproxy.svg';
import { createNewConnection } from '../core/store/actions/connections';
import store from '../core/store/store';
import { SideMenuActions } from './SideMenuActions';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../utils/constants';
import { useIsMobileHook } from '../hooks/useIsMobileHook';
import { UIProps } from '../types';

export const MobileTechSupport = styled.div`
  display: flex;
  background-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  height: 70px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  align-content: center;
  margin-top: 40px;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  align-items: center;
`;

const StepperContainer = styled.div`
  padding-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;

interface OnboardButtonProps extends UIProps {
  disable?: boolean;
  color?: 'blue' | 'green';
  fullWith?: boolean;
}
export const OnboardButton = styled.div<OnboardButtonProps>`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props: OnboardButtonProps) =>
    (!props?.fullWith ? 'fit-content' : '100%')};
  background: ${(props: OnboardButtonProps) =>
    (props?.color === 'green' ? '#60C7A2' : props.theme.colors.deepBlue)};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  font-style: normal;
  ${(props: UIProps) =>
    props.theme.sizes.font.smallBold};
  text-align: center;
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  padding: 16px 40px;
  cursor: pointer;
  transition: 0.3s;
  ${(props: OnboardButtonProps) =>
    props?.disable
    && css`
      opacity: 0.4;
    `}

  :hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  background-color: ${(props: UIProps) =>
    props.theme.colors.white};
  z-index: 2000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  flex-direction: column;
  width: 100%;
  height: 100%;

  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    padding: 0 30px;
    width: 100%;
  }

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    padding: 50px 0;
    justify-content: space-between;
    .content-container {
      width: 100%;
      min-width: 100%;
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

export const Sidebar = styled.div`
  background-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  width: 380px;
  min-width: 300px;
  box-sizing: border-box;
  padding: 27px;
  position: relative;

  .onb_logo {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    padding: 25px 25px 15px;

    .onb_logo {
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
      margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};

      img {
        width: 150px;
      }
    }
  }
`;

export interface OnboardingScreenProps {}

export const OnboardingScreen: FunctionComponent<
OnboardingScreenProps
> = () => {
  const [activeStep, setStep] = useState(0);
  const [onboardingDone, setDone] = useState(false);
  const [history, setHistory] = useState({});
  const context = React.useContext(CommonAppContext);
  const { t } = useTranslation();
  const isMobile = useIsMobileHook();

  const goToNextScreen = () => {
    if (activeStep < stepperConfig.length - 1) {
      setStep(activeStep + 1);
      setHistory({ ...history, [activeStep]: true });
    } else {
      setDone(true);
    }
  };

  const stepperConfig = [
    {
      title: t('onboarding.email.confirm'),
      content: (
        <OnbEmailForm
          onNext={() => {
            if (
              (context?.onboarding == null
                || context?.onboarding?.isOnboarding)
              && context?.onboarding?.phone == null
            ) {
              store.dispatch(
                createNewConnection.request({
                  onboarding: true,
                  callback: () => {
                    context?.$loadUserOnboardingStatus().then(() => {});
                  },
                }),
              );
            }
            goToNextScreen();
          }}
        />
      ),
    },
    {
      title: t('onboarding.steps.download'),
      content: <OnbDownloadForm />,
    },
    {
      title: t('proxy.authorization'),
      content: (
        <OnbSetupForm phoneId={context?.onboarding?.phone?.id} />
      ),
    },
    {
      title: t('onboarding.steps.setup'),
      content: <OnbConnectionForm onboadingData={context?.onboarding} />,
    },
    {
      title: t('onboarding.steps.finish'),
      content: <OnbFinalForm onboadingData={context?.onboarding} />,
    },
  ];

  return !onboardingDone
    && context?.onboarding != null
    && context?.onboarding?.isOnboarding ? (
      <Wrapper>
        <Content>
          <div className="content-container">
            {React.cloneElement(stepperConfig[activeStep]?.content, {
              nextScreen: goToNextScreen,
            })}
          </div>

          {
            isMobile && activeStep !== null ? (
              <MobileTechSupport>
                <SideMenuActions />
              </MobileTechSupport>
            ) : null
          }
        </Content>
        <Sidebar>
          <div className="onb_logo">
            <img width="199px" src={logo} alt="" />
          </div>

          {
            activeStep !== null ? (
              <StepperContainer>
                <Stepper config={stepperConfig} activeIndex={activeStep} />
              </StepperContainer>
            ) : null
          }

          {
            !isMobile && (
              <SideMenuActions />
            )
          }
        </Sidebar>
      </Wrapper>
    ) : null;
};
