import React, {
  useEffect, FunctionComponent, useContext, useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import { ConnectionEditViewContext } from '../../context/ConnectionEditViewContext';
import { TabsAtom, TabsAtomConfig } from '../atoms';
import { PhoneUptimePanel } from './StatisticsSettings/PhoneUptimePanel';
import { TrafficMonthly } from './StatisticsSettings/TrafficMonthly';
import { TrafficDaily } from './StatisticsSettings/TrafficDaily';

interface StatisticFormProps {
}

export const StatisticForm: FunctionComponent<StatisticFormProps> = () => {
  const context = useContext(ConnectionEditViewContext);
  const { t } = useTranslation();
  const [dailyTrafficTab, setDailyTrafficTab] = useState(0);
  const [chosenDayTimestamp, setChosenDayTimestamp] = useState(+new Date());

  const onChangeDailyTrafficTab = (tab) => {
    setDailyTrafficTab(tab);
  };

  const onChooseDay = (timestamp) => {
    setChosenDayTimestamp(timestamp);
  };

  const tabsConfig: TabsAtomConfig[] = [
    {
      key: 'uptime',
      title: 'Uptime',
      component: (
        <PhoneUptimePanel
          id={context?.selectedPhone?.id}
          setChosenDayTimestamp={setChosenDayTimestamp}
        />
      ),
    },
    {
      key: 'traffic monthly',
      title: t('stats.trafficMonthly'),
      component: (
        <TrafficMonthly
          handleChangeDailyTrafficTab={onChangeDailyTrafficTab}
          handleChooseDay={onChooseDay}
          setChosenDayTimestamp={setChosenDayTimestamp}
        />
      ),
    },
    {
      key: 'traffic daily',
      title: t('stats.trafficDaily'),
      component: (
        <TrafficDaily
          chosenDayTimestamp={chosenDayTimestamp}
        />
      ),
    },
  ];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Wrapper>
      <ReactTooltip />
      <TabsAtom
        config={tabsConfig}
        getActiveTab={onChangeDailyTrafficTab}
        dailyTab={dailyTrafficTab}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;
