import React, { FunctionComponent, Suspense, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { t } from 'i18next';
import store from './core/store/store';
import { setDashboardConfig } from './core/store/actions/app';
import { ConnectionViewContext } from './context/ConnectionViewContext';

import { LoaderIcon } from './components/common/LoaderIcon';
import { Phone } from './types/phones';
import { NON_GROUP } from './utils/constants';
import { UIProps } from './types';
import { PhonesGridComponent } from './components/PhonesGridComponent';
import { PhonesListComponent } from './components/PhonesListComponent';

interface PhoneViewProps {
  dashboardConfigs?: any;
  showOnlyOnline?: any;
}

export const PhonesView: FunctionComponent<PhoneViewProps> = (props) => {
  const { dashboardConfigs, showOnlyOnline } = props;
  const context = React.useContext(ConnectionViewContext);

  const $onConnectionFormatType = (e) => {
    store.dispatch(
      setDashboardConfig.request({
        configs: {
          ...dashboardConfigs?.configs,
          credExportFormat: e,
        },
      }),
    );
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [context?.boardData]);

  return (
    <Wrapper>
      <ReactTooltip />
      <Suspense
        fallback={(
          <ComponentLoaderHolder>
            <LoaderIcon width="100px" height="100px" />
          </ComponentLoaderHolder>
        )}
      >
        {context?.boardData != null ? (
          context?.boardData.some((phone: Phone) =>
            (showOnlyOnline !== null
              ? showOnlyOnline
                ? phone?.onlineStatus?.online
                : !phone?.onlineStatus?.online
              : true)) && (
              <div>
                {context?.gridView ? (
                  context?.allGridView ? (
                    context.groups.map((group) => {
                      const phones = context?.phones?.filter((item) =>
                        group.connections.find((c) =>
                          c.id === item.id));
                      return phones?.length ? (
                        <React.Fragment key={group.key}>
                          <div className="group">
                            {group.key !== NON_GROUP
                              ? group.key
                              : t('connection.noGroup')}
                          </div>

                          <PhonesGridComponent
                            searchPhrase={context?.searchKey}
                            showOnlyOnline={showOnlyOnline}
                            phones={phones}
                          />
                        </React.Fragment>
                      ) : null;
                    })
                  ) : (
                    <PhonesGridComponent
                      searchPhrase={context?.searchKey}
                      showOnlyOnline={showOnlyOnline}
                      phones={context?.boardData}
                    />
                  )
                ) : (
                  <PhonesListComponent
                    selectedPhonesIds={context?.selectedPhones}
                    searchPhrase={context?.searchKey}
                    inGroup
                    connectionSeparator={
                    dashboardConfigs?.configs?.credExportFormat
                  }
                    onConnectionFormatType={(e) =>
                      $onConnectionFormatType(e)}
                    showOnlyOnline={showOnlyOnline}
                    phones={context?.boardData}
                    onSelectedPhones={(data) =>
                      context?.setSelectedPhones(data)}
                  />
                )}
              </div>
          )
        ) : (
          <ComponentLoaderHolder>
            <LoaderIcon width="100px" height="100px" />
          </ComponentLoaderHolder>
        )}
      </Suspense>
    </Wrapper>
  );
};

const ComponentLoaderHolder = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 100%;
`;

const Wrapper = styled.div`
  .group {
    cursor: unset;
    white-space: wrap;
    color: ${(props) =>
    props.theme.colors.deepBlue};
    ${(props: UIProps) =>
    props.theme.sizes.font.medium};
    font-weight: 600;
    margin: 24px 0 10px;
  }
`;
