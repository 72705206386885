import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Error } from '../../elements';

import checkIcon from '../../assets/check-icon.svg';
import errorIcon from '../../assets/close-red.svg';
import { UIProps } from '../../types';

interface NewPasswordChecksProps {
  check: boolean;
  msg: string;
}

export const NewPasswordChecks: FunctionComponent<NewPasswordChecksProps> = (props) => {
  const { check, msg } = props;

  return (
    <InnerWrapper>
      <img
        alt={check ? 'Check icon' : 'Error icon'}
        src={check ? checkIcon : errorIcon}
      />
      <Error>
        {msg}
      </Error>
    </InnerWrapper>
  );
};

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;
