import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getUrlParameterByName } from '../utils';

import en from './en.json';
import ru from './ru.json';
import tr from './tr.json';
import th from './th.json';
import uk from './uk.json';
import pt from './pt.json';
import vi from './vi.json';
import es from './es.json';
import hi from './hi.json';
import zh from './zh.json';

import { storage } from '../services';

const DEFAULT_LANG = 'en';

const resources = {
  en: {
    translation: en,
  },
  pt: {
    translation: pt,
  },
  ru: {
    translation: ru,
  },
  es: {
    translation: es,
  },
  tr: {
    translation: tr,
  },
  ua: {
    translation: uk,
  },
  vi: {
    translation: vi,
  },
  th: {
    translation: th,
  },
  zh: {
    translation: zh,
  },
  hi: {
    translation: hi,
  },
};

const getLng = () => {
  const lsLang = window.localStorage.getItem('system/locale');
  const paramLang = getUrlParameterByName('lang') === 'uk' ? 'ua' : getUrlParameterByName('lang');

  if (paramLang && resources[paramLang]) {
    storage.addItem('SYSTEM', 'locale', paramLang);
    return resources[paramLang] != null ? paramLang : DEFAULT_LANG;
  }

  if (lsLang) {
    return resources[lsLang] != null ? lsLang : DEFAULT_LANG;
  }

  return DEFAULT_LANG;
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLng(),

    // keySeparator: true, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
