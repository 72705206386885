import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { BasicButton, InputLabel, InputWrapper } from '../../../styles/ui-controls';
import { DropdownElement, InputField } from '../../../elements';
import {
  CouponModel, CouponType, Plan, UIProps,
} from '../../../types';
import iconWarning from '../../../assets/warning_icon.svg';
import iconHelper from '../../../assets/helper_icon.svg';
import { Api } from '../../../core/api';
import store from '../../../core/store/store';
import { buyPhonePhonePlan } from '../../../core/store/actions/connections';
import {
  NotificationStreams,
  useModalHook,
  useNotificatorHook,
} from '../../../hooks';
import { ReactComponent as IconIpChange } from '../../../assets/ipchange-icon.svg';
import { numberWithSpaces } from '../../../utils';
import { TariffButton, DetailsHeadline } from './TariffCommon';
import { getOptions } from '../../../constants';
import { analyticsClassnames } from '../../../utils/analytics-utils';
import { PromocodeBannerContext } from '../../../context/PromocodeBannerContext';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

interface TariffExtendProps {
  selectedTariff: Plan;
  selectedPhone;
  userBalance;
  goBack: Function;
}

export const debounceLoad = debounce(
  (callback) => {
    callback();
  },
  1000,
  {
    leading: false,
    trailing: true,
  },
);

export const TariffExtend: FunctionComponent<TariffExtendProps> = (props) => {
  const {
    selectedTariff,
    userBalance,
    selectedPhone,
    goBack = () => {},
  } = props;
  const modalOptions = useModalHook();
  const { t, i18n } = useTranslation();
  const notificator = useNotificatorHook();

  const [coupon, setCoupon] = useState<string>('');
  const [couponDetails, setCouponDetails] = useState<CouponModel>(null);
  const [couponIsValid, setCouponIsValid] = useState(null);
  const [period, setPeriod] = useState(getOptions().periodOptions[0]);
  const [requestProcessing, setRequestProcessing] = useState({
    coupon: false,
    purchase: false,
  });

  const { promocode, showPromocodeBanner, closePromocodeBanner } = React.useContext(PromocodeBannerContext);
  const isQueryPromocodeEqualToCoupon = couponDetails?.code?.toLowerCase() === promocode?.code?.toLowerCase();

  const selectedTariffName = i18n.language === 'ru' ? selectedTariff?.ruTitle : selectedTariff?.enTitle;
  const selectedTariffPrice = selectedTariff?.usdPrice;

  const updateProcessingStatus = (key, value) =>
    setRequestProcessing({ ...requestProcessing, [key]: value });

  const calculatePrice = (price, withDiscount = false) => {
    const tprice = period.value * price;
    if (!withDiscount) {
      return tprice;
    }
    return couponDetails?.type === CouponType.PERCENT
      ? tprice - (tprice / 100) * couponDetails.amount
      : tprice - couponDetails.amount;
  };

  const resetRequestLock = () => {
    setTimeout(() => {
      updateProcessingStatus('coupon', false);
      updateProcessingStatus('purchase', false);
    }, 1000);
  };

  const $applyCoupon = async () => {
    if (!requestProcessing.coupon && !requestProcessing.purchase) {
      updateProcessingStatus('coupon', true);
      try {
        const { data } = await Api.get(
          `coupon/${coupon}?planId=${selectedTariff.id}`,
          null,
          null,
          false,
        );
        setCouponDetails(data?.result == null ? {} : data?.result);

        if (data?.hasOwnProperty('result') && data != null && data?.result) {
          setCouponIsValid(data?.result.canUseCoupon);
        } else {
          setCouponIsValid(false);
        }
      } catch (e) {
        console.error('Error:> error :=', e);
      }
      resetRequestLock();
    }
  };

  const $buyTariff = () => {
    if (!requestProcessing.coupon && !requestProcessing.purchase) {
      if (
        userBalance
          - calculatePrice(selectedTariffPrice, couponDetails != null)
        < 0
      ) {
        return;
      }
      updateProcessingStatus('purchase', true);
      store.dispatch(
        buyPhonePhonePlan.request({
          phoneId: selectedPhone?.id,
          planId: selectedTariff?.id,
          code: couponIsValid ? coupon : '',
          month: period?.value != null ? period.value : 1,
        }),
      );
      resetRequestLock();
      setTimeout(() => {
        notificator.notify(
          `${t('notification.planRenewal', {
            phoneName: `${selectedPhone?.name}`,
            period: `${period?.label}`,
          })}`,
          NotificationStreams.CONNECTION_EDIT_FORM,
        );

        goBack();
      }, 1200);

      if (isQueryPromocodeEqualToCoupon && couponIsValid && showPromocodeBanner) {
        closePromocodeBanner();
      }
    }
  };

  const insufficientFunds = (calculatePrice(selectedTariffPrice) ?? 0) - (userBalance ?? 0);

  return (
    <Wrapper>
      <DetailsHeadline>
        <div
          className="goback"
          onClick={() =>
            goBack()}
        >
          {t('editForm.goBack')}
        </div>
        <div className="title">
          {t('tariff.renewal', { plan: `«${selectedTariffName}»` })}
        </div>
      </DetailsHeadline>
      <DetailsContainer hasCoupon={couponDetails != null && couponIsValid}>
        <InputWrapper>
          <InputLabel>{t('tariff.period')}</InputLabel>
          <div className="drop-down">
            <DropdownElement
              value={period}
              isSearchable={false}
              onSelected={(value) =>
                setPeriod(value)}
              options={getOptions().periodOptions}
            />
          </div>
        </InputWrapper>
        <InputWrapper>
          <InputLabel>{t('tariff.price')}</InputLabel>
          <div className="price">
            {t('wallet.rub')}
            {calculatePrice(selectedTariff?.usdPrice)}
          </div>
          {couponDetails != null && couponIsValid ? (
            <div className="discount-price">
              {t('wallet.rub')}
              {calculatePrice(selectedTariff?.usdPrice, true)}
            </div>
          ) : null}
        </InputWrapper>
        <CustomInputWrapper>
          <InputWrapper
            width="50%"
            marginBottom="0"
          >
            <InputLabel>
              {' '}
              {t('wallet.title')}
              {' '}
            </InputLabel>
            <div className="total-balance">
              {t('wallet.rub')}
              {numberWithSpaces(userBalance)}
            </div>
          </InputWrapper>

          <BasicButton
            size="small"
            className={analyticsClassnames.topUpBalance}
            onClick={() => {
              modalOptions.show('balance.topUp', { defaultValue: insufficientFunds > 0 ? insufficientFunds : null });
            }}
          >
            {t('wallet.topUp')}
          </BasicButton>
        </CustomInputWrapper>
        <PromoCodeArea>
          <ApplyPromoInput>
            <InputField
              value={coupon}
              placeholder={`${t('tariff.enterPromoCode')}`}
              onChange={({ target }) =>
                setCoupon(target.value)}
            />
            {couponDetails != null ? (
              couponIsValid ? (
                <div className="status-info">
                  {t('tariff.appliedDiscount', {
                    amount: `${couponDetails?.amount}`,
                    price: couponDetails?.type === CouponType.PERCENT ? '' : t('wallet.rub'),
                    percent: couponDetails?.type === CouponType.PERCENT ? '%' : '',
                  })}
                </div>
              ) : (
                <div className="status-info error">
                  {t('tariff.invalidPromoCode')}
                </div>
              )
            ) : null}
            {couponDetails != null && couponIsValid ? (
              <PromocodeInfo>
                {couponDetails.maxUsagesNumber > 10000
                  ? t('tariff.promoCodeWithAutoRenewal')
                  : t('tariff.promoCodeWithoutAutoRenewal')}
                <div
                  className="info-icon"
                  data-tip={`${
                    !(couponDetails.maxUsagesNumber > 10000)
                      ? t('toolTip.none')
                      : t('toolTip.yes')
                  }`}
                />
              </PromocodeInfo>
            ) : null}
          </ApplyPromoInput>
          <ApplyPromo>
            <TariffButton
              isActive={false}
              onClick={() =>
                $applyCoupon()}
            >
              {t('tariff.applyPromoCode')}
            </TariffButton>
          </ApplyPromo>
        </PromoCodeArea>
        <ButtonsArea>
          {userBalance
            - calculatePrice(selectedTariffPrice, couponDetails != null)
          < 0 ? (
            <div className="warning_msg">
              {t('tariff.insufficientFunds', {
                amount: insufficientFunds,
              })}
            </div>
            ) : null}
          <BuyTariffButton
            className={`${
              analyticsClassnames.extendCurrentTariff
            }_${selectedTariff.enTitle.replace(' ', '')}`}
            isDisabled={
              userBalance
                - calculatePrice(
                  selectedTariffPrice,
                  couponDetails != null,
                )
              < 0
            }
            onClick={() =>
              $buyTariff()}
          >
            {selectedPhone?.activePlans[0]?.id === selectedTariff?.id
              ? `${t('tariff.renew')}`
              : `${t('tariff.buy')}`}
            {requestProcessing?.purchase ? <IpChangeProgress /> : null}
          </BuyTariffButton>
        </ButtonsArea>
        <ReactTooltip />
      </DetailsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

interface DetailsContainerProps extends UIProps {
  hasCoupon: boolean;
}

const DetailsContainer = styled.div<DetailsContainerProps>`
  .drop-down {
    width: 310px;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 100%;
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: flex;
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .warning_msg {
    color: ${(props: UIProps) =>
    props.theme.colors.warning};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    display: flex;

    &:before {
      content: '';
      display: block;
      background-image: url(${iconWarning});
      background-position: center;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }
  }

  .price {
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumSemibold};
    text-decoration: ${(props: DetailsContainerProps) =>
    (props.hasCoupon ? 'line-through' : 'none')};
    color: ${(props: DetailsContainerProps) =>
    (props.hasCoupon ? props.theme.colors.darkGray : props.theme.colors.dark)};
  }

  .discount-price {
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumSemibold};
    color: ${(props: DetailsContainerProps) =>
    props.theme.colors.greenMud};
  }

  .total-balance {
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumSemibold};
  }
`;

const PromoCodeArea = styled.div`
  display: flex;
  align-items: flex-start;
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.midsmallZero};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const ButtonsArea = styled.div`
  & > div {
    margin: 0 16px 16px 0;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin: 0 0 10px;
    }
  }
`;

const ApplyPromoInput = styled.div`
  width: 100%;
  box-sizing: border-box;

  .status-info {
    padding-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    color: ${(props: UIProps) =>
    props.theme.colors.greenMud};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    &.error {
      color: ${(props: UIProps) =>
    props.theme.colors.warning};
    }
  }
`;

const ApplyPromo = styled.div`
  width: 100%;
  margin: 0 0 0 16px;

  & > div {
    padding: 13px 16px;
    line-height: 1;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: ${(props: UIProps) =>
    props.theme.sizes.gap.small} 0 0;
  }
`;

const PromocodeInfo = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.smallerSemibold};
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  display: flex;
  align-items: center;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

  .info-icon {
    width: 13px;
    height: 13px;
    background-image: url(${iconHelper});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    cursor: pointer;
  }
`;

const IpChangeProgress = styled(IconIpChange)`
  margin: auto;
  display: flex;

  * {
    fill: ${(props: UIProps) =>
    props.theme.colors.white};
  }
`;

interface ActivateBtProps extends UIProps {
  isDisabled?: boolean;
}

const BuyTariffButton = styled.div<ActivateBtProps>`
  display: inline-block;
  min-width: 155px;
  position: relative;
  box-sizing: border-box;

  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  background-color: ${(props: ActivateBtProps) =>
    (props.isDisabled
      ? props.theme.colors.spaceGray
      : props.theme.colors.greenMud)};

  text-align: center;
  cursor: pointer;

  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
  }
`;

const CustomInputWrapper = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;

    div {
      min-width: 100%;
    }
  }
`;
