import {
  ThemeUI, UIColors, UIGradients, UIShadows, UISize,
} from '../types';

const fontSizes = {
  smaller: '12px',
  small: '14px',
  medium: '16px',
  large: '18px',
};

const fontWeights = {
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
};

const lineHeight = 1.4;

const sizes: UISize = {
  maxWidth: '98%',
  borderRadius: {
    zero: '0',
    smaller: '5px',
    small: '10px',
    round: '50%',
    leftAnglesSmaller: '5px 0 0 5px',
    rightAnglesSmaller: '0 5px 5px 0',
  },
  padding: {
    zeroSmaller: '0 5px',
    smallerZero: '5px 0',
    zeroSmall: '0 10px',
    midsmallZero: '15px 0',
    smallerMedium: '5px 10px',
    smallZero: '10px 0',
    smallMedium: '10px 20px',
    zeroMedium: '0 20px',
    mediumZero: '20px 0',
  },
  gap: {
    zero: '0',
    smaller: '5px',
    small: '10px',
    midSmall: '15px',
    medium: '20px',
    large: '30px',
  },
  font: {
    smaller: `
      font-size: ${fontSizes.smaller};
      font-weight: ${fontWeights.regular};
      line-height: ${lineHeight};
    `,
    smallerMedium: `
      font-size: ${fontSizes.smaller};
      font-weight: ${fontWeights.medium};
      line-height: ${lineHeight};
    `,
    smallerSemibold: `
      font-size: ${fontSizes.smaller};
      font-weight: ${fontWeights.semiBold};
      line-height: ${lineHeight};
    `,
    smallerBold: `
      font-size: ${fontSizes.smaller};
      font-weight: ${fontWeights.bold};
      line-height: ${lineHeight};
    `,
    small: `
      font-size: ${fontSizes.small};
      font-weight: ${fontWeights.regular};
      line-height: ${lineHeight};
    `,
    smallMedium: `
      font-size: ${fontSizes.small};
      font-weight: ${fontWeights.medium};
      line-height: ${lineHeight};
    `,
    smallSemibold: `
      font-size: ${fontSizes.small};
      font-weight: ${fontWeights.semiBold};
      line-height: ${lineHeight};
    `,
    smallBold: `
      font-size: ${fontSizes.small};
      font-weight: ${fontWeights.bold};
      line-height: ${lineHeight};
    `,
    medium: `
      font-size: ${fontSizes.medium};
      font-weight: ${fontWeights.regular};
      line-height: ${lineHeight};
    `,
    mediumMedium: `
      font-size: ${fontSizes.medium};
      font-weight: ${fontWeights.medium};
      line-height: ${lineHeight};
    `,
    mediumSemibold: `
      font-size: ${fontSizes.medium};
      font-weight: ${fontWeights.semiBold};
      line-height: ${lineHeight};
    `,
    mediumBold: `
      font-size: ${fontSizes.medium};
      font-weight: ${fontWeights.bold};
      line-height: ${lineHeight};
    `,
    largeMedium: `
      font-size: ${fontSizes.large};
      font-weight: ${fontWeights.medium};
      line-height: ${lineHeight};
    `,
    largeBold: `
      font-size: ${fontSizes.large};
      font-weight: ${fontWeights.bold};
      line-height: ${lineHeight};
    `,
  },
};

const colors: UIColors = {
  lightOcean: '#EBF5FF',
  lightGray: '#f1f4fa',
  lightSky: '#F7FBFF',
  deepBlue: '#244EB2',
  darkBlue: '#001851',
  brightBlue: '#4770d1',
  paleBlue: '#eef1f8',
  azureBlue: '#6198DE',
  warning: '#FF3737',
  pinkRed: '#FF4B56',
  ligthYellow: '#fffae4',
  strongYellow: '#fff3bf',
  lightPink: '#ffe0e5',
  skyBlueTint: '#f5f8ff',
  darkGray: '#757575',
  dark: '#1D293F',
  clearDark: 'rgba(4,3,24,0.6)',
  clearWhite: 'rgb(4 3 24 / 40%)',
  lazure: '#3C75FF',
  green: '#0AC806',
  greenMud: '#1AB580',
  white: '#FFFFFF',
  corol: '#FFD6D6',
  astrall: '#A0A5D3',
  darkOcean: '#81AFDF',
  ocenlight: '#EAEEF8',
  spaceGray: '#c3c3c3',
  ashGray: '#ebebeb',
  black: '#000',
  silverGray: '#e9e9e9',
  scrollBg: 'rgba(0, 0, 0, 0.2)',
  neutralGray: '#f4f4f4',
  indigo: '#244eb18a',
  iceBlue: '#EAEEF8',
  deepIndigo: '#244eb23b',
  aquamarine: '#67CDAA',
  transparentDeepBlue: '#244eb214',
  softCloud: '#F7F8FA',
};

const gradients: UIGradients = {
  lightOcean: 'background: linear-gradient(250.86deg, #88B8E0 2.04%, #6388DA 97.97%)',
  darkBlue: 'background: linear-gradient(283.99deg, #536EFF 19.64%, #0007B5 104.42%);',
};

const shadows:UIShadows = {
  spread: '0 4px 24px #E1E8F5',
  tiny: '',
  default: '1px 1px 6px 2px #1d293f24',
};

const theme: ThemeUI = {
  fonts: '',
  colors,
  gradients,
  sizes,
  shadows,
};

export default theme;
