import React, {
  useState,
  FunctionComponent,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { isEmpty, unionBy, orderBy } from 'lodash';
import { Phone } from '../types/phones';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { LoaderIcon } from './common/LoaderIcon';
import { UIProps } from '../types';
import iconExpand from '../assets/expand_icon.svg';

import swapIcon from '../assets/swap.svg';
import { storage } from '../services';
import { DashboardConfig } from '../types/dashboard';
import store from '../core/store/store';
import { setDashboardConfig } from '../core/store/actions/app';
import { debounceCall } from '../utils';
import iconApproveActive from '../assets/checkbox.svg';
import iconApprove from '../assets/phone_icons/yes_icon.svg';
import { SortComponent } from './common/SortComponent';
import { ConnectionViewContext } from '../context/ConnectionViewContext';
import { BREAKPOINT_DESKTOP, BREAKPOINT_MOBILE } from '../utils/constants';
import { PhoneItemRowComponent } from './PhoneItemRowComponent';

const isSelected = (selectedPhones, phoneId) => {
  if (selectedPhones != null && selectedPhones.length) {
    return selectedPhones.includes(phoneId);
  }
  return false;
};

interface PhonesListComponentProps {
  phones?: Phone[];
  inGroup?: boolean;
  selectedPhonesIds?: string[];
  onSelectedPhones: Function;
  onConnectionFormatType?: Function;
  connectionSeparator?: string | null;
  showOnlyOnline: boolean | null;
  searchPhrase?: string;
}

interface SortingOrderProps {
  order: 'desc' | 'asc';
  orderKey: string;
}

export const PhonesListComponent: FunctionComponent<
PhonesListComponentProps
> = (props) => {
  const {
    phones,
    inGroup = false,
    connectionSeparator = null,
    onConnectionFormatType = () => {},
    selectedPhonesIds = [],
    searchPhrase,
    showOnlyOnline = null,
    onSelectedPhones = () => {},
  } = props;

  const { t } = useTranslation();
  const appVersion = useTypedSelector(({ app }) =>
    app.app_version);
  const {
    connectionGroups,
    isConnectionIdColumn,
    isOvpnConfigsColumn,
    isRotationColumn,
    isExternalIpColumn,
    isTariffColumn,
    isExpirationDatesColumn,
    isUsageColumn,
    isServerGeoColumn,
    isTrafficColumn,
    isCustomSorting,
  } = useTypedSelector(({ app }) =>
    app.dashboard_config?.configs);
  const { groupKey } = useContext(ConnectionViewContext);
  const currentGroup = connectionGroups?.find((item) =>
    item.name === groupKey);

  const [credTemplate, setCredTemplate] = useState(connectionSeparator === '|');
  const appConfigs = useTypedSelector(({ app }) =>
    app);
  const [expandAll, setExpandAll] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const sortingOrderObj: SortingOrderProps = {
    order: 'desc',
    orderKey: isCustomSorting ? '' : 'name',
  };
  const [sortingOrder, setSortingOrder] = useState(sortingOrderObj);

  useEffect(() => {
    setSortingOrder(sortingOrderObj);
  }, [isCustomSorting]);

  const swapTemplate = () => {
    setCredTemplate(!credTemplate);
    const template = !credTemplate ? '|' : ':';
    storage.addItem('SYSTEM', 'export_format', template);
    onConnectionFormatType(template);
  };

  const sortedLIst = useMemo(() => {
    const modifiedPhones = phones
      ?.map((item) => {
        const connectionsLength = item?.connections?.length || 0;
        return {
          ...item,
          connectionsLength,
        };
      })
      .sort((a, b) => {
        const connections = currentGroup?.connections || [];
        return connections.indexOf(a.id) - connections.indexOf(b.id);
      });

    const sortedPhones = orderBy(
      modifiedPhones,
      [sortingOrder.orderKey],
      [sortingOrder.order],
    );

    return phones != null
      ? inGroup
        ? sortedPhones?.filter((phone: Phone) =>
          (showOnlyOnline !== null
            ? showOnlyOnline
              ? phone?.onlineStatus?.online
              : !phone?.onlineStatus?.online
            : true))
        : phones?.filter((phone: Phone) =>
          (showOnlyOnline !== null
            ? showOnlyOnline
              ? phone?.onlineStatus?.online
              : !phone?.onlineStatus?.online
            : true))
      : null;
  }, [phones, showOnlyOnline, inGroup, sortingOrder, connectionGroups]);

  useEffect(() => {
    debounceCall(() => {
      setExpandAll(!isEmpty(searchPhrase));
    });
  }, [searchPhrase]);

  if (sortedLIst == null) {
    return null;
  }

  const updateExpandConfig = (status) => {
    const dashboardConf: DashboardConfig = appConfigs?.dashboard_config as DashboardConfig;
    const expandConf = dashboardConf?.configs?.expandStatus == null
      ? []
      : dashboardConf.configs?.expandStatus;

    const expand = phones?.map((phone) =>
      ({
        status,
        phoneId: phone?.id,
      }));

    store.dispatch(
      setDashboardConfig.request({
        configs: {
          ...dashboardConf.configs,
          expandStatus: unionBy(expand, expandConf as any, 'phoneId'),
        },
      }),
    );
    setExpandAll(status);
  };

  const renderPhonePreset = (phone: Phone) => {
    const title = phone?.uiActivityStatus === 'new'
      ? `${t('status.adding')}`
      : `${t('status.removing')}`;
    return (
      <ActonRowPreset
        creating={phone?.uiActivityStatus === 'new'}
        key={phone?.id}
      >
        <td colSpan={7}>
          <div className="info">
            <LoaderIcon />
            <div className="process-title">{title}</div>
          </div>
        </td>
      </ActonRowPreset>
    );
  };

  return (
    <Wrapper>
      <ConnectionsNumber>
        {`${t('connection.numberOfConnections')}: ${phones?.length}`}
      </ConnectionsNumber>
      <Table>
        <TableThead>
          <tr className="table_head_tr">
            <th className="void_th">
              <ExpandAll
                aria-label="Expand all"
                active={expandAll}
                onClick={() => {
                  updateExpandConfig(!expandAll);
                }}
              />
            </th>
            <SortComponent
              title="connection.name"
              className="name_th"
              sortingOrder={sortingOrder}
              orderKey="name"
              setSortingOrder={setSortingOrder}
            />
            {isServerGeoColumn && (
              <th className="server_geo">{t('connection.serverGeo')}</th>
            )}
            {isUsageColumn && (
              <SortComponent
                title="connection.usage"
                className="name_th"
                sortingOrder={sortingOrder}
                orderKey="connectionsLength"
                setSortingOrder={setSortingOrder}
                dataTip={t('connection.usageTooltip')}
              />
            )}
            <th className="creds_th">
              <CredentialsTemplate>
                <div
                  data-place="left"
                  data-tip={`${t('toolTip.switchProxyAccessFormat')}`}
                  className="iconBt"
                  onClick={() =>
                    swapTemplate()}
                />
                {credTemplate
                  ? `${t('connection.credentialsWithBars')}`
                  : `${t('connection.credentialsWithColons')}`}
              </CredentialsTemplate>
            </th>
            {isOvpnConfigsColumn && (
              <th className="ovpn_configs_th">oVPN Configs</th>
            )}
            {isRotationColumn && (
              <th className="rotation_th">{t('rotation.title')}</th>
            )}
            {isExternalIpColumn && (
              <th className="ex_ip_th">{t('rotation.externalIP')}</th>
            )}
            {isTariffColumn && (
              <SortComponent
                title="tariff.title"
                className="tariff_th"
                sortingOrder={sortingOrder}
                orderKey="tariffName"
                setSortingOrder={setSortingOrder}
              />
            )}
            {isExpirationDatesColumn && (
              <SortComponent
                title="tariff.expirationDates"
                className="tariff_th"
                sortingOrder={sortingOrder}
                orderKey="expDate"
                setSortingOrder={setSortingOrder}
              />
            )}
            {isConnectionIdColumn && (
              <SortComponent
                title="dashboard.connectionID"
                className="ex_ip_th"
                sortingOrder={sortingOrder}
                orderKey="id"
                setSortingOrder={setSortingOrder}
              />
            )}
            {isTrafficColumn && (
              <th className="traffic_th">{t('connection.traffic')}</th>
            )}
            <th className="last_th">
              <div
                aria-label="Select all"
                className="select-all"
                data-place="left"
                data-tip={`${t('toolTip.selectAll')}`}
                onClick={() => {
                  const selectedIds = sortedLIst?.map((i) =>
                    i?.id);

                  if (!selectAll) {
                    onSelectedPhones([
                      ...(selectedPhonesIds == null ? [] : selectedPhonesIds),
                      ...selectedIds,
                    ]);
                  } else {
                    onSelectedPhones(
                      selectedPhonesIds?.filter((i) =>
                        !selectedIds.includes(i)),
                    );
                  }
                  setSelectAll(!selectAll);
                }}
              >
                <IconBt icons={selectAll ? iconApproveActive : iconApprove} />
              </div>
            </th>
          </tr>
        </TableThead>
        <tbody>
          {sortedLIst?.map((phone: Phone, index) =>
            (phone?.uiActivityStatus != null ? (
              <React.Fragment key={phone.appId}>
                {renderPhonePreset(phone)}
              </React.Fragment>
            ) : (
              <PhoneItemRowComponent
                key={`phone_row_${phone?.id}`}
                searchKeys={searchPhrase?.split(' ')}
                phone={phone}
                expanded={expandAll}
                realTimeStatus={phone?.onlineStatus}
                index={index}
                appDashBoardConf={appConfigs?.dashboard_config}
                credTemplate={credTemplate}
                needAppUpdate={+appVersion > +phone.appVersion}
                isSelected={isSelected(selectedPhonesIds, phone.id)}
                onSelected={(phoneId) => {
                  if (isSelected(selectedPhonesIds, phoneId)) {
                    onSelectedPhones(
                      selectedPhonesIds?.filter((i) =>
                        i !== phoneId),
                    );
                  } else {
                    onSelectedPhones([
                      ...(selectedPhonesIds == null ? [] : selectedPhonesIds),
                      phoneId,
                    ]);
                  }
                }}
              />
            )))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

const TableThead = styled.thead`
  th.void_th {
    width: 30px;
  }

  th.server_geo {
    width: 150px;
    text-align: center;
  }

  th.traffic_th {
    text-align: center;
  }

  .select-all {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  th {
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      width: auto !important;
    }
  }
`;

const Wrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    overflow-x: auto;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 1700px;
  }

  thead {
    text-align: left;
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    color: rgba(0, 24, 81, 0.46);

    tr {
      border-top: 1px solid rgba(191, 191, 191, 0.4);
      position: sticky;
      top: -1px;
      background-color: ${(props: UIProps) =>
    props.theme.colors.white};
      z-index: 100;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba(191, 191, 191, 0.4);
      }

      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        z-index: 0;
      }
    }

    th {
      font-weight: normal;
      padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
      }
    }

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    }
  }

  tbody {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    }

    tr {
      border-bottom: 1px solid rgba(191, 191, 191, 0.4);
      height: 80px;
    }

    td {
      vertical-align: top;
      position: relative;
      padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerMedium};
      min-height: 60px;
      box-sizing: border-box;

      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
      }
    }
  }
`;

const CredentialsTemplate = styled.div`
  display: flex;
  align-items: center;

  .iconBt {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    cursor: pointer;
    background-image: url(${swapIcon});
    background-position: center;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
  }
`;

interface ActonRowPresetProps extends UIProps {
  creating?: boolean;
}

const ActonRowPreset = styled.tr<ActonRowPresetProps>`
  position: relative;
  height: 70px;
  width: 100%;

  .info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }

  .process-title {
    color: ${(props: ActonRowPresetProps) =>
    (props.creating
      ? props.theme.colors.deepBlue
      : props.theme.colors.warning)};
  }
`;

interface ExpandAllProps {
  active: boolean;
}

const ExpandAll = styled.div<ExpandAllProps>`
  width: 10px;
  height: 12px;
  background-image: url(${iconExpand});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  cursor: pointer;
  transform: rotate(
    ${(props: ExpandAllProps) =>
    (props.active ? '0' : '180deg')}
  );
`;

interface IconBtProps {
  icons: string;
}

const IconBt = styled.div`
  width: 20px;
  height: 15px;
  cursor: pointer;
  background-image: url(${(props: IconBtProps) =>
    props.icons});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px;
`;

const ConnectionsNumber = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: rgba(0, 24, 81, 0.46);
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    background-color: unset;

    .highlight {
      color: ${(props) =>
    props.theme.colors.deepBlue};
    }
  }
`;
