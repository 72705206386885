import React, {
  useState,
  useEffect,
  useMemo,
  FunctionComponent,
} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';
import { API_ENDPOINT } from '../../../core/api';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';

const HOURS = Array.from({ length: 24 }).map((a, i) =>
  +i);
const M_SLOTS = Array.from({ length: 12 }).map((a, i) =>
  +i);

const RED = 'rgba(255, 75, 86, 0.34)';
const YELLOW = 'rgba(255, 220, 36, 0.5)';
const GREEN = 'rgba(103, 205, 170, 0.65)';

export function perc2color(perc) {
  if (perc >= 50.0) {
    return GREEN;
  }
  if (perc > 0) {
    return YELLOW;
  }
  return RED;
}

function getSmoothPoints(arr) {
  const windowSize = 3;
  const resArr = JSON.parse(JSON.stringify(arr));
  for (let i = windowSize - 1; i < arr.length; i += 1) {
    let f = false;
    for (let j = i - windowSize + 1; j <= i; j += 1) {
      f = f || arr[j].status;
    }
    resArr[i].status = f;
  }
  return resArr;
}

interface PhoneDayUptimePanelProps {
  id: string;
  dayTimestamp: number;
  loading: boolean;
  setLoading: Function;
}

export const PhoneDayUptimePanel: FunctionComponent<PhoneDayUptimePanelProps> = (props) => {
  const {
    id, dayTimestamp, loading, setLoading,
  } = props;
  const [points, setPoints] = useState([]);
  const pDur = 5 * 60 * 1000;

  useEffect(() => {
    const from = +moment(+dayTimestamp).startOf('day');
    const to = +moment(+dayTimestamp).endOf('day') + 1;
    setLoading(true);
    axios
      .get(`${API_ENDPOINT}/v2/pinger/phone/${id}/${from}/${to}`)
      .then((d) =>
        d.data.result)
      .then((arr) => {
        setPoints(getSmoothPoints(arr));
        setLoading(false);
      });
  }, [id, dayTimestamp]);

  const slotsMap = useMemo(() => {
    const from = +moment(+dayTimestamp).startOf('day');
    const map = {};
    for (const i in points) {
      if (i) {
        const p = points[i];
        const t = +p.ts_millis;
        const k = Math.floor((+t - +from) / pDur);
        const kT = +from + k * pDur;
        const key = `t-${kT}`;
        if (map[key] === undefined) {
          map[key] = {
            t: kT,
            value: 0,
            number: 0,
          };
        }
        map[key].number = +map[key].number + 1;
        if (p.status === true) {
          map[key].value = +map[key].value + 1;
        }
      }
    }
    for (const key in map) {
      if (map[key].number !== undefined && map[key].number > 0) {
        map[key].percent = (100.0 * map[key].value) / map[key].number;
      }
    }
    return map;
  }, [loading, points, dayTimestamp, id]);

  return (
    <Wrapper>
      {HOURS.map((a, i) =>
        (
          <Hour key={`h-${a}`}>
            {M_SLOTS.map((b, j) => {
              const t = +moment(dayTimestamp).startOf('day')
                + 3600 * i * 1000
                + pDur * j;
              const slot = slotsMap[`t-${t}`];
              const vPerc = slot?.percent === undefined ? undefined : slot?.percent;
              const col = vPerc === undefined ? 'transparent' : perc2color(vPerc);
              return (
                <MinItem key={b} style={{ backgroundColor: col }}>
                  <TimeSpan>
                    {`${`${i}`.padStart(2, '0')}:${`${j * 5}`.padStart(
                      2,
                      '0',
                    )}`}
                  </TimeSpan>
                </MinItem>
              );
            })}
          </Hour>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  padding-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  height: 370px;
`;

const TimeSpan = styled.div`
  opacity: 0.5;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const Hour = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MinItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25px;
  padding: 8px;
  margin: 1px;
  border: 1px solid #f5f5f587;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: 26px 13px;
  }
`;
