import { Epic } from 'redux-observable';
import {
  catchError, filter, switchMap, withLatestFrom,
} from 'rxjs/operators';
import { isActionOf, RootAction, RootState } from 'typesafe-actions';
import { from, of } from 'rxjs';

import { Api } from '../../api';
import { idle } from '../actions/app';
import { loadPlanList } from '../actions/plans';

const ApiURLLoadPlansList = () =>
  '/plans/all';

export const loadPlanListEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loadPlanList.request)),
    withLatestFrom(state$),
    switchMap(() =>
      from(Api.get(ApiURLLoadPlansList(), null, null, false)).pipe(
        switchMap(({ data }) =>
          of(loadPlanList.success(data?.result))),
        catchError(() =>
          of(loadPlanList.failure(null))),

      )),
    catchError(() =>
      of(idle())),
  );
