import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MIN_PASSWORD_LENGTH, PASSWORD_REG_EXP } from '../constants/common';

export function useChangePasswordHook() {
  const { t } = useTranslation();

  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);

  const [passwordErrors, setPasswordErrors] = useState({
    old: {
      msg: '',
      hasError: false,
    },
    new: {
      msg: '',
      hasError: false,
    },
    confirm: {
      msg: '',
      hasError: false,
    },
  });
  const [newPasswordChecks, setNewPasswordChecks] = useState({
    isLowerCaseChar: false,
    isUpperCaseChar: false,
    isDigitChar: false,
    isSpecialChar: false,
    isValidLength: false,
  });

  const validateOldPassword = () =>
    oldPassword !== null && oldPassword !== '';

  const validateNewPassword = () =>
    password !== null
    && password !== ''
    && password !== oldPassword
    && PASSWORD_REG_EXP.test(password);

  const validateConfirmationPassword = () =>
    password === passwordConfirm;

  const isFormWithOldPasswordValid = () =>
    validateOldPassword()
      && validateNewPassword()
      && validateConfirmationPassword();

  const isFormWithoutOldPasswordValid = () =>
    validateNewPassword()
      && validateConfirmationPassword();

  const validateNewPasswordChecks = (value: string) => {
    const isValidSpecialChars = /^[A-Za-z\d@$!%*#?&]*$/.test(value);
    const isLowerCaseChar = /[a-z]/.test(value);
    const isUpperCaseChar = /[A-Z]/.test(value);
    const isDigitChar = /\d/.test(value);
    const isSpecialChar = /[@$!%*#?&]/.test(value);
    const isValidLength = value.length >= MIN_PASSWORD_LENGTH;

    if (!isValidSpecialChars) {
      setNewPasswordChecks((prevState) =>
        ({
          ...prevState,
          isLowerCaseChar,
          isUpperCaseChar,
          isDigitChar,
          isSpecialChar: false,
          isValidLength,
        }));
      return;
    }

    setNewPasswordChecks((prevState) =>
      ({
        ...prevState,
        isLowerCaseChar,
        isUpperCaseChar,
        isDigitChar,
        isSpecialChar,
        isValidLength,
      }));
  };

  useEffect(() => {
    setPasswordErrors((prevErrors) =>
      ({
        ...prevErrors,
        old: {
          msg:
          !validateOldPassword() && oldPassword?.length > 0
            ? `${t('profile.oldPswAlert')}`
            : '',
          hasError: !validateOldPassword() && oldPassword?.length > 0,
        },
      }));
  }, [oldPassword]);

  useEffect(() => {
    setPasswordErrors((prevErrors) =>
      ({
        ...prevErrors,
        new: {
          msg:
          !validateNewPassword() && password?.length > 0
            ? password === oldPassword
              ? `${t('profile.newPswMatchesOld')}`
              : password
                ? `${t('profile.newPasswordValidation')}`
                : `${t('profile.newPswAlert')}`
            : '',
          hasError: !validateNewPassword() && password?.length > 0,
        },
      }));
  }, [password, oldPassword]);

  useEffect(() => {
    setPasswordErrors((prevErrors) =>
      ({
        ...prevErrors,
        confirm: {
          msg:
          !validateConfirmationPassword() && passwordConfirm?.length > 0
            ? `${t('profile.confirmPswAlert')}`
            : '',
          hasError:
          !validateConfirmationPassword() && passwordConfirm?.length > 0,
        },
      }));
  }, [password, passwordConfirm]);

  return {
    changePasswordParams: {
      oldPassword,
      password,
      passwordConfirm,
      passwordErrors,
      newPasswordChecks,
      setOldPassword,
      setPassword,
      setPasswordConfirm,
      setPasswordErrors,
      isFormWithOldPasswordValid,
      isFormWithoutOldPasswordValid,
      validateNewPasswordChecks,
    },
  };
}
