import React, {
  useState, FunctionComponent, useMemo, useEffect,
} from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { OnboardButton } from '../../screens/OnboardingScreen';
import { UIProps } from '../../types';
import iconCopy from '../../assets/coppy_icon.svg';
import { showNotification } from '../../utils';
import { Api } from '../../core/api';
import { CommonAppContext } from '../../context/CommonAppContext';
import store from '../../core/store/store';
import { updateUserProfile } from '../../core/store/actions/user';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { CheckBox } from '../atoms/Checkbox';
import { BREAKPOINT_MOBILE } from '../../utils/constants';
import { useIsMobileHook } from '../../hooks/useIsMobileHook';

interface OnbFinalFormProps {
  onboadingData?: any;
  nextScreen?: Function;
}

export const OnbFinalForm: FunctionComponent<OnbFinalFormProps> = (props) => {
  const { onboadingData, nextScreen = () => {} } = props;
  const isMobile = useIsMobileHook();

  const [mode, setMode] = useState('http');
  const { t } = useTranslation();
  const context = React.useContext(CommonAppContext);

  const [isSubscription, setIsSubscription] = useState(true);

  const { profile } = useTypedSelector((state) =>
    state?.user);

  const proxy = useMemo(() => {
    if (onboadingData == null) return null;
    return onboadingData?.proxies?.find(
      (proxy: any) =>
        proxy.listen_service === mode,
    );
  }, [mode]);

  const changeIPUrl = `https://i.fxdx.in/api-rt/changeip/${onboadingData?.phone?.id}/${onboadingData?.phone?.changeIPKeys[0]}`;

  const $finish = async () => {
    try {
      await Api.post('/onboarding/finish').then(() => {
        context.setOnboarding({ isOnboarding: false });

        store.dispatch(
          updateUserProfile.request({
            ...profile,
            subscriptions: {
              newsletters: isSubscription,
              announcements: isSubscription,
              promotions: isSubscription,
              notifications: isSubscription,
              legal: true,
              reason: '',
            },
          }),
        );
      });
    } catch (e) {
      console.error(':>Error: ', e);
    }
  };

  useEffect(() => {
    if (!profile?.visitedOnboarding?.finish) {
      store.dispatch(
        updateUserProfile.request({
          ...profile,
          visitedOnboarding: {
            ...profile?.visitedOnboarding,
            finish: Date.now(),
          },
        }),
      );
    }
  }, []);

  return (
    <Wrapper>
      <h2>{t('onboarding.access.title')}</h2>

      <div className="info">{t('onboarding.access.desc1')}</div>

      <div className="info">{t('onboarding.access.desc2')}</div>

      <div className="connection-access">
        <SwitcherContainer>
          <Switch
            active={mode === 'http'}
            onClick={() =>
              setMode('http')}
          >
            http
          </Switch>
          <Switch
            active={mode === 'socks5'}
            onClick={() =>
              setMode('socks5')}
          >
            socks5
          </Switch>
        </SwitcherContainer>

        <div className="cred-block">
          <ConnectionContainer>
            {
              isMobile ? (
                <MobileCredView
                  onClick={() => {
                    showNotification(`${t('notification.dataCopiedSuccessfully')}`);
                    copy(
                      `${mode}:${proxy?.hostname}:${proxy?.port}:${proxy?.login}:${proxy?.password}`,
                    );
                  }}
                >
                  <div className="item">
                    <div className="label">
                      {t('proxy.type')}
                      :
                    </div>
                    <div>{mode}</div>
                  </div>

                  <div className="item">
                    <div className="label">
                      {t('proxy.ip')}
                      :
                    </div>
                    <div>{proxy?.hostname || '-'}</div>
                  </div>

                  <div className="item">
                    <div className="label">
                      {t('proxy.port')}
                      :
                    </div>
                    <div>{proxy?.port || '-'}</div>
                  </div>

                  <div className="item">
                    <div className="label">
                      {t('profile.login')}
                      :
                    </div>
                    <div>{proxy?.login || '-'}</div>
                  </div>

                  <div className="item">
                    <div className="label">
                      {t('profile.password')}
                      :
                    </div>
                    <div>{proxy?.password || '-'}</div>
                  </div>
                  <div />
                </MobileCredView>
              ) : (
                <>
                  <ItemContent
                    style={{ width: '180px' }}
                    content={`${t('proxy.type')}`}
                  >
                    <div className="overflow">{mode}</div>
                  </ItemContent>
                  <ItemContent
                    style={{ width: '370px' }}
                    content={`${t('proxy.ip')}`}
                  >
                    <div className="overflow">{proxy?.hostname || '-'}</div>
                  </ItemContent>
                  <ItemContent
                    style={{ width: '220px' }}
                    content={`${t('proxy.port')}`}
                  >
                    <div className="overflow">{proxy?.port || '-'}</div>
                  </ItemContent>
                  <ItemContent
                    style={{ width: '420px' }}
                    content={`${t('profile.login')}`}
                  >
                    <div className="overflow">{proxy?.login || '-'}</div>
                  </ItemContent>
                  <ItemContent
                    style={{ width: '500px' }}
                    content={`${t('profile.password')}`}
                  >
                    <div className="overflow">{proxy?.password || '-'}</div>
                  </ItemContent>
                </>
              )
            }
          </ConnectionContainer>
          <div className="cop-con">
            <ConnectionsCredCopy
              onClick={() => {
                showNotification(`${t('notification.dataCopiedSuccessfully')}`);
                copy(
                  `${mode}:${proxy?.hostname}:${proxy?.port}:${proxy?.login}:${proxy?.password}`,
                );
              }}
            />
          </div>
        </div>
      </div>

      <div className="ip-change">
        <div className="info">{t('onboarding.access.ipchange')}</div>
        <div className="ip-link">
          <div
            className="link"
            onClick={() => {
              showNotification(`${t('notification.dataCopiedSuccessfully')}`);
              copy(changeIPUrl);
            }}
          >
            {changeIPUrl}
          </div>
          <ConnectionsCredCopy
            onClick={() => {
              showNotification(`${t('notification.dataCopiedSuccessfully')}`);
              copy(changeIPUrl);
            }}
          />
        </div>
      </div>
      <div className="checkbox">
        <CheckBox
          value={isSubscription}
          onClick={(status) => {
            setIsSubscription(status);
          }}
          label={t('onboarding.access.checkbox')}
          className="warning"
          hasLabel
          hasBorder
        />
      </div>

      <div className="actions-finish">
        <OnboardButton
          id="bt"
          className="get_iproxi_account_information"
          color="green"
          onClick={() => {
            $finish();
            nextScreen();
          }}
        >
          {t('onboarding.access.action')}
        </OnboardButton>
      </div>
    </Wrapper>
  );
};

const MobileCredView = styled.div`
  cursor: pointer;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  display: block;
  :active,
  :focus,
  :hover {
    opacity: 0.8;
  }

  .item {
    display: flex;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }

  .label {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    ${(props: UIProps) =>
    props.theme.sizes.font.smallBold};
  }
`;

const ConnectionContainer = styled.div`
  display: flex;
  margin-top: 25px;
  width: 100%;
  max-width: 800px;
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  box-sizing: border-box;

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

interface ItemContentProps extends UIProps {
  content: string;
  isEdit?: boolean;
}
const ItemContent = styled.div<ItemContentProps>`
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  color: ${(props: ItemContentProps) =>
    (props?.isEdit ? props.theme.colors.greenMud : props.theme.colors.dark)};
  cursor: ${(props: ItemContentProps) =>
    (props?.isEdit ? 'pointer' : 'auto')};
  height: 40px;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 22px 10px;
  border-left: none;
  position: relative;
  width: 100%;

  .overflow {
    overflow: hidden;
    width: 100%;
    display: flex;
  }

  :first-child {
    border-left: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    border-bottom-left-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    border-top-left-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  }

  :last-child {
    border-bottom-right-radius:${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    border-top-right-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  }

  :before {
    content: ${(props: ItemContentProps) =>
    `"${props.content}"`};
    position: absolute;
    color: ${(props: UIProps) =>
    props.theme.colors.dark};
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    top: -20px;
    z-index: 2;
    left: 5px;
  }
`;

const ConnectionsCredCopy = styled.div`
  width: 15px;
  height: 15px;
  cursor: pointer;
  text-transform: lowercase;
  background-image: url(${iconCopy});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const SwitcherContainer = styled.div`
  background: ${(props: UIProps) =>
    props.theme.colors.skyBlueTint};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  display: flex;
  width: 200px;
  box-sizing: border-box;
  padding: 4px;
`;

interface SwitchProps {
  active?: boolean;
}

const Switch = styled.div<SwitchProps>`
  ${(props: SwitchProps) => {
    if (props.active) {
      return css`
        box-shadow: 0px 0px 4px #d2ddf9;
        background: ${(props: UIProps) =>
    props.theme.colors.white};
        color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
      `;
    }
    return '';
  }}
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  width: 100%;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallMedium};
  ${(props: UIProps) =>
    props.theme.sizes.font.smallBold};
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;

  .checkbox {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

    .warning {
      background-color: rgb(255, 206, 206);
    }
  }

  .cop-con {
    display: flex;
    align-items: center;
  }

  .cred-block {
    display: flex;
    align-items: baseline;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      box-sizing: border-box;
      width: 100%;
      border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
      background-color: ${(props: UIProps) =>
    props.theme.colors.softCloud};
    }
  }

  .connection-access {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      box-sizing: border-box;
      width: 100%;
    }
  }

  .video-guide {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    margin-bottom: 40px;
    ${(props: UIProps) =>
    props.theme.sizes.font.smallBold};
    align-items: center;
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  }

  .actions-finish {
    margin-top: 40px;

    @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
      width: 100%;
      #bt {
        width: 100%;
      }
    }
  }

  .ip-change {
    margin-top: 40px;

    .info {
      ${(props: UIProps) =>
    props.theme.sizes.font.small};
      color: ${(props: UIProps) =>
    props.theme.colors.black};
    }

    .ip-link {
      cursor: pointer;
      ${(props: UIProps) =>
    props.theme.sizes.font.smallBold};
      display: flex;
      align-items: center;
      color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

      .link {
        margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

        @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
          margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
          width: 90%;
          margin: auto;
          text-align: center;
        }
      }
    }
  }

  .actions {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  }

  .icon {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  }

  h2 {
    ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  }

  .info {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  }
`;
