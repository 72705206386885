import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useLottie } from 'lottie-react';

import animation from '../../assets/animation/animation.json';

interface LoaderIconProps {
  height?: string | number;
  width?: string | number;
}

export const LoaderIcon: FunctionComponent<LoaderIconProps> = (props) => {
  const {
    height = '50px',
    width = '50px',
  } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    height,
    width,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const { View } = useLottie(defaultOptions);

  return (
    <Wrapper height={height} width={width}>{View}</Wrapper>
  );
};

interface WrapperProps {
  width: any;
  height: any;
}

const Wrapper = styled.div<WrapperProps>`
  width: ${(props:WrapperProps) =>
    props.width};
  height: ${(props:WrapperProps) =>
    props.height};
`;
