import React, {
  useState,
  useEffect,
  useRef,
  FunctionComponent,
  useMemo,
} from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { IpHistory, Phone } from '../../../types/phones';
import store from '../../../core/store/store';
import { loadPhoneIpHistory } from '../../../core/store/actions/connections';
import { formatDate, saveToFile } from '../../../utils';
import { useOnClickOutside } from '../../../hooks';
import { InputLabel } from '../../../styles/ui-controls';
import { UIProps } from '../../../types';
import { CustomeDateInputAtom } from '../../atoms';
import iconDownload from '../../../assets/icon_download.svg';
import ScrollWrapper from '../../common/ScrollWrapper';
import { dateFormats, BREAKPOINT_MOBILE } from '../../../utils/constants';
import { EmptyMessage } from '../../common/EmptyMessage';
import { TimePickerContent } from './SmsHistoryForm';

interface IpHistoryFormProps {
  phone?: Phone;
}

const cssStyle = {
  idContainer: {
    width: '80px',
    minWidth: '50px',
  },
  date: {
    width: '185px',
    minWidth: '80px',
  },
  ip4: {
    width: '135px',
    minWidth: '100px',
  },
  ip6: {
    width: '290px',
    minWidth: '100px',
  },
};

const pageResult = 100;

export const IpHistoryForm: FunctionComponent<IpHistoryFormProps> = (props) => {
  const { phone } = props;
  const [startDate, setStartDate] = useState(
    +new Date(moment().subtract(1, 'weeks').startOf('day').toISOString()),
  );
  const [endDate, setEndDate] = useState(+new Date());
  const [page] = useState(0);
  const dateContainer = useRef(null);
  const [dateSort, setDateSort] = useState<'asc' | 'desc'>('desc');

  const [activeData, setActiveData] = useState({
    start: false,
    end: false,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (phone != null && startDate != null && endDate != null) {
      store.dispatch(
        loadPhoneIpHistory.request({
          phoneId: phone.id,
          from: +new Date(startDate),
          to: +new Date(endDate),
        }),
      );
    }
  }, [phone === undefined ? '' : phone.id, startDate, endDate]);

  const results = useMemo(
    () =>
      phone?.ipHistory
        ?.sort((a, b) =>
          (dateSort === 'desc'
            ? +new Date(b?.t) - +new Date(a.t)
            : +new Date(a?.t) - +new Date(b.t)))
        ?.reduce((accum, value) => {
          if (
            !accum.length
            || (accum.length && accum[accum.length - 1]?.length >= pageResult)
          ) {
            accum.push([value]);
          } else {
            accum[accum.length - 1].push(value);
          }
          return accum;
        }, []) || [],
    [phone, pageResult, dateSort],
  );

  const $closeCalendars = () =>
    setActiveData({ start: false, end: false });

  const $saveAsFile = () => {
    if (phone.ipHistory == null) return;
    const filename = `ips_${formatDate(moment(), dateFormats.fileDateAlt)}.txt`;
    const data = phone?.ipHistory
      .sort((a, b) =>
        +b.t - +a.t)
      .map((a) =>
        `${a.ip} - ${formatDate(a.t, dateFormats.dateTimeSec)}`)
      .join('\n');
    saveToFile(filename, data);
  };

  useOnClickOutside(dateContainer, () =>
    $closeCalendars());

  return (
    <ScrollWrapper>
      <Wrapper>
        <ControlBoard>
          <TimePickerContent ref={dateContainer}>
            <div className="date_wrap">
              <InputLabel className="label">
                {`${t(
                  'rotation.from',
                )}:`}
              </InputLabel>
              <CustomeDateInputAtom
                indicator
                value={new Date(startDate)}
                activeData={activeData.start}
                locale={i18n.language}
                onChange={(e) =>
                  setStartDate(+moment(new Date(e)).startOf('day'))}
              />
            </div>

            <div className="date_wrap">
              <InputLabel className="label">{t('rotation.to')}</InputLabel>
              <CustomeDateInputAtom
                indicator
                value={new Date(endDate)}
                activeData={activeData.end}
                locale={i18n.language}
                minDate={startDate}
                onChange={(e) =>
                  setEndDate(+moment(new Date(e)).endOf('day'))}
              />
            </div>
          </TimePickerContent>
        </ControlBoard>

        {results?.length ? (
          <>
            <HistoryContainer>
              <HeaderHistoryRow>
                <ItemHeader header style={{ ...cssStyle.idContainer }}>
                  {t('rotation.number')}
                </ItemHeader>
                <ItemHeader
                  style={{ ...cssStyle.date }}
                  hover
                  header
                  onClick={() =>
                    setDateSort(dateSort === 'desc' ? 'asc' : 'desc')}
                >
                  {t('rotation.date')}
                </ItemHeader>
                <ItemHeader header style={{ ...cssStyle.ip4 }}>
                  {t('rotation.ip')}
                </ItemHeader>
                <ItemHeader header style={{ ...cssStyle.ip6 }}>
                  {t('rotation.ip6')}
                </ItemHeader>
              </HeaderHistoryRow>
              {results[page]?.map((history: IpHistory, index) =>
                (
                  <HistoryRow key={history.ip}>
                    <Item style={{ ...cssStyle.idContainer }}>{index + 1}</Item>
                    <Item style={{ ...cssStyle.date }}>
                      {formatDate(history.t, dateFormats.dateTimeSec)}
                    </Item>
                    <Item style={{ ...cssStyle.ip4 }}>{history.ip}</Item>
                    <Item style={{ ...cssStyle.ip6 }}>{history.ip6}</Item>
                  </HistoryRow>
                ))}
            </HistoryContainer>
            <DownloadHistory onClick={() =>
              $saveAsFile()}
            >
              {t('rotation.downloadHistory')}
            </DownloadHistory>
          </>
        ) : (
          <EmptyMessage>{t('rotation.notFound')}</EmptyMessage>
        )}
      </Wrapper>
    </ScrollWrapper>
  );
};

export const Wrapper = styled.div`
  min-height: 370px;

  .calender {
    min-width: 321px;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    min-height: 100%;
  }
`;

export const ControlBoard = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;

  width: 100%;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${(props: UIProps) =>
    props.theme.colors.ashGray};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};
  box-sizing: border-box;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  overflow: auto;
  height: 200px;
`;

export const HistoryRow = styled.div`
  display: flex;
  height: 48px;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  border-bottom: 1px solid ${(props: UIProps) =>
    props.theme.colors.ashGray};

  :hover {
    background-color: ${(props: UIProps) =>
    props.theme.colors.skyBlueTint};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: ItemProps) =>
    props.theme.sizes.font.smaller};
  }
`;

export const HeaderHistoryRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props: UIProps) =>
    props.theme.colors.ashGray};
`;

interface ItemProps extends UIProps {
  header?: boolean;
}

export const Item = styled.div<ItemProps>`
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  width: 100%;
  color: ${(props: ItemProps) =>
    (props.header ? props.theme.colors.white : props.theme.colors.dark)};
  display: flex;
  align-items: center;
  ${(props: ItemProps) =>
    props.theme.sizes.font.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: ItemProps) =>
    props.theme.sizes.font.smaller};
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  :first-child {
    border-bottom-left-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    border-top-left-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  }

  :last-child {
    border-bottom-right-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    border-top-right-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  }
`;

interface ItemHeaderProps extends UIProps {
  header?: boolean;
  hover?: boolean;
}

export const ItemHeader = styled.div<ItemHeaderProps>`
  box-sizing: border-box;
  padding: 7px;
  width: 100%;
  color: ${(props: ItemProps) =>
    props.theme.colors.darkGray};
  ${(props: ItemProps) =>
    props.theme.sizes.font.small};

  :first-child {
    border-bottom-left-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    border-top-left-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  }

  :last-child {
    border-bottom-right-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    border-top-right-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: ItemProps) =>
    props.theme.sizes.font.smaller};
  }

  ${(props: ItemHeaderProps) =>
    props.hover
    && css`
      :hover {
        cursor: pointer;
        transition: 0.2s;
        opacity: 0.6;
      }
    `}
`;

export const DownloadHistory = styled.div`
  cursor: pointer;
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  position: relative;
  padding-left: 25px;
  transition: 0.3s;

  :hover {
    opacity: 0.8;
  }

  :after {
    content: '';
    display: block;
    background-image: url(${iconDownload});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;
