import { createAsyncAction } from 'typesafe-actions';
import {
  ActivityLog, AutoPaymentInfo, User, UserProfile,
} from '../../../types';

const LOGIN_USER = 'user/LOGIN_USER';
const LOGIN_USER_SUCCESS = 'user/LOGIN_USER_SUCCESS';
const LOGIN_USER_FAILURE = 'user/LOGIN_USER_FAILURE';

export const logInUser = createAsyncAction(
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
)<User, undefined, Error>();

const LOAD_USER_INFO = 'user/LOAD_USER_INFO';
const LOAD_USER_INFO_SUCCESS = 'user/LOAD_USER_INFO_SUCCESS';
const LOAD_USER_INFO_FAILURE = 'user/LOAD_USER_INFO_FAILURE';

export const loadUserInfo = createAsyncAction(
  LOAD_USER_INFO,
  LOAD_USER_INFO_SUCCESS,
  LOAD_USER_INFO_FAILURE,
)<null, UserProfile, Error>();

const LOAD_USER_BALANCE = 'user/LOAD_USER_BALANCE';
const LOAD_USER_BALANCE_SUCCESS = 'user/LOAD_USER_BALANCE_SUCCESS';
const LOAD_USER_BALANCE_FAILURE = 'user/LOAD_USER_BALANCE_FAILURE';

export const loadUserBalance = createAsyncAction(
  LOAD_USER_BALANCE,
  LOAD_USER_BALANCE_SUCCESS,
  LOAD_USER_BALANCE_FAILURE,
)<{ userId: string }, { balance: number }, Error>();

const LOAD_USER_TRANSACTIONS = 'user/LOAD_USER_TRANSACTIONS';
const LOAD_USER_TRANSACTIONS_SUCCESS = 'user/LOAD_USER_TRANSACTIONS_SUCCESS';
const LOAD_USER_TRANSACTIONS_FAILURE = 'user/LOAD_USER_TRANSACTIONS_FAILURE';

export const loadUserTransaction = createAsyncAction(
  LOAD_USER_TRANSACTIONS,
  LOAD_USER_TRANSACTIONS_SUCCESS,
  LOAD_USER_TRANSACTIONS_FAILURE,
)<null, { data: any }, Error>();

export const loadUserTransactions = createAsyncAction(
  LOAD_USER_BALANCE,
  LOAD_USER_BALANCE_SUCCESS,
  LOAD_USER_BALANCE_FAILURE,
)<{ userId: string }, { balance: number }, Error>();

const LOAD_USER_AUTOPAYMENT_INFO = 'user/LOAD_USER_AUTOPAYMENT_INFO';
const LOAD_USER_AUTOPAYMENT_INFO_SUCCESS = 'user/LOAD_USER_AUTOPAYMENT_INFO_SUCCESS';
const LOAD_USER_AUTOPAYMENT_INFO_FAILURE = 'user/LOAD_USER_AUTOPAYMENT_INFO_FAILURE';

export const loadUserAutoPaymentInfo = createAsyncAction(
  LOAD_USER_AUTOPAYMENT_INFO,
  LOAD_USER_AUTOPAYMENT_INFO_SUCCESS,
  LOAD_USER_AUTOPAYMENT_INFO_FAILURE,
)<null, { data: AutoPaymentInfo }, Error>();

const GET_API_KEY = 'user/GET_API_KEY';
const GET_API_KEY_SUCCESS = 'user/GET_API_KEY_SUCCESS';
const GET_API_KEY_ERROR = 'user/GET_API_KEY_ERROR';

export const getApiKey = createAsyncAction(
  GET_API_KEY,
  GET_API_KEY_SUCCESS,
  GET_API_KEY_ERROR,
)<{ userId: string }, { token: string }, Error>();

const UPDATE_API_KEY = 'user/UPDATE_API_KEY';
const UPDATE_API_KEY_SUCCESS = 'user/UPDATE_API_KEY_SUCCESS';
const UPDATE_API_KEY_ERROR = 'user/UPDATE_API_KEY_ERROR';

export const updateApiKey = createAsyncAction(
  UPDATE_API_KEY,
  UPDATE_API_KEY_SUCCESS,
  UPDATE_API_KEY_ERROR,
)<{ callback?: Function }, { token: string }, Error>();

const GET_INVITED_USERS = 'user/GET_INVITED_USERS';
const GET_INVITED_USERS_SUCCESS = 'user/GET_INVITED_USERS_SUCCESS';
const GET_INVITED_USERS_ERROR = 'user/GET_INVITED_USERS_ERROR';

export const getInvitedUsers = createAsyncAction(
  GET_INVITED_USERS,
  GET_INVITED_USERS_SUCCESS,
  GET_INVITED_USERS_ERROR,
)<null, { result: any }, Error>();

const GET_REFERRAL_CODES = 'user/GET_REFERRAL_CODES';
const GET_REFERRAL_CODES_SUCCESS = 'user/GET_REFERRAL_CODES_SUCCESS';
const GET_REFERRAL_CODES_ERROR = 'user/GET_REFERRAL_CODES_ERROR';

export const getReferralCodes = createAsyncAction(
  GET_REFERRAL_CODES,
  GET_REFERRAL_CODES_SUCCESS,
  GET_REFERRAL_CODES_ERROR,
)<null, string[], Error>();

const ADD_REFERRAL_CODE = 'user/ADD_REFERRAL_CODE';
const ADD_REFERRAL_CODE_SUCCESS = 'user/ADD_REFERRAL_CODE_SUCCESS';
const ADD_REFERRAL_CODE_ERROR = 'user/ADD_REFERRAL_CODE_ERROR';

export const addReferralCode = createAsyncAction(
  ADD_REFERRAL_CODE,
  ADD_REFERRAL_CODE_SUCCESS,
  ADD_REFERRAL_CODE_ERROR,
)<{ code: string, callback?: Function }, { result: any }, Error>();

const REMOVE_REFERRAL_CODE = 'user/REMOVE_REFERRAL_CODE';
const REMOVE_REFERRAL_CODE_SUCCESS = 'user/REMOVE_REFERRAL_CODE_SUCCESS';
const REMOVE_REFERRAL_CODE_ERROR = 'user/REMOVE_REFERRAL_CODE_ERROR';

export const removeReferralCode = createAsyncAction(
  REMOVE_REFERRAL_CODE,
  REMOVE_REFERRAL_CODE_SUCCESS,
  REMOVE_REFERRAL_CODE_ERROR,
)<{ code: string, callback?: Function }, { result: any }, Error>();

const GET_PAYMENT_METHODS = 'user/GET_PAYMENT_METHODS';
const GET_PAYMENT_METHODS_SUCCESS = 'user/GET_PAYMENT_METHODS_SUCCESS';
const GET_PAYMENT_METHODS_ERROR = 'user/GET_PAYMENT_METHODS_ERROR';

export const loadPaymentMethods = createAsyncAction(
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_ERROR,
)<null, any, Error>();

const UPDATE_USER_PROFILE = 'user/UPDATE_USER_PROFILE';
const UPDATE_USER_PROFILE_SUCCESS = 'user/UPDATE_USER_PROFILE_SUCCESS';
const UPDATE_USER_PROFILE_ERROR = 'user/UPDATE_USER_PROFILE_ERROR';

export const updateUserProfile = createAsyncAction(
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
)<UserProfile, { result: any, callback?: Function }, Error>();

const UPDATE_USER_PASSWORD = 'user/UPDATE_USER_PASSWORD';
const UPDATE_USER_PASSWORD_SUCCESS = 'user/UPDATE_USER_PASSWORD_SUCCESS';
const UPDATE_USER_PASSWORD_ERROR = 'user/UPDATE_USER_PASSWORD_ERROR';

export const updateUserPassword = createAsyncAction(
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
)<{ newPassword: string, oldPassword }, { result: any }, Error>();

const LOAD_USER_ACTIVITY_LOGS = 'user/LOAD_USER_ACTIVITY_LOGS';
const LOAD_USER_ACTIVITY_LOGS_SUCCESS = 'user/LOAD_USER_ACTIVITY_LOGS_SUCCESS';
const LOAD_USER_ACTIVITY_LOGS_ERROR = 'user/LOAD_USER_ACTIVITY_LOGS_ERROR';

export const loadUserActivityLogs = createAsyncAction(
  LOAD_USER_ACTIVITY_LOGS,
  LOAD_USER_ACTIVITY_LOGS_SUCCESS,
  LOAD_USER_ACTIVITY_LOGS_ERROR,
)<{ userId: string, from: number, to: number, callback?: Function, }, ActivityLog[], Error>();
