import styled from 'styled-components';
import { UIProps } from '../../../types';
import iconLink from '../../../assets/link_icon.svg';
import { BREAKPOINT_MOBILE } from '../../../utils/constants';

export const FormContainer = styled.div`
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  }

  .title {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.largeBold};
    margin-bottom: 12px;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smallBold};
    }
  }

  .info {
    display: flex;
    align-items: center;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    }
  }

  .description {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumMedium};
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smallerMedium};
    }
  }

  .helper {
    position: relative;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    display: flex;
    cursor: pointer;
    font-weight: 600;
    margin-top: -28px;
    margin-left: 21px;

    :before {
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
      content: '';
      display: block;
      width: 28px;
      height: 12px;
      background-image: url(${iconLink});
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: ${BREAKPOINT_MOBILE}px) {
        width: 28px;
        height: 12px;
      }
    }
  }
`;

export const CheckBoxWrap = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
  }
`;
