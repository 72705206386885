import React, { FunctionComponent, useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import copy from 'copy-to-clipboard';
import { ButtonLoader } from '../../atoms';
import store from '../../../core/store/store';
import {
  findPhone,
  fixPhoneLTE,
  loadUserPhones,
  rebootPhone,
  refreshPhoneConnection,
  switchPhone,
  updateConnection,
} from '../../../core/store/actions/connections';
import { useWidgetPolicyHook } from '../../../hooks/useWidgetPolicyHook';
import { ConnectionEditViewContext } from '../../../context/ConnectionEditViewContext';
import { SelectOption, UIProps } from '../../../types';
import { showNotification } from '../../../utils';

import iconCopy from '../../../assets/coppy_icon.svg';
import { DropdownElement } from '../../../elements';
import { useTypedSelector } from '../../../core/store/selectors/type-selector';
import { Api } from '../../../core/api';
import {
  BREAKPOINT_MOBILE,
  LINK_REL,
  TARGET_BLANK,
} from '../../../utils/constants';
import { ReactMD } from '../../common/ReactMarkdown';
import theme from '../../../styles/ApplicationTheme';

interface PhoneActionsProps {
  selectedPhone;
}

export const PhoneActions: FunctionComponent<PhoneActionsProps> = (props) => {
  const { selectedPhone } = props;
  const { id, rebootKeys } = selectedPhone;
  const { t } = useTranslation();
  const { hasWidgetPermission } = useWidgetPolicyHook(selectedPhone);
  const context = useContext(ConnectionEditViewContext);
  const [connectionOption, setConnectionOption] = useState<SelectOption<any>>();
  const phoneConnections = useTypedSelector(
    ({ connections }) =>
      connections.data,
  );
  const [isRemote, setIsRemote] = useState(false);

  const connectionsOptions: SelectOption<any>[] = phoneConnections
    .filter((phone) =>
      phone.id !== selectedPhone?.id)
    .map((el) =>
      ({
        value: el.id,
        label: el.name,
      }));

  const copyData = (value: string) => {
    copy(value);
    showNotification(`${t('notification.dataCopiedSuccessfully')}`);
  };

  const remoteConnection$ = async () => {
    if (connectionOption?.value) {
      await Api.post(
        `/phones/${context?.selectedPhone?.id}/change_connection`,
        {
          connectionId: connectionOption.value,
        },
        null,
        false,
      ).then(() => {
        store.dispatch(loadUserPhones.request(null));
        setIsRemote(true);
        showNotification(`${t('notification.sms')}`);
      });
    }
  };

  return (
    <Wrapper>
      <ReactTooltip />
      <Item>
        <ButtonLoader
          size="small"
          onClick={() =>
            store.dispatch(
              refreshPhoneConnection({ phoneId: selectedPhone.id }),
            )}
        >
          {t('proSettings.refreshConnections')}
        </ButtonLoader>
      </Item>
      {!context?.isOwner && !context?.permissions?.rebootDevice ? null : (
        <Item>
          <span data-tip="ROOT / Owner Mode">
            <ButtonLoader
              size="small"
              onClick={() =>
                store.dispatch(rebootPhone({ phoneId: selectedPhone.id }))}
            >
              {t('proSettings.rebootDevice')}
            </ButtonLoader>
          </span>
        </Item>
      )}
      {/* <Item>
        <ButtonLoader
          onClick={() =>
            store.dispatch(logoutPhone({ phoneId: selectedPhone.id }))
          }
        >
          {t("proSettings.logOutConnection")}
        </ButtonLoader>
      </Item> */}
      {!context?.isOwner && !context?.permissions?.fixLTE ? null : (
        <Item
          data-tip={
            !hasWidgetPermission('actionFixLte')
              ? `${t('policyMsg.msgForPro')}`
              : null
          }
        >
          <span data-tip="Only ROOT Alcatel 1 5033d">
            <ButtonLoader
              size="small"
              disabled={!hasWidgetPermission('actionFixLte')}
              onClick={() =>
                store.dispatch(fixPhoneLTE({ phoneId: selectedPhone.id }))}
            >
              {t('proSettings.fixLTE')}
            </ButtonLoader>
          </span>
        </Item>
      )}
      {!context?.isOwner && !context?.permissions?.findPhone ? null : (
        <Item>
          <ButtonLoader
            size="small"
            onClick={() =>
              store.dispatch(findPhone({ phoneId: selectedPhone.id }))}
          >
            {t('proSettings.findPhone')}
          </ButtonLoader>
        </Item>
      )}
      <Item>
        <ButtonLoader
          size="small"
          onClick={() => {
            store.dispatch(
              updateConnection.request({
                id: selectedPhone.id,
                isProxyActive: true,
              }),
            );
            store.dispatch(
              switchPhone({ phoneId: selectedPhone.id, enabled: 1 }),
            );
          }}
        >
          {t('proSettings.turnOnProxy')}
        </ButtonLoader>
      </Item>
      <Item>
        <ButtonLoader
          size="small"
          onClick={() => {
            store.dispatch(
              updateConnection.request({
                id: selectedPhone.id,
                isProxyActive: false,
              }),
            );
            store.dispatch(
              switchPhone({ phoneId: selectedPhone.id, enabled: 0 }),
            );
          }}
        >
          {t('proSettings.turnOffProxy')}
        </ButtonLoader>
      </Item>
      {context?.isOwner && (
        <BlockWrapper>
          <TitleBlock>{t('proSettings.remoteChangeConnection')}</TitleBlock>
          {context?.selectedPhone?.deviceModel ? (
            !isRemote ? (
              <>
                <ReactMD
                  markdown={t('proSettings.remoteChangeConnectionInfo', {
                    connection: `**${context?.selectedPhone?.name}**`,
                    device: `
                        **${context?.selectedPhone?.deviceManufacturer}
                          ${context?.selectedPhone?.deviceModel}
                          (Android ${context?.selectedPhone?.version})
                        **`,
                  })}
                  fontSize={theme.sizes.font.small}
                  color={theme.colors.darkGray}
                />
                <BlockWrapper>
                  <DropdownElement
                    value={connectionOption}
                    onSelected={(value) =>
                      setConnectionOption(value)}
                    options={connectionsOptions}
                    placeholder={t('proSettings.chooseConnection')}
                  />
                </BlockWrapper>
                <ButtonWrapper>
                  <ButtonLoader
                    disabled={!connectionOption}
                    size="medium"
                    onClick={remoteConnection$}
                  >
                    {t('proSettings.changeConnection')}
                  </ButtonLoader>
                </ButtonWrapper>
              </>
            ) : (
              <ReactMD
                markdown={t('proSettings.remoteChangeConnectionSuccess', {
                  connection: `**${context?.selectedPhone?.name}**`,
                })}
                fontSize={theme.sizes.font.small}
                color={theme.colors.darkGray}
              />
            )
          ) : (
            <BaseDescription>
              {t('proSettings.thisConnectionNot')}
            </BaseDescription>
          )}
        </BlockWrapper>
      )}
      <BlockWrapper>
        <TitleBlock>{t('proSettings.urlToRebootDevice')}</TitleBlock>

        <Block>
          {rebootKeys.map((item) => {
            const urlToRebootDevice = `https://iproxy.online/api-rt/reboot/${id}/${item}`;

            return (
              <LinkWrapper key={item}>
                <Link
                  href={urlToRebootDevice}
                  target={TARGET_BLANK}
                  rel={LINK_REL}
                >
                  {urlToRebootDevice}
                </Link>

                <div
                  data-class="cus_tooltip_basic"
                  data-place="top"
                  data-type="dark"
                  data-tip={t('toolTip.copy')}
                >
                  <ActionBt
                    icon={iconCopy}
                    onClick={() =>
                      copyData(urlToRebootDevice)}
                  />
                </div>
              </LinkWrapper>
            );
          })}
        </Block>
      </BlockWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Item = styled.div`
  width: 48%;
  box-sizing: border-box;
  margin: 0 0 8px 0;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const BlockWrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  width: 100%;

  &:first-child {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  }
`;

const TitleBlock = styled.div`
  box-sizing: border-box;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  font-weight: 600;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  justify-content: space-between;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Link = styled.a`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  transition: opacity 0.3s ease;
  word-break: break-word;
`;

interface ActionBtProps extends UIProps {
  icon?: string;
}

const ActionBt = styled.div<ActionBtProps>`
  display: flex;
  align-items: center;

  width: 15px;
  height: 15px;
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmaller};

  background-image: url(${(props: ActionBtProps) =>
    props.icon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  cursor: pointer;
  transition: 0.1s;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

const ButtonWrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 8px;
`;

const BaseDescription = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
`;
