import { combineReducers } from 'redux';

import app from './app';
import user from './user';
import connections from './connections';
import servers from './servers';
import plans from './plans';

export default combineReducers({
  app,
  user,
  connections,
  plans,
  servers,
});
