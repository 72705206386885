import React, { useState, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalFormParams } from '../../../modal';
import store from '../../../core/store/store';
import { PhonesScrollList } from '../../PhonesScrollList';
import { useModalHook } from '../../../hooks';
import { WrapperScreen } from '../../../styles/ui-controls';
import { Api } from '../../../core/api';
import { loadUserPhones } from '../../../core/store/actions/connections';
import { DNSAtom } from '../../edit-connection/AdvancedSettings/DNS/DNSAtom';
import { FormContainer, Wrapper } from './styles';
import { ProgressBarComponent } from './progress-bar-component';

interface MultiSetupDNSProps extends BaseModalFormParams {}

export const MultiSetupDNS: FunctionComponent<MultiSetupDNSProps> = (props) => {
  const { modalParams } = props;

  const modalOptions = useModalHook();
  const { t } = useTranslation();

  const [selectedPhones, setSelectedPhones] = useState<string[]>(null);
  const [progressView, setProgressView] = useState(false);
  const [buyCount, setBuyCount] = useState(0);

  const [ipList, setIpList] = useState([]);

  const submitHandler = (phonesId: string[]) => {
    setProgressView(true);
    setSelectedPhones(phonesId);
  };

  useEffect(() => {
    if (progressView && selectedPhones != null) {
      const request = selectedPhones.map(async (id) =>
        Api.post(
          '/phone/update',
          {
            id,
            dnsIpList: ipList,
          },
          null,
          false,
        ).then(() =>
          setBuyCount(() =>
            buyCount + 1)));

      Promise.all(request).then(() => {
        store.dispatch(loadUserPhones.request(null));
        setTimeout(() => {
          modalOptions.hide();
        }, 1200);
      });
    }
  }, [progressView, selectedPhones]);

  return (
    <WrapperScreen>
      <Wrapper scrollContainerHeigth="200px">
        <FormContainer marginBottom="0" marginBottomAdaptive="100px">
          <div className="info">
            <div className="title">{t('massActions.globalDNS')}</div>
          </div>
          <PhonesScrollList
            isValid={!progressView}
            phonesId={
              modalParams?.selectedPhones !== null
                ? modalParams?.selectedPhones
                : []
            }
            actionText={t('massActions.globalDNS')}
            onActionSubmit={submitHandler}
            additionalBlock={(
              <div className="additional-block">
                <DNSAtom setIpList={setIpList} />
              </div>
            )}
          />
        </FormContainer>
        {progressView && (
          <ProgressBarComponent
            buyCount={buyCount}
            selectedPhones={selectedPhones}
          />
        )}
      </Wrapper>
    </WrapperScreen>
  );
};
