import { SelectOption } from '../../../types';

export enum CredentialFields {
  TYPE = 'type',
  IP = 'ip',
  HOSTNAME = 'hostname',
  PORT = 'port',
  LOGIN = 'login',
  PASSWORD = 'password',
  TYPE_SYMBOL = '://',
  COLON_SYMBOL = ':',
  AT_SYMBOL = '@',
  PIPE = '|',
}

export const predefinedOptions: SelectOption<string>[] = [
  {
    value: 'type://hostname:port:login:password',
    label: 'type://hostname:port:login:password',
  },
  {
    value: 'type://ip:port:login:password',
    label: 'type://ip:port:login:password',
  },
  {
    value: 'type://login:password@hostname:port',
    label: 'type://login:password@hostname:port',
  },
  {
    value: 'type://login:password@ip:port',
    label: 'type://login:password@ip:port',
  },
];

export const fileFormats: SelectOption<string>[] = [
  {
    value: 'text/plain',
    label: '.txt',
  },
  {
    value: 'text/csv',
    label: '.csv',
  },
];

export const credentialValues = Object.values(CredentialFields);
