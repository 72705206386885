import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FormContainer } from './common';
import { CreateCredentialsFormat } from '../../common/credentials/create-credentials-format';

interface CredentialsSettingsFormProps {}

export const CredentialsSettingsForm: FunctionComponent<CredentialsSettingsFormProps> = () =>
  (
    <Wrapper>
      <FormContainer>
        <CreateCredentialsFormat
          isCredentialsSettings
        />
      </FormContainer>
    </Wrapper>
  );

const Wrapper = styled.div``;
