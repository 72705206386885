import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../../types';
import { BasicButton } from '../../styles/ui-controls';
import store from '../../core/store/store';
import { updateAppVersion } from '../../core/store/actions/connections';
import { BaseModalFormParams } from '../../modal';
import { useModalHook } from '../../hooks';
import { useTypedSelector } from '../../core/store/selectors/type-selector';

interface UpdateAppConfirmationProps extends BaseModalFormParams {}

export const UpdateAppConfirmation: FunctionComponent<UpdateAppConfirmationProps> = (props) => {
  const { modalParams } = props;
  const { phone } = modalParams;
  const modalOptions = useModalHook();
  const { t } = useTranslation();
  const app = useTypedSelector(({ app }) =>
    app);

  return (
    <Wrapper>
      <TitleBlock>{t('updateAppModal.title')}</TitleBlock>
      <Subinfo>
        {t('updateAppModal.supTitle')}
      </Subinfo>
      <Description>
        <div className="item">
          {`${t('update.current')} `}
          <span className="version old">
            {phone?.appVersion == null ? '-' : phone?.appVersion}
          </span>
        </div>
        <div className="item">
          {`${t('update.final')} `}
          <span className="version new">{app?.app_version}</span>
        </div>
      </Description>
      <Container>
        <BasicButton
          className="cancel-bt bt"
          color="warning"
          size="small"
          fillWidth
          onClick={() =>
            modalOptions.hide()}
        >
          {t('updateAppModal.no')}
        </BasicButton>
        <BasicButton
          fillWidth
          size="small"
          className="bt"
          onClick={() => {
            store.dispatch(updateAppVersion.request({ phoneId: phone?.id }));
            modalOptions.hide();
          }}
        >
          {t('updateAppModal.yes')}
        </BasicButton>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
  width: 100%;
`;

const Description = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  width: 100%;
  margin-top: -15px;
  border-top: 1px solid ${(props: UIProps) =>
    props.theme.colors.ocenlight};
  border-bottom: 1px solid ${(props: UIProps) =>
    props.theme.colors.ocenlight};
  .item {
    margin-bottom: 8px;
  }

  .new {
    color: ${(props: UIProps) =>
    props.theme.colors.greenMud};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumBold};
  }
  .old {
    color: ${(props: UIProps) =>
    props.theme.colors.warning};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.mediumBold};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  .bt {
    max-width: 100px;
    width: 100%;
  }

  .cancel-bt {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    background-color: ${(props: UIProps) =>
    props.theme.colors.warning};
    :hover {
      opacity: 0.8;
    }
  }
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.largeBold};
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  width: 100%;
`;

const Subinfo = styled.div`
  display: flex;
  align-items: end;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;
