import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import store from '../core/store/store';
import { BasicButton, WrapperScreen } from '../styles/ui-controls';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { updateApiKey } from '../core/store/actions/user';
import { ScreenInfo, InfoBlock } from './styles';

import { ApiWarningBlock } from '../components/common/ApiWarningBlock';
import { ApiKeyInput } from '../components/common/ApiKeyInput';
import { UIProps } from '../types';
import { BREAKPOINT_TABLET, telegramSupport } from '../utils/constants';
import { ReactMD } from '../components/common/ReactMarkdown';
import { showNotification } from '../utils';

interface APIScreenProps {
  onUpdate?: Function;
  noFullHeight?: boolean;
}

export function APIScreen({
  onUpdate,
  noFullHeight,
}: APIScreenProps) {
  const { api_key: apiKey } = useTypedSelector(({ user }) =>
    user.profile);
  const { t } = useTranslation();

  return (
    <WrapperScreen noFullHeight={noFullHeight}>
      <ScreenInfo
        marginBottom="25px"
        height="32px"
        justifyContent
        alignItems
        width
        flex
      >
        <div className="title">{t('api.title')}</div>
      </ScreenInfo>
      <Content>
        <ApiKeyInput
          apiKey={apiKey || '-'}
          marginBottom="12px"
        />
        <ApiWarningBlock
          components={(
            <ReactMD
              markdown={t('api.secure')}
            />
          )}
          width="100%"
          padding="0"
          imgSize="50px"
          textAlign="left"
          isWarning
        />
        <InfoBlock>
          <ReactMD
            markdown={t('api.description')}
          />
          <ReactMD
            markdown={t('api.text', {
              support: `**${t('api.support')}**`,
              link: `[@iproxy_online_support](${telegramSupport})`,
            })}
          />
        </InfoBlock>
        <Controls>
          <BasicButton
            onClick={() => {
              store.dispatch(updateApiKey.request({
                callback: () =>
                  showNotification(`${t('api.updated')}`),
              }));
              if (onUpdate) onUpdate();
            }}
            size="small"
          >
            {t('api.updateApiKey')}
          </BasicButton>
        </Controls>
      </Content>
    </WrapperScreen>
  );
}

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  max-width: 620px;

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    max-width: none;
  }

  .skip-block {
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  }
`;
