import { createAsyncAction } from 'typesafe-actions';
import { ServerListZone, UserServers } from '../../../types';

const LOAD_SERVERS_LIST = 'servers/LOAD_SERVERS_LIST';
const LOAD_SERVERS_LIST_SUCCESS = 'servers/LOAD_SERVERS_LIST_SUCCESS';
const LOAD_SERVERS_LIST_FAILURE = 'servers/LOAD_SERVERS_LIST_FAILURE';

export const loadServersList = createAsyncAction(
  LOAD_SERVERS_LIST,
  LOAD_SERVERS_LIST_SUCCESS,
  LOAD_SERVERS_LIST_FAILURE,
)<null, ServerListZone[], Error>();

const LOAD_USER_SERVERS = 'servers/LOAD_USER_SERVERS';
const LOAD_USER_SERVERS_SUCCESS = 'servers/LOAD_USER_SERVERS_SUCCESS';
const LOAD_USER_SERVERS_FAILURE = 'servers/LOAD_USER_SERVERS_FAILURE';

export const loadUserServers = createAsyncAction(
  LOAD_USER_SERVERS,
  LOAD_USER_SERVERS_SUCCESS,
  LOAD_USER_SERVERS_FAILURE,
)<null, UserServers[], Error>();
