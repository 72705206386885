import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import store from '../../core/store/store';
import { Plan, UIProps } from '../../types';
import { BasicButton } from '../../styles/ui-controls';
import { buyPhoneTariff } from '../../core/store/actions/billing';
import { Phone } from '../../types/phones';

interface TariffPurchaseConfirmationFormProps {
  plan?: Plan;
  phone?: Phone;
}

export const TariffPurchaseConfirmationForm: FunctionComponent<TariffPurchaseConfirmationFormProps> = (
  { plan, phone },
) => {
  const { t } = useTranslation();

  const $onPurchase = () => {
    store.dispatch(buyPhoneTariff({ planId: plan.id, phoneId: phone.id }));
  };
  return (
    <Wrapper>
      <Title>{plan.name}</Title>
      <Description>{plan.description}</Description>
      <PaymentRow>
        <PayBt>
          <BasicButton onClick={() =>
            $onPurchase()}
          >
            {t('tariff.payBtn', { price: `${plan.price}` })}
          </BasicButton>
        </PayBt>
      </PaymentRow>

      <Note>
        {t('tariff.paymentNote', { amount: '550' })}
      </Note>
    </Wrapper>
  );
};

const Wrapper = styled.div`

`;

const PaymentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

const Title = styled.div`
  ${(prop: UIProps) =>
    prop.theme.sizes.font.largeMedium};
`;

const Description = styled.div`
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.medium};
`;

const Note = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
`;

const PayBt = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;
