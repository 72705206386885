import React, {
  Suspense,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import Highlighter from 'react-highlight-words';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { AffiliationPhoneType, UIProps } from '../types';

import { LoaderIcon } from './common/LoaderIcon';
import {
  debounceCall,
  getExpiredPhoneConnections,
  numberWithSpaces,
} from '../utils';
import { TooltipElement } from '../elements';
import WelcomePanel from './welcome/WelcomePanel';
import { DashboardConfig } from '../types/dashboard';
import { ConnectionSearch } from './connection-board/ConnectionSearch';
import { ConnectionViewContext } from '../context/ConnectionViewContext';
import { Attention } from './common/Attantion';
import { SystemAlertComponent } from './common/SystemAlertComponent';
import { useModalHook } from '../hooks';
import {
  LINK_REL,
  NON_GROUP,
  TARGET_BLANK,
  telegramSupport,
  BREAKPOINT_MOBILE,
} from '../utils/constants';
import { DashboardActionsMenu } from './DashboardActionsMenu';
import { PhonesView } from '../PhonesView';

interface ConnectionsBoardProps {}

export const ConnectionsBoard: FunctionComponent<
ConnectionsBoardProps
> = () => {
  const modalOptions = useModalHook();

  const phoneConnections = useTypedSelector(
    ({ connections }) =>
      connections.data,
  );
  const { profile, autoPaymentInfo } = useTypedSelector(({ user }) =>
    user);
  const dashboardConfigs: DashboardConfig = useTypedSelector(
    ({ app }) =>
      app?.dashboard_config,
  );
  const { balance } = useTypedSelector(({ user }) =>
    user);
  const { t } = useTranslation();

  const [loadingPhones, setLoadingPhones] = useState(true);
  const [showOnlyOnline, setShowOnlyOnline] = useState<boolean | null>(null);
  /// / Connect context data layer
  const context = useContext(ConnectionViewContext);
  const onlinePhones = phoneConnections?.filter(
    (item) =>
      item?.onlineStatus?.online,
  )?.length;
  const expiredPhonesCount = getExpiredPhoneConnections(phoneConnections)?.length;

  useEffect(() => {
    if (phoneConnections != null) {
      debounceCall(() => {
        setTimeout(() => {
          setLoadingPhones(false);
        }, 1200);
      });
    }
  }, [phoneConnections]);

  useEffect(() => {
    if (phoneConnections != null) {
      context?.setGroupConfig(dashboardConfigs?.configs?.connectionGroups);
      context?.setPhones(phoneConnections);
    }
  }, [phoneConnections, dashboardConfigs]);

  const renderPhonesLoader = () =>
    (
      <LoaderPreset>
        <div className="cont">
          <LoaderIcon width="80px" height="80px" />
          {t('status.loading')}
        </div>
      </LoaderPreset>
    );

  const hasBoardData = context?.boardData != null && context?.boardData?.length !== 0;

  const RenderBalance = useMemo(
    () =>
      (
        <BalanceWrapper>
          <BalanceInfo>
            <Balance>
              <span className="title">
                {t('wallet.title')}
                :
              </span>
              <b
                className="span value"
                onClick={() =>
                  modalOptions.show('balance.topUp')}
              >
                {t('wallet.rub')}
                {numberWithSpaces(balance)}
              </b>
            </Balance>
            <Balance>
              <span className="title">
                {t('wallet.nextPayment')}
                :
              </span>
              <b
                className="span value"
                onClick={() =>
                  modalOptions.show('screen.upcomingCharges')}
              >
                {t('wallet.rub')}
                {numberWithSpaces(autoPaymentInfo?.data?.sum || 0)}
              </b>
            </Balance>
            <Balance>
              <span className="title">
                {t('common.online')}
                :
              </span>
              <b className="span">
                {phoneConnections ? (
                  `${onlinePhones} / ${phoneConnections?.length}`
                ) : (
                  <LoaderIcon width="15px" height="15px" />
                )}
              </b>
            </Balance>
            <Balance>
              <span className="title">
                {t('common.expired')}
                :
              </span>
              <b
                className="span value"
                onClick={() =>
                  modalOptions.show('screen.expired-connections')}
              >
                {phoneConnections ? (
                  `${expiredPhonesCount} / ${phoneConnections?.length}`
                ) : (
                  <LoaderIcon width="15px" height="15px" />
                )}
              </b>
            </Balance>
          </BalanceInfo>
        </BalanceWrapper>
      ),
    [balance, autoPaymentInfo, t, phoneConnections],
  );

  return (
    <Wrapper>
      <Suspense fallback="">
        {hasBoardData
        || !isEmpty(context?.searchKey)
        || context?.affiliation !== AffiliationPhoneType.ALL ? (
          <>
            <ControlsContainer>
              {RenderBalance}
              <DashboardActionsMenu
                onSelectAll={(allSelected) => {
                  const ids = allSelected
                    ? null
                    : phoneConnections?.map((item) =>
                      item?.id);

                  context?.setSelectedPhones(ids);
                }}
                hasPhones={
                  phoneConnections != null
                  && phoneConnections?.length
                  && !loadingPhones
                }
                onOnlineOnly={(e) => {
                  setShowOnlyOnline(e);
                }}
                hasSelectedItems={
                  context?.selectedPhones != null
                  && context?.selectedPhones?.length
                }
                selectedPhones={context?.selectedPhones}
                onClear={() =>
                  context?.setSelectedPhones(null)}
              />
            </ControlsContainer>

            <div className="row">
              <SystemAlertComponent />
            </div>

            {profile?.emailVerified != null && !profile?.emailVerified ? (
              <div className="row">
                <Attention>
                  {t('dashboard.emailVerification')}
                  {' '}
                  <Link
                    href={telegramSupport}
                    target={TARGET_BLANK}
                    rel={LINK_REL}
                  >
                    {t('onboarding.email.sendEmailAgain')}
                  </Link>
                </Attention>
              </div>
            ) : null}

            <div className="row filters">
              <ConnectionSearch
                onChange={(value) => {
                  context?.setSearchKey(value);
                }}
              />
            </div>
          </>
          ) : null}

        {loadingPhones ? (
          renderPhonesLoader()
        ) : (
          <>
            {context?.groups != null ? (
              <GroupTabs>
                {context?.gridView && (
                  <div
                    className={`group-title ${
                      context?.allGridView ? 'active' : ''
                    }`}
                    onClick={() =>
                      context?.setAllGridView(!context?.allGridView)}
                  >
                    {t('stats.allPorts')}
                  </div>
                )}
                {!!phoneConnections?.length
                  && context?.groups.map(({ key }) =>
                    (
                      <Highlighter
                        highlightClassName="highlight"
                        className={`group-title ${
                          key === context?.groupKey && !context?.allGridView
                            ? 'active'
                            : ''
                        }`}
                        searchWords={
                        context?.otherSearchKeyGroups?.includes(key)
                          ? [key !== NON_GROUP ? key : t('connection.noGroup')]
                          : []
                      }
                        autoEscape
                        textToHighlight={
                        key !== NON_GROUP ? key : t('connection.noGroup')
                      }
                        onClick={() => {
                          context?.setAllGridView(false);
                          context?.setGroupKey(key);
                        }}
                        key={key}
                      />
                    ))}
              </GroupTabs>
            ) : null}
            {!hasBoardData ? (
              !isEmpty(context?.searchKey)
              || (context?.affiliation !== AffiliationPhoneType.ALL
                && phoneConnections != null
                && phoneConnections?.length) ? (
                  <div className="search-empty">Nothing was found</div>
                ) : (
                  <WelcomePanel />
                )
            ) : (
              <PhonesView
                dashboardConfigs={dashboardConfigs}
                showOnlyOnline={showOnlyOnline}
              />
            )}
          </>
        )}

        <TooltipElement />
      </Suspense>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .search-empty {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    text-align: center;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  }

  .group-title {
    padding: 4px 8px;
    cursor: pointer;
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
    border-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    border: 1px solid ${(props) =>
    props.theme.colors.deepBlue};
    text-align: center;

    color: ${(props) =>
    props.theme.colors.deepBlue};

    .highlight {
      color: ${(props) =>
    props.theme.colors.deepBlue};
      background-color: unset;
    }

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
    }
  }

  .group-title:has(> mark) {
    background-color: yellow;
  }

  .active {
    background-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue} !important;
    color: ${(props: UIProps) =>
    props.theme.colors.white};

    .highlight {
      color: yellow;
      font-weight: 700;
    }
  }
`;

const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: block;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const LoaderPreset = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .cont {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const BalanceWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  }
`;

const BalanceInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
  }
`;

const Balance = styled.div`
  ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
  color: ${(prop: UIProps) =>
    prop.theme.colors.darkGray};
  margin-right: 40px;

  &:last-child {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    display: flex;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }

  .span {
    display: block;
    margin-top: 6px;

    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    font-weight: 700;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }
  }

  .value {
    :hover {
      cursor: pointer;
    }
  }
`;

const Link = styled.a`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  box-sizing: content-box;
  transition: 0.3s;
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const GroupTabs = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 5px;
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.spaceGray};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.corol};
    cursor: pointer;
  }
`;
